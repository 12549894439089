import { makeStyles } from "@material-ui/core/styles";

export const AppControlStyles = makeStyles((theme) => ({
    details: {
        display: "flex",
        flexDirection: "column",
        color: "#7b7b7b",
    },
    media: {
        maxWidth: "10em",
    },
    imgCardMedia: {
        width: "auto",
        height: "3.5em",
        float: "left",
    },

    fit: {
        maxWidth: "fit-content",
    },

    remarcar: {
        fontWeight: "bold !important",
        padding: "1.2em 0.5em 0em 0em",
        marginLeft :"1em",
        textAlign: 'justify',
    },

    card: {
        marginTop: "3em",
    },
    
}));
