import React, { useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  IconButton,
} from "@material-ui/core";
import { FaBuilding, FaUser, FaUsers } from "react-icons/fa";
import { AtcSelectTypePersonStyles } from "./AtcSelectTypePersonStyles";
import { selectedPersonType } from "../../../commons/Enums";
import { useTranslation } from "react-i18next";
import { CommonStyles } from "../../../commons/CommonStyles";

export function AtcSelectTypePerson(props) {
  const { changeStep, setPersonType, appType } = props;
  const { t, i18n } = useTranslation();
  const commonClasses = CommonStyles();
  const classes = AtcSelectTypePersonStyles();

  useEffect(() => { }, []);

  const handleNext = (value) => {
    setPersonType(value);
    changeStep(3, 1);
  };

  return (
    <Grid>
      <Grid styles={{ margin: "auto" }}>
        <Container maxWidth={"lg"} xs={4}>
          <Grid container item xs={12} style={{ justifyContent: "center" }}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              style={{ marginBottom: "3vh" }}
            >
              <Typography
                variant="h4"
                className={commonClasses.dialogTitleText}
              >
                {t("atcSelectPersonTypeTitle")}
              </Typography>
            </Grid>
            {appType === "AMBOS" || appType === "PARTICULAR" ? (
              <Grid xs={4}>
                <Card className={classes.cardButton}>
                  <CardContent>
                    <IconButton>
                      <FaUser
                        className={classes.iconStyle}
                        onClick={() =>
                          handleNext(selectedPersonType.PARTICULAR)
                        }
                      />
                    </IconButton>
                    <Typography className={classes.cardTextStyle}>
                      {t("registerParticularType")}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

            {appType === "AMBOS" || appType === "EMPRESA" ? (
              <Grid xs={4}>
                <Card className={classes.cardButton}>
                  <CardContent>
                    <IconButton>
                      <FaBuilding
                        className={classes.iconStyle}
                        onClick={() => handleNext(selectedPersonType.EMPRESA)}
                      />
                    </IconButton>
                    <Typography className={classes.cardTextStyle}>
                      {t("registerEnterpriseType")}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}

            {appType === "AMBOS" || appType === "EMPRESA" ? (
              <Grid xs={4}>
                <Card className={classes.cardButton}>
                  <CardContent>
                    <IconButton>
                      <FaUsers
                        className={classes.iconStyle}
                        onClick={() =>
                          handleNext(selectedPersonType.AUTONOMO)
                        }
                      />
                    </IconButton>
                    <Typography className={classes.cardTextStyle}>
                      {t("registerFreelanceType")}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}
