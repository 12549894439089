import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import { MainPageStyles } from "./MainPageStyles";
import { Main } from "../../components/Main/Main";
import { useParams } from "react-router-dom";
import { CorreosAlert } from "../../components/CorreosAlert/CorreosAlert";
import { EnumPages, RolLevelPages, RolLevels } from "../../commons/Enums";
import { Header } from "./Components/Header";
import { getUserGlobalRoles } from "../../services/Commons";
import { ActivatedEndpoints } from "../../components/ActivatedEndpoints/ActivatedEndpoints";
import { ServiceList } from "../../components/ServiceList/ServiceList";
import { AppControl } from "../../components/AppControl/AppControl";
import { Roles } from "../../components/Roles/Roles";
import { Atc } from "../../components/Atc/Atc";
import { Rgpd } from "../../components/Rgpd/Rgpd";
import { AdvertisingCancellations } from "../../components/AdvertisingCancellations/AdvertisingCancellations";
import { ApplicationModification } from "../../components/ApplicationModification/ApplicationModification";
import { UserInfo } from "../../components/UserInfo/UserInfo";
import { CircularProgress, Container, CssBaseline, Grid } from "@material-ui/core";
import { UsersExport } from "../../components/UsersExport/UsersExport";
import { ApplicationAdmin } from "../../components/ApplicationAdmin/ApplicationAdmin";
import { UserDelete } from "../../components/UserDelete/UserDelete";
import { GetPermissionGroups } from "../../services/ReasonService";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { SideMenu } from "./Components/SideMenu";
import { Constants } from "../../commons/Constants";
import { SystemUserAdmin } from "../../components/SystemUserAdmin/SystemUserAdmin";
import { UserAdmin } from "../../components/UserAdmin/UserAdmin";

export function MainPage() {
  const { idPage } = useParams();

  const [circularProgress, setCircularProgress] = useState(true);
  const [subApplication, setSubApplication] = useState(null);
  const [showAppsSelect, setShowAppsSelect] = useState(false);
  const [appsSelectOptions, setAppsSelectOptions] = useState([]);
  const [applicationName, setApplicationName] = useState("");
  const [selectedApp, setSelectedApp] = useState({});
  const [allApps, setAllApps] = useState(false);
  const [userGroupActions, setUserGroupActions] = useState([]);
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [userGlobalRoles, setUserGlobalRoles] = useState([]);
  const [selectedRolAndLevel, setSelectedRolAndLevel] = useState({});
  const [availablePages, setAvailablePages] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });

  const theme = useTheme();
  const classes = MainPageStyles(theme, openSideMenu);

  useEffect(() => {
    getUserCapacityInfo();
  }, []);

  const getUserCapacityInfo = () => {
    GetPermissionGroups().then((response) => {
      if (response && !response.Status) {
        sessionStorage.setItem("UserGroupActions", response);
        setUserGroupActions(response);
        let auxUserGlobalRoles = getUserGlobalRoles();
        fillRolLevels(auxUserGlobalRoles);
        setUserGlobalRoles(auxUserGlobalRoles);
        getAvailablePages(auxUserGlobalRoles);
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const fillRolLevels = (subAppsAndRoles) => {
    let auxRolesLevels = [RolLevels.N5, RolLevels.N4, RolLevels.N3, RolLevels.N2, RolLevels.N1];
    let auxFlag = false;
    subAppsAndRoles.forEach((rolBlock) => {
      let auxRoles = [];
      let auxSubApps = rolBlock.roles[0].apps;
      let auxRolesArray = rolBlock.roles.map((rolLevel) => rolLevel.level);
      auxRolesLevels.forEach((rolLevel) => {
        if (auxFlag && !checkIsNullUndefined(RolLevelPages[rolBlock.rolCode][rolLevel])) {
          auxRoles.push({
            level: rolLevel,
            apps: auxSubApps
          })
        } else if (auxRolesArray.includes(rolLevel)) {
          auxFlag = true;
          auxRoles.push({
            level: rolLevel,
            apps: auxSubApps
          })
        }
      });
      rolBlock.roles = auxRoles;
    })
  };

  const getAvailablePages = (auxUserGlobalRoles) => {
    let auxPages = [];
    auxUserGlobalRoles.forEach((rol) => {
      rol.roles.forEach((level) => {
        let levelPages = RolLevelPages[rol.rolCode][level.level];
        levelPages.forEach((page) => {
          auxPages.push(page);
        });
      });
    });
    auxPages = [...new Set(auxPages)];
    setAvailablePages(auxPages);
    setShowSideMenu(auxPages.length > 1);
  }

  const handleShowAppsSelect = (isShow) => {
    if (idPage == EnumPages.ApplicationAdmin) {
      setAllApps(true);
    } else {
      setAllApps(false);
    }
    setShowAppsSelect(isShow);
  }

  const handleSetAppsSelectOptions = (auxRolCode, auxRolLevel) => {
    setSelectedRolAndLevel({ rol: auxRolCode, level: auxRolLevel });
    let auxRol = userGlobalRoles.find((rol) => rol.rolCode == auxRolCode);
    let auxRolInfo = auxRol.roles.find((rolInfo) => rolInfo.level == auxRolLevel);
    if (checkIsNullUndefined(auxRolInfo)) {
      auxRolInfo = { apps: [] };
    }else if(checkIsNullUndefined(auxRolInfo.apps)){
      auxRolInfo.apps = [];
    };
    setAppsSelectOptions(auxRolInfo.apps);
    let auxSelectedApp = {};
    if (!checkIsNullUndefined(auxRolInfo.apps) && auxRolInfo.apps.length != 0) {
      auxSelectedApp = auxRolInfo.apps[0];
    };
    setSelectedApp(auxSelectedApp);
  }

  const handleApplicationName = (appName) => {
    setApplicationName(appName);
  }

  const handleSideMenu = (flag) => {
    let auxFlag = checkIsNullUndefined(flag) ? !openSideMenu : flag;
    setOpenSideMenu(auxFlag);
  };

  const renderPage = () => {
    switch (idPage) {
      case EnumPages.Main:
        return <Main availablePages={availablePages} />;

      //NO SE USA
      case EnumPages.Roles:
        return <Roles
          subApplication={subApplication}
        // handleSubApplication={handleSubApplication}
        />;

      //NO SE USA
      case EnumPages.Endpoints:
        return <ActivatedEndpoints
          subApplication={subApplication}
          // handleSubApplication={handleSubApplication}
          handleShowAppsSelect={handleShowAppsSelect}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          appCode={selectedApp.value}
        />;

      //NO SE USA
      case EnumPages.AppControl:
        return <AppControl
          subApplication={subApplication}
          // handleSubApplication={handleSubApplication}
          handleShowAppsSelect={handleShowAppsSelect}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
        />;

      //NO SE USA
      case EnumPages.Services:
        return <ServiceList
          subApplication={subApplication}
        // handleSubApplication={handleSubApplication}
        />;

      case EnumPages.Atc:
        return <Atc
          subApplication={subApplication}
          // handleSubApplication={handleSubApplication}
          handleApplicationName={handleApplicationName}
        />;

      //NO SE USA
      case EnumPages.Rgpds:
        return <Rgpd
          subApplication={subApplication}
        // handleSubApplication={handleSubApplication}
        />;

      case EnumPages.AdvertisingCancellations:
        return <AdvertisingCancellations
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
        />;

      case EnumPages.UsersExport:
        return <UsersExport
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
          appCode={selectedApp.value}
        />;

      case EnumPages.ApplicationModification:
        return <ApplicationModification
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
        />;

      case EnumPages.UserInfo:
        return <UserInfo
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
        />;

      case EnumPages.ApplicationAdmin:
        return <ApplicationAdmin
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
          selectedApp={selectedApp}
        />

      case EnumPages.UserDelete:
        return <UserDelete
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
        />;

      case EnumPages.SystemUserAdmin:
        return <SystemUserAdmin
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
          theme={theme}
        />

      case EnumPages.UserAdmin:
        return <UserAdmin
          finishOperation={finishOperation}
          userGroupActions={userGroupActions}
          handleSetAppsSelectOptions={handleSetAppsSelectOptions}
          handleShowAppsSelect={handleShowAppsSelect}
        />;

      default:
        return <Main availablePages={availablePages} />;
    }
  };

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <CorreosAlert alert={alert} setAlert={setAlert}></CorreosAlert>
      <Header
        selectedRolAndLevel={selectedRolAndLevel}
        setSelectedRolAndLevel={setSelectedRolAndLevel}
        showAppsSelect={showAppsSelect}
        setShowAppsSelect={setShowAppsSelect}
        appsSelectOptions={appsSelectOptions}
        applicationName={applicationName}
        appCode={selectedApp.value}
        setSelectedApp={setSelectedApp}
        allApps={allApps}
        theme={theme}
        handleSideMenu={handleSideMenu}
      />
      {showSideMenu && idPage != EnumPages.Main && idPage != EnumPages.Atc ? (
        <SideMenu
          theme={theme}
          open={openSideMenu}
          handleSideMenu={handleSideMenu}
          selectedPage={idPage}
          availablePages={availablePages}
        />
      ) : null}
      <Grid className={openSideMenu ? classes.contentOpen : classes.content}>
        {circularProgress ? (
          <CircularProgress className={classes.generalContainer} />
        ) : (
          <Grid className={classes.generalContainer}>{renderPage()}</Grid>
        )}
      </Grid>
    </Container>
  );
}
