import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextareaAutosize,
  CircularProgress
} from "@material-ui/core";
import {
  PostGetPaginatedUsersRgpd,
  PostUnacceptRgpds,
} from "../../services/ReasonService";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { AdvertisingCancellationsStyles } from "./AdvertisingCancellationsStyles"
import EnhancedTable from "./EnhancedTable/EnhancedTable"
import { RolLevels, SubApp } from "../../commons/Enums";

export function AdvertisingCancellations(props) {

  const { finishOperation, userGroupActions, handleSetAppsSelectOptions, handleShowAppsSelect } = props;

  const [circularProgress, setCircularProgress] = useState(false);
  const [disableButtonSearch, setDisableButtonSearch] = useState(true);
  const [idList, setIdList] = useState("");
  const [endpointInput, setEndpointInput] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [renderTable, setRenderTable] = useState(false);


  const commonClasses = CommonStyles();
  const classes = AdvertisingCancellationsStyles();

  useEffect(() => {
    handleSetAppsSelectOptions(SubApp.LEG, RolLevels.N1);
    handleShowAppsSelect(false);
  }, []);

  useEffect(() => {
    if (checkIsNullUndefined(idList) || idList == "") {
      setDisableButtonSearch(true);
    } else {
      setDisableButtonSearch(false);
    }
  }, [idList]);

  useEffect(() => {

    if (!checkIsNullUndefined(endpointInput)) {
      PostGetPaginatedUsersRgpd(endpointInput).then((response) => {

        if (!response || response.Status) {
          finishOperation("error", getErrorMessage(response));
        } else {
          setUsersData(response.data);
          setRenderTable(true);
        }
        setCircularProgress(false);
      });
    };

  }, [endpointInput]);

  const handleManageDataFromInput = () => {
    let auxValue = idList.trim();
    auxValue = auxValue.replaceAll(/[\n\r]/g, ';');
    let identifiers = auxValue.split(";");
    let data = [];
    identifiers.forEach((elem) => {
      data.push(elem);
    });
    setEndpointInput(data);

  }

  const _handleInputChange = (e) => {
    let value = e.target.value;
    setIdList(value);

  };

  const _handleSearch = () => {
    setCircularProgress(true);
    setRenderTable(false);
    handleManageDataFromInput(idList);

  };

  const _handleUnacceptRgpds = (selecteds) => {
    setRenderTable(false);
    setCircularProgress(true);
    PostUnacceptRgpds(selecteds).then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
      } else {
        _handleSearch();
      }
      setCircularProgress(false);
      setRenderTable(true);
    });

  };

  return (
    <Grid container xs={12}>
      <Grid item
        xs={12} className={classes.marginBottom}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {spanishLanguage["advcTitle"]}
        </Typography>
        <Typography
          align="center"
          className={commonClasses.hitText}
          style={{ marginTop: "2em" }}
        >
          {spanishLanguage["advcSubTitle"]}
        </Typography>
        <Typography
          align="center"
          className={commonClasses.hitText}
        >
          {spanishLanguage["advcSubTitle2"]}
        </Typography>
      </Grid>

      {userGroupActions.includes("LGP") ?
        <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
          <Grid item xs={12} >
            <TextareaAutosize
              className={classes.textArea}
              minRows={4}
              maxRows={4}
              placeholder={spanishLanguage["advcTextareaPH"]}
              onChange={_handleInputChange}
            />
          </Grid>
          <Grid item xs={0} md={2} style={{ marginTop: "2em" }} />
          <Grid item xs={12} md={8} style={{ marginTop: "2em" }} >
            <CorreosButton
              variant="contained"
              color="primary"
              onClick={() => _handleSearch()}
              disabled={disableButtonSearch}
            >
              {spanishLanguage["search"]}
            </CorreosButton>

          </Grid>
          <Grid item xs={12} md={2} style={{ marginTop: "2em" }} >
            <a href="files\AdvertisingCancellations\ejemplo.xlsx" download>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => { }}
                className={commonClasses.rightButton}
              >
                {spanishLanguage["advcDownload"]}
              </CorreosButton>
            </a>
          </Grid>
        </Grid>
        : null}
      <Grid container item xs={12} justifyContent="center" style={{ marginBottom: "2em" }}>

        {circularProgress ? <CircularProgress /> : null}
        {renderTable ?
          <EnhancedTable
            data={usersData}
            handleUnacceptRgpds={_handleUnacceptRgpds}
            UserGroupActions={userGroupActions}
          />
          : null}

      </Grid>

    </Grid>
  );
}