import { makeStyles } from '@material-ui/core/styles';

export const AtcUserSearcherStyles = makeStyles((theme) => ({
  buttonSearch: {
        marginLeft: "1em",
        marginRight: "1em",
        height: "2.5em",
        marginTop: "0.75em",
        float: "center"
    },
    buttonNewAcc: {
      float: "right",
      marginTop:"5em",  
      [theme.breakpoints.down("sm")]: {
        float: "inherit",
      },
    },
    gridIdentity: {
        display: "flex",
        // margin: "auto",
    },/*
    formSelectorControl: {
        minWidth: "10.5vh",
        marginRight: "0.5vh",
        "& .MuiFilledInput-underline": {
          "&:before": {
            border: "1px solid #FFCD00",
          },
          "&:hover:before": {
            border: "2px solid #FFCD00",
          },
          "&:after": {
            border: "2px solid #002453",
          },
        },
    },*/
    textFieldRow: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    hitText: {
      fontSize: "1.1rem",
      marginBottom: "0.5em",
      marginTop: "0.5em",
      color: "grey",
    },
    searchIcon: {
      marginRight: "0.2em"
    }
}));
