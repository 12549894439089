
import React, { useEffect, useState } from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { UserData } from "../../UserData/UserData";
import { backToLogin } from "../../../services/Commons";

export function AtcUserProfile(props) {
  const commonClasses = CommonStyles();
  const {
    changeStep,
    groupActions,
    pathType,
    registryData,
    moveToApp,
    appType,
  } = props;

  const [allOk, setAllOk] = useState(false);
  const [errorTypeDocument, setErrorTypeDocument] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {}, []);

  const handleNext = (value) => {
    setNext(value);
  };

  return (
    <Container>
      <Grid
        style={{ margin: "0 auto" }}
        container
        item
        justifyContent="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12}>
          <Typography
            color="secondary"
            align="center"
            className={commonClasses.hitText}
          >
            {spanishLanguage["atcUserDataHint"]}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <UserData
            setAllOk={setAllOk}
            setErrorTypeDocument={setErrorTypeDocument}
            errorTypeDocument={errorTypeDocument}
            next={next}
            handleNext={handleNext}
            changeStep={changeStep}
            groupActions={groupActions}
            pathType={pathType}
            registryData={registryData}
            moveToApp={moveToApp}
            appType={appType}
          ></UserData>
        </Grid>
      </Grid>

      <Grid
        style={{ margin: "auto" }}
        container
        item
        justifyContent="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={6} style={{ marginTop: "3vh" }}>
          <CorreosButton
            onClick={() => backToLogin()}
            className={commonClasses.leftButton}
          >
            {spanishLanguage["atcCancel"]}
          </CorreosButton>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "3vh" }}>
          <CorreosButton
            circularProgress={next}
            variant="contained"
            color="primary"
            onClick={() => handleNext(true)}
            className={commonClasses.rightButton}
            disabled={!allOk || errorTypeDocument}
          >
            {spanishLanguage["save"]}
          </CorreosButton>
        </Grid>
      </Grid>
    </Container>
  );
}
