import { makeStyles } from "@material-ui/core/styles";

export const AtcLegalDialogStyles = makeStyles((theme) => ({

  //Dialogs
  dialogWindow: {
    margin: "auto",
    padding: "1.5em 2em 1em 2em",
  },
  agreeButtons: {
    marginBottom: "2em",
  },
  flagIcon: {
    width: "30px",
    height:" 20px",
    marginRight: "10px",
  }


}));
