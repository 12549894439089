import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  Button,
  TextField,
  CircularProgress
} from "@material-ui/core";

import { CommonStyles } from "../../../commons/CommonStyles";
import { checkIsNullUndefined } from "../../../services/Utilities";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { DialogStyles } from "./DialogStyles";
import { ImagesResponseModel, LanguageResponseModel, NotifierUrlsResponseModel } from "../ApplicationModificationObjects";

export function DialogShowImage(props) {
  const { open, setOpen, title, action, closeAction, image, circularProgress } = props;
  const [auxModel, setAuxModel] = useState(null);
  const [error, setError] = useState(false);
  const commonClasses = CommonStyles();
  const classes = DialogStyles();

  const handleCancel = () => {
    if (!checkIsNullUndefined(closeAction)) {      
      closeAction();
    }
    setOpen(false);
  };

  const handleConfirm = (auxModel) => {    
    handleCancel();    
  };
  
  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleCancel}
      PaperProps={{ classes: { root: classes.dialogWindow } }}
    >
      <Container className={classes.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={commonClasses.dialogTitleText}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
        <Grid item xs={12}>
            <a href={image} target="_blank" rel="noopener noreferrer">
              <img src={image} alt="Image" className={classes.image} />
            </a>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              <Button
                size="small"
                onClick={handleCancel}
                className={commonClasses.dialogButtonLeft}
              >
                {spanishLanguage["atcClose"]}
              </Button>
            </Grid>           
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
