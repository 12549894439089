import { makeStyles } from "@material-ui/core/styles";

export const SystemUserAdminStyles = makeStyles((theme) => ({
  searchButton: {
    float: "center",
    marginLeft: "2em"
  },
  tableRow: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
  },
  tableCell: {
    padding: "0.5em 1em",
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
    color: "#002453",
  },
  tableCellText: {
    display: "block",
    maxWidth: "12em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "auto",
  },
  tableCellHeader: {
    fontSize: "1.25em",
    padding: "0.5em 1em",
    border: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
    color: "#002453",
    height: "2em",
  },
  selectedTableRow: {
    backgroundColor: '#002453',
  },
  customPaper: {
    width: "100%",
    overflow: "auto",
    overflowY: "auto",
    border: "1px solid #ccc",
    borderRadius: "0.25em",
    boxShadow: "0 0.125em 0.25em rgba(0, 0, 0, 0.1)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  stickyTableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "#ffcd00",
    opacity: 1,
    zIndex: 2,
  },
  customTableRow: {
    borderCollapse: "separate"
  },
  customTableLeft: {
    paddingRight: "1em",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  customTableRight: {
    paddingLeft: "1em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      marginTop: "1em",
    },
  },
}));
