import React, { useEffect, useState } from "react";
import {
    TextField,
    Grid,
} from "@material-ui/core";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import {
    GetMasterCountry,
    PostProfileUserProfileUserByIdentification,
} from "../../services/ReasonService";
import { useTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import { PrefixPhone } from "../TransversalComponents/PrefixPhone/PrefixPhone";
import {
    checkIsNullUndefined,
    getPhonePrefixCountry,
} from "../../services/Utilities";
import { EnumCountries } from "../../commons/Enums";

export function UserSearcher(props) {
    const {
        countryList,
        handleSetUser,
    } = props;
    const validator = require("react-email-validator");
    const { t, i18n } = useTranslation();

    const [identifier, setIdentifier] = useState("");
    const [identifierType, setIdentifierType] = useState("");
    const [disableButtonNext, setDisableButtonNext] = useState(true);
    const [showPrefixes, setShowPrefixes] = useState(false);
    const [prefixPhoneCode, setPrefixPhoneCode] = useState(EnumCountries.ESP);
    const [idSearcherError, setIdSearcherError] = useState(false);
    const [idSearcherErrorText, setIdSearcherErrorText] = useState("");
    const [ownCountryList, setOwnCountryList] = useState([]);

    useEffect(() => {
        if (checkIsNullUndefined(countryList) || countryList.length === 0) {
            GetMasterCountry().then((response) => {
                if (response && !response.Status) {
                    setOwnCountryList(response);
                };
            });
        } else {
            setOwnCountryList(countryList);
        };
    }, [countryList]);

    // controla el cambio en el selector de prefijo
    const handleSelectorChange = (country) => {
        setPrefixPhoneCode(country.code);
    };

    // Cambia al siguiente paso y guarda los datos necesarios para el siguiente paso en "props"
    const handleNext = () => {
        let identifierAux = identifier;

        if (identifierType === "userPhone") {
            let country = getPhonePrefixCountry(ownCountryList, prefixPhoneCode, "code");
            identifierAux = country.phonePrefix + "-" + identifier;
        };

        PostProfileUserProfileUserByIdentification(identifierAux).then(
            (response) => {
                if ((response && !response.Status) && response.length > 0) {
                    handleSetUser(response, identifier, identifierType);
                } else {
                    setIdSearcherError(true);
                    setIdSearcherErrorText(t("genMsg_noUser"));
                };
            }
        );
    };

    // Identifica el tipo de identificador introducido (email, dni/nie, teléfono) y habilita el botón cuando el valor sea válido
    const handleTypeOfIdentifier = (e) => {
        let auxShowPrefixes = false;
        let auxIdentifierType = "";
        let value = e.target.value.trim();
        let auxDisableButton = true;
        const checkDni = /^\d{8}[a-zA-Z]$/i;
        const checkNie = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
        const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

        if (validator.validate(value)) {
            auxIdentifierType = "userEmail";
            auxDisableButton = false;
        } else if (checkPhoneNumber.test(value)) {
            // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
            auxShowPrefixes = true;
            if (value.length >= 9) {
                auxIdentifierType = "userPhone";
                auxDisableButton = false;
            };
        } else if (checkDni.test(value) || checkNie.test(value)) {
            auxIdentifierType = "userDocument";
            auxDisableButton = false;
        };

        setIdSearcherError(false);
        setIdentifier(value);
        setIdentifierType(auxIdentifierType);
        setShowPrefixes(auxShowPrefixes);
        setDisableButtonNext(auxDisableButton);
    };

    return (
        <Grid
            container
            item
            justifyContent="center"
            xs={12}
        >
            <Grid
                container
                item
                xs={12}
                spacing={1}
            >
                {showPrefixes ? (
                    <Grid item sm={3} xs={12}>
                        <PrefixPhone
                            countryList={ownCountryList}
                            handleSelectorChange={handleSelectorChange}
                        />
                    </Grid>
                ) : null}
                <Grid
                    item
                    sm={showPrefixes ? 6 : 9}
                    xs={12}
                >
                    <TextField
                        variant="outlined"
                        fullWidth
                        id="idSearcher"
                        label={t("genLabel_userIdentity")}
                        name="idSearcher"
                        color="secondary"
                        required={true}
                        onChange={handleTypeOfIdentifier}
                        value={identifier}
                        error={idSearcherError}
                        helperText={idSearcherError ? idSearcherErrorText : ""}
                    />
                </Grid>
                <Grid item sm={3} xs={12} style={{ margin: 'auto' }}>
                    <CorreosButton
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        style={{ float: "center", width: "100%" }}
                        disabled={disableButtonNext}
                    >
                        <SearchIcon style={{ marginRight: "0.2em" }} />
                        {t("search")}
                    </CorreosButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
