import { makeStyles } from "@material-ui/core/styles";

export const MainStyles = makeStyles((theme) => ({
  cardButton: {
    width: "100%"
  },
  cardStyle: {
    width: "100%",
    height: "17em",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      height: "7em",
    }
  },
  iconStyle: {
    width: "6em",
    height: "6em",
    margin: "auto",
    color: "#002453",
    [theme.breakpoints.down("sm")]: {
      height: "3em",
    }
  },
  cardTextStyle: {
    color: "#002453",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "100%",
    }
  },

  cardTextBoxStyle: {
    [theme.breakpoints.down("sm")]: {
      display: "contents"
    }
  },

  mainContainer: {
    margin: "auto"
  },

  mainContainerTitle: {
    color: "#002453",
    margin: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      margin: "7px"
    }
  },

  centerContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "90%",
    }
  }
}));
