import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  Button,
  TextField,
  CircularProgress
} from "@material-ui/core";

import { CommonStyles } from "../../../commons/CommonStyles";
import { checkIsNullUndefined } from "../../../services/Utilities";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { DialogStyles } from "./DialogStyles";
import { ImagesResponseModel, LanguageResponseModel, NotifierUrlsResponseModel } from "../ApplicationModificationObjects";

export function DialogUpdateApplicationData(props) {
  const { open, setOpen, title, action, closeAction, auxFunction, model, circularProgress } = props;
  const [auxModel, setAuxModel] = useState(null);
  const [error, setError] = useState(false);
  const commonClasses = CommonStyles();
  const classes = DialogStyles();



  useEffect(() => {
    setAuxModel(model);
  }, [model, open]);


  const handleCancel = () => {
    if (!checkIsNullUndefined(closeAction)) {
      setAuxModel(model);
      closeAction();
    }
    setOpen(false);
  };

  const handleConfirm = (auxModel) => {
    if (auxModel !== model) {
      action(auxModel);
      handleCancel();
    } else {
      handleCancel()
    }

  };

  const compareObjects = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }
    return keys1.every(key => keys2.includes(key));
  }

  return (
    <Dialog
      maxWidth={"md"}
      open={open}
      onClose={handleCancel}
      PaperProps={{ classes: { root: classes.dialogWindow } }}
    >
      <Container className={classes.dialogTitle}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              className={commonClasses.dialogTitleText}
            >
              {title}
            </Typography>
          </Grid>

        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>


          {/* Generar inputs cuando el modelo es de tipo LanguageResponseModel*/}
          {auxModel && compareObjects(auxModel, LanguageResponseModel) ? (
            Object.entries(auxModel).map(([key, value]) => {
              const handleChange = (e) => {
                const updatedModel = { ...auxModel, [key]: e.target.value };
                let err = error; // Mantener el estado actual del error

                if (key === "es" && e.target.value === "") {
                  err = true;
                } else if (key === "es" && e.target.value !== "") {
                  err = false;
                }

                setError(err); // Establecer el estado de error

                setAuxModel(updatedModel);
              };

              return (
                <Grid item xs={12}>
                  <TextField
                    value={auxModel[key]}
                    variant="outlined"
                    fullWidth
                    name="textbox"
                    color="secondary"
                    onChange={handleChange}
                    label={auxFunction(key)}
                    required={key === "es"}
                    error={error && (key === "es" ? auxModel[key] === "" : false)} // Usar el estado de error general
                    helperText={key === "es" && auxModel[key] === "" ? spanishLanguage["amodRequiredFieldText"] : ""}
                    disabled={false}
                  />
                </Grid>
              );
            })
          ) : null}
          {/* Generar inputs cuando el modelo es de tipo ImagesResponseModel*/}
          {auxModel && compareObjects(auxModel, ImagesResponseModel) ? (
            Object.entries(auxModel).map(([key, value]) => {

              const handleChange = (e) => {
                const updatedModel = { ...auxModel, [key]: e.target.value };
                setAuxModel(updatedModel);
              };

              return (
                <Grid item xs={12}>
                  <TextField
                    value={auxModel[key]}
                    variant="outlined"
                    fullWidth
                    name="textbox"
                    color="secondary"
                    onChange={handleChange}
                    label={auxFunction(key)}
                    required={false}
                    error={error}
                    disabled={false}
                  />
                </Grid>
              );
            })
          ) : null}
          {/* Generar inputs cuando el modelo es una string*/}
          {(typeof auxModel === 'string') ? (
            <Grid item xs={12}>
              <TextField
                value={auxModel}
                variant="outlined"
                fullWidth
                name="textbox"
                color="secondary"
                onChange={(e) => setAuxModel(e.target.value)}
                label={auxFunction('urlRedireccion')}
                required={false}
                error={error}
                disabled={false}
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
          ) : null}
          {/* Generar inputs cuando el modelo es de tipo NotifierUrlsResponseModel*/}
          {auxModel && compareObjects(auxModel, NotifierUrlsResponseModel) ? (
            Object.entries(auxModel).map(([key, value]) => {

              const handleChange = (e) => {
                let updatedModel = { ...auxModel };

                if (key === "userNotifier") {
                  updatedModel = {
                    ...updatedModel,
                    userNotifier: {
                      ...updatedModel.userNotifier,
                      [e.target.name]: e.target.value
                    }
                  };
                } else if (key === "enterpriseNotifier") {
                  updatedModel = {
                    ...updatedModel,
                    enterpriseNotifier: {
                      ...updatedModel.enterpriseNotifier,
                      [e.target.name]: e.target.value
                    }
                  };
                } else {
                  updatedModel = { ...updatedModel, [key]: e.target.value };
                }

                setAuxModel(updatedModel);
              };

              if (value != null) {
                if (key === "userNotifier") {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.subTitle}>
                          {spanishLanguage["amodUserNotifierText"]}
                        </Typography>
                      </Grid>
                      {Object.entries(value).map(([nestedKey, nestedValue]) => (
                        <Grid item xs={12} key={nestedKey}>
                          <TextField
                            value={auxModel["userNotifier"][nestedKey]}
                            variant="outlined"
                            fullWidth
                            name={nestedKey}
                            color="secondary"
                            onChange={handleChange}
                            label={auxFunction(nestedKey)}
                            required={false}
                            error={error}
                            disabled={false}
                            inputProps={{ maxLength: 1000 }}
                          />
                        </Grid>
                      ))}
                    </>
                  );
                } else if (key === "enterpriseNotifier") {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.subTitle}>
                          {spanishLanguage["amodEnterpriseNotifierText"]}
                        </Typography>
                      </Grid>
                      {Object.entries(value).map(([nestedKey, nestedValue]) => (
                        <Grid item xs={12} key={nestedKey}>
                          <TextField
                            value={auxModel["enterpriseNotifier"][nestedKey]}
                            variant="outlined"
                            fullWidth
                            name={nestedKey}
                            color="secondary"
                            onChange={handleChange}
                            label={auxFunction(nestedKey)}
                            required={false}
                            error={error}
                            disabled={false}
                            inputProps={{ maxLength: 1000 }}
                          />
                        </Grid>
                      ))}
                    </>
                  );
                } else {
                  return (
                    <Grid item xs={12} key={key}>
                      <TextField
                        value={auxModel[key]}
                        variant="outlined"
                        fullWidth
                        name={key}
                        color="secondary"
                        onChange={handleChange}
                        label={auxFunction(key)}
                        required={false}
                        error={error}
                        disabled={false}
                        inputProps={{ maxLength: 500 }}
                      />
                    </Grid>
                  );
                }
              }
            })
          ) : null}



          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              <Button
                size="small"
                onClick={handleCancel}
                className={commonClasses.dialogButtonLeft}
              >
                {spanishLanguage["cancel"]}
              </Button>
            </Grid>
            <Grid item xs={6}>
              {circularProgress ? (
                <CircularProgress className={commonClasses.dialogButtonRight} />
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm(auxModel)}
                  className={commonClasses.dialogButtonRight}
                  disabled={error}
                >
                  {spanishLanguage["accept"]}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
