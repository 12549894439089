import React, { useEffect } from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import { MdDoneAll, MdVpnKey, MdDateRange } from "react-icons/md";
import { CommonComponentsStyles } from "./CommonComponentsStyles";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, formatIsoDate } from "../../services/Utilities";

export function UserSpecialCard(props) {
    const { otpData, identifier } = props;
    const classes = CommonComponentsStyles();
    const { t, i18n } = useTranslation();

    const attrNames = ["fecOtpGeneration", "codOtp", "used"];
    const icons = { fecOtpGeneration: <MdDateRange className={classes.iconStyle} />, codOtp: <MdVpnKey className={classes.iconStyle} />, used: <MdDoneAll className={classes.iconStyle} /> };
    const texts = { fecOtpGeneration: t("userInfoFecOtpGeneration"), codOtp: t("userInfoOtpCode"), used: t("userInfoUsed") };

    useEffect(() => { }, []);

    const formatData = (data) => {
        let auxData = data;
        if (checkIsNullUndefined(data)) {
            auxData = <span className={classes.notSetText}>{t("userInfoNoSeted")}</span>;
        } else {
            let isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
            auxData = auxData.toString();
            if (auxData === "false") {
                auxData = t("no");
            } else if (auxData === "true") {
                auxData = t("yes");
            } else if (isoDateFormatRegex.test(auxData)) {
                auxData = formatIsoDate(auxData);
            }
        }
        return auxData;
    };

    return (
        <Grid container>
            <Grid
                container
                style={{
                    alignContent: "center",
                    alignItems: "center",
                    height: "3em",
                }}
            >
                <Grid item xs={12} className={classes.titleText} style={{ marginBottom: "1em" }}>
                    <Typography variant="h6">
                        {identifier + " - " + t("userInfoNoActiveAccounts")}
                    </Typography>
                </Grid>
            </Grid>
            {attrNames.map((attrName) =>
                <Grid
                    key={"key_" + attrName}
                    container
                    style={{
                        alignContent: "center",
                        alignItems: "center",
                        height: "3em",
                    }}
                >
                    <Grid
                        item
                        xs={1}
                        align="left"
                        style={{ marginLeft: "2em" }}
                    >
                        {icons[attrName]}
                    </Grid>
                    <Grid item xs={7} align="left">
                        <Typography noWrap>
                            {texts[attrName]}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} align="center">
                        {otpData ? formatData(otpData[attrName]) : <span className={classes.notSetText}>{"(" + t("userInfoNoData") + ")"}</span>}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
