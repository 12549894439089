import { makeStyles } from "@material-ui/core/styles";

export const DialogStyles = makeStyles((theme) => ({

    dialogWindow: {     
        maxHeight:"auto",
        maxWidth: "auto",
        marginTop:"5em",        
    
        [theme.breakpoints.down("sm")]: {
          width: "auto",
          height: "auto",
        },
      },

      dialogTitle: {           
        
        color: "#002453",
        width: "98%",        
        paddingTop:"2em",
        paddingBottom: "2em"
      },
      subTitle: {
        fontSize:"120%",
        color: "#002453",
        textAlign: "center",
        paddingTop: "1em",
        paddingBottom: "1em"
      },

      image:{
        maxWidth:"100%",
        maxHeight:"100%",
        width: "auto",
        height: "auto"
      }

      
}));
