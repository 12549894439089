import React, { useEffect, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Divider,
  Container,
  CircularProgress,
  Typography,
  Dialog,
  IconButton,
  Tooltip,
  Backdrop,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TextBoxAttribute } from "../BuildInputs/TextBoxAttribute";
import { DatePickerAttribute } from "../BuildInputs/DatePicker";
import { DropdownAttribute } from "../BuildInputs/Dropdown";
import AutocompleteAttribute from "../BuildInputs/AutocompleteDropdown";
import {
  checkIsNullUndefined,
  getErrorMessage,
} from "../../services/Utilities";
import {
  ManageAddressFirstIteration,
  ManageAddressRelation,
} from "../../commons/AddressManager";
import { CommonStyles } from "../../commons/CommonStyles";
import { userDataStyles } from "./UserDataStyles";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import {
  GetApplicationAttributesUser,
  GetMasterAttribute,
  GetMasterFieldType,
  GetProfileUser,
  PutProfileUser,
  GetSubscriptionUser,
  GetApplicationUserSubscription,
  PostNotificationWelcomeEmail,
} from "../../services/ReasonService";
import { Alerts } from "../Alerts/Alerts";
import { Attributes } from "../../commons/Attributes";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import {
  getVerificationSession,
  backToLogin,
  getCodeApp,
  moveToOtherApp,
} from "../../services/Commons";
import { AddAttribute } from "../AddAttribute/AddAttribute";
import { AddAttributePhone } from "../AddAttribute/AddAttributePhone";
import { DialogConfirmAdd } from "./components/DialogConfirmAdd";
import { DialogConfirmNext } from "./components/DialogConfirmNext";
import { DialogDelete } from "./components/DialogDelete";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { AtcDialogSubscribeToApp } from "../Atc/AtcDialogSubscribeToApp/AtcDialogSubscribeToApp";
import { AppType, GroupActions, PathType, PersonType, EnumCountries } from "../../commons/Enums";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`attributes-tabpanel-${index}`}
      aria-labelledby={`attributes-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `attributes-tab-${index}`,
    "aria-controls": `attributes-tabpanel-${index}`,
  };
}

export function UserData(props) {
  const {
    next,
    setAllOk,
    errorTypeDocument,
    setErrorTypeDocument,
    handleNext,
    changeStep,
    groupActions,
    pathType,
    registryData,
    moveToApp,
    appType,
  } = props;
  const commonClasses = CommonStyles();
  const classes = userDataStyles();

  const [originalUser, setOriginalUser] = useState({});
  const [usableModel, setUsableModel] = useState([]);
  const [formGroups, setFormGroups] = useState([]);
  const [originalEmails, setOriginalEmails] = useState([]);
  const [originalPhones, setOriginalPhones] = useState([]);
  const [originalDocuments, setOriginalDocuments] = useState([]);
  const [editableDirections, setEditableDirections] = useState([]);
  const [activeDirection, setActiveDirection] = useState();
  const [tab, setTab] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [circularProgress, setCircularProgress] = useState(false);
  const [openEntryDialog, setOpenEntryDialog] = useState(true);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [addType, setAddType] = useState(false);
  const [emptyDirection, setEmptyDirection] = useState();
  const [openAddDirection, setOpenAddDirection] = useState(false);
  const [showMessageInfo, setShowMessageInfo] = useState(false);
  const [ndocumentIdn, setNdocumentIdn] = useState("");
  const [typedocumentIdn, setTypedocumentIdn] = useState(0);
  const [valueToDelete, setValueToDelete] = useState({});
  const [newValues, setNewValues] = useState([]);
  const [disableDeleteEmailBtn, setDisableDeleteEmailBtn] =
    useState(true);
  const [disableDeleteTlfBtn, setDisableDeleteTlfBtn] = useState(true);
  const [openDialogAskSubscribeApp, setOpenDialogAskSubscribeApp] =
    useState(false);
  const [appListDialogAskSubscribeApp, setAppListDialogAskSubscribeApp] =
    useState([]);
  const [disabledButtonAddDirUser, setDisabledButtonAddDirUser] =
    useState("User");
  const [needsAddress, setNeedsAddress] = useState(false);
  const [hassAddress, setHasAddress] = useState(false);
  const [isControlledCountry, setIsControlledCountry] = useState(true);
  const [auxDisabled, setAuxDisabled] = useState(false);

  useEffect(() => {
    // setHasUserSelected(!checkIsNullUndefined(sessionStorage.getItem("userSelectedOid")));
    preparePage();
    // setUserGroupActions(sessionStorage.getItem("UserGroupActions"));
  }, []);

  useEffect(() => {
    validateDocumentNumber();
  }, [ndocumentIdn, typedocumentIdn]);


  useEffect(() => {
    checkAllOk();
  }, [editableDirections, usableModel]);

  useEffect(() => {
    if (next === true) {
      handleNextStep();
    }
  }, [next]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  useEffect(() => {
    if (usableModel.length > 0) {
      let emailAttribute = usableModel[0].filter(
        (m) => m.codeAttribute === "EMA"
      )[0].value;
      let movilAttribute = usableModel[0].filter(
        (m) => m.codeAttribute === "MOV"
      )[0].value;
      setDisableDeleteEmailBtn(
        checkIsNullUndefined(emailAttribute) ||
        emailAttribute === spanishLanguage.userDataSelectOption
      );
      setDisableDeleteTlfBtn(
        checkIsNullUndefined(movilAttribute) ||
        movilAttribute === spanishLanguage.userDataSelectOption
      );
    }
  }, [usableModel]);

  const preparePage = () => {
    var emailInfo = getVerificationSession("email");
    var phoneInfo = getVerificationSession("phone");
    // if (!checkIsNullUndefined(emailInfo)) {
    //   var emailText = emailInfo
    //     ? spanishLanguage["userDataVerifyEmailOk"]
    //     : spanishLanguage["userDataVerifyEmailKo"];
    //   setVerifyInfo(emailText);
    // } else if (!checkIsNullUndefined(phoneInfo)) {
    //   var phoneText = phoneInfo
    //     ? spanishLanguage["userDataVerifyPhoneOk"]
    //     : spanishLanguage["userDataVerifyPhoneKo"];
    //   setVerifyInfo(phoneText);
    // }
    let cont = 0;
    let auxiliarAttributeType = [];
    let auxiliarFieldType = [];
    setCircularProgress(true);
    GetMasterAttribute().then((response) => {
      cont++;
      if (response && !response.Status) {
        auxiliarAttributeType = response;
        if (cont === 2) {
          chargeUserData(auxiliarAttributeType, auxiliarFieldType);
        }
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response));
      }
    });
    GetMasterFieldType().then((response) => {
      cont++;
      if (response && !response.Status) {
        auxiliarFieldType = response;
        if (cont === 2) {
          chargeUserData(auxiliarAttributeType, auxiliarFieldType);
        }
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response));
      }
    });
  };

  const chargeUserData = (auxiliarAttributeType, auxiliarFieldType) => {
    setCircularProgress(true);
    //Para partidular es 1
    GetApplicationAttributesUser(getCodeApp()).then((response) => {
      if (response && !response.Status) {
        let completeForm = response;
        GetProfileUser().then((response1) => {
          if (response1 && !response1.Status) {
            // let refilledUser = preparePreviousUserData(response1);
            setOriginalUser(response1);
            prepareData(
              response1,
              completeForm,
              auxiliarAttributeType,
              auxiliarFieldType
            );
            setData(completeForm, response1);
          } else {
            setCircularProgress(false);
            //Alerta
            finishOperation("error", getErrorMessage(response1));
          }
        });
      } else {
        setCircularProgress(false);
        //Alerta
        finishOperation("error", getErrorMessage(response));
      }
    });
  };

  const prepareData = (
    userObject,
    attributes,
    auxiliarAttributeType,
    auxiliarFieldType
  ) => {
    var completeForm = attributes;
    setOriginalEmails(userObject.userEmail);
    setOriginalPhones(userObject.userPhone);
    setOriginalDocuments(userObject.userDocument);
    fillUserDocumentValues(userObject.userDocument);
    completeForm.forEach((attribute) => {
      attribute.value = "";
      let fieldInfo = obtainFieldType(
        attribute.codeAttribute,
        auxiliarAttributeType,
        auxiliarFieldType
      );
      attribute.fieldType = fieldInfo.fieldTypeName;
      attribute.fieldCode = fieldInfo.fieldTypeCode;
      fillValues(attribute, userObject);
      attribute.position = Attributes[fieldInfo.fieldTypeCode].position;
      attribute.disabled = pathType !== PathType.navigation
        ? false
        : groupActions.includes(GroupActions.EUS)
          ? false
          : true;
    });
  };

  const setData = (attributes, userObject) => {
    let orderAttributes = attributes.sort(function (a, b) {
      return a.position - b.position;
    });
    //Guardamos un array con los distintos grupos
    var finalGroups = getGroups(orderAttributes);
    //Creamos un modelo que tiene los campos separados por grupos
    var finalModel = getFinalModel(orderAttributes, finalGroups);
    preparePreviousUserData(finalModel, userObject);
    let auxModel = JSON.parse(JSON.stringify(finalModel));
    setUsableModel(JSON.parse(JSON.stringify(finalModel)));
    setFormGroups(finalGroups);
    if (!checkIsNullUndefined(finalModel[1])) {
      //Modelo de Direcciones
      let auxUserDirectionModel = finalModel[1];
      auxUserDirectionModel.sort(function (a, b) {
        return (
          Attributes[a.codeAttribute].position -
          Attributes[b.codeAttribute].position
        );
      });
      let auxAddressMandatorys = auxUserDirectionModel.filter(
        (attr) => attr.mandatory
      );

      let auxNeedAddress = auxAddressMandatorys.length > 0;
      setNeedsAddress(auxNeedAddress);

      if (auxNeedAddress && !checkIsNullUndefined(userObject.userAddress) && userObject.userAddress.length !== 0) {
        setHasAddress(true);
      }

      let auxEditableAddress = handleDirections(
        userObject.userAddress,
        auxUserDirectionModel
      );
      setAuxDisabled(true);
      ManageAddressFirstIteration(
        auxModel,
        setAll,
        auxEditableAddress,
        getFavouriteDirection(auxEditableAddress),
        null,
        finishOperation
      );
    }

    setCircularProgress(false);
  };

  const preparePreviousUserData = (auxModel, auxUserObject) => {
    if (
      !checkIsNullUndefined(registryData.email) &&
      auxUserObject.userEmail.filter((auxEmail) => auxEmail.email == registryData.email)
        .length === 0
    ) {
      setNewValues((nv) => [
        ...nv,
        { value: registryData.email, codeAttribute: "EMA" },
      ]);
      addAttributes(registryData.email, "EMA", auxModel);
    }
    if (
      !checkIsNullUndefined(registryData.phoneNumber) &&
      auxUserObject.userPhone.filter(
        (auxPhone) => auxPhone.phone == registryData.phoneNumber
      ).length === 0
    ) {
      setNewValues((nv) => [
        ...nv,
        { value: registryData.phoneNumber, codeAttribute: "MOV" },
      ]);
      addAttributes(
        { phone: registryData.phoneNumber, pre: registryData.prefixPhoneCountry?.id },
        "MOV",
        auxModel
      );
    }
    //Documentos
    if (!checkIsNullUndefined(registryData.identifierDocument)) {
      auxModel[0].forEach((element) => {
        if (element.fieldCode === "NDO") {
          element.value = registryData.identifierDocument;
        } else if (element.fieldCode === "TDO") {
          element.value = registryData.identifierDocumentType;
        }
        // else if (element.fieldCode === "PEX") {
        //   element.value = userAtc.idCountry;
        // } else if (element.fieldCode === "FCA") {
        //   element.value = userAtc.expirationDate;
        // }
      });
    }
  };

  const handleDirections = (addresses, addressElements) => {
    let auxiliaryAdresses = JSON.parse(JSON.stringify(addresses));
    //Creamos una dirección vacía para los add
    let auxEmptyDirection = {};
    addressElements.forEach((value) => {
      auxEmptyDirection[Attributes[value.fieldCode].attributeName] = "";
    });
    auxEmptyDirection.id = 0;
    setEmptyDirection(auxEmptyDirection);
    auxiliaryAdresses.unshift(auxEmptyDirection);
    setEditableDirections(auxiliaryAdresses);
    setActiveDirection(getFavouriteDirection(auxiliaryAdresses));
    return auxiliaryAdresses;
  };

  const getFavouriteDirection = (addresses) => {
    let position = 0;
    if (addresses.length > 0) {
      addresses.forEach((address, index) => {
        if (address.favourite) {
          position = index;
        }
      });
    }
    return position;
  };

  const fillUserDocumentValues = (documents) => {
    if (documents.length > 0) {
      if (!checkIsNullUndefined(documents[0].number)) {
        setNdocumentIdn(documents[0].number);
      }
      if (!checkIsNullUndefined(documents[0].idTypeUserDocument)) {
        setTypedocumentIdn(documents[0].idTypeUserDocument.toString());
      }
    }
  };

  const fillValues = (attribute, userObject) => {
    if (checkIsNullUndefined(userObject)) {
      attribute.value = "";
    } else {
      let attributeName = Attributes[attribute.fieldCode].attributeName;
      switch (attribute.fieldCode) {
        case "EMA":
          fillMultiple(attribute, userObject, attributeName, "userEmail");
          break;
        case "MOV":
        case "PRE":
          fillMultiple(attribute, userObject, attributeName, "userPhone");
          break;
        case "DIR":
        case "AD1":
        case "ALI":
        case "PAI":
        case "PRO":
        case "POB":
        case "CPO":
        case "VIA":
        case "CMM":
          fillMultiple(attribute, userObject, attributeName, "userAddress");
          break;
        case "TDO":
        case "NDO":
        case "PEX":
        case "FCA":
          fillMultiple(attribute, userObject, attributeName, "userDocument");
          break;
        default:
          attribute.value =
            userObject[Attributes[attribute.fieldCode].attributeName];
          break;
      }
    }
  };

  const fillMultiple = (attribute, userObject, attributeName, option) => {
    //Para rellenar las opciones de los desplegables
    if (
      attribute.fieldCode === "EMA" ||
      attribute.fieldCode === "MOV" ||
      attribute.fieldCode === "ALI" ||
      attribute.fieldCode === "NDO"
    ) {
      //Para relacionar el teléfono con el prefijo
      if (attribute.fieldCode === "MOV") {
        attribute.prefixRel = [];
      }
      attribute.options = userObject[option].map((attrib) => {
        if (attribute.fieldCode === "MOV") {
          attribute.prefixRel.push({
            phone: attrib[attributeName],
            pre: attrib.idCountry,
          });
        }
        return attrib[attributeName];
      });
    }
    if (attribute.fieldCode === "PRE") {
      attribute.hasPhones = userObject.userPhone.length > 0;
    }
    if (attribute.fieldCode === "ALI") {
      let auxiliaryoptions = attribute.options.map((elem) => {
        return { code: elem, name: elem, id: elem };
      });
      attribute.dropdownValues = auxiliaryoptions;
      attribute.originalValues = auxiliaryoptions;
      attribute.actualize = true;
    }
    if (option === "userAddress") {
      let pos = getFavouriteDirection(userObject.userAddress);
      let auxiliarFavDirection = userObject.userAddress[pos];
      attribute.value = checkIsNullUndefined(auxiliarFavDirection)
        ? ""
        : auxiliarFavDirection[attributeName];
    } else if (attribute.fieldCode === "EMA" || attribute.fieldCode === "MOV") {

      let selectedAttribute = spanishLanguage.userDataSelectOption;

      if (attribute.fieldCode === "EMA") {

        if (userObject.userEmail.length == 1) {
          selectedAttribute = userObject.userEmail[0].email;
        } else {
          if (!checkIsNullUndefined(registryData.email)) {
            selectedAttribute = registryData.email;
          }
        }
      }

      if (attribute.fieldCode === "MOV") {

        if (userObject.userPhone.length == 1) {
          selectedAttribute = userObject.userPhone[0].phone;
        } else {
          if (!checkIsNullUndefined(registryData.phoneNumber)) {
            selectedAttribute = registryData.phoneNumber;
          }
        }
      }

      attribute.value = selectedAttribute;

    } else {
      attribute.value =
        userObject[option].length > 0
          ? userObject[option][0][attributeName]
          : "";
    }
  };

  const obtainFieldType = (code, auxiliarAttributeType, auxiliarFieldType) => {
    let attributeTypeArray = auxiliarAttributeType.filter(
      (attributes) => attributes.code === code
    );

    let fieldTypeArray = auxiliarFieldType.filter(
      (types) => types.id === attributeTypeArray[0].idFieldType
    );
    let auxFieldTypeName = code === "POB" ? "DropDown" : fieldTypeArray[0].name;
    return {
      fieldTypeName: auxFieldTypeName,
      fieldTypeCode: code,
    };
  };

  const getGroups = (model) => {
    var auxiliarGroups = [];
    model.forEach((element) => {
      if (auxiliarGroups.indexOf(element.nameGroup) === -1) {
        auxiliarGroups.push(element.nameGroup);
      }
    });
    return auxiliarGroups;
  };

  const getFinalModel = (model, groups) => {
    var auxiliarModel = [];
    groups.forEach((group, index) => {
      var auxiliarGroup = [];
      model.forEach((element) => {
        if (element.nameGroup === group) {
          element.auxiliarGroup = index;
          auxiliarGroup.push(element);
        }
      });
      auxiliarModel.push(auxiliarGroup);
    });
    return auxiliarModel;
  };

  //Generador del resto de atributos
  const selectGenerator = (attribute) => {
    switch (attribute.fieldType) {
      case "Textbox":
      case "Numeric":
        return generateTextbox(attribute);
      case "DropDown":
        return generateDropdowns(attribute);
      case "Date":
        return generateDatePicker(attribute);
      default:
        break;
    }
  };

  const validateDocumentNumber = (attributeValue, documentTypeParam) => {
    let documentNumber = ndocumentIdn;
    let documentType = typedocumentIdn;
    switch (documentTypeParam) {
      case "NDO":
        documentNumber = attributeValue;
        setNdocumentIdn(attributeValue);
        break;
      case "TDO":
        documentType = attributeValue;
        setTypedocumentIdn(attributeValue);
        break;
      default:
        break;
    }

    let flag = true;
    if (
      !(
        checkIsNullUndefined(documentType) ||
        checkIsNullUndefined(documentNumber)
      )
    ) {
      switch (documentType) {
        case "1":
          const checkDNI = new RegExp(/^[0-9]{8,8}[A-Za-z]$/);
          flag = checkDNI.test(documentNumber);
          break;

        case "2":
          if (documentNumber.length < 20 && documentNumber.length > 0) {
            flag = true;
          } else {
            flag = false;
          }

          break;

        case "3":
          const checkCIF = new RegExp(/^[XYZ]\d{7,8}[A-Z]$/);
          flag = checkCIF.test(documentNumber);
          break;
        default:
          break;
      }
      setErrorTypeDocument(!flag); //if (documentTypeParam === "TDO")
    } else {
      setErrorTypeDocument(false);
    }
    return flag;
  };

  const generateTextbox = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    return (
      <Grid
        key={attribute.nameGroup + "_" + attribute.nameAttritube}
        item
        md={fieldSize}
        xs={12}
      >
        {attribute.fieldCode === "NDO" ? (
          <TextBoxAttribute
            attribute={attribute}
            saveGroupsChanges={saveGroupsChanges}
            validateDocumentNumber={validateDocumentNumber}
            errorTypeDocument={errorTypeDocument}
          />
        ) : (
          <TextBoxAttribute
            attribute={attribute}
            saveGroupsChanges={saveGroupsChanges}
          />
        )}
      </Grid>
    );
  };

  const generateDropdowns = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    let ast = attribute.mandatory ? " *" : "";
    if (
      attribute.fieldCode === "CPO" ||
      attribute.fieldCode === "POB" ||
      attribute.fieldCode === "PRO"
    ) {
      if (isControlledCountry) {
        return (
          <Grid key={attribute.nameAttritube} item md={fieldSize} xs={12}>
            <AutocompleteAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
              finishOperation={finishOperation}
              setAuxDisabled={setAuxDisabled}
            />
          </Grid>
        );
      }
    } else if (attribute.fieldCode === "EMA" || attribute.fieldCode === "MOV") {
      if (
        !checkIsNullUndefined(attribute.options) &&
        attribute.options.length === 0
      ) {
        return (
          <Grid item sm={6} xs={12}>
            <CorreosButton
              variant="contained"
              color="primary"
              onClick={() => handleOpen(true, attribute.fieldCode)}
              className={classes.centerButton}
            // attribute = {attribute}
            // disabled={!allOk || errorTypeDocument}
            >
              {attribute.fieldCode === "EMA"
                ? spanishLanguage["userDataAddEmail"] + ast
                : spanishLanguage["userDataAddPhone"] + ast}
            </CorreosButton>
          </Grid>
        );
      } else {
        return (
          <Grid container item md={fieldSize} xs={12}>
            <Grid key={attribute.nameAttritube} item md={10} xs={8}>
              <DropdownAttribute
                attribute={attribute}
                saveGroupsChanges={saveGroupsChanges}
              />
            </Grid>
            <Grid item md={1} xs={2} className={commonClasses.gridIdentity}>
              <Tooltip
                title={
                  attribute.fieldCode === "EMA"
                    ? spanishLanguage["userDataAddEmail"]
                    : spanishLanguage["userDataAddPhone"]
                }
              >
                <IconButton
                  edge="inherit"
                  className={commonClasses.iconActionStyle}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => handleOpen(true, attribute.fieldCode)}
                  disabled={
                    pathType !== PathType.navigation
                      ? false
                      : groupActions.includes(GroupActions.EUS)
                        ? false
                        : true
                  }
                >
                  <AddToPhotosIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              item
              md={1}
              xs={2}
              className={
                attribute.fieldCode === "EMA"
                  ? commonClasses.gridIdentity1
                  : commonClasses.gridIdentity
              }
            >
              <DialogDelete
                finishOperation={finishOperation}
                handleOpenDelete={handleOpenDelete}
                data={valueToDelete}
                removeAttributes={removeAttributes}
                attribute={attribute}
                disableDeleteEmailBtn={disableDeleteEmailBtn}
                disableDeleteTlfBtn={disableDeleteTlfBtn}
              />
            </Grid>
          </Grid>
        );
      }
    } else if (attribute.fieldCode === "PRE") {
      if (attribute.hasPhones) {
        return (
          <Grid container item md={fieldSize} xs={12}>
            <DropdownAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
            />
          </Grid>
        );
      } else {
        return null;
      }
    } else if (attribute.fieldCode === "ALI") {
      return (
        <Grid container item md={fieldSize} xs={12}>
          <Grid key={attribute.nameAttritube} item xs={12}>
            <AutocompleteAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
              handleChangeAlias={handleChangeAlias}
              finishOperation={finishOperation}
              setAuxDisabled={setAuxDisabled}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid key={attribute.nameAttritube} item md={fieldSize} xs={12}>
          {attribute.fieldCode === "TDO" ? (
            <DropdownAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
              validateDocumentNumber={validateDocumentNumber}
              errorTypeDocument={errorTypeDocument}
            />
          ) : (
            <DropdownAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
              setIsControlledCountry={attribute.fieldCode === "PAI" ? setIsControlledCountry : null}
            />
          )}
        </Grid>
      );
    }
  };

  const generateDatePicker = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    return (
      <Grid item key={attribute.nameAttritube} md={fieldSize} xs={12}>
        <DatePickerAttribute
          attribute={attribute}
          saveGroupsChanges={saveGroupsChanges}
        />
      </Grid>
    );
  };

  const getPreRel = (relation, value) => {
    let auxValue = 67;
    relation.forEach((rel) => {
      if (rel.phone == value) {
        auxValue = rel.pre;
      }
    });
    return auxValue;
  };

  //Guarda los cambios de los valores tanto en front como en back
  const saveGroupsChanges = (attribute, idValue, first) => {
    //Tiene que ser comillas simples por defecto para que funcione el input correctamente
    let auxValue = checkIsNullUndefined(idValue) ? "" : idValue;
    if (
      (attribute.fieldCode === "PAI" ||
        attribute.fieldCode === "PRO" ||
        attribute.fieldCode === "POB" ||
        attribute.fieldCode === "CPO") &&
      first
    ) {
      setAuxDisabled(true);
      ManageAddressRelation(
        editableDirections,
        activeDirection,
        usableModel,
        attribute.fieldCode,
        setAll,
        idValue,
        finishOperation
      );
    } else if (attribute.fieldCode === "ALI") {
      if (!checkIsNullUndefined(idValue)) {
        let auxPosotion = changeDirectionsInfo(auxValue);
        usableModel[1].forEach((attribute) => {
          if (attribute.fieldCode === "ALI") {
            attribute.value = auxValue;
            attribute.actualize = true;
          } else {
            attribute.value = auxValue
              ? checkIsNullUndefined(
                editableDirections[auxPosotion][
                Attributes[attribute.fieldCode].attributeName
                ]
              )
                ? ""
                : editableDirections[auxPosotion][
                Attributes[attribute.fieldCode].attributeName
                ]
              : "";
          }
        });
        setAuxDisabled(true);
        ManageAddressFirstIteration(
          usableModel,
          setAll,
          editableDirections,
          auxPosotion,
          setIsControlledCountry,
          finishOperation
        );
      }
    } else {
      let auxiliarModel = JSON.parse(JSON.stringify(usableModel));
      auxiliarModel[attribute.auxiliarGroup].forEach((element) => {
        if (attribute.fieldCode === element.fieldCode) {
          element.value = auxValue;
        }
        if (attribute.fieldCode === "MOV" && element.fieldCode === "PRE") {
          element.value = getPreRel(attribute.prefixRel, auxValue);
        }
      });
      if (attribute.auxiliarGroup === 1) {
        //Guardamos los datos en objeto de direcciones editable
        let auxiliarEditableDirections = JSON.parse(
          JSON.stringify(editableDirections)
        );
        auxiliarEditableDirections[activeDirection][
          Attributes[attribute.fieldCode].attributeName
        ] = auxValue;
        setEditableDirections(auxiliarEditableDirections);
      }

      setUsableModel(auxiliarModel);

    }
  };

  const changeDirectionsInfo = (alias) => {
    let position = 0;
    editableDirections.forEach((direction, index) => {
      if (direction.alias === alias) {
        position = index;
      }
    });
    setActiveDirection(position);
    return position;
  };

  const handleChangeAlias = (alias) => {
    usableModel[1].forEach((attribute) => {
      if (attribute.fieldCode === "ALI") {
        attribute.value = alias;
        attribute.actualize = false;
      }
    });
    let auxEditableAddress = JSON.parse(JSON.stringify(editableDirections));
    auxEditableAddress[0] = editableDirections[activeDirection];
    auxEditableAddress[0].alias = alias;
    auxEditableAddress[0].id = 0;
    setEditableDirections(auxEditableAddress);
    setActiveDirection(0);
  };

  const handleAddDirection = () => {
    let auxiliarEditableDirections = JSON.parse(
      JSON.stringify(editableDirections)
    );
    let auxiliarModel = JSON.parse(JSON.stringify(usableModel));
    let auxAlias = checkIsNullUndefined(auxiliarEditableDirections[0].alias)
      ? spanishLanguage["address"] + auxiliarEditableDirections.length
      : auxiliarEditableDirections[0].alias;
    auxiliarEditableDirections[0].alias = auxAlias;
    auxiliarModel[1].forEach((attribute) => {
      if (attribute.fieldCode === "ALI") {
        attribute.value = auxAlias;
        attribute.options.push(auxAlias);
        attribute.actualize = true;
        attribute.dropdownValues.push({
          code: auxAlias,
          name: auxAlias,
          id: auxAlias,
        });
      } else {
        auxiliarEditableDirections[0][Attributes[attribute.fieldCode].attributeName] = attribute.value;
      }
    });
    // setNoDirection(false);
    auxiliarEditableDirections.push(auxiliarEditableDirections[0]);
    let newActiveDirection = auxiliarEditableDirections.length - 1;
    setActiveDirection(newActiveDirection);
    auxiliarEditableDirections[0] = emptyDirection;
    setEditableDirections(auxiliarEditableDirections);
    setUsableModel(auxiliarModel);
    finishOperation("warning", spanishLanguage["userDataAddDirectionSuccess"]);

    setHasAddress(true);
  };

  const mountUserObject = () => {
    let auxiliarEmails = mountUserEmails();
    let auxiliarPhones = mountUserPhones();
    let auxiliarDocuments = mountUserDocuments();
    let auxiliarAdresses = mountUserDirections();
    let userObject = originalUser;
    usableModel[0].forEach((attribute) => {
      if (Attributes.PlaneUserAttributes.indexOf(attribute.fieldCode) >= 0) {
        let value = checkIsNullUndefined(attribute.value)
          ? null
          : attribute.value;
        if (
          attribute.fieldCode === "NAC" ||
          attribute.fieldCode === "GEN" ||
          attribute.fieldCode === "LAN"
        ) {
          value = parseInt(value);
        }
        userObject[Attributes[attribute.fieldCode].attributeName] = value;
      }
    });
    userObject.userAddress = auxiliarAdresses;
    userObject.userDocument = auxiliarDocuments;
    userObject.userEmail = auxiliarEmails;
    userObject.userPhone = auxiliarPhones;
    return userObject;
  };

  const mountUserEmails = () => {
    let actualEmails = usableModel[0].filter(
      (attribute) => attribute.fieldCode === "EMA"
    )[0].options;
    let auxiliarEmails = actualEmails.map((email) => {
      return { id: 0, email: email };
    });
    auxiliarEmails.forEach((email) =>
      originalEmails.forEach((originalEMail) => {
        if (email.email === originalEMail.email) {
          email.id = originalEMail.id;
        }
      })
    );
    return auxiliarEmails;
  };

  const mountUserPhones = () => {
    let actualPhones = usableModel[0].filter(
      (attribute) => attribute.fieldCode === "MOV"
    )[0].prefixRel;
    let auxiliarPhones = actualPhones.map((phone) => {
      return {
        id: 0,
        phone: parseInt(phone.phone),
        idCountry: parseInt(phone.pre),
      };
    });
    auxiliarPhones.forEach((phone) =>
      originalPhones.forEach((originalPhone) => {
        if (phone.phone === originalPhone.phone) {
          phone.id = originalPhone.id;
        }
      })
    );
    return auxiliarPhones;
  };

  const mountUserDocuments = () => {
    //Para cuando permitamos múltiples documentos
    /*let actualDocuments = usableModel[0].filter(
      (attribute) => attribute.fieldCode === "NDO"
    )[0].options;
    
    let auxiliarDocuments = actualDocuments.map((document) => {
      return {
        id: 0,
        idTypeUserDocument: getValue("TDO"),
        idCountry: getValue("PEX"),
        number: document,
        expirationDate: getValue("FCA")
      };
    });
    auxiliarDocuments.forEach((document) =>
      originalDocuments.forEach((originalDocument) => {
        if (document.number === originalDocument.number) {
          document.id = originalDocument.id;
        }
      })
    );*/
    let auxiliarDocuments = [
      {
        id: 0,
        idTypeUserDocument: checkIsNullUndefined(getValue("TDO"))
          ? 0
          : parseInt(getValue("TDO")),
        idCountry: checkIsNullUndefined(getValue("PEX"))
          ? 0
          : parseInt(getValue("PEX")),
        number: getValue("NDO"),
        expirationDate: getValue("FCA"),
      },
    ];
    auxiliarDocuments.forEach((document) => {
      if (originalDocuments.length > 0) {
        document.id = originalDocuments[0].id;
      }
    });
    if (
      auxiliarDocuments[0].id === 0 &&
      checkIsNullUndefined(auxiliarDocuments[0].number)
    ) {
      auxiliarDocuments = [];
    }
    return auxiliarDocuments;
  };

  const mountUserDirections = () => {
    let auxiliarAdresses = editableDirections.map((auxiliarAdress) => {
      let address = {
        id: auxiliarAdress.id,
        address: auxiliarAdress.address,
        address1: auxiliarAdress.address1,
        alias: auxiliarAdress.alias,
        idCountry: getId(auxiliarAdress.idCountry),
        countryCode: checkIsNullUndefined(auxiliarAdress.countryCode) ? null : auxiliarAdress.countryCode,
        idProvince: getId(auxiliarAdress.idProvince),
        provinceCode: checkIsNullUndefined(auxiliarAdress.provinceCode) ? null : auxiliarAdress.provinceCode,
        idCity: getId(auxiliarAdress.idCity),
        cityCode: checkIsNullUndefined(auxiliarAdress.cityCode) ? null : auxiliarAdress.cityCode,
        idPostalCode: getId(auxiliarAdress.idPostalCode),
        postalCode: checkIsNullUndefined(auxiliarAdress.postalCode) ? null : auxiliarAdress.postalCode,
        idRoadType: getId(auxiliarAdress.idRoadType),
        roadTypeCode: checkIsNullUndefined(auxiliarAdress.roadTypeCode) ? null : auxiliarAdress.roadTypeCode,
        favourite: checkIsNullUndefined(auxiliarAdress.favourite)
          ? true
          : auxiliarAdress.favourite,
        comment: auxiliarAdress.comment,
      };
      return address;
    });
    //Quitamos la dirección vacia editable
    auxiliarAdresses.shift();
    return auxiliarAdresses;
  };

  const getValue = (code) => {
    let value = null;
    let auxiliar = usableModel[0].filter(
      (attribute) => attribute.fieldCode === code
    );
    if (auxiliar.length > 0) {
      value = auxiliar[0].value;
    }
    value = checkIsNullUndefined(value) ? null : value;
    return value;
  };

  const getId = (value) => {
    return checkIsNullUndefined(value) ? null : parseInt(value);
  };

  const handleTabChange = (event, tabValue) => {
    setTab(tabValue);
  };

  const checkDirectionsAdded = () => {
    //Comprobamos si ha rellenado una dirección y no la ha añadido
    let auxShowWarning = false;
    let auxAliasList = [];
    let auxAlias = editableDirections[0].alias;
    if (!checkIsNullUndefined(auxAlias)) {
      usableModel[1].forEach((attribute) => {
        if (attribute.fieldCode === "ALI") {
          attribute.dropdownValues.forEach((value) => {
            auxAliasList.push(value.name);
          });
        }
      });
      if (auxAliasList.length > 0) {
        auxShowWarning = auxAliasList.indexOf(auxAlias) === -1;
      } else if (auxAliasList.length === 0) {
        auxShowWarning = true;
      }
    } else {
      auxShowWarning = true;
    }
    if (!auxShowWarning) {
      handleNextStep();
    } else {
      setShowMessageInfo(true);
    }
  };

  const handleUsesEnterpriseData = () => {
    if (groupActions.includes(GroupActions.SUS)) {
      // Obtener las suscripciones del usuario.
      GetSubscriptionUser().then((usrSubsResponse) => {
        if (usrSubsResponse && !usrSubsResponse.Status) {
          // Obtener las aplicaciones al las que se puede suscribir.
          GetApplicationUserSubscription(getCodeApp()).then(
            (appSubsResponse) => {
              if (appSubsResponse && !appSubsResponse.Status) {
                let tmpAppList = [];

                // Comprobar a que aplicaciones no está suscrito o le falta firmar los TyC's.
                appSubsResponse.forEach((a) => {
                  if (
                    checkIsNullUndefined(
                      usrSubsResponse.find(
                        (u) => u.applicationCode === a.destinationAppCode
                      )
                    ) ||
                    !checkIsNullUndefined(
                      usrSubsResponse.find(
                        (u) =>
                          u.applicationCode === a.destinationAppCode &&
                          (u.idState === 1 || u.idState === 3) &&
                          !u.signedTyc
                      )
                    ) ||
                    !checkIsNullUndefined(
                      usrSubsResponse.find(
                        (u) =>
                          u.applicationCode === a.destinationAppCode &&
                          u.idState === 2
                      )
                    )
                  ) {
                    tmpAppList.push(a);
                  }
                });

                if (tmpAppList.length > 0) {
                  setAppListDialogAskSubscribeApp(tmpAppList);
                  setOpenDialogAskSubscribeApp(true);
                } else {
                  handleSubscriptionNavigation();
                }
              } else {
                finishOperation("error", getErrorMessage(appSubsResponse));
                setCircularProgress(false);
                handleNext(false);
              }
            }
          );
        } else {
          finishOperation("error", getErrorMessage(usrSubsResponse));
          setCircularProgress(false);
          handleNext(false);
        }
      });
    } else {
      handleSubscriptionNavigation();
    }
  };

  const handleNextStep = () => {
    let auxiliarData = mountUserObject();
    // setCircularProgress(true);

    // Actualizar los datos de usuario.
    if (groupActions.includes(GroupActions.EUS)) {
      PutProfileUser(auxiliarData, getCodeApp(), "true").then((response) => {
        if (response && (!response.Status || response.Status === 202)) {
          finishOperation("success", "Datos actualizados con exito");
          setCircularProgress(false);
          if (moveToApp && appType === AppType.particular) {
            moveToOtherApp();
          } else {
            handleUsesEnterpriseData();
          }
        } else {
          // Alerta.
          finishOperation("error", getErrorMessage(response));
          setCircularProgress(false);
          handleNext(false);
        }
      });
    } else {
      if (moveToApp && appType === AppType.particular) {
        moveToOtherApp();
      } else {
        handleUsesEnterpriseData();
      }
    }
  };

  const handleSubscriptionNavigation = () => {
    if (appType === AppType.particular) {
      if (pathType !== PathType.navigation) {
        // Mandar correo de bienvenida y navegar a applicación de usuario.
        PostNotificationWelcomeEmail(getCodeApp()).then(() => {
          moveToOtherApp();
        });
      } else {
        moveToOtherApp();
      }
    } else {
      changeStep(6, 5);
    }
  };

  // const handleCancel = () => {
  //   if (!isRegistry) {
  //     preparePage();
  //   } else {
  //     //Caso de ser un registro
  //     setOpenExitDialog(true);
  //   }
  // };

  const goToLogin = () => {
    backToLogin();
  };

  const checkAllOk = () => {
    if (usableModel.length > 0) {

      let flag = true;

      usableModel[0].forEach((attribute) => {
        if (attribute.mandatory) {
          if (((attribute.codeAttribute == "EMA" || attribute.codeAttribute == "MOV") && attribute.options.length == 0) || checkIsNullUndefined(attribute.value)) {
            flag = false;
          }
        }
      });

      if (flag && !checkIsNullUndefined(usableModel[1])) {
        let aliasAttribute = usableModel[1].find((attribute) => attribute.codeAttribute == "ALI");
        let countryAttribute = usableModel[1].find((attribute) => attribute.codeAttribute == "PAI");
        let isControlledCountry = countryAttribute.value == EnumCountries.ESP || countryAttribute.value == EnumCountries.AND;

        if (aliasAttribute.options.indexOf(aliasAttribute.value) != -1) {
          flag = checkMandatoriesAddress(isControlledCountry);
        }
      }

      if (flag && needsAddress && !hassAddress) {
        flag = false;
      }

      setAllOk(flag);
    }
  };

  const checkMandatoriesAddress = (isControlledCountry) => {
    let addressOk = true;
    // Para comprobar en direcciones si faltan campos obligatorios
    usableModel[1].forEach((element) => {
      if (element.mandatory && checkIsNullUndefined(element.value)) {
        if (isControlledCountry || (element.codeAttribute != "PRO" && element.codeAttribute != "POB" && element.codeAttribute != "CPO")) {
          addressOk = false;
        }
      }
    });
    return addressOk;
  };

  const addAttributes = (value, auxAddType, auxModel) => {
    let usAddType = !checkIsNullUndefined(auxAddType) ? auxAddType : addType;
    let auxiliarModel = !checkIsNullUndefined(auxModel)
      ? auxModel
      : JSON.parse(JSON.stringify(usableModel));
    let auxValue = usAddType === "EMA" ? value : value.phone;
    auxiliarModel[0].forEach((element) => {
      if (element.fieldCode === usAddType) {
        element.options.push(auxValue);
        element.value = auxValue;

        if (usAddType === "MOV") {
          element.prefixRel.push({ phone: auxValue, pre: value.pre });
        }
      } else if (usAddType === "MOV" && element.fieldCode === "PRE") {
        element.value = value.pre;
        element.hasPhones = true;
      }
    });

    setUsableModel(auxiliarModel);

    finishOperation("warning", spanishLanguage["userDataSaveWarning"]);
  };

  const removeAttributes = (data) => {
    let auxiliarModel = JSON.parse(JSON.stringify(usableModel));
    let auxiliarflag = true;

    auxiliarModel[0].forEach((element) => {
      if (element.fieldCode === data.type) {
        const index = element.options.indexOf(data.value);
        element.options.splice(index, 1);
        element.value = "";
        if (data.type === "MOV") {
          auxiliarflag = element.options.length !== 0;
          element.value = spanishLanguage.userDataSelectOption;
        } else if (data.type === "EMA") {
          element.value = spanishLanguage.userDataSelectOption;
        }
      }
    });
    auxiliarModel[0].forEach((element) => {
      if (element.fieldCode === "PRE") {
        element.hasPhones = auxiliarflag;
      }
    });
    setUsableModel(auxiliarModel);
  };

  const checkIfExistsInLocalModel = (value, type) => {
    let test = usableModel[0]
      .filter((m) => m.codeAttribute === type)[0]
      .options.find((o) => o === value);
    let exists = checkIsNullUndefined(test) ? false : true;
    return exists;
  };

  const handleOpen = (open, type) => {
    if (type === "EMA") {
      setOpen(open);
    } else if (type === "MOV") {
      setOpenPhone(open);
    }
    setAddType(type);
  };

  const handleOpenDelete = (type) => {
    let valueToDelete = {};
    if (type === "EMA") {
      let email = usableModel[0].filter((m) => m.codeAttribute === type)[0]
        .value;
      let isNewEmail = checkIsNullUndefined(
        newValues.find((n) => n.codeAttribute === type && n.value === email)
      )
        ? false
        : true;
      valueToDelete = { type: "EMA", value: email, isNew: isNewEmail };
    }
    if (type === "MOV") {
      let phone = usableModel[0]
        .filter((m) => m.codeAttribute === type)[0]
        .prefixRel.filter(
          (option) =>
            parseInt(option.phone) ===
            parseInt(
              usableModel[0].filter((m) => m.codeAttribute === type)[0].value
            )
        )[0];

      if (!checkIsNullUndefined(phone)) {
        let isNewPhone = checkIsNullUndefined(
          newValues.find(
            (n) => n.codeAttribute === type && n.value === phone.phone
          )
        )
          ? false
          : true;

        valueToDelete = {
          type: "MOV",
          value: phone.phone,
          idCountry: phone.pre,
          isNew: isNewPhone,
        };
      } else
        valueToDelete = {
          type: "MOV",
          value: usableModel[0].filter((m) => m.codeAttribute === type)[0]
            .value,
          idCountry: "",
          isNew: false,
        };
    }

    if (
      !checkIsNullUndefined(valueToDelete.value) &&
      valueToDelete.value.toString().trim() != ""
    ) {
      setValueToDelete(valueToDelete);
    }
  };

  const setAll = (
    auxCountry,
    auxProv,
    auxCity,
    auxPoCo,
    auxEditableDir,
    auxFavourite,
    auxUsableMod
  ) => {
    let editDirect = checkIsNullUndefined(auxEditableDir)
      ? editableDirections
      : auxEditableDir;
    let actDirect = checkIsNullUndefined(auxFavourite)
      ? activeDirection
      : auxFavourite;
    let usaMod = checkIsNullUndefined(auxUsableMod)
      ? usableModel
      : auxUsableMod;
    //Guardamos los datos en objeto de direcciones editable
    let auxiliarEditableDirections = JSON.parse(JSON.stringify(editDirect));
    auxiliarEditableDirections[actDirect][Attributes["PAI"].attributeName] =
      checkIsNullUndefined(auxCountry.value) ? "" : auxCountry.value;
    auxiliarEditableDirections[actDirect][Attributes["PRO"].attributeName] =
      checkIsNullUndefined(auxProv.value) ? "" : auxProv.value;
    auxiliarEditableDirections[actDirect][Attributes["POB"].attributeName] =
      checkIsNullUndefined(auxCity.value) ? "" : auxCity.value;
    auxiliarEditableDirections[actDirect][Attributes["CPO"].attributeName] =
      checkIsNullUndefined(auxPoCo.value) ? "" : auxPoCo.value;
    setEditableDirections(auxiliarEditableDirections);
    let auxiliarModel = JSON.parse(JSON.stringify(usaMod));
    auxiliarModel[1].forEach((element) => {
      if (element.fieldCode === "PAI") {
        element.value = auxCountry.value;
      }
      if (element.fieldCode === "PRO") {
        element.value = checkIsNullUndefined(auxProv.value)
          ? ""
          : auxProv.value;
        element.dropdownValues = auxProv.dropdownValues;
        element.originalValues = auxProv.originalValues;
      }
      if (element.fieldCode === "POB") {
        element.value = checkIsNullUndefined(auxCity.value)
          ? ""
          : auxCity.value;
        element.dropdownValues = auxCity.dropdownValues;
        element.originalValues = auxCity.originalValues;
      }
      if (element.fieldCode === "CPO") {
        element.value = checkIsNullUndefined(auxPoCo.value)
          ? ""
          : auxPoCo.value;
        element.dropdownValues = auxPoCo.dropdownValues;
        element.originalValues = auxPoCo.originalValues;
      }
    });

    setUsableModel(auxiliarModel);
    setAuxDisabled(false);
    setCircularProgress(false);
  };

  const getLastGrid = (group) => {
    let totalSize = 0;
    group.forEach((attribute) => {
      totalSize += Attributes[attribute.fieldCode].size;
    });
    let offSideSize = totalSize % 12;
    return <Grid item xs={offSideSize}></Grid>;
  };

  const mountTabText = (group, index) => {
    return index == 1 && needsAddress ? group + " *" : group;
  };

  return (
    <Grid item xs={12}>
      <Backdrop style={{zIndex:100, color: '#fff'}} open={auxDisabled}>
        <div>
          <CircularProgress color="inherit" />
          <Typography variant="h6" style={{ margin: "auto" }}>
            {spanishLanguage["charging"]}
          </Typography>
        </div>
      </Backdrop>
      <AddAttribute
        open={open}
        setOpen={setOpen}
        addAttribute={addAttributes}
        setNewValues={setNewValues}
        checkIfExistsInLocalModel={checkIfExistsInLocalModel}
      />
      <AddAttributePhone
        open={openPhone}
        setOpen={setOpenPhone}
        addAttribute={addAttributes}
        setNewValues={setNewValues}
        checkIfExistsInLocalModel={checkIfExistsInLocalModel}
      />
      <AtcDialogSubscribeToApp
        open={openDialogAskSubscribeApp}
        setOpen={setOpenDialogAskSubscribeApp}
        finishOperation={finishOperation}
        appList={appListDialogAskSubscribeApp}
        selectedOtpEmailOrPhone={registryData.identifier}
        handleSubscriptionNavigation={handleSubscriptionNavigation}
        enterpriseId={null}
        personType={PersonType.PARTICULAR}
        cancelPopup={handleNext}
      />
      <Grid style={{ marginTop: "20px" }}>
        <Dialog
          maxWidth="xs"
          open={openEntryDialog && pathType === PathType.creation}
          onClose={() => () => setOpenEntryDialog(false)}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Container className={commonClasses.dialogTitle}>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  className={commonClasses.dialogTitleText}
                >
                  {spanishLanguage["userDataRegister"]}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  edge="inherit"
                  className={commonClasses.dialogClosebutton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpenEntryDialog(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
          <Container className={commonClasses.dialogContainer}>
            <Grid item xs={12} spacing={1}>
              <Typography className={commonClasses.dialogContentText}>
                {spanishLanguage["userDataInfo"]}
              </Typography>
            </Grid>
            {/* <Grid item xs={1}>
              <IconButton
                edge="inherit"
                className={commonClasses.dialogClosebutton}
                color="inherit"
                aria-label="menu"
                onClick={() => setOpenEntryDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid> */}
          </Container>
        </Dialog>
        <Dialog
          maxWidth="xs"
          open={openExitDialog}
          onClose={() => setOpenExitDialog(false)}
        >
          <Container className={commonClasses.dialogTitle}>
            <Grid container>
              <Grid item xs={11}>
                <Typography
                  variant="h6"
                  className={commonClasses.dialogTitleText}
                >
                  {spanishLanguage["userDataInfoQuestion"]}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  edge="inherit"
                  className={commonClasses.dialogClosebutton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setOpenExitDialog(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Container>
          <Container className={commonClasses.dialogContainer}>
            <Grid item xs={12} spacing={1}>
              <Typography className={commonClasses.dialogContentText}>
                {spanishLanguage["userDataExitInfo"]}
              </Typography>
            </Grid>
            <Grid
              container
              item
              spacing={1}
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item sm={12} xs={12}>
                <CorreosButton
                  onClick={() => setOpenExitDialog(false)}
                  color="secondary"
                  style={{ float: "left" }}
                >
                  {spanishLanguage["cancel"]}
                </CorreosButton>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={goToLogin}
                  style={{ float: "right" }}
                >
                  {spanishLanguage["accept"]}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </Grid>
      <DialogConfirmNext
        showMessageInfo={showMessageInfo}
        setShowMessageInfo={setShowMessageInfo}
        handleNext={handleNextStep}
      />
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Grid item xs={12} className={commonClasses.mainContainer}>
        {circularProgress ? (
          <CircularProgress className={commonClasses.mainContainer} />
        ) : (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Attributes Tab"
              >
                {formGroups.map((groupName, index) => {
                  return (
                    <Tab
                      key={"Tab" + groupName}
                      label={mountTabText(groupName, index)}
                      {...a11yProps(index)}
                      value={index}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Divider></Divider>
            {usableModel.map((group, index) => {
              group = group.sort(function (a, b) {
                return a.position - b.position;
              });
              return (
                <TabPanel
                  key={"Panel" + group[0].nameGroup}
                  value={tab}
                  index={index}
                >
                  <Box style={{ marginTop: "2em" }}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      spacing={1}
                      xs={12}
                      fullWidth
                    >
                      {group.map((attr) => {
                        return selectGenerator(attr);
                      })}
                      {getLastGrid(group)}
                    </Grid>
                  </Box>
                </TabPanel>
              );
            })}
            <Grid container item xs={12} className={commonClasses.marginTop1em}>
              <Grid item sm={4} xs={12}></Grid>
              <Grid item sm={4} xs={12}>
                {tab !== 0 ? (
                  <DialogConfirmAdd
                    addDirection={handleAddDirection}
                    usableModel={usableModel}
                    openAddDirection={openAddDirection}
                    setOpenAddDirection={setOpenAddDirection}
                    disabledButtonAddDirUser={disabledButtonAddDirUser}
                    isRegistry={pathType !== PathType.navigation}
                    personTypeButtonAddDirection={"user"}
                  />
                ) : null}
              </Grid>
              <Grid md={4} xs={12}></Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
