import React, { useEffect, useState } from "react";
import { AtcUserSearcher } from "./AtcUserSearcher/AtcUserSearcher";
import { AtcSelectCreateAccount } from "./AtcSelectCreateAccount/AtcSelectCreateAccount";
import { AtcSendOtpConsent } from "./AtcSendOtpConsent/AtcSendOtpConsent";
import { AtcUserProfile } from "./AtcUserProfile/AtcUserProfile";
import { AtcEnterpriseProfile } from "./AtcEnterpriseProfile/AtcEnterpriseProfile";
import { getSelectedSubApp, getCodeApp } from "../../services/Commons";
import { BackgroundAtc } from "./BackgroundAtc/BackgroundAtc";
import { checkIsNullUndefined, getErrorMessage, isResultOk, showAlert } from "../../services/Utilities";
import { GetApplication, GetPermissionGroups, GetMasterCountry, GetApplicationPersonType } from "../../services/ReasonService";
import { AtcRegisterAccount } from "./AtcRegisterAccount/AtcRegisterAccount";
import { AtcSelectCreateEnterprise } from "./AtcSelectCreateEnterprise/AtcSelectCreateEnterprise";
import { AtcSelectTypePerson } from "./AtcSelectTypePerson/AtcSelectTypePerson";
import { Grid } from "@material-ui/core";
import { Alerts } from "../Alerts/Alerts";
import { AppType } from "../../commons/Enums";

export function Atc(props) {
  const { subApplication, handleSubApplication, handleApplicationName } = props;

  const [step, setStep] = useState(0);
  const [data, setData] = useState(null);
  const [userSelectedData, setUserSelectedData] = useState({});
  const [moveToApp, setMoveToApp] = useState(false);
  const [enterpriseId, setEnterpriseId] = useState();
  const [countryList, setCountryList] = useState([]);
  const [previousPage, setPreviousPage] = useState(0);
  const [personType, setPersonType] = useState();
  const [checkDNI, setCheckDNI] = useState(false);
  const [mustAttribute, setMustAttribute] = useState({ document: {} });
  const [groupActions, setGroupActions] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [pathType, setPathType] = useState("");
  const [appType, setAppType] = useState(AppType.empresa);
  const [registryData, setRegistryData] = useState({});
  const [isUserCorrect, setIsUserCorrect] = useState(false);

  const codeApp = getCodeApp();

  const _changeStep = (newStep, previousStep) => {
    setPreviousPage(previousStep);
    setStep(newStep);
  };

  const _saveData = (data) => {
    setData(data);
  };

  useEffect(() => {
    preparePage();
}, []);

  const preparePage = async () => {
    const [countries, applicationInfo, appPersonType, permissionsGroups] = await Promise.all([
        GetMasterCountry(),
        GetApplication(codeApp),
        GetApplicationPersonType(codeApp),
        GetPermissionGroups()
    ]);
    if (isResultOk([countries, applicationInfo, appPersonType, permissionsGroups])) {
        setCountryList(countries);
        let auxAppName = checkIsNullUndefined(applicationInfo) ? "" : applicationInfo.applicationName;
        handleApplicationName(auxAppName);
        let auxPersonType = AppType.particular;
        if (appPersonType.some((tipo) => tipo.id === 3)) {
            auxPersonType = AppType.ambos;
        } else if (appPersonType.some((tipo) => tipo.id === 2)) {
            auxPersonType = AppType.empresa;
        }
        setAppType(auxPersonType);
        sessionStorage.setItem("UserGroupActions", permissionsGroups);
        setGroupActions(permissionsGroups);
    } else {
        showAlert([countries, applicationInfo, appPersonType], finishOperation);
    }
};

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };



  return (
    <Grid>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <BackgroundAtc size="xl" registry={false} step={step}>
        {step === 0 ? (
          <AtcUserSearcher
            changeStep={_changeStep}
            saveData={_saveData}
            countryList={countryList}
            previousPage={previousPage}
            groupActions={groupActions}
            setPathType={setPathType}
            setRegistryData={setRegistryData}
            registryData={registryData}
            setUserSelectedData={setUserSelectedData}
          />
        ) : null}

        {step === 1 ? (
          <AtcSelectTypePerson
            changeStep={_changeStep}
            setPersonType={setPersonType}
            appType={appType}
          />
        ) : null}
        {step === 2 ? (
          <AtcSelectCreateAccount
            data={data}
            changeStep={_changeStep}
            setMoveToApp={setMoveToApp}
            saveData={_saveData}
            countryList={countryList}
            setUserSelectedData={setUserSelectedData}
            userSelectedData={userSelectedData}
            setPathType={setPathType}
            registryData={registryData}
            appType={appType}
          />
        ) : null}
        {step === 3 ? (
          <AtcRegisterAccount
            data={data}
            changeStep={_changeStep}
            checkDNI={checkDNI}
            setCheckDNI={setCheckDNI}
            mustAttribute={mustAttribute}
            setMustAttribute={setMustAttribute}
            countryList={countryList}
            previousPage={previousPage}
            pathType={pathType}
            userSelectedData={userSelectedData}
            setRegistryData={setRegistryData}
            registryData={registryData}
          />
        ) : null}
        {step === 4 ? (
          <AtcSendOtpConsent
            finishOperation={finishOperation}
            changeStep={_changeStep}
            userSelectedData={userSelectedData}
            countryList={countryList}
            mustAttribute={mustAttribute}
            personType={personType}
            pathType={pathType}
            registryData={registryData}
            setRegistryData={setRegistryData}
            setIsUserCorrect={setIsUserCorrect}
            isUserCorrect={isUserCorrect}
            setMoveToApp={setMoveToApp}
            appType={appType}
          />
        ) : null}
        {step === 5 ? (
          <AtcUserProfile
            changeStep={_changeStep}
            groupActions={groupActions}
            pathType={pathType}
            registryData={registryData}
            moveToApp={moveToApp}
            appType={appType}
          />
        ) : null}
        {step === 6 ? (
          <AtcSelectCreateEnterprise
            userSelectedData={userSelectedData}
            moveToApp={moveToApp}
            changeStep={_changeStep}
            setEnterpriseId={setEnterpriseId}
            enterpriseId={enterpriseId}
          />
        ) : null}

        {step === 7 ? (
          <AtcEnterpriseProfile
            changeStep={_changeStep}
            enterpriseId={enterpriseId}
            setEnterpriseId={setEnterpriseId}
            previousPage={previousPage}
            personType={personType}
            pathType={pathType}
            registryData={registryData}
          />
        ) : null}
      </BackgroundAtc>
    </Grid>
  );
}
