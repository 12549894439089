import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PutSystemuserResetCredentials } from "../../../services/ReasonService";
import { Container, Dialog, Grid, Typography } from "@material-ui/core";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";

export function DialogChangeSecret(props) {
    const { systemUserData, finishOperation } = props;
    const { t, i18n } = useTranslation();
    const dialogClasses = DialogStyles();

    const [open, setOpen] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [clientSecret, setClientSecret] = useState(false);
    const [identifier, setIdentifier] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => {
        let auxIdentifier = checkIsNullUndefined(systemUserData.systemUserName) ? systemUserData.client_id : systemUserData.systemUserName;
        setIdentifier(auxIdentifier);
    }, [systemUserData]);

    const handleConfirm = () => {
        setCircularProgress(true);
        PutSystemuserResetCredentials(systemUserData.client_id).then((response) => {
            if (response && !response.Status) {
                setIsChanged(true);
                setClientSecret(response.client_secret);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            setCircularProgress(false);
        });
    };

    const handleClose = () => {
        setOpen(false);
        setIsChanged(false);
    };

    const getText = () => {
        if (isChanged) {
            return (
                <>
                    <Typography style={{ marginBottom: "1em" }}>{t("userSysAdminClientSecretChanged")}</Typography>
                    <Typography><b>{t("userSysAdminClientSecret") + ": "}</b><span>{clientSecret}</span></Typography>
                </>
            );
        } else {
            return t("userSysAdminClientSecretText").replace("{user}", identifier);
        };
    };

    return (
        <>
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                style={{ float: "center", width: "100%" }}
            >
                <EnhancedEncryptionIcon style={{ marginRight: "0.2em" }} />
                {t("userSysAdminClientSecret")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userSysAdminClientSecretTitle")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {getText()}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={isChanged ? 12 : 6} style={{ textAlign: isChanged ? "center" : null }}>
                                <CorreosButton
                                    onClick={handleClose}
                                    className={isChanged ? dialogClasses.dialogButtonCenter : dialogClasses.dialogButtonLeft}
                                >
                                    {isChanged ? t("close") : t("cancel")}
                                </CorreosButton>
                            </Grid>
                            {isChanged ? null : (
                                <Grid item xs={6}>
                                    <CorreosButton
                                        variant="contained"
                                        color="primary"
                                        onClick={handleConfirm}
                                        className={dialogClasses.dialogButtonRight}
                                        circularProgress={circularProgress}
                                    >
                                        {t("accept")}
                                    </CorreosButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>

    );
}
