


import { makeStyles } from "@material-ui/core/styles";

export const RgpdStyles = makeStyles((theme) => ({

  mainContainer: {
    margin: "auto",
  },

  formContainer: {
    padding: "1em 1em 1em 1.6em",
  },

  cardContainer: {
    marginTop: "2em",
    marginLeft: "2em",
    marginRight: "2em",

  },

  title: {
    margin: "1em",
    color: "#002453",
  },

  switchButton: {
    width: "3em",
  },
  switchButtonNoActive: {
    width: "3em",
    color: "white",
  },

  agreeConditionsTermsConsent: {
    textAlign: "left",
    color: "#666666",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em !important",
    },
  },

  cardFile: {
    alignItems: "center",
  },

  textFieldRow: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  }, buttonSearch: {
    marginLeft: "1em",
    marginRight: "1em",
    height: "2.5em",
    marginTop: "0.75em"
  }, formSelectorControl: {
    minWidth: "10.5vh",
    marginRight: "0.5vh",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  }
}));
