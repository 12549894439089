import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  TextField,
} from "@material-ui/core";
import {
  GetMasterCountry,
  GetExtendedUserDataByIdentificationNotCancelled,
  PostCancelAccount,
} from "../../services/ReasonService";
import { checkIsNullUndefined, getErrorMessage, getPhonePrefixCountry, formatIsoDate } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserDeleteStyles } from "./UserDeleteStyles";
import { EnumCountries, RolLevels, SubApp } from "../../commons/Enums";
import { PrefixPhone } from "../TransversalComponents/PrefixPhone/PrefixPhone";
import SearchIcon from "@material-ui/icons/Search";
import { MdMail, MdPhoneAndroid, MdDateRange } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaIdCard, FaUser } from "react-icons/fa";
import { AtcModal } from "../Atc/AtcModal/AtcModal";
import { MdDoneAll } from "react-icons/md";
import { MdVpnKey } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { BasicDialog } from "../CommonComponents/BasicDialog";
import { DialogUnsubscribe } from "./Components/DialogUnsubscribe";
import { PageHeader } from "../CommonComponents/PageHeader";
import { UserInfoCard } from "../CommonComponents/UserInfoCard";


export function UserDelete(props) {

  const { finishOperation, userGroupActions, handleSetAppsSelectOptions, handleShowAppsSelect } = props;

  const { t, i18n } = useTranslation();
  const validator = require("react-email-validator");
  const classes = UserDeleteStyles();
  const commonClasses = CommonStyles();
  const [circularProgress, setCircularProgress] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [identifierType, setIdentifierType] = useState("");
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  const [showPrefixes, setShowPrefixes] = useState(false);
  const [prefixPhoneCode, setPrefixPhoneCode] = useState(EnumCountries.ESP);
  const [countryList, setCountryList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [otpData, setOtpData] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [option, setOption] = useState("");
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openUnsubscribe, setOpenUnsubscribe] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const validSubscriptionStates = [1, 3];

  useEffect(() => {
    handleSetAppsSelectOptions(SubApp.CAU, RolLevels.N5);
    handleShowAppsSelect(false);
    getCountries();
  }, []);

  const getCountries = () => {
    GetMasterCountry().then((response) => {
      if (response && !response.Status) {
        setCountryList(response);
      }
    });
  }

  const _handleVerfiedData = (value, type) => {
    let auxTypeMatch = { identifierDocument: 7, email: 2, phone: 5 }
    // let verifiedData = [];
    // verifiedData = value.filter((v) => v.idConTypeVerification === auxTypeMatch[type]);
    return value.filter((v) => v.idConTypeVerification === auxTypeMatch[type]);
  };

  const _formatPhone = (value) => {
    return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4");
  };

  // Función cerrar modal
  const _handleClose = () => {
    setOpenModal(false);
  };

  const _handleOpen = (index, x) => {
    let value = parseInt(index);
    if (value >= 0) {
      setSelectedModalData(userData[value]);
    } else {
      setSelectedModalData({});
    }
    setOption(x);
    setOpenModal(true);
  };

  // controla el cambio en el selector de prefijo
  const handleSelectorChange = (country) => {
    setPrefixPhoneCode(country.code);
  };

  const handleOpenDeleteConfirmation = (user) => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };

  const deleteUser = () => {
    setCircularProgress(true);
    PostCancelAccount({ userOid: selectedUser.userOid }).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("udelSuccessDeleteMessage"));
        setSelectedUser({});
        _handleNext();
      } else {
        finishOperation("error", getErrorMessage(response));
        setCircularProgress(false);
      }
    })

  };

  const _handleNext = () => {
    setCircularProgress(true);
    setShowCards(false);
    let identifierAux = identifier;
    if (identifierType === "phoneNumber") {
      let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
      identifierAux = country.phonePrefix + "-" + identifier;
    }
    GetExtendedUserDataByIdentificationNotCancelled(identifierAux).then(
      (response) => {
        if (!response || response.Status) {
          finishOperation("error", getErrorMessage(response));
        } else {
          if (response.listExtendedUserData != null) {
            const nonCancelledUserData = response.listExtendedUserData.filter(account => account.fecCancelAccount === null);
            setOtpData(response.lastOtp);
            setUserData(nonCancelledUserData);
            setShowCards(true);
          }
        }
        setCircularProgress(false);
      }
    );
  };

  // Identifica el tipo de identificador introducido (email, teléfono) y habilita el botón cuando el valor sea válido
  const _handleTypeOfIdentifier = (e) => {

    setShowCards(false);
    setUserData(null);
    setOtpData(null);

    let auxDisableButtonNext = true;
    let auxShowPrefixes = false;
    let auxIdentifierType = "";
    let value = e.target.value.trim();

    const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (validator.validate(value)) {
      auxIdentifierType = "email";
      auxDisableButtonNext = false;
    } else if (checkPhoneNumber.test(value)) {
      // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
      auxShowPrefixes = true;
      if (value.length >= 9) {
        auxIdentifierType = "phoneNumber";
        auxDisableButtonNext = false;
      }
    }

    setIdentifier(value);
    setIdentifierType(auxIdentifierType);
    setDisableButtonNext(auxDisableButtonNext);
    setShowPrefixes(auxShowPrefixes);
  };

  const formatData = (data) => {
    if (checkIsNullUndefined(data)) {
      return <span className={classes.notSetText}> t("udelNotSetText") </span>;
    }
    data = data.toString();

    if (data === "false") return "No";
    if (data === "true") return "Sí";
    let isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
    if (isoDateFormatRegex.test(data)) {
      return formatIsoDate(data);
    }
    return data;
  };

  const handleNoUserData = () => {
    let auxNoDataInfo = [
      {
        icon: <MdDateRange className={commonClasses.iconStyle} />,
        text: t("udelfecOtpGenerationLabel"),
        otpDataAttr: otpData ? formatData(otpData.fecOtpGeneration) : <span className={classes.notSetText}>{"(" + t("udelNoData") + ")"}</span>,
      }, {
        icon: <MdVpnKey className={commonClasses.iconStyle} />,
        text: t("udelcodOtpLabel"),
        otpDataAttr: otpData ? formatData(otpData.codOtp) : <span className={classes.notSetText}>{"(" + t("udelNoData") + ")"}</span>,
      }, {
        icon: <MdDoneAll className={commonClasses.iconStyle} />,
        text: t("udelusedLabel"),
        otpDataAttr: otpData ? formatData(otpData.used) : <span className={classes.notSetText}>{"(" + t("udelNoData") + ")"}</span>,
      }
    ];
    return (
      auxNoDataInfo.map((noDataInfo) => (
        getDataCardInfo(noDataInfo.icon, noDataInfo.text, noDataInfo.otpDataAttr)
      ))
    );
  };

  const getDataCardInfo = (icon, text, popup) => {
    return (
      <Grid
        container
        item xs={12}
        style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
      >
        <Grid
          item
          xs={1}
          align="left"
          style={{ marginLeft: "2em", display: "flex", alignItems: "center" }}
        >
          {icon}
        </Grid>
        <Grid item xs={7} align="left" style={{ display: "flex", alignItems: "center" }}>
          <Typography noWrap>
            {text}
          </Typography>
        </Grid>
        <Grid item xs={3} align="center" style={{ display: "flex", alignItems: "center" }}>
          {popup}
        </Grid>
      </Grid>
    );
  };

  const manageText = (text) => {
    return checkIsNullUndefined(text) ? "" : text;
  }

  const handleUserData = (account) => {
    let icon = <FaUser className={commonClasses.iconStyle} />;
    let text = (manageText(account.name) + " " + manageText(account.surname1) + " " + manageText(account.surname2)).trim();
    let popup = null;
    return getDataCardInfo(icon, text, popup);
  }

  const handleEmailData = (account, index) => {
    let icon = <MdMail className={commonClasses.iconStyle} />;
    let text = _handleVerfiedData(account.userEmail, "email")[0].email;
    let popup = _handleVerfiedData(account.userEmail, "email").length > 1 ? (
      <Tooltip title={t("udelShowEmail")}>
        <IconButton className={classes.iconButton}>
          <AiOutlineFileSearch
            onClick={() => {
              _handleOpen(index, "email");
            }}
          />
        </IconButton>
      </Tooltip>) : null;
    return getDataCardInfo(icon, text, popup);
  };

  const handlePhoneData = (account, index) => {
    let icon = <MdPhoneAndroid className={commonClasses.iconStyle} />
    let text = <Typography>
      {"+" +
        getPhonePrefixCountry(countryList, account.userPhone[0].idCountry, "id").phonePrefix +
        " " +
        _formatPhone(
          _handleVerfiedData(
            account.userPhone,
            "phone"
          )[0].phone.toString()
        )}
    </Typography>;
    let popup = _handleVerfiedData(account.userPhone, "phone")
      .length > 1 ? (
      <Tooltip title={t("udelShowPhone")}>
        <IconButton className={classes.iconButton}>
          <AiOutlineFileSearch
            onClick={() => {
              _handleOpen(index, "phone");
            }}
          />
        </IconButton>
      </Tooltip>
    ) : null;
    return getDataCardInfo(icon, text, popup);
  }

  const handleDocumentData = (account) => {
    let icon = <FaIdCard className={commonClasses.iconStyle} />;
    let text = <Typography>
      {account.userDocument.length === 0
        ? ""
        : _handleVerfiedData(
          account.userDocument,
          "identifierDocument"
        ).length === 0
          ? ""
          :
          _handleVerfiedData(
            account.userDocument,
            "identifierDocument"
          )[0].number}
    </Typography>;
    let popup = null;
    return getDataCardInfo(icon, text, popup);
  }

  const getUserSubscriptions = (account) => {
    let auxSubscribedApps = account.userSubscription.filter((subsApp) => validSubscriptionStates.includes(subsApp.idStateSubscription) && subsApp.codeApp != "CID");
    let auxSubsApps = auxSubscribedApps.map((subsApp) => {
      return { value: subsApp.codeApp, text: subsApp.nameApp }
    });
    return auxSubsApps;
  };

  const renderCards = (data) => {
    let hasActiveAccounts = data !== null && data.length > 0;
    let showSpecialCard = data === null || !hasActiveAccounts || data.every(account => account.fecCancelAccount !== null);

    return <Grid container item xs={12}>
      {showSpecialCard && (
        <Grid container item xs={12} md={6} justifyContent="center" alignItems="center" style={{ margin: data.length >= 2 ? "" : "auto" }}>
          <Card className={`${classes.cardStyles}`}>
            <Grid
              container
              item
              xs={12}
              style={{
                height: "3em",
              }}
            >
              <Grid item xs={12} className={classes.titleText} style={{ marginBottom: "1em" }}>
                <Typography variant="h6">
                  {identifier + " - " + t("udelNoActiveAccountsLabel")}
                </Typography>
              </Grid>
              {handleNoUserData()}
            </Grid>
          </Card>
        </Grid>
      )}
      {hasActiveAccounts && (
        data
          .filter(account => account.fecCancelAccount === null)
          .map((account, index) => (
            <Grid container item key={index} xs={12} md={6} justifyContent="center" alignItems="center" style={{ margin: data.filter(account => account.fecCancelAccount === null).length > 1 ? "" : "auto" }}>
              <Card
                className={`${classes.cardStyles}`}
              >
                <UserInfoCard account={account} countryList={countryList} />
                {/* <Grid
                  container
                  item xs={12}
                  style={{
                    minHeight: "3em",
                  }}
                >
                  {handleUserData(account)}
                  <Grid item xs={12} className={classes.titleText}>
                    <Typography variant="h6">
                      {t("udelUserVerifiedData")}
                    </Typography>
                  </Grid>

                  {(_handleVerfiedData(account.userEmail, "email").length === 0)
                    && (_handleVerfiedData(account.userPhone, "phone").length === 0)
                    && (_handleVerfiedData(account.userDocument, "identifierDocument").length === 0) ?
                    <span className={classes.notSetText}>{t("udelNoData")}</span> :
                    null}
                  {_handleVerfiedData(account.userEmail, "email").length > 0 ? (
                    handleEmailData(account, index)
                  ) : null}
                  {_handleVerfiedData(account.userPhone, "phone").length > 0 ? (
                    handlePhoneData(account, index)
                  ) : null}
                  {_handleVerfiedData(account.userDocument, "identifierDocument").length > 0 ? (
                    handleDocumentData(account)
                  ) : null} */}

                {/* <CardContent> */}
                <Grid
                  container
                  item
                  xs={12}
                  style={{ padding: "2em", bottom: 0 }}
                >
                  <Grid item xs={6}>
                    <CorreosButton
                      variant="contained"
                      color="primary"
                      onClick={() => { handleOpenUnsuscribe(account) }}
                      className={commonClasses.dialogButtonLeft}
                      disabled={getUserSubscriptions(account).length === 0}
                    >
                      {t("udelUnsubscribe")}
                    </CorreosButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CorreosButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenDeleteConfirmation(account);
                      }}
                      className={commonClasses.dialogButtonRight}
                    >
                      {t("udelDeleteAccount")}
                    </CorreosButton>
                  </Grid>
                </Grid>
                {/* </Grid> */}

              </Card>
            </Grid>
          ))
      )}
      <BasicDialog
        open={openDeleteConfirmation}
        title={t("udelConfirmationTitle")}
        text={t("udelDeletionMessage")}
        action={deleteUser}
        setOpen={setOpenDeleteConfirmation}
        circularProgress={circularProgress}
      ></BasicDialog>
      <DialogUnsubscribe
        open={openUnsubscribe}
        setOpen={setOpenUnsubscribe}
        user={selectedUser}
        handleNext={_handleNext}
        finishOperation={finishOperation}
        getUserSubscriptions={getUserSubscriptions}
      />
    </Grid>
  }

  const handleOpenUnsuscribe = (user) => {
    setSelectedUser(user);
    setOpenUnsubscribe(true);
  }

  return (
    userGroupActions.includes("CID") ?
      <Grid container item xs={12}>
        <PageHeader title={t("udelTitle")} text={t("udelSubtitle")} />
        <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
          <Grid
            container
            item
            justifyContent="center"
            spacing={1}
            xs={12}
          >
            <Card style={{ width: "100%", padding: "3em" }}>
              <Grid container item sm={10} xs={12} spacing={1} className={classes.textFieldRow} style={{ margin: "auto" }}>
                {showPrefixes ? (
                  <Grid item sm={2} xs={12}>
                    <PrefixPhone countryList={countryList} handleSelectorChange={handleSelectorChange} />
                  </Grid>
                ) : null}
                <Grid item sm={showPrefixes ? 8 : 10} xs={12} className={classes.textFieldRow}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="idSearcher"
                    label={t("udelIdentifierField")}
                    name="idSearcher"
                    color="secondary"
                    required={true}
                    onChange={_handleTypeOfIdentifier}
                    value={identifier}
                  />
                </Grid>
                <Grid item sm={2} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <CorreosButton
                    className={classes.buttonSearch}
                    variant="contained"
                    color="primary"
                    onClick={() => _handleNext()}
                    style={{ float: "center", marginLeft: "2em" }}
                    disabled={disableButtonNext}
                  >
                    <SearchIcon style={{ marginRight: "0.2em" }} />
                    {t("search")}
                  </CorreosButton>
                </Grid>
              </Grid>
            </Card>

          </Grid>

          <CardContent style={{ marginTop: "2em", width: "100%" }}>
            {circularProgress ? (
              <CircularProgress />
            ) : (
              showCards && userData && (
                <Grid container item xs={12}>
                  {openModal ? (
                    <AtcModal
                      open={openModal}
                      handleClose={_handleClose}
                      userData={selectedModalData}
                      option={option}
                      countryList={countryList}
                    />
                  ) : null}
                  {renderCards(userData)}
                </Grid>
              )
            )}

          </CardContent>
        </Grid>
      </Grid >
      : null
  );
}
