import { makeStyles } from '@material-ui/core/styles';

export const LoginPageStyles = makeStyles((theme) => ({
    mainDiv: {
      marginTop: "11rem",
      textAlign: "center",
      minHeight: "100vh",
      display: "flow-root",
      position: "relative",
    },
    circularProgresLogin: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));