import React from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import BallotIcon from '@material-ui/icons/Ballot';
// import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

export const EnumTyC = {
    WarninLegal: "AvisoLegal",
    PrivacyPolicy: "PolíticaDePrivacidad",
    Cookies: "PolíticaDeCookies"
};

export const EnumAlertColors = {
    success: "#348F41",
    error: "#F32735",
    info: "#1ECAD3",
    warning: "#DBB000",
};

export const EnumPages = {
    Login: "Login",
    Main: "Main",
    Roles: "Roles",
    Endpoints: "Endpoints",
    Services: "Services",
    AppControl: "AppControl",
    Atc: "Atc",
    Registry: "Registry",
    EnterpriseData: "EnterpriseData",
    Rgpds: "Rgpds",
    AdminAppAttributes: "AdminAppAttributes",
    AdvertisingCancellations: "AdvertisingCancellations",
    UsersExport: "UsersExport",
    ApplicationModification: "ApplicationModification",
    // UserInfo: "UserInfo",
    ApplicationAdmin: "ApplicationAdmin",
    // UserDelete: "UserDelete",
    SystemUserAdmin: "SystemUserAdmin",
    UserAdmin: "UserAdmin",
};

export const EnumExternalPages = {
    OktaLogin : "https://acceso.correospre.es/oauth2/default/v1/authorize?redirect_uri=https://azam-d-correosid-a0-svc-netcore-correosidbackoffice-01.azurewebsites.net/NTI/Atc&response_type=code&state=i7zHd5vJCL&scope=openid&client_id=0oa57mje5ggWlBgKR0x7"
}

export const EnumWindowSizeNumbers = {
    MarginTop: "6em",
    MarginTopMobile: "8.8em",
    MarginTopApp: "12em",
}

export const Actions = {
    A01: "A01",
    A02: "A02",
    A03: "A03"
}

export const RolLevels = {
    N1: "N1",
    N2: "N2",
    N3: "N3",
    N4: "N4",
    N5: "N5",
}

export const SubApp = {
    AAP: "AAP",
    KYB: "KYB",
    LEG: "LEG",
    CAU: "CAU",
    CID: "CID"
}

export const SubscriptionState = {
    Subscribed: 1,
    Unsubscribed: 2
}

export const GroupActions = {

    BEP: "BEP",
    BUS: "BUS",
    EEP: "EEP",
    EOP: "EOP",
    EUS: "EUS",
    REP: "REP",
    RUS: "RUS",
    SLE: "SLE",
    SLU: "SLU",
    SUE: "SUE",
    SUS: "SUS",
    LGP: "LGP",
    AUE: "AUE",
    AMA: "AMA",
    AdminUser:"CUE",
    VerifyUser: "CUE",
    SubscribeUser: "CID",
    DeleteUser: "CID",
}

export const selectedPersonType = {
    PARTICULAR: "user",
    EMPRESA: "enterprise",
    AUTONOMO: "freelance",
}

export const PersonType = {
    PARTICULAR: 1,
    EMPRESA: 2,
}

export const PathType = {
    creation: "creation",
    suscription: "suscription",
    navigation: "navigation"
}

export const AppType = {
    particular: "PARTICULAR",
    empresa: "EMPRESA",
    ambos: "AMBOS",
}
export const EnumCountries = {
    ESP: process?.env?.REACT_APP_ENVELOP_ACTIVE_3CODE_COUNTRY == "true" ? "ESP" : "ES",
    AND: process?.env?.REACT_APP_ENVELOP_ACTIVE_3CODE_COUNTRY == "true" ? "AND" : "AD",
};

export const RolLevelPages = {
    [SubApp.AAP]: {
        [RolLevels.N1]: [EnumPages.UsersExport],
        [RolLevels.N2]: [EnumPages.ApplicationModification],
    },
    [SubApp.CAU]: {
        // [RolLevels.N2]: [EnumPages.UserInfo],
        [RolLevels.N4]: [EnumPages.ApplicationAdmin, EnumPages.SystemUserAdmin],
        // [RolLevels.N4]: [EnumPages.ApplicationAdmin],
        [RolLevels.N5]: [EnumPages.UserAdmin],
    },
    [SubApp.LEG]: {
        [RolLevels.N1]: [EnumPages.AdvertisingCancellations],
    }
};

export const PageInfo = (doTraduction, iconClass) => {
    return {
        [EnumPages.UsersExport]: { pageIcon: <RecentActorsIcon className={iconClass} />, pageName: doTraduction(EnumPages.UsersExport) },
        [EnumPages.ApplicationModification]: { pageIcon: <BallotOutlinedIcon className={iconClass} />, pageName: doTraduction(EnumPages.ApplicationModification) },
        // [EnumPages.UserInfo]: { pageIcon: <FolderSharedIcon className={iconClass} />, pageName: doTraduction(EnumPages.UserInfo) },
        [EnumPages.ApplicationAdmin]: { pageIcon: <BallotIcon className={iconClass} />, pageName: doTraduction(EnumPages.ApplicationAdmin) },
        // [EnumPages.UserDelete]: { pageIcon: <PersonAddDisabledIcon className={iconClass} />, pageName: doTraduction(EnumPages.UserDelete) },
        [EnumPages.AdvertisingCancellations]: { pageIcon: <CancelPresentationIcon className={iconClass} />, pageName: doTraduction(EnumPages.AdvertisingCancellations) },
        [EnumPages.SystemUserAdmin]: { pageIcon: <AssignmentIndIcon className={iconClass} />, pageName: doTraduction(EnumPages.SystemUserAdmin) },
        [EnumPages.UserAdmin]: { pageIcon: <FolderSharedIcon className={iconClass} />, pageName: doTraduction(EnumPages.UserAdmin) },
    }
};