import React, { useEffect } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";

export function BasicDialog(props) {
    const { open, setOpen, title, text, action, circularProgress, remoteClose, manageClose } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    useEffect(() => { }, []);

    const handleCancel = () => {
        if (!checkIsNullUndefined(manageClose)) {
            manageClose();
        } else if (!checkIsNullUndefined(setOpen)) {
            setOpen(false);
        };
    };

    const handleConfirm = () => {
        action();
        if (checkIsNullUndefined(remoteClose)) {
            handleCancel();
        };
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleCancel}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            <Container className={dialogClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={dialogClasses.dialogTitleText}
                        >
                            {title}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <Container className={dialogClasses.dialogContainer}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography className={dialogClasses.dialogContentText}>
                            {text}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={dialogClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={dialogClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                className={dialogClasses.dialogButtonRight}
                                circularProgress={circularProgress}
                            >
                                {t("accept")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}
