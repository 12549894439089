import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
    Divider,
    IconButton,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import { CorreosButton } from "../../../CorreosButton/CorreosButton";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../../../commons/CommonStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../../services/Utilities";
import {
    PostAtcOtpSendEmail,
    PostAtcOtpSendPhone,
    PostVerificationValidationOtpEmailWithoutToken,
    PostVerificationValidationOtpPhoneWithoutToken,
} from "../../../../services/ReasonService";
import { spanishLanguage } from "../../../../config/language/spanishLanguage";

export function DialogIdentityVerify(props) {
    const { open, setOpen, otpType, verificatedValues, continueSteps, userOid } = props;
    const commonClasses = CommonStyles();

    const [otp, setOtp] = useState();
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(false);
    const [helper, setHelper] = useState("");
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgress1, setCircularProgress1] = useState(false);
    const [otpSended, setOtpSended] = useState(false);
    const [verificableValue, setVerificableValue] = useState(false);

    useEffect(() => {
        let auxOptions = [];
        if (otpType == "Email") {
            auxOptions = verificatedValues.map((auxEmail) => auxEmail.email);
        } else if (otpType == "Phone") {
            auxOptions = verificatedValues.map((auxPhone) => auxPhone.idCountry + "-" + auxPhone.phone);
        }
        setOptions(auxOptions);
        if (auxOptions.length > 0) {
            setVerificableValue(auxOptions[0]);
        }
    }, [otpType, verificatedValues]);

    const handleCancel = () => {
        setOtp("");
        setError(false);
        setCircularProgress(false);
        setCircularProgress1(false);
        setHelper("");
        setOtpSended(false);
        setOpen(false);
    };

    const handleVerification = (auxValue) => {
        setVerificableValue(auxValue);
    }

    const handleSend = () => {
        setCircularProgress(true);
        let auxCall = otpType == "Email" ? PostAtcOtpSendEmail : PostAtcOtpSendPhone;
        auxCall(verificableValue).then((response) => {
            if (response && !response.Status) {
                setCircularProgress(false);
                setOtpSended(true);
            } else {
                setCircularProgress(false);
            }
        });
    };

    const handleConfirm = () => {
        let auxCall = otpType == "Email" ? PostVerificationValidationOtpEmailWithoutToken : PostVerificationValidationOtpPhoneWithoutToken;
        auxCall(verificableValue, otp.trim(), userOid).then((response) => {
            if (response && !response.Status) {
                continueSteps();
            } else {
                setError(true);
                setHelper(spanishLanguage["registerVerifyOtpError"]);
            }
        });
    };

    const handleChangeOtp = (value) => {
        let auxiliarError = false;
        let auxiliarHelper = "";
        if (checkIsNullUndefined(value)) {
            auxiliarError = true;
            auxiliarHelper = spanishLanguage["required"];
        }
        setError(auxiliarError);
        setHelper(auxiliarHelper);
        setOtp(value);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={() => handleCancel()}
            className={commonClasses.dialogWindow}
        >
            <Grid>
                <Container className={commonClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography variant="h6" className={commonClasses.dialogTitleText}>
                                {spanishLanguage["registerVerifyIdentityTitle"]}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                edge="start"
                                className={commonClasses.dialogClosebutton}
                                color="inherit"
                                aria-label="menu"
                                onClick={() => handleCancel()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={commonClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={commonClasses.dialogContentText}>
                                {spanishLanguage["registerVerifyIdentityText"].replace("{userType}", spanishLanguage["registerVerify" + otpType])}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <FormControl fullWidth variant="outlined" color="secondary">
                                <InputLabel>{spanishLanguage["registerVerify" + otpType]}</InputLabel>
                                <Select
                                    native
                                    label={spanishLanguage["registerVerify" + otpType]}
                                    fullWidth
                                    value={verificableValue}
                                    onChange={(e) => handleVerification(e.target.value)}
                                >
                                    {options.map((auxObject) => {
                                        return (
                                            <option key={auxObject} value={auxObject}>
                                                {auxObject}
                                            </option>
                                        );

                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} className={commonClasses.gridIdentity} >
                            <CorreosButton
                                edge="end"
                                variant={otpSended ? "text" : "contained"}
                                color={otpSended ? "secondary" : "primary"}
                                onClick={() => handleSend()}
                                disabled={circularProgress1}
                                circularProgress={circularProgress}
                                className={commonClasses.dialogButtonRight}
                            >
                                {otpSended ? spanishLanguage["resend"] : spanishLanguage["send"]}
                            </CorreosButton>
                        </Grid>
                        {otpSended ? (
                            <Grid item xs={10}>
                                <TextField
                                    color="secondary"
                                    value={otp}
                                    onChange={(e) => handleChangeOtp(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    name="Otp"
                                    label={spanishLanguage["registerVerifyOtp"]}
                                    type="text"
                                    error={error}
                                    helperText={helper}
                                    required
                                />
                            </Grid>
                        ) : null}
                        {otpSended ? (
                            <Grid item xs={2} className={commonClasses.gridIdentity} >
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    disabled={checkIsNullUndefined(otp) || circularProgress}
                                    onClick={() => handleConfirm()}
                                    circularProgress={circularProgress1}
                                    className={commonClasses.dialogButtonRight}
                                >
                                    {spanishLanguage["send"]}
                                </CorreosButton>
                            </Grid>
                        ) : null}
                    </Grid>
                </Container>
            </Grid>
        </Dialog>
    );
}
