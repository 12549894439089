import React, { useEffect, useState } from 'react'
import { AtcModalStyles } from './AtcModalStyles';
import {
    Typography,
    IconButton,
    Grid,
    List,
    ListItemText,
    Container
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { CommonStyles } from "../../../commons/CommonStyles";
import { getPhonePrefixCountry } from '../../../services/Utilities';

export function AtcModal(props) {

    const classes = AtcModalStyles();
    const commonClasses = CommonStyles();
    const { open, handleClose, userData, option, countryList } = props;

    // Contiene los emails verificados del usuario
    const [userVerifiedEmails, setUserVerifiedEmails] = useState([]);
    // Contiene los teléfonos verificados del usuario
    const [userVerifiedPhones, setUserVerifiedPhones] = useState([]);
    const [userVerifiedDocument, setUserVerifiedDocument] = useState([]);

    useEffect(() => {
        const { userEmail } = userData;
        setUserVerifiedEmails(userEmail.filter(v => v.idConTypeVerification === 2));
    }, []);

    useEffect(() => {
        const { userPhone } = userData;
        setUserVerifiedPhones(userPhone.filter(v => v.idConTypeVerification === 5));
    }, []);
    useEffect(() => {
        const { userDocument } = userData;
        setUserVerifiedDocument(userDocument.filter(v => v.idConTypeVerification === 7));
    }, []);

    const _formatPhone = (value) => {
        return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');
    };

    // Ofuscará el value pasado por parámetro, con '*', los 3 últimos caracteres si es un teléfono, y si es un email desde la @ hasta el final.
    const _ofuscateData = (value, mode) => {
        let ofuscatedPart = "";
        let number = 0;

        if (mode === "email") {
            let position = value.indexOf("@") + 1;
            number = value.length - position;
        }

        if (mode === "phone") {
            value = value.toString();
            number = 3;
        }

        if (mode === "identifierDocument") {
            number = 3;
        }

        for (let i = 1; i <= number; i++) {
            ofuscatedPart = ofuscatedPart + "*";
        }

        const ofuscatedText =
            value.substring(0, value.length - number) + ofuscatedPart;
        return value;

        // Aqui quitamos ofuscatedText por value para inhabilitar temporalmente el ofuscamiento
    };

    return (

        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            className={commonClasses.dialogWindow}
        >
            <Container className={commonClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={11}>
                        <Typography
                            variant="h6"
                            className={commonClasses.dialogTitleText}
                        >
                            {spanishLanguage["atcModalTitle"]}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            edge="inherit"
                            className={commonClasses.dialogClosebutton}
                            color="inherit"
                            aria-label="menu"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                {userVerifiedEmails.length > 0 && option === "email" ?
                    <Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={1} align="left" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>                                
                                    <MdMail className={commonClasses.iconStyle} />                                
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    {"Email"}
                                </Typography>
                            </Grid>
                        </Grid>

                        <List className={classes.listItem}>
                            {userVerifiedEmails.map((email) => (
                                <ListItemText secondary={_ofuscateData(email.email, "email")} />
                            ))}
                        </List>
                    </Grid> : null
                }

                {userVerifiedPhones.length > 0 && option === "phone" ?
                    <Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={1} align="left" style={{ display: 'flex', alignItems: 'center', height: '100%' }} >
                                <MdPhoneAndroid className={commonClasses.iconStyle} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    {"Teléfono"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <List className={classes.listItem}>
                            {userVerifiedPhones.map((phone) => (
                                <ListItemText secondary={'+' + getPhonePrefixCountry(countryList, phone.idCountry, "id").phonePrefix + ' ' + _formatPhone(_ofuscateData(phone.phone, "phone").toString())} />
                            ))}
                        </List>
                    </Grid> : null
                }
                {userVerifiedDocument.length > 0 && option === "identifierDocument" ?
                    <Grid>
                        <Grid container alignItems="center">
                            <Grid item xs={1} align="left" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <FaIdCard className={commonClasses.iconStyle} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    {"Documento identificativo"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <List className={classes.listItem}>
                            {userVerifiedDocument.map((document) => (
                                <ListItemText secondary={_ofuscateData(document.document, "identifierDocument")} />
                            ))}
                        </List>
                    </Grid> : null
                }
            </Container>

            <Container className={commonClasses.dialogContainer}>
                <Grid
                    container
                    item
                    xs={12}
                    className={commonClasses.dialogButtonContainer}
                >
                    <Grid item xs={12}>
                        <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={handleClose}
                            className={commonClasses.dialogButtonRight}
                        >
                            {spanishLanguage["atcClose"]}
                        </CorreosButton>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}

