import { makeStyles } from '@material-ui/core/styles';

export const ExternalLoginPageStyles = makeStyles((theme) => ({
    mainDiv: {
      marginTop: "11rem",
      textAlign: "center",
      minHeight: "100vh",
      display: "flow-root",
      position: "relative",
    },
    circularProgresLogin: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    dialogClosebutton: {
      //marginTop: "-0.7em",
      //marginRight: "-0.15em",
      float: "right",
      color: "#666666",
    },
    dialogTitle: {
      padding: "1.5em 0em 0em 0em",
      color: "#002453",
      width: "100%",
    },
    loadingGrid: {
      marginTop: "11rem",
      textAlign: "center",
      height: "100%",
      width: "100%",
      display: "flow-root",
      position: "relative",
    }
  }));