import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  Popper,
} from "@material-ui/core";
import { CommonStyles } from "../../../commons/CommonStyles";
import { checkIsNullUndefined, useOutsideAlerter } from "../../../services/Utilities";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { EnumCountries } from "../../../commons/Enums";

export function DialogConfirmAdd(props) {
  const { isRegistry, usableModel, addDirection, personTypeButtonAddDirection } = props;
  const commonClasses = CommonStyles();
  const wrapperRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [allOk, setAllOk] = useState(null);
  const [openAddDirection, setOpenAddDirection] = useState(false);
  const [UserGroupActions, setUserGroupActions] = useState([]);
  const [showAddButton, setshowAddButton] = useState();



  useEffect(() => {
    setUserGroupActions(sessionStorage.getItem("UserGroupActions"));



  }, []);
  useEffect(() => {
    if (UserGroupActions.length > 0) {
      handleButton();
    }

  });

  useEffect(() => {
    checkMandatories();
  }, [usableModel]);



  const handleCancel = () => {
    setAnchorEl(null);
    setOpenAddDirection(false);
  };

  const handleConfirm = () => {
    addDirection();
    handleCancel();
  };

  const handleAddDirection = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAddDirection(true);
  };

  const handleButton = () => {
    let auxCode = false;
    if (isRegistry) {
      auxCode = true;
    } else {
      if (personTypeButtonAddDirection === "enterprise" && UserGroupActions.includes("EEP")) {
        auxCode = true;
      }
      else if (personTypeButtonAddDirection === "user" && UserGroupActions.includes("EUS")) {
        auxCode = true;
      } else {
        auxCode = false;
      }
    }
    setshowAddButton(auxCode);
    return showAddButton;
  }

  const checkMandatories = () => {
    let auxAllOk = true;
    if (!checkIsNullUndefined(usableModel) && !checkIsNullUndefined(usableModel[1])) {
      // Para comprobar en direcciones si faltan campos obligatorios
      let countryAttribute = usableModel[1].find((attribute) => attribute.codeAttribute == "PAI" || attribute.codeAttribute == "EPI");
      let isControlledCountry = countryAttribute.value == EnumCountries.ESP || countryAttribute.value == EnumCountries.AND;
      auxAllOk = checkMandatoriesAddress(isControlledCountry);
    }
    setAllOk(auxAllOk);
  };

  const checkMandatoriesAddress = (isControlledCountry) => {
    let addressOk = true;
    // Para comprobar en direcciones si faltan campos obligatorios
    usableModel[1].forEach((element) => {
      if (element.codeAttribute != "ALI" && element.mandatory && checkIsNullUndefined(element.value)) {
        if (isControlledCountry || (element.codeAttribute != "PRO" && element.codeAttribute != "POB" && element.codeAttribute != "CPO" && element.codeAttribute != "EPR" && element.codeAttribute != "EPO" && element.codeAttribute != "ECP")) {
          addressOk = false;
        }
      }
    });
    return addressOk;
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid>



      {showAddButton ? (
        <CorreosButton
          variant="contained"
          color="primary"
          onClick={(event) => handleAddDirection(event)}
          style={{ margin: "auto" }}
        >
          {spanishLanguage["addDirection"]}
        </CorreosButton>
      ) : null}
      <Popper
        open={openAddDirection}
        anchorEl={anchorEl}
        onClose={handleCancel}
        placement={"top"}
        style={{ zIndex: 5000 }}
        ref={wrapperRef}
      >
        <Paper className={commonClasses.popperAddContainer}>
          {/* <Paper className={commonClasses.popperContainer}> */}
          <Container>
            {allOk ? (
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                  <Typography className={commonClasses.popperContentText}>
                    {spanishLanguage["userDataAddDirection"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="small"
                    onClick={handleCancel}
                    className={commonClasses.leftButton}
                  >
                    {spanishLanguage["no"]}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    className={commonClasses.rightButton}
                  >
                    {spanishLanguage["yes"]}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                  <Typography className={commonClasses.popperContentText}>
                    {spanishLanguage["userDataAddDirectionMandatorie"]}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Container>
        </Paper>
        {/* </Paper> */}
      </Popper>

    </Grid>
  );
}
