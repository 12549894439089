import React, { useState } from "react";
import {
    Tooltip,
    IconButton,
    Grid,
    Typography,
} from "@material-ui/core";
import { checkIsNullUndefined, formatIsoDate, getErrorMessage } from "../../../services/Utilities";
import { UserAdminStyles } from "../UserAdminStyles";
import { useTranslation } from "react-i18next";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SendIcon from '@material-ui/icons/Send';
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { DialogUnsubscribe } from "./DialogUnsubscribe";
import { DialogVerify } from "./DialogVerify";
import { PostAtcOtpSendEmail, PostAtcOtpSendPhone } from "../../../services/ReasonService";
// import { AlternateEmailIcon, PhoneIcon, FingerprintIcon, PlaylistAddCheckIcon, HomeIcon, BusinessIcon, KeyboardArrowDownIcon, KeyboardArrowUpIcon, } from '@material-ui/icons';

export function VerifyButtons(props) {
    const { auxInfoObj, itemType, doTraduction, finishOperation, user } = props;
    const [circularProgressOtp, setCircularProgressOtp] = useState(false);

    const handleSendOtp = (auxilIdentifier, auxilIdentifierType) => {
        let auxIdentifier = auxilIdentifier[auxilIdentifierType].user;
        let auxFunction = auxilIdentifierType === "userPhone" ? PostAtcOtpSendPhone : PostAtcOtpSendEmail;
        setCircularProgressOtp(true);

        auxFunction(auxIdentifier).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", doTraduction("userInfoOtpSent").replace("{identifier}", auxIdentifier));
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgressOtp(false);
        });
    };

    return (
        <CorreosButton
            variant="contained"
            color="primary"
            onClick={() => handleSendOtp(auxInfoObj, itemType)}
            disabled={!checkIsNullUndefined(user.fecCancelAccount)}
            circularProgress={circularProgressOtp}
        >
            <SendIcon style={{ marginRight: "0.2em" }} />
            {doTraduction("userInfoSendOtp")}
        </CorreosButton>
    );
};

export function renderItem(itemType, doTraduction, item, classes) {
    let auxItem = getItem(item, itemType);
    let auxItemAttrNames = getItemAttrNames(itemType);
    return auxItemAttrNames.map((attrName, index) => (
        <Grid container item xs={12} key={itemType + "_" + index}>
            <Grid item xs={4} className={classes.rowAlign}>
                <strong style={{ color: "#333333" }}>{doTraduction("genLabel_" + attrName)}</strong>
            </Grid>
            <Grid item xs={8} className={classes.rowAlign}>
                {formatData(auxItem[attrName], classes, doTraduction)}
            </Grid>
        </Grid>
    ));
};

const getItem = (item, itemType) => {
    let auxItem = JSON.parse(JSON.stringify(item));
    if (itemType == "userEmail") {
        // TODO Debería poder hacerse automático
        auxItem.codOtp = item.lastOtp?.codOtp;
        auxItem.fecOtpGeneration = item.lastOtp?.fecOtpGeneration;
        auxItem.used = item.lastOtp?.used;
    }
    return auxItem;
};

const formatData = (data, classes, doTraduction) => {
    let auxReturn;
    if (checkIsNullUndefined(data)) {
        auxReturn = <Typography className={classes.notSetText} style={{ textAlign: "right", width: "100%" }}>{doTraduction("userInfoNoSeted")}</Typography>;
    } else {
        let auxText = data;
        let isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
        data = data.toString();
        if (data === "false") {
            auxText = doTraduction("No");
        } else if (data === "true") {
            auxText = doTraduction("Sí");
        } else if (isoDateFormatRegex.test(data)) {
            auxText = formatIsoDate(data);
        }
        auxReturn = <Typography style={{ textAlign: "right", width: "100%", color: "#333333" }}>{auxText}</Typography>;
    };
    return auxReturn;
};

const getItemAttrNames = (itemType) => {
    let propertiesToFilter = [];
    switch (itemType) {
        case 'userData':
            propertiesToFilter = ["userOid", "name", "surname1", "surname2", "birthDate", "nationality", "gender", "language"];
            break;
        case 'userEmail':
            propertiesToFilter = ["codOtp", "fecOtpGeneration", "used"];
            break;
        case 'userPhone':
            propertiesToFilter = ["country"];
            break;
        case 'userDocument':
            propertiesToFilter = ["country", "expirationDate"];
            break;
        case 'userEnterprises':
            propertiesToFilter = ["id", "tradeName", "documentNumber", "country", "professionalActivity"];
            break;
        case 'userAddress':
        case 'enterpriseAddress':
            propertiesToFilter = ["roadTypeName", "address", "address1", "provinceName", "comment", "favourite"];
            break;
        case 'userSubscription':
        case 'enterpriseSubscription':
            propertiesToFilter = ["fecCreated", "fecUpdated"];
            break;
        default:
            break;

    };
    return propertiesToFilter;
};

export function RenderAttrInfo(props) {
    const {
        index,
        itemType,
        item,
        identifier,
        user,
        finishOperation,
        handleNext
    } = props;
    const classes = UserAdminStyles();
    const [openCollapse, setOpenCollapse] = useState(false);
    const { t, i18n } = useTranslation();

    const auxItemsInfo = {
        userEmail: { label: "email", icon: <AlternateEmailIcon style={{ marginRight: "1em" }} />, attrName: "email", attrPrefix: null, attrVerification: "conTypeVerification" },
        userPhone: { label: "phone", icon: <PhoneIcon style={{ marginRight: "1em" }} />, attrName: "phone", attrPrefix: "prefix", attrVerification: "conTypeVerification" },
        userDocument: { label: "document", icon: <FingerprintIcon style={{ marginRight: "1em" }} />, attrName: "number", attrPrefix: "typeUserDocument", attrVerification: "conTypeVerification" },
        userAddress: { label: "address", icon: <HomeIcon style={{ marginRight: "1em" }} />, attrName: "address", attrPrefix: "alias", attrVerification: "conTypeVerification" },
        enterpriseAddress: { label: "address", icon: <HomeIcon style={{ marginRight: "1em" }} />, attrName: "address", attrPrefix: "alias", attrVerification: "conTypeVerification" },
        userSubscription: { label: "subscription", icon: <PlaylistAddCheckIcon style={{ marginRight: "1em" }} />, attrName: "nameApp", attrPrefix: null, attrVerification: "stateSubscription" },
        enterpriseSubscription: { label: "subscription", icon: <PlaylistAddCheckIcon style={{ marginRight: "1em" }} />, attrName: "nameApp", attrPrefix: null, attrVerification: "stateSubscription" },
        userEnterprises: { label: "companyName", icon: <BusinessIcon style={{ marginRight: "1em" }} />, attrName: "companyName", attrPrefix: "nameRol", attrVerification: "nameRol" },
    };

    const getIcon = () => {
        return auxItemsInfo[itemType].icon;
    };

    const getColorClass = (idVerification) => {
        let auxLabel = auxItemsInfo[itemType].label;
        let auxVerificationInfo = {
            email: 2, // OTP
            phone: 5, // OTP
            document: 7, // Onboarding presencial
            subscription_OK: 1, // Suscrito
            subscription_KO: 2, // Dado de baja
            companyName: "Titular", // Titular
        };
        let auxColor;
        if (auxLabel == "subscription") {
            auxColor = auxVerificationInfo[auxLabel + "_OK"] == idVerification ? classes.greenText :
                auxVerificationInfo[auxLabel + "_OK"] == idVerification ? classes.redText : classes.orangeText;
        } else {
            auxColor = auxVerificationInfo[auxLabel] == idVerification ? classes.greenText : classes.orangeText;
        };
        return auxColor;
    };

    const getText = () => {
        let auxType = auxItemsInfo[itemType].label;
        let auxText = item[auxItemsInfo[itemType].attrName];
        if (auxType == "phone") {
            auxText = item[auxItemsInfo[itemType].attrPrefix] + " " + auxText;
        } else if (auxType == "document") {
            auxText = item[auxItemsInfo[itemType].attrPrefix] + ": " + auxText;
        }
        return auxText;
    }

    const renderVerificationCell = () => {
        let auxType = auxItemsInfo[itemType].label;
        let verifTextAttrName = "conTypeVerification";
        let verifTextAttrId = "idConTypeVerification";
        if (auxType == "subscription") {
            verifTextAttrName = "stateSubscription";
            verifTextAttrId = "idStateSubscription";
        } else if (auxType == "companyName") {
            verifTextAttrName = "nameRol";
            verifTextAttrId = "nameRol";
        }
        let colorClass = getColorClass(item[verifTextAttrId]);
        return <>
            <Typography className={colorClass}>
                {item[verifTextAttrName]}
            </Typography>
        </>
    };

    const handleOperation = (item, itemType) => {
        let auxReturn = <Grid item xs={2}></Grid>;
        if (itemType === 'userEmail' || itemType === 'userPhone') {
            auxReturn = mountVerifyButtons(item, itemType);
        } else if (itemType === "userSubscription" && item.idStateSubscription != 2) {
            auxReturn = <DialogUnsubscribe user={user} identifier={identifier} application={item} handleNext={handleNext} finishOperation={finishOperation} />
        }
        return auxReturn;
    }

    const mountVerifyButtons = (item, itemType) => {
        let auxInfoObj = {
            userEmail: {
                user: item.email,
                maxVerification: 2,
                text: t("userInfoVerifyEmail"),
            },
            userPhone: {
                user: item.prefix + "-" + item.phone,
                maxVerification: 5,
                text: t("userInfoVerifyPhone"),
            },
        };
        let auxReturn = (
            <VerifyButtons
                auxInfoObj={auxInfoObj}
                itemType={itemType}
                doTraduction={t}
                finishOperation={finishOperation}
                user={user}
            />
        );
        if (item.idConTypeVerification < auxInfoObj[itemType].maxVerification) {
            let auxIsEmail = identifier.indexOf("@") > -1;
            auxReturn = (
                <DialogVerify
                    accIdentifier={identifier}
                    accIdIsEmail={auxIsEmail}
                    emailOrPhone={auxInfoObj[itemType].user}
                    isEmail={itemType == "userEmail"}
                    handleNext={handleNext}
                    finishOperation={finishOperation}
                    isCancelled={!checkIsNullUndefined(user.fecCancelAccount)}
                />
            );
        };
        return auxReturn;
    };

    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} key={itemType + "_" + index}>
                <Grid item xs={1} className={classes.rowAlign}>
                    <Tooltip
                        title={openCollapse ? t('userInfoCollapse') : t('userInfoExpand')}
                        placement="top"
                        arrow
                    >
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapse(!openCollapse)}>
                            {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={5} className={classes.rowAlign} >
                    {getIcon()}<strong className={classes.rowTitle}>{getText()}</strong>
                </Grid>
                {!checkIsNullUndefined(item[auxItemsInfo[itemType].attrVerification]) ? (
                    <Grid container item xs={6}>
                        <Grid item xs={7} className={classes.rowAlign}>
                            {renderVerificationCell()}
                        </Grid>
                        <Grid item xs={5} className={classes.rowAlign}>
                            {handleOperation(item, itemType)}
                        </Grid>
                    </Grid>
                ) : <Grid container item xs={6}></Grid>}
            </Grid>
            <Grid container item xs={12} key={itemType + "_" + index + "coll"} style={{ display: openCollapse ? "block" : "none" }}>
                <Grid container item xs={12}>
                    {renderItem(itemType, t, item, classes)}
                </Grid>
                {itemType == "userEnterprises" && item.enterpriseAddress?.length > 0 ? (
                    item.enterpriseAddress.map((address, index) => (
                        <RenderAttrInfo
                            index={index}
                            itemType={"enterpriseAddress"}
                            item={address} />
                    ))
                ) : (null)}
                {itemType == "userEnterprises" && item.enterpriseSubscription?.length > 0 ? (
                    item.enterpriseSubscription.map((subscription, index) => (
                        <RenderAttrInfo
                            index={index}
                            itemType={"enterpriseSubscription"}
                            item={subscription} />
                    ))
                ) : (null)}
            </Grid>
        </Grid>
    );
};