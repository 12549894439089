import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
} from "@material-ui/core";
import { MdMail, MdPhoneAndroid } from "react-icons/md";
import { FaIdCard, FaUser } from "react-icons/fa";
import { CommonComponentsStyles } from "./CommonComponentsStyles";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getPhonePrefixCountry } from "../../services/Utilities";
import { UserInfoPopup } from "./UserInfoPopup";

export function UserInfoCard(props) {
    const { account, countryList } = props;
    const classes = CommonComponentsStyles();
    const { t, i18n } = useTranslation();

    const [emailData, setEmailData] = useState([]);
    const [phoneData, setPhoneData] = useState([]);
    const [documentData, setDocumentData] = useState([]);

    useEffect(() => {
        setEmailData(handleVerfiedData(account.userEmail, "email"));
        setPhoneData(handleVerfiedData(account.userPhone, "phone"));
        setDocumentData(handleVerfiedData(account.userDocument, "identifierDocument"));
    }, []);

    const handleVerfiedData = (value, type) => {
        const verifyIds = { identifierDocument: 7, email: 2, phone: 5 };
        let verifiedData = value.filter((v) => v.idConTypeVerification === verifyIds[type]);
        return verifiedData;
    };

    const getDataCardInfo = (icon, text, popup) => {
        return (
            <Grid
                container
                style={{
                    alignContent: "center",
                    alignItems: "center",
                    height: "3em",
                }}
            >
                <Grid
                    item
                    xs={1}
                    align="left"
                    style={{ marginLeft: "2em", display: "flex", alignItems: "center" }}
                >
                    {icon}
                </Grid>
                <Grid item xs={7} align="left" style={{ display: "flex", alignItems: "center" }}>
                    <Typography noWrap>
                        {text}
                    </Typography>
                </Grid>
                <Grid item xs={3} align="center" style={{ display: "flex", alignItems: "center" }}>
                    {popup}
                </Grid>
            </Grid>
        );
    };

    const manageText = (text) => {
        return checkIsNullUndefined(text) ? "" : text;
    };

    const handleUserData = (account) => {
        let icon = <FaUser className={classes.iconStyle} />;
        let text = (manageText(account.name) + " " + manageText(account.surname1) + " " + manageText(account.surname2)).trim();
        let popup = null;
        return getDataCardInfo(icon, text, popup);
    };

    const handleMultipleData = (account, dataType) => {
        let auxReturn = null;
        if (!checkIsNullUndefined(account)) {
            let auxVerifiedData = dataType == "email" ? emailData : dataType == "phone" ? phoneData : documentData;
            const icons = {
                email: <MdMail className={classes.iconStyle} />,
                phone: <MdPhoneAndroid className={classes.iconStyle} />,
                identifierDocument: <FaIdCard className={classes.iconStyle} />
            };
            const texts = {
                email: <Typography>{emailData[0]?.email}</Typography>,
                phone: <Typography>
                    {"+" + getPhonePrefixCountry(countryList, account.userPhone[0]?.idCountry, "id")?.phonePrefix +
                        " " +
                        formatPhone(
                            phoneData[0]?.phone.toString()
                        )}
                </Typography>,
                identifierDocument: <Typography>
                    {account.userDocument.length === 0 ? ""
                        : documentData.length === 0 ? ""
                            : documentData[0]?.number}
                </Typography>
            };
            let popup = dataType != "identifierDocument" ? <UserInfoPopup verifiedData={auxVerifiedData} dataType={dataType} countryList={countryList} /> : null;
            auxReturn = getDataCardInfo(icons[dataType], texts[dataType], popup);
        }
        return auxReturn;

    };

    const formatPhone = (value) => {
        let auxReturn = "";
        if (!checkIsNullUndefined(value)) {
            auxReturn = value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4");
        }
        return auxReturn;
    };

    return (
        <Grid container>
            {handleUserData(account)}
            <Grid item xs={12} className={classes.titleText}>
                <Typography variant="h6">
                    {t("udelUserVerifiedData")}
                </Typography>
            </Grid>

            {(emailData.length === 0)
                && (phoneData.length === 0)
                && (documentData.length === 0) ?
                <span className={classes.notSetText}>{t("udelNoData")}</span> :
                null}
            {emailData.length > 0 ? (
                handleMultipleData(account, "email")
            ) : null}
            {phoneData.length > 0 ? (
                handleMultipleData(account, "phone")
            ) : null}
            {documentData.length > 0 ? (
                handleMultipleData(account, "identifierDocument")
            ) : null}
        </Grid>
    );
}
