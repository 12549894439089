import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Card
} from "@material-ui/core";
import { ApplicationAdminStyles } from "./ApplicationAdminStyles";
import { AttributeSelection } from "./AttributeSelection/AttributeSelection";
import { useTranslation } from "react-i18next";
import { CommonStyles } from "../../commons/CommonStyles";
import { EndpointSelection } from "./EndpointSelection/EndpointSelection";
import { EndpointGroupSelection } from "./EndpointGroupSelection/EndpointGroupSelection";
import { RolLevels, SubApp } from "../../commons/Enums";
import { PageHeader } from "../CommonComponents/PageHeader";

export function ApplicationAdmin(props) {
    const {
        finishOperation,
        userGroupActions,
        handleShowAppsSelect,
        handleSetAppsSelectOptions,
        selectedApp
    } = props;

    const commonClasses = CommonStyles();
    const classes = ApplicationAdminStyles();
    const [restartActionsFlag, setRestartActionsFlag] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        handleSetAppsSelectOptions(SubApp.CAU, RolLevels.N4);
        handleShowAppsSelect(true);
    }, []);

    const restartActions = () => {
        setRestartActionsFlag(!restartActionsFlag);
    }

    return (
        userGroupActions.includes("EYA") ?
            <Grid container item xs={12}>
                <PageHeader title={t("appAdmTitle")} text={t("appAdmSubtitle")} />
                {process.env.REACT_APP_ENVELOP_ACTIVE_ENDPOINT_GROUP == "true" ? (
                    <Card style={{ width: "100%", paddingLeft: "5em", paddingRight: "5em", marginBottom: "3em" }}>
                        <Grid container item xs={12} className={classes.componentContainer}>
                            <EndpointGroupSelection finishOperation={finishOperation} selectedApp={selectedApp} restartActions={restartActions} />
                        </Grid>
                    </Card>
                ) : null}
                <Card style={{ width: "100%", paddingLeft: "5em", paddingRight: "5em", marginBottom: "3em" }}>
                    <Grid container item xs={12} className={classes.componentContainer}>
                        <EndpointSelection finishOperation={finishOperation} appCode={selectedApp.value} restartActions={restartActionsFlag} />
                    </Grid>
                </Card>
                <Card style={{ width: "100%", paddingLeft: "5em", paddingRight: "5em", marginBottom: "3em" }}>
                    <Grid container item xs={12} className={classes.componentContainer}>
                        <AttributeSelection finishOperation={finishOperation} applicationCode={selectedApp.value} />
                    </Grid>
                </Card>
            </Grid>
            : null
    );

}