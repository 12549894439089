import React, { useEffect } from "react";
import { spanishLanguage } from "../config/language/spanishLanguage";

export const checkIsNullUndefined = (value) => {
  let isNullUndefined = value === undefined || value === null || value === "" || value === "null";
  if (!isNullUndefined && (typeof value === "string")) {
    isNullUndefined = value.trim() === "";
  }
  return isNullUndefined;
};

export const getErrorMessage = (error) => {
  var errorMessage =
    !checkIsNullUndefined(error) &&
      !checkIsNullUndefined(error.Error) &&
      !checkIsNullUndefined(error.Error.resultError) &&
      !checkIsNullUndefined(error.Error.resultError[0])
      ? error.Error.resultError[0]
      : spanishLanguage.genericError;
  return errorMessage;
};

export const useOutsideAlerter = (ref, action) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const getPhonePrefixCountry = (countryList, value, enterType) => {
  let auxResponse = {};
  let auxCountry = countryList.find((country) => country[enterType] == value);
  if (!checkIsNullUndefined(auxCountry)) {
    auxResponse = auxCountry;
  }
  return auxResponse;
};

export const getPhoneCountry = (countryList, phone) => {
  let auxResponse = {};
  let auxPrefix = phone.split("-")[0];
  let auxCountry = countryList.find(
    (country) => country["phonePrefix"] == auxPrefix
  );
  if (!checkIsNullUndefined(auxCountry)) {
    auxResponse = auxCountry;
  }
  return auxResponse;
};

export const formatIsoDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const validatePhone = (phone, country) => {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  if (process?.env?.REACT_APP_ENVELOP_ACTIVE_3CODE_COUNTRY == "true") {
    let auxCode = phoneUtil.getRegionCodeForCountryCode(country.phonePrefix);
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone.toString(), auxCode), auxCode);
  } else {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parseAndKeepRawInput(phone.toString(), country.code), country.code);
  }
}

export const isResultOk = (resultsList) => {
  let flag = true;
  resultsList.forEach((result) => {
    if (checkIsNullUndefined(result) || !checkIsNullUndefined(result.Status)) {
      flag = false;
    }
  });
  return flag;
};

export const showAlert = (resultsList, finishOperation) => {
  resultsList.forEach((result) => {
    if (!checkIsNullUndefined(result) || !checkIsNullUndefined(result?.Status)) {
      finishOperation("error", getErrorMessage(result));
    }
  });
};

export const getDate = (fec, esp) => {
  let date = new Date(fec);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (day < 10) {
    day = "0" + day.toString();
  };
  if (month < 10) {
    month = "0" + month.toString();
  };
  let year = date.getFullYear();
  let auxDate = esp ? day + "-" + month + "-" + year : year + "-" + month + "-" + day
  return auxDate;
};
