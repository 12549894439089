import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PostSubscriptionUserUnsubscribe } from "../../../services/ReasonService";
import CancelIcon from '@material-ui/icons/Cancel';

export function DialogUnsubscribe(props) {
    const { user, identifier, application, handleNext, finishOperation } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => { }, []);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setCircularProgress(true);
        PostSubscriptionUserUnsubscribe(user.userOid, application.codeApp).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("udelUnsubscribeSuccess"));
                handleCancel();
                handleNext(true);
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgress(false);
        })
    };

    return (
        <>
            <CorreosButton
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(true)}
                disabled={!checkIsNullUndefined(user.fecCancelAccount)}
            >
                <CancelIcon style={{ marginRight: "0.2em" }} />
                {t("userAdminUnsubscribe")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("udelUnsubscribe")}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {t("udelUnsubscribeText").replace("{user}", identifier).replace("{application}", application.nameApp)}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    onClick={handleCancel}
                                    className={dialogClasses.dialogButtonLeft}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirm}
                                    className={dialogClasses.dialogButtonRight}
                                    circularProgress={circularProgress}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog >
        </>

    );
}
