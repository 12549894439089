import React, { useEffect, useState } from "react";

import {
  Typography,
  IconButton,
  Grid,
  Container,
  Card,
  CardContent,
  FormLabel,
  Tooltip,
} from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { AtcSelectCreateAccountStyles } from "./AtcSelectCreateAccountStyles";
import {
  GetUserSubscriptionsByOid,
} from "../../../services/ReasonService";
import { AtcModal } from "../AtcModal/AtcModal";
import { getPhonePrefixCountry } from "../../../services/Utilities";
import { MdMail } from "react-icons/md";
import { MdPhoneAndroid } from "react-icons/md";
import { FaIdCard } from "react-icons/fa";
import { CommonStyles } from "../../../commons/CommonStyles";
import { getCodeApp } from "../../../services/Commons";
// import { AtcDialog } from "../AtcGoToOrNavigateDialog/AtcGoToOrNavigateDialog";
import { AiOutlineFileSearch } from "react-icons/ai";
import { AppType, GroupActions, PathType } from "../../../commons/Enums";

export function AtcSelectCreateAccount(props) {
  const classes = AtcSelectCreateAccountStyles();
  const commonClasses = CommonStyles();
  const {
    data,
    changeStep,
    countryList,
    setUserSelectedData,
    userSelectedData,
    setPathType,
    registryData,
    appType
  } = props;

  // Contiene el valor del usuario encontrado en BD
  // const [userSelectedData, setUserSelectedData] = useState({});
  const [option, setOption] = useState("");
  const [UserGroupActions, setUserGroupActions] = useState([]);


  // STATES MODAL
  // Maneja abrir el modal
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setUserGroupActions(sessionStorage.getItem("UserGroupActions"));
  }, []);

  // Cambia al siguiente paso y guarda los datos necesarios para el siguiente paso en "props"
  const _handleNext = (auxUserData) => {
    setUserSelectedData(auxUserData);
    //Comprobar si el usuario tiene alguna empresa
    let hasEnterprises = auxUserData.enterpriseSubscribedToApp?.length > 0;
    if (!hasEnterprises) {
      changeStep(1, 2);
    } else {
      changeStep(3, 2);
    }
  };

  // Maneja si se cargan los datos en el formulario o no (Nueva Cuenta)
  const handleCreateNewAccount = () => {
    setPathType(PathType.creation);
    _handleNext({});
  };

  const _handleOpenDialog = (index) => {
    //obtener código de aplicación actual
    let appCode = getCodeApp();
    let auxUser = data.userData[index];
    if (appType == AppType.empresa) {
      let suscribedToApp = checkHasSubscribedEnterprises(auxUser);
      goToHandleNext(suscribedToApp, auxUser);
    } else {
      GetUserSubscriptionsByOid(auxUser.oid).then((userSubscriptions) => {
        let suscribedToApp = false;
        let actualSubscription = userSubscriptions.filter((subs) => subs.applicationCode == appCode);
        if (actualSubscription.length > 0) {
          suscribedToApp = actualSubscription[0].idState == 1 || actualSubscription[0].idState == 3;
        }
        goToHandleNext(suscribedToApp, auxUser);
      });
    }
  };

  const checkHasSubscribedEnterprises = (auxUser) => {
    let subsEnter = auxUser.enterpriseSubscribedToApp.filter((enterprise) => enterprise.isSuscribedToApp);
    return subsEnter.length > 0;
  }

  const goToHandleNext = (suscribedToApp, auxUser) => {
    let auxPathType = suscribedToApp ? PathType.navigation : PathType.suscription;
    setPathType(auxPathType);
    _handleNext(auxUser);
  }

  // Ofuscará el value pasado por parámetro, con '*', los 3 últimos caracteres si es un teléfono, y si es un email desde la @ hasta el final.
  const _ofuscateData = (value, mode) => {
    let ofuscatedPart = "";
    let number = 0;

    if (mode === "email") {
      let position = value.indexOf("@") + 1;
      number = value.length - position;
    }

    if (mode === "phone") {
      value = value.toString();
      number = 3;
    }

    if (mode === "identifierDocument") {
      number = 3;
    }

    for (let i = 1; i <= number; i++) {
      ofuscatedPart = ofuscatedPart + "*";
    }

    const ofuscatedText =
      value.substring(0, value.length - number) + ofuscatedPart;
    return ofuscatedText;
  };

  // Método que busca los emails o teléfonos o dni validados
  const _handleVerfiedData = (value, type) => {
    let verifiedData = [];
    if (type === "identifierDocument") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 7); // Sustituir por Enum??
    }

    if (type === "email") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 2); // Sustituir por Enum??
    }

    if (type === "phone") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 5); // Sustituir por Enum??
    }

    if (verifiedData.length === 0) {
      return [];
    } else {
      return verifiedData;
    }
  };

  // FUNCIONES MODAL

  // Función cerrar modal
  const _handleClose = () => {
    setOpenModal(false);
  };

  // Función abrir modal con la cuenta seleccionada
  const _handleOpen = (index, x) => {
    let value = parseInt(index);
    if (value >= 0) {
      setUserSelectedData(data.userData[value]);
    } else {
      setUserSelectedData({});
    }
    setOption(x);
    setOpenModal(true);
  };

  const _formatPhone = (value) => {
    return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4");
  };

  return (
    <Grid>
      <Container style={{ marginTop: "2em" }}>
        <Grid
          style={{ margin: "0 auto" }}
          container
          item
          justify="center"
          spacing={1}
          md={10}
          xs={12}
        >
          <Grid item md={12} xs={12}>
            <FormLabel component="legend" style={{ color: "grey" }}>
              {spanishLanguage["atcAccountsFounds"] +
                ' - "' +
                registryData.identifier +
                '". \n' +
                spanishLanguage["atcAccountsFounds2"]}
            </FormLabel>


            <Grid container>
              {data.userData.map((account, index) => (
                <Grid
                  xs={12}
                  md={6}
                  style={{ margin: data.userData.length >= 2 ? "" : "auto" }}
                >
                  <Card className={classes.cardStyles}>
                    <Grid item xs={12} className={classes.titleText}>
                      <Typography variant="h6">
                        {spanishLanguage["atcUserVerifiedData"]}
                      </Typography>
                    </Grid>

                    {_handleVerfiedData(account.userEmail, "email").length >
                      0 ? (
                      <Grid
                        container
                        style={{
                          alignContent: "center",
                          alignItems: "center",
                          height: "3em",
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          align="left"
                          style={{ marginLeft: "2em" }}
                        >
                          <MdMail className={commonClasses.iconStyle} />
                        </Grid>
                        <Grid item xs={9} align="left">
                          <Typography noWrap>
                            {
                              _handleVerfiedData(
                                account.userEmail,
                                "email"
                              )[0].email
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {_handleVerfiedData(account.userEmail, "email")
                            .length > 1 ? (
                            <Tooltip title={spanishLanguage["atcShowEmail"]}>
                              <IconButton className={classes.iconButton}>
                                <AiOutlineFileSearch
                                  onClick={() => {
                                    _handleOpen(index, "email");
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}

                    {_handleVerfiedData(account.userPhone, "phone").length >
                      0 ? (
                      <Grid
                        xs={12}
                        container
                        style={{
                          alignContent: "center",
                          alignItems: "center",
                          height: "3em",
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          align="left"
                          style={{ marginLeft: "2em" }}
                        >
                          <MdPhoneAndroid
                            className={commonClasses.iconStyle}
                          />
                        </Grid>
                        <Grid item xs={9} align="left">
                          <Typography>
                            {"+" +
                              getPhonePrefixCountry(countryList, account.userPhone[0].idCountry, "id").phonePrefix +
                              " " +
                              _formatPhone(
                                _handleVerfiedData(
                                  account.userPhone,
                                  "phone"
                                )[0].phone.toString()
                              )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {_handleVerfiedData(account.userPhone, "phone")
                            .length > 1 ? (
                            <Tooltip title={spanishLanguage["atcShowPhone"]}>
                              <IconButton className={classes.iconButton}>
                                <AiOutlineFileSearch
                                  onClick={() => {
                                    _handleOpen(index, "phone");
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}

                    {_handleVerfiedData(
                      account.userDocument,
                      "identifierDocument"
                    ).length > 0 ? (
                      <Grid
                        container
                        xs={12}
                        style={{
                          alignContent: "center",
                          alignItems: "center",
                          height: "3em",
                        }}
                      >
                        <Grid
                          xs={1}
                          align="left"
                          style={{ marginLeft: "2em" }}
                        >
                          <FaIdCard className={commonClasses.iconStyle} />
                        </Grid>
                        <Grid xs={9} align="left">
                          <Typography>
                            {account.userDocument.length === 0
                              ? ""
                              : _handleVerfiedData(
                                account.userDocument,
                                "identifierDocument"
                              ).length === 0
                                ? ""
                                : _ofuscateData(
                                  _handleVerfiedData(
                                    account.userDocument,
                                    "identifierDocument"
                                  )[0].number,
                                  "identifierDocument"
                                )}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {_handleVerfiedData(
                            account.userDocument,
                            "identifierDocument"
                          ).length > 1 ? (
                            <IconButton className={classes.iconButton}>
                              <AiOutlineFileSearch
                                color="primary"
                                onClick={() => {
                                  _handleOpen(index, "IdDocument");
                                }}
                              />
                            </IconButton>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}

                    {UserGroupActions.includes(GroupActions.SLU) ? (
                      <CardContent>
                        <CorreosButton
                          variant="contained"
                          color="primary"
                          onClick={() => _handleOpenDialog(index)}
                          className={classes.selectAccount}
                        >
                          {spanishLanguage["atcSelectCreateAccountSelect"]}
                        </CorreosButton>
                      </CardContent>
                    ) : null}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: "2vh" }}>
          {UserGroupActions.includes(GroupActions.RUS) ? (
            <CorreosButton
              className={classes.agreeButtons}
              variant="contained"
              color="primary"
              onClick={handleCreateNewAccount}
              style={{ float: "right" }}
            >
              {spanishLanguage["atcNewAccount"]}
            </CorreosButton>
          ) : null}

          <CorreosButton
            onClick={() => changeStep(0, 0)}
            className={classes.nextButton}
          >
            {spanishLanguage["previous"]}
          </CorreosButton>
        </Grid>

        {openModal ? (
          <AtcModal
            open={openModal}
            handleClose={_handleClose}
            userData={userSelectedData}
            option={option}
            prefixPhone={data.prefixPhone}
            countryList={countryList}
          />
        ) : null}
      </Container>
    </Grid>
  );
}
