import { makeStyles } from '@material-ui/core/styles';

export const AtcModalStyles = makeStyles((theme) => ({
    agreeButtons: {
        margin: "2px !important",
        border: "2px solid #FFCD00",
        "&:hover": {
          border: "2px solid #FFCD00",
        },
    },    
    number: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0
        }
      },
    formSelectorControl: {
      minWidth: "10.5vh",
      marginRight: "0.5vh",
      "& .MuiFilledInput-underline": {
        "&:before": {
          border: "1px solid #FFCD00",
        },
        "&:hover:before": {
          border: "2px solid #FFCD00",
        },
        "&:after": {
          border: "2px solid #002453",
        },
      },
    },  
    paper: { 
      minWidth: "35em",
      [theme.breakpoints.down("sm")]: {
        margin:"auto"
      }    
    }, 
    dialogClosebutton: {
      position: 'absolute',
      right: theme.spacing.unit / 2,
      top: theme.spacing.unit / 2,      
    },  
    dialogActions: {
      paddingBottom:"1.5em", 
      paddingRight:"1.5em"
    }, 
    iframe: {
      width: "100%",
      height: "45em"
    },    
    modalWidth: {
      display: "block"
    },
    listItem:{
      paddingLeft: "1em", 
      marginLeft: "2em"
    },
    

}));