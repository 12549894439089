import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../../commons/CommonStyles";
import { spanishLanguage } from "../../../config/language/spanishLanguage";

export function DialogConfirmNext(props) {
  const { setShowMessageInfo, handleNext, showMessageInfo } = props;
  const commonClasses = CommonStyles();

  useEffect(() => {}, []);

  const handleCancel = () => {
    setShowMessageInfo(false);
  };

  const handleConfirm = () => {
    handleNext();
    setShowMessageInfo(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showMessageInfo}
      onClose={() => handleCancel()}
      className={commonClasses.generalDialog}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={11}>
            <Typography
              variant="h6"
              className={commonClasses.dialogTitleText}
            >
              {spanishLanguage["usersaveData"]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="inherit"
              className={commonClasses.dialogClosebutton}
              color="inherit"
              aria-label="menu"
              onClick={() => handleCancel()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography className={commonClasses.dialogContentText}>
              {spanishLanguage["userDataWarningMessage"]}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item xs={6}>
              <Button
                size="small"
                onClick={() => handleCancel()}
                className={commonClasses.dialogButtonLeft}
              >
                {spanishLanguage["cancel"]}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleConfirm()}
                className={commonClasses.dialogButtonRight}
              >
                {spanishLanguage["accept"]}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
