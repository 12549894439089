import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Container,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  GetUserEnterprises,
  GetEnterpriseSelect,
} from "../../../services/ReasonService";
import { CommonStyles } from "../../../commons/CommonStyles";
import {
  moveToOtherApp,
  setUserSession,
  getApplicationOid,
} from "../../../services/Commons";
import { AtcSelectCreateEnterpriseStyles } from "./AtcSelectCreateEnterpriseStyles";
import { checkIsNullUndefined } from "../../../services/Utilities";
import { GroupActions } from "../../../commons/Enums";

export function AtcSelectCreateEnterprise(props) {
  const classes = AtcSelectCreateEnterpriseStyles();
  const commonClasses = CommonStyles();
  const infoTypes = ["companyName", "tradeName", "documentNumber"];
  const { userSelectedData, moveToApp, changeStep, setEnterpriseId, enterpriseId } = props;
  const [circularProgress, setCircularProgress] = useState(false);
  const [generalCircularProgress, setGeneralCircularProgress] = useState(true);

  // Contiene el valor del usuario encontrado en BD
  const [userEnterprises, setuserEnterprises] = useState([]);
  const [UserGroupActions, setUserGroupActions] = useState([]);


  useEffect(() => {
    setCircularProgress(true);
    let auxUserGroupActions = sessionStorage.getItem("UserGroupActions");
    setUserGroupActions(auxUserGroupActions);
    if (moveToApp) {
      let auxSubscribedEnterprises = userSelectedData.enterpriseSubscribedToApp.filter((enterprise) => enterprise.isSuscribedToApp == true);
      if (auxSubscribedEnterprises.length === 1) {
        setEnterpriseId(auxSubscribedEnterprises[0].idEnterprise);
      } else {
        manageEnterprisesInfo(auxUserGroupActions, auxSubscribedEnterprises);
      }
    } else {
      manageEnterprisesInfo(auxUserGroupActions);
    }
  }, []);

  useEffect(() => {
    if (!checkIsNullUndefined(enterpriseId)) {
      handleNext();
    }
  }, [enterpriseId]);

  const manageEnterprisesInfo = (auxUserGroupActions, auxSuscribedEnteprises) => {
    if (auxUserGroupActions.includes(GroupActions.BEP)) {
      GetUserEnterprises().then((response) => {
        if (response && !response.status) {
          if (response.length > 0) {
            if (moveToApp) {
              let auxSuscribedEnteprisesInfo = [];
              auxSuscribedEnteprises.forEach((subscribedEnterprise) => {
                response.forEach((enteprise) => {
                  if (subscribedEnterprise.idEnterprise === enteprise.id) {
                    auxSuscribedEnteprisesInfo.push(enteprise);
                  }
                })
              });
              setuserEnterprises(auxSuscribedEnteprisesInfo);
              setGeneralCircularProgress(false);
            } else {
              setuserEnterprises(response);
              setGeneralCircularProgress(false);
            }
          } else {
            _handleCreateEnterprise();
          }
          setCircularProgress(false);
          setGeneralCircularProgress(false);
        }
      });
    }
  };

  const handleNext = () => {
    let auxEnterpriseErrorList = [202, 203, 206, 207];
    GetEnterpriseSelect(enterpriseId).then((response) => {
      if (response && (!response.Status || auxEnterpriseErrorList.includes(response.Status))) {
        let auxIsCorrectEnterprise = !response.Status;
        setUserSession(response, getApplicationOid()).then(() => {
          if (moveToApp && auxIsCorrectEnterprise) {
            moveToOtherApp();
          } else {
            changeStep(7, 6);
            setCircularProgress(false);
            setGeneralCircularProgress(false);
          }
        });
      } else {
        setGeneralCircularProgress(false);
      }
    });
  };

  const _handleCreateEnterprise = () => {
    setEnterpriseId(null);
    changeStep(7, 6);
    setGeneralCircularProgress(false);
  };

  const handleGoBack = () => {
    setEnterpriseId(null);
    changeStep(5, 0);
    setGeneralCircularProgress(false);
  };

  const handleCard = (infoType) => {
    let text = "";
    switch (infoType) {
      case "companyName":
        text = spanishLanguage["atcSelectCreateEnterpriseName"];
        break;
      case "tradeName":
        text = spanishLanguage["atcSelectCreateEnterpriseRason"];
        break;
      case "documentNumber":
        text = spanishLanguage["atcSelectCreateEnterpriseDoc"];
        break;
      default:
        break;
    }

    return text;
  };

  return (
    <Grid>
      {generalCircularProgress ? <CircularProgress></CircularProgress> : (
        <Grid>
          <Container>
            <Grid
              style={{ margin: "0 auto", marginTop: "1em" }}
              container
              item
              justify="center"
              spacing={1}
              md={10}
              xs={12}
            >
              <Grid item xs={12}>
                <Typography
                  color="secondary"
                  align="center"
                  className={commonClasses.hitText}
                >
                  {!UserGroupActions.includes(GroupActions.BEP)
                    ? spanishLanguage["atcSelectCreateEnterpriseCant"]
                    : UserGroupActions.includes(GroupActions.SLE) &&
                      UserGroupActions.includes(GroupActions.REP)
                      ? spanishLanguage["atcSelectCreateEnterpriseBoth"]
                      : UserGroupActions.includes(GroupActions.SLE) &&
                        !UserGroupActions.includes(GroupActions.REP)
                        ? spanishLanguage["atcSelectCreateEnterpriseSel"]
                        : !UserGroupActions.includes(GroupActions.SLE) &&
                          UserGroupActions.includes(GroupActions.REP)
                          ? spanishLanguage["atcSelectCreateEnterpriseCre"]
                          : spanishLanguage["atcSelectCreateEnterpriseNone"]}
                </Typography>
              </Grid>
              <Grid container>
                {userEnterprises.map((enterprise) => (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    style={{ margin: userEnterprises.length >= 2 ? "" : "auto" }}
                    key={enterprise.id}
                  >
                    <Card className={classes.cardStyles}>
                      <Grid item xs={12} className={classes.titleText}>
                        <Typography variant="h6">
                          {spanishLanguage["atcSelectCreateEnterpriseData"]}
                        </Typography>
                      </Grid>
                      {infoTypes.map((infoType) => {
                        return (
                          <Grid container item xs={12}>
                            {!checkIsNullUndefined(enterprise[infoType]) ? (
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={1}
                                key={enterprise[infoType]}
                              >
                                <Grid item xs={4}>
                                  <Typography
                                    color="secondary"
                                    align="left"
                                    className={classes.remarcar}
                                  >
                                    {handleCard(infoType)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography noWrap align="left">
                                    {enterprise[infoType]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                        );
                      })}
                      <Grid container>
                        {UserGroupActions.includes(GroupActions.SLE) ? (
                          <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={() => setEnterpriseId(enterprise.id)}
                            className={classes.selectAccount}
                            circularProgress={
                              circularProgress && enterpriseId === enterprise.id
                            }
                          >
                            {spanishLanguage["atcSelectCreateEnterpriseSelect"]}
                          </CorreosButton>
                        ) : null}
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Container>

          <Grid style={{ marginTop: "2vh" }}>
            <CorreosButton onClick={handleGoBack} className={classes.nextButton}>
              {spanishLanguage["previous"]}
            </CorreosButton>
            {UserGroupActions.includes(GroupActions.REP) ? (
              <CorreosButton
                className={classes.agreeButtons}
                variant="contained"
                color="primary"
                onClick={() => _handleCreateEnterprise()}
                style={{ float: "right" }}
              >
                {spanishLanguage["atcCreateEnterprise"]}
              </CorreosButton>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
