import React, { useEffect } from "react";
import { Grid, Typography, Card, CardMedia, Container, CardContent} from "@material-ui/core";
import { StylesServiceList } from "./StylesServiceList";
import { getSelectedSubApp } from "../../services/Commons";

export function ServiceList(props) {

    const { subApplication, handleSubApplication } = props;
    const classes = StylesServiceList();

    useEffect(() => {
        // Necesario para pintar el título de la cabecera si se punsa F5.
        if (!subApplication) {
            let subApp = getSelectedSubApp();
            handleSubApplication(subApp);
        }
    }, []);

    let response = [
        {applicationOid: "f12c9720-c1c7-4edc-9410-c51086f5a42f", 
        idApplicationType: 3, 
        logo: "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/LOGO-WEB_Cabecera_CorreosPrepago.png", 
        nameApplication: "Prepago Empresa",
        url: "https://correos-prepayws-dev.mo2o.com/v1/enterprise/login/notification"},

        {applicationOid: "abbf0e86-91b9-4f18-bb4a-4e536fc63173", 
        idApplicationType: 3, 
        logo: "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/correosid.jpg", 
        nameApplication: "Correos ID", 
        url: null},

        {applicationOid: "04cc964f-de6b-44f8-8069-826629f67365", 
        idApplicationType: 3,
        logo: "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/correosid.jpg",
        nameApplication: "Panacea",
        url: "https://panacea-pub.correosdesa.es"}]
    
    return (
        <Grid container xs={12}
        >
            {response.map((value)=>
            <Grid lg={6} xs={12}>
            <Container  xs={12}
                className={classes.root}
                >
                <Card>
                    <Grid 
                        container
                        className = {classes.cardStyles}
                        xs={12}
                    >
                        <Grid item container  xs={7}>
                            <CardMedia className={classes.media}>
                                <img
                                    className={classes.imgCardMedia}
                                    src={
                                      value.logo == null
                                        ? process.env.REACT_APP_CORREOS_ID_LOGO
                                        : value.logo
                                    }
                                    alt="Logo"
                                />
                            </CardMedia>
                        </Grid>


                        <Grid item container xs = {5} className={classes.details}>
                            <CardContent className={classes.details}>
                                <Typography component="h1" variant="h6" noWrap color="textPrimary">
                                    {value.nameApplication}
                                </Typography>

                                {value.idApplicationType !==1 ? (
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {("Suscrito")}
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1" color="textSecondary">
                                        {("No suscrito")}
                                    </Typography>
                                )}
                            </CardContent>
                        </Grid>

                        
                    </Grid>

                    {/* <Grid item  xs = {12} className={classes.GridButtons} >
                        <CorreosButton
                            variant="contained"
                            size="medium"
                            className={classes.ButtonRight}
                            color= "primary"
                        >
                            {t("unsubscribe")}
                        </CorreosButton>
                    </Grid> */}
                </Card>
            </Container>   
            </Grid> 
            )}
        </Grid>
        
  );
}
