import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { DeleteSystemuser } from "../../../services/ReasonService";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { BasicDialog } from "../../CommonComponents/BasicDialog";
import { CorreosButton } from "../../CorreosButton/CorreosButton";

export function DialogDelete(props) {
    const { systemUser, handleCHange, finishOperation } = props;
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const [identifier, setIdentifier] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => {
        let auxIdentifier = checkIsNullUndefined(systemUser.systemUserName) ? systemUser.client_id : systemUser.systemUserName;
        setIdentifier(auxIdentifier);
    }, [systemUser]);

    const handleConfirm = () => {
        setCircularProgress(true);
        DeleteSystemuser(systemUser.client_id).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("userSysAdminDeleted"));
                handleCHange("");
                setOpen(false);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            setCircularProgress(false);
        })
    };

    return (
        <>
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                style={{ float: "center", width: "100%" }}
            >
                <DeleteForeverIcon style={{ marginRight: "0.2em" }} />
                {t("delete")}
            </CorreosButton>
            <BasicDialog
                open={open}
                title={t("userSysAdminDeleteTitle")}
                text={t("userSysAdminDeleteMessage").replace("{user}", identifier)}
                action={handleConfirm}
                circularProgress={circularProgress}
                setOpen={setOpen}
                remoteClose={true}
            ></BasicDialog>
        </>

    );
}
