import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../commons/CommonStyles";
import { checkIsNullUndefined } from "../../services/Utilities";
import { GetExistsUser } from "../../services/ReasonService";
import { spanishLanguage } from "../../config/language/spanishLanguage";

export function AddAttribute(props) {
  const {
    checkIfExistsInLocalModel,
    setOpen,
    setNewValues,
    addAttribute,
    open,
  } = props;
  const validator = require("react-email-validator");
  const commonClasses = CommonStyles();
  

  const [data, setData] = React.useState();
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [emailExists, setEmailExists] = React.useState(true);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (!error && !checkIsNullUndefined(data)) {
      setError(true);
      if (!checkIfExistsInLocalModel(data, "EMA")) {
        GetExistsUser(data).then(async (response) => {
          if (response === false) {
            setError(false);
            setEmailExists(false);
            setErrorText("");
          } else {
            setError(true);
            setEmailExists(true);
            setErrorText(spanishLanguage["verificationsEmailExists"]);
          }
        });
      } else {
        setError(true);
        setEmailExists(true);
        setErrorText(spanishLanguage["verificationsEmailExists"]);
      }
    }
  }, [data]);

  const handleCancel = () => {
    setData("");
    setError(false);
    setOpen(false);
    setErrorText("");
  };

  const handleChangeData = (value) => {
    let okData = validator.validate(value.target.value);
    if (checkIsNullUndefined(value.target.value)) okData = false;
    setData(value.target.value);
    setError(!okData);
    let errorText = okData ? "" : spanishLanguage["registerPageMailError"];
    setErrorText(errorText);
  };

  const handleAdd = () => {
    setNewValues((nv) => [...nv, { value: data, codeAttribute: "EMA" }]);
    addAttribute(data);
    setData();
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleCancel()}
      className={commonClasses.dialogWindow}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6" className={commonClasses.dialogTitleText}>
              {spanishLanguage["userDataAddEmail"]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="inherit"
              className={commonClasses.dialogClosebutton}
              color="inherit"
              aria-label="menu"
              onClick={() => handleCancel()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              color="secondary"
              value={data}
              onChange={(value) => handleChangeData(value)}
              variant="outlined"
              fullWidth
              name="data"
              autoFocus
              label={spanishLanguage["UserEmail"]}
              type="text"
              error={error}
              helperText={error ? errorText : ""}
              required
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={commonClasses.dialogButtonContainer}
          >
            <Grid item md={6} xs={12}>
              <CorreosButton
                onClick={() => handleCancel()}
                className={commonClasses.dialogButtonLeft}
              >
                {spanishLanguage["cancel"]}
              </CorreosButton>
            </Grid>
              <Grid item md={6} xs={12}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleAdd()}
                  className={commonClasses.dialogButtonRight}
                  disabled={checkIsNullUndefined(data) || error || emailExists}
                >
                  {spanishLanguage["add"]}
                </CorreosButton>
              </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
