import React, { useEffect, useState } from "react";
import { AtcSendOtpConsentStyles } from "./AtcSendOtpConsentStyles";
import {
  TextField,
  Typography,
  Grid,
  Container,
  FormControl,
  Select,
  InputLabel,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  GetRegistryAttribute,
  PostAtcOtpSendEmail,
  PostAtcOtpSendPhone,
  GetMustRegistry,
  PostLegalSendTyCs,
  GetUserSubscriptionsByOid,
  PostRegistryByMail,
  PostRegistryByPhone,
  PostSendOtpTycEmail,
  PostSendOtpTycPhone,
  PostEnterpriseSubscription,
  PostRegistryByEmailAndPhone,
  GetApplicationOidByCode,
  PostVerificationValidationOtpEmail,
  PostVerificationValidationOtpPhone,
  PostUserCreateOrUpdateDocument,
} from "../../../services/ReasonService";
import {
  getErrorMessage,
  checkIsNullUndefined,
  getPhonePrefixCountry,
} from "../../../services/Utilities";
import { AtcCreateAccountModal } from "../AtcCreateAccountModal/AtcCreateAccountModal";
import { SetSecretKey } from "../../../services/SecureStorage";
import {
  SubApp,
  SubscriptionState,
  PersonType,
  selectedPersonType,
  PathType,
  AppType,
} from "../../../commons/Enums";
import {
  getCodeApp,
  moveToOtherApp,
  setApplicationOid,
  setUserSession,
} from "../../../services/Commons";
import { CommonStyles } from "../../../commons/CommonStyles";
import { id } from "date-fns/locale";
import { PrefixPhone } from "../../TransversalComponents/PrefixPhone/PrefixPhone";
import { AtcGoToOrNavigateDialog } from "../AtcGoToOrNavigateDialog/AtcGoToOrNavigateDialog";

export function AtcSendOtpConsent(props) {
  const classes = AtcSendOtpConsentStyles();
  const {
    finishOperation,
    userSelectedData,
    changeStep,
    countryList,
    mustAttribute,
    personType,
    pathType,
    registryData,
    setRegistryData,
    setIsUserCorrect,
    isUserCorrect,
    setMoveToApp,
    appType,
  } = props;
  const commonClasses = CommonStyles();

  // Valor del dni o pasaporte;
  // const [identifierDocument, setIdentifierDocument] = useState(
  //   registryData.identifierDocument
  // );
  // Valor del email
  const [email, setEmail] = useState(registryData.email);
  // Valor del número de teléfono
  const [phoneNumber, setPhoneNumber] = useState(registryData.phoneNumber);
  const [country, setCountry] = useState(
    registryData.prefixPhoneCountry ? registryData.prefixPhoneCountry : {}
  );
  // Flag para habilitar/deshabilitar en botón Siguiente
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  // Obtiene los teléfonos validados
  const [validatedPhones, setValidatedPhones] = useState([]);
  // Obtiene los emails validados
  const [validatedEmails, setValidatedEmails] = useState([]);
  // Obtiene los dni/pasaportes validados
  // const [validatedIdentifierDocuments, setValidatedIdentifierDocuments] =
  //   useState([]);
  const [manageEmailCodeOtpError, setManageEmailCodeOtpError] = useState();
  const [messageEmailCodeOtpError, setMessageEmailCodeOtpError] = useState();
  const [managePhoneCodeOtpError, setManagePhoneCodeOtpError] = useState();
  const [messagePhoneCodeOtpError, setMessagePhoneCodeOtpError] = useState();
  const [auxCode, setAuxCode] = useState();
  const [circularProgressSendOtpMail, setCircularProgressSendOtpMail] =
    useState(false);
  const [circularProgressSendOtpPhone, setCircularProgressSendOtpPhone] =
    useState(false);
  const [sendedOtpMail, setSendedOtpMail] = useState(false);
  const [sendedOtpPhone, setSendedOtpPhone] = useState(false);
  const [checkedOtpMail, setCheckedOtpMail] = useState(false);
  const [checkedOtpPhone, setCheckedOtpPhone] = useState(false);
  const [circularProgressCheckOtpMail, setCircularProgressCheckOtpMail] = useState(false);
  const [circularProgressCheckOtpPhone, setCircularProgressCheckOtpPhone] = useState(false);
  const [otpValueEmail, setOtpValueEmail] = useState();
  const [otpValuePhone, setOtpValuePhone] = useState();
  const [auxPersonType, setAuxPersonType] = useState();

  // STATES MODAL
  // Maneja abrir el modal
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    handlePersonType();
    handleCode();
  }, []);

  useEffect(() => {
    _manageFields();
  }, [auxCode]);

  const handleCode = () => {
    let auxiliarCode = "";
    if (
      (mustAttribute.email.mustRegistry && mustAttribute.phone.mustRegistry) ||
      (!mustAttribute.email.mustRegistry && !mustAttribute.phone.mustRegistry)
    ) {
      auxiliarCode = "DOS";
    } else if (
      mustAttribute.email.mustRegistry &&
      !mustAttribute.phone.mustRegistry
    ) {
      auxiliarCode = "EMA";
    } else if (
      !mustAttribute.email.mustRegistry &&
      mustAttribute.phone.mustRegistry
    ) {
      auxiliarCode = "MOV";
    }
    if (pathType === PathType.creation) {
      let auxRegistryData = JSON.parse(JSON.stringify(registryData));
      let auxRegistryIdentifier = "";
      if (auxiliarCode === "EMA" || (auxiliarCode === "DOS" && !checkIsNullUndefined(registryData.email))) {
        auxRegistryIdentifier = registryData.email;
      } else if (auxiliarCode === "MOV" || (auxiliarCode === "DOS" && !checkIsNullUndefined(registryData.phoneNumber))) {
        auxRegistryIdentifier = registryData.prefixPhoneCountry.phonePrefix + "-" + registryData.phoneNumber;
      }
      auxRegistryData.identifier = auxRegistryIdentifier;
      setRegistryData(auxRegistryData);
    }
    setAuxCode(auxiliarCode);
  };

  const handlePersonType = () => {
    if (personType === selectedPersonType.PARTICULAR) {
      setAuxPersonType(PersonType.PARTICULAR);
    } else {
      setAuxPersonType(PersonType.EMPRESA);
    }
  };

  // Función para manejar si se muestran los campos o no y obtener los valores verificados si se ha seleccionado una cuenta
  const _manageFields = () => {
    if (pathType !== PathType.creation) {
      if (auxCode === "DOS" || auxCode === "MOV") {
        if (!checkIsNullUndefined(userSelectedData.userPhone)) {
          // Obtiene los teléfonos validados
          let phones = userSelectedData.userPhone.filter(
            (v) => v.idConTypeVerification === 5
          );
          if (phones.length > 0) {
            setValidatedPhones(phones);
            setPhoneNumber(phones[0].phone);
            setCountry(
              getPhonePrefixCountry(countryList, phones[0].idCountry, "id")
            );
          } else {
            setValidatedPhones([
              {
                id: 0,
                idCountry: registryData.prefixPhoneCountry?.id,
                phone: registryData.phoneNumber,
                idConTypeVerification: 4,
                communication: null,
              },
            ]);
          }
        }
      }
      if (auxCode === "DOS" || auxCode === "EMA") {
        if (!checkIsNullUndefined(userSelectedData.userPhone)) {
          // Obtiene los email validados
          let emails = userSelectedData.userEmail.filter(
            (v) => v.idConTypeVerification === 2
          );

          if (emails.length > 0) {
            setValidatedEmails(emails);
            setEmail(emails[0].email);
          } else {
            setValidatedEmails([
              {
                id: 0,
                email: registryData.email,
                idConTypeVerification: 2,
                communication: null,
              },
            ]);
          }
        }
      }

      // Obtiene los dni/pasaporte validados
      // const identifierDocuments = userSelectedData.userDocument.filter(
      //   (v) => v.idConTypeVerification === 7
      // );

      // if (identifierDocuments.length > 0) {
      //   setValidatedIdentifierDocuments(identifierDocuments);
      //   setIdentifierDocument(identifierDocuments[0].number);
      // }
    }
  };

  const handleOtpSendReturn = (response, value, auxCircularProgress) => {
    let auxOtpSended = value === "email" ? setSendedOtpMail : setSendedOtpPhone;
    if (!response || response.Status) {
      finishOperation("error", getErrorMessage(response));
    } else {
      auxOtpSended(true);
    }
    auxCircularProgress(false);
  }

  const _handleSendOTP = (value) => {
    let auxCircularProgress = value === "email" ? setCircularProgressSendOtpMail : setCircularProgressSendOtpPhone;
    let auxOtpFunction = value === "email" ? PostSendOtpTycEmail : PostSendOtpTycPhone;
    let auxSetOtpValue = value === "email" ? setOtpValueEmail : setOtpValuePhone;
    let auxValue = value === "email" ? email : country.phonePrefix + "-" + phoneNumber;
    auxCircularProgress(true);
    auxSetOtpValue("");
    if (pathType === PathType.navigation) {
      auxOtpFunction = value === "email" ? PostAtcOtpSendEmail : PostAtcOtpSendPhone;
      auxOtpFunction(auxValue).then(
        (response) => { handleOtpSendReturn(response, value, auxCircularProgress) });
    } else {
      auxOtpFunction(getCodeApp(), auxPersonType, auxValue).then(
        (response) => { handleOtpSendReturn(response, value, auxCircularProgress) })
    }
  };

  const handleOtpManager = (event, otpType) => {
    let auxOtpSetter = otpType === "email" ? setOtpValueEmail : setOtpValuePhone;
    handleErrors(otpType, false, "");
    auxOtpSetter(event.target.value);
  }

  const _checkOtpCode = () => {
    let appCode = getCodeApp();
    let flowType = personType == selectedPersonType.PARTICULAR ? 1 : 2;
    GetApplicationOidByCode(appCode).then((responseApp) => {
      if (responseApp && !responseApp.Status) {
        setApplicationOid(responseApp);
        if (auxCode === "EMA") {
          setCircularProgressCheckOtpMail(true);
          if (pathType === PathType.navigation) {
            PostVerificationValidationOtpEmail(email, otpValueEmail.trim(), userSelectedData.oid).then((response) => {
              setCircularProgressCheckOtpMail(false);
              handleManageReturnCheckOtp(response, responseApp);
            });
          } else {
            PostRegistryByMail(appCode, email, otpValueEmail.trim(), flowType).then(
              (response) => {
                setCircularProgressCheckOtpMail(false);
                handleManageReturnCheckOtp(response, responseApp);
              });
          }
        } else if (auxCode === "MOV") {
          setCircularProgressCheckOtpPhone(true);
          if (pathType === PathType.navigation) {
            PostVerificationValidationOtpPhone(
              country.phonePrefix + "-" + phoneNumber,
              otpValuePhone.trim(),
              userSelectedData.oid
            ).then((response) => {
              setCircularProgressCheckOtpPhone(false);
              handleManageReturnCheckOtp(response, responseApp);
            });
          } else {
            PostRegistryByPhone(
              appCode,
              parseInt(country.id),
              phoneNumber.toString(),
              otpValuePhone.trim(),
              userSelectedData.oid,
              flowType
            ).then((response) => {
              setCircularProgressCheckOtpPhone(false);
              handleManageReturnCheckOtp(response, responseApp);
            });
          }
        } else {
          setCircularProgressCheckOtpMail(true);
          setCircularProgressCheckOtpPhone(true);
          PostRegistryByEmailAndPhone(
            appCode,
            email,
            otpValueEmail.trim(),
            parseInt(country.id),
            phoneNumber,
            otpValuePhone.trim(),
            flowType
          ).then((response) => {
            setCircularProgressCheckOtpMail(false);
            setCircularProgressCheckOtpPhone(false);
            handleManageReturnCheckOtp(response, responseApp);
          });
        }
      }
    });
  };

  const handleManageReturnCheckOtp = (response, applicationOid) => {
    let auxUserErrorList = [202, 203, 206, 207];
    if (response && (!response.Status || auxUserErrorList.includes(response.Status))) {
      setIsUserCorrect(!response.Status);
      if (auxCode === "EMA" || auxCode === "DOS") {
        setCheckedOtpMail(true);
      }
      if (auxCode === "MOV" || auxCode === "DOS") {
        setCheckedOtpPhone(true);
      }
      setUserSession(response, applicationOid).then(() => {
        if (!checkIsNullUndefined(registryData.identifierDocument)) {
          let auxDocSelected = pathType === PathType.creation ? [] : userSelectedData.userDocument.filter((doc) => doc.idTypeUserDocument == registryData.identifierDocumentType);
          let auxNeedValidation = auxDocSelected.length == 0 || auxDocSelected[0].idConTypeVerification < 7;
          if (auxNeedValidation) {
            let auxDocId = auxDocSelected.length == 0 ? 0 : auxDocSelected[0].id;
            PostUserCreateOrUpdateDocument(auxDocId, registryData.identifierDocumentType, registryData.identifierDocument, null, null).then((response) => {
              setDisableButtonNext(false);
            })
          } else {
            setDisableButtonNext(false);
          }
        } else {
          setDisableButtonNext(false);
        }
      });
    } else {
      if (response && response.Status === 400) {
        if (auxCode === "EMA" || auxCode === "DOS") {
          handleErrors("email", true, getErrorMessage(response));
        }
        if (auxCode === "MOV" || auxCode === "DOS") {
          handleErrors("phone", true, getErrorMessage(response));
        }
      } else {
        finishOperation("error", getErrorMessage(response));
      }
    }
  };

  const handleErrors = (inputType, flag, message) => {
    let auxSetOtpError = inputType === "email" ? setManageEmailCodeOtpError : setManagePhoneCodeOtpError;
    let auxSetOtpErrorMessage = inputType === "email" ? setMessageEmailCodeOtpError : setMessagePhoneCodeOtpError;
    auxSetOtpError(flag);
    auxSetOtpErrorMessage(message);
  }

  // Ofuscará el value pasado por parámetro, con '*', los 3 últimos caracteres si es un teléfono, y si es un email desde la @ hasta el final.
  const _ofuscateData = (value, mode) => {
    let ofuscatedPart = "";
    let number = 0;

    if (mode === "email") {
      let position = value.indexOf("@") + 1;
      number = value.length - position;
    }

    if (mode === "phone") {
      value = value.toString();
      number = 3;
    }

    if (mode === "identifierDocument") {
      number = 3;
    }

    for (let i = 1; i <= number; i++) {
      ofuscatedPart = ofuscatedPart + "*";
    }

    const ofuscatedText =
      value.substring(0, value.length - number) + ofuscatedPart;
    return ofuscatedText;
  };

  const _handleSelectorChange = (event, inputType) => {
    if (inputType === "email") {
      setEmail(event.target.value);
    } else {
      //TODO modificar también el country
      setPhoneNumber(event.target.value);
    }
  }

  // FUNCIONES MODAL

  // Función cerrar modal
  const _handleClose = () => {
    setOpenModal(false);
  };

  const mountTextField = (inputType) => {
    let auxLabel = inputType === "email" ? "atcRegisterPageEmail" : "atcRegisterPagePhone";
    let auxValue = inputType === "email" ? email : phoneNumber;
    return (
      <TextField
        className={classes.number}
        variant="outlined"
        fullWidth
        id={inputType}
        name={inputType}
        label={spanishLanguage[auxLabel]}
        color="secondary"
        type="text"
        value={auxValue}
        disabled={true}
        required
      />);
  }

  const mountSelect = (inputType) => {
    let auxLabel = inputType === "email" ? "atcRegisterPageEmail" : "atcRegisterPagePhone";
    let auxChecked = inputType === "email" ? checkedOtpMail : checkedOtpPhone;
    let auxValidatedList = inputType === "email" ? validatedEmails : validatedPhones;
    return (
      <FormControl
        variant="outlined"
        className={classes.formSelectorControl}
        color="secondary"
        fullWidth
      >
        <InputLabel
          label={spanishLanguage[auxLabel]}
          color="secondary"
        >
          {spanishLanguage[auxLabel]}
        </InputLabel>
        <Select
          native
          id={inputType + "Selector"}
          name={inputType + "Selector"}
          label={spanishLanguage[auxLabel]}
          left
          variant="outlined"
          onChange={(e) => _handleSelectorChange(e, inputType)}
          disabled={auxChecked}
        >
          {auxValidatedList.map((value, index) => (
            <option key={inputType + index} value={value[inputType]}>
              {_ofuscateData(value[inputType], inputType)}
            </option>
          ))}
        </Select>
      </FormControl>);
  }

  const mountSendButton = (inputType) => {
    let auxCircularProgress = inputType === "email" ? circularProgressSendOtpMail : circularProgressSendOtpPhone;
    let auxSendedOtp = inputType === "email" ? sendedOtpMail : sendedOtpPhone;
    let auxDisabled = inputType === "email" ? circularProgressCheckOtpMail : circularProgressCheckOtpPhone;
    let auxSendText = inputType === "email" ? "atcSendEmailButton" : "atcSendSmsButton";
    let auxResendText = inputType === "email" ? "atcResendEmailButton" : "atcResendSmsButton";
    let auxChecked = inputType === "email" ? checkedOtpMail : checkedOtpPhone;
    return (
      <CorreosButton
        circularProgress={auxCircularProgress}
        className={classes.agreeButtons}
        variant={auxSendedOtp ? "outlined" : "contained"}
        color={auxSendedOtp ? "secondary" : "primary"}
        onClick={() => _handleSendOTP(inputType)}
        disabled={auxDisabled || auxChecked}
      >
        {auxSendedOtp
          ? spanishLanguage[auxResendText]
          : spanishLanguage[auxSendText]}
      </CorreosButton>);
  }

  const mountOtpForm = (inputType) => {
    let auxOtpValue = inputType === "email" ? otpValueEmail : otpValuePhone;
    let auxOtpError = inputType === "email" ? manageEmailCodeOtpError : managePhoneCodeOtpError;
    let auxOtpErrorMessage = inputType === "email" ? messageEmailCodeOtpError : messagePhoneCodeOtpError;
    let auxCheckedOtp = inputType === "email" ? checkedOtpMail : checkedOtpPhone;
    let auxCircularProgress = inputType === "email" ? circularProgressCheckOtpMail : circularProgressCheckOtpPhone;
    let auxCircularProgressSend = inputType === "email" ? circularProgressSendOtpMail : circularProgressSendOtpPhone;
    return (
      <Grid container item xs={12} className={classes.container} spacing={1}>
        <Grid item xs={12} md={10}>
          <TextField
            fullWidth
            variant="outlined"
            id={inputType + "otp"}
            name={inputType + "otp"}
            label={spanishLanguage["atcRegisterPageSendOTP"]}
            color="secondary"
            onChange={(e) => handleOtpManager(e, inputType)}
            value={auxOtpValue}
            disabled={auxCheckedOtp}
            error={auxOtpError}
            helperText={auxOtpError ? auxOtpErrorMessage : ""}
            InputProps={
              auxCheckedOtp
                ? { endAdornment: <CheckIcon /> }
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <CorreosButton
            circularProgress={auxCircularProgress}
            className={classes.agreeButtons}
            variant={"contained"}
            color={"primary"}
            id={inputType + "checkOtpCode"}
            name={inputType + "checkOtpCode"}
            onClick={_checkOtpCode}
            disabled={checkIsNullUndefined(auxOtpValue) || auxCircularProgressSend || auxCheckedOtp}
          >
            {pathType === PathType.creation
              ? spanishLanguage["atcCreateAccount"]
              : spanishLanguage["atcCheckOtpCode"]}
          </CorreosButton>
        </Grid>
      </Grid>);
  }

  // Cambia al siguiente paso y guarda los datos necesarios para el siguiente paso en "props"
  const _handleNext = () => {
    if (pathType === PathType.navigation) {
      if (appType == AppType.particular) {
        // setOpenModal(true);
        handleMoveToApp();
      } else {
        let auxSubscribedEnteprises = userSelectedData.enterpriseSubscribedToApp.filter((enterprise) => enterprise.isSuscribedToApp == true);
        if (auxSubscribedEnteprises.length > 0) {
          // setOpenModal(true);
          handleMoveToApp();
        } else {
          changeStep(5, 0);
        };
      };
    } else {
      changeStep(5, 0);
    };
  };

  //Mientras no tengan notificador
  const handleMoveToApp = () => {
    setMoveToApp(true);
    if (!isUserCorrect) {
      changeStep(5, 0);
    } else {
      if (appType === AppType.particular) {
        moveToOtherApp();
      } else {
        changeStep(6, 5);
      };
    };
  };

  return (
    <Grid
      container
      spacing={1}
      className={commonClasses.atcContainer}
      xs={12}
    >
      <Grid container spacing={1} xs={12}>
        <Grid item md={12} sm={12} xs={12} style={{ marginBottom: "3vh" }}>
          <Typography style={{ color: "#666666" }}>
            {spanishLanguage["atcOtpText"]}
          </Typography>
        </Grid>

        {auxCode === "EMA" || auxCode === "DOS" ? (
          <Container xs={12} spacing={1}>
            <Grid container spacing={1} className={classes.container}>
              <Grid item xs={12} md={8}>
                {pathType === PathType.creation || validatedEmails.length === 0 ? (
                  mountTextField("email")
                ) : (mountSelect("email"))}
              </Grid>
              <Grid item xs={12} md={4}>
                {mountSendButton("email")}
              </Grid>
              {sendedOtpMail ? (mountOtpForm("email")) : null}
            </Grid>
          </Container>
        ) : null}
        {auxCode === "MOV" || auxCode === "DOS" ? (
          <Container xs={12} spacing={1}>
            <Grid container spacing={1}>
              <Grid container item xs={12} className={classes.container} spacing={1}>
                <Grid item xs={12} md={3}>
                  <PrefixPhone
                    country={country}
                    countryList={countryList}
                    // handleSelectorChange={handleSelectorChange}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  {pathType === PathType.creation || validatedPhones.length === 0 ? (
                    mountTextField("phone")
                  ) : (mountSelect("phone"))}
                </Grid>
                <Grid item xs={12} md={2}>
                  {mountSendButton("phone")}
                </Grid>
              </Grid>
              {sendedOtpPhone ? (mountOtpForm("phone")) : null}
            </Grid>
          </Container>
        ) : null}
        {/* {sendedOtpPhone ? (mountOtpForm("phone")) : null} */}
      </Grid>

      <Grid className={commonClasses.divButtons} item xs={12}>
        <CorreosButton
          onClick={() => changeStep(3, 0)}
          className={commonClasses.leftButton}
          disabled={!disableButtonNext}
        >
          {spanishLanguage["previous"]}
        </CorreosButton>

        <CorreosButton
          className={commonClasses.rightButton}
          variant="contained"
          color="primary"
          onClick={_handleNext}
          disabled={disableButtonNext}
        >
          {spanishLanguage["next"]}
        </CorreosButton>
      </Grid>

      {openModal ? (
        <AtcGoToOrNavigateDialog
          open={openModal}
          handleClose={_handleClose}
          changeStep={changeStep}
          isUserCorrect={isUserCorrect}
          setMoveToApp={setMoveToApp}
          appType={appType}
        />
      ) : null}
    </Grid>
  );
}
