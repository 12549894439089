export const Constants = {
    DrawerWidth: "5em",
    HeaderHeight: "7.6em",
    MarginTop: "15em",
    MarginTopMobile: "10em",
    MarginTopApp: "10em",
    Si: "SI",
    No: "NO",
    lvlVerification: {
        low: 1,
        medium: 2,
        high: 3
    },
    DaysForCancelAccount: 30,
    CorreosIdLogoUrl: "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/correosid.jpg",
    LoginRetryLimit: 3,
    minDate: "1800-01-01",
    maxDate: "8000-01-01",
};