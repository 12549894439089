import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import {
  PostUsersExport,
  GetApplicationOidByCode
} from "../../services/ReasonService";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { UsersExportStyles } from "./UsersExportStyles";
import { RolLevels, SubApp } from "../../commons/Enums";

export function UsersExport(props) {

  const { finishOperation, userGroupActions, handleSetAppsSelectOptions, handleShowAppsSelect, appCode } = props;

  const [circularProgress, setCircularProgress] = useState(false);
  const [handleFile, setHandleFile] = useState(null);
  const [checkFillOids, setCheckFillOids] = useState(false);
  const [checkFillEids, setCheckFillEids] = useState(false);
  const [enableGenerateButton, setEnableGenerateButton] = useState(false);
  const hiddenFileInput = useRef(null);

  const commonClasses = CommonStyles();
  const classes = UsersExportStyles();

  useEffect(() => {
    handleSetAppsSelectOptions(SubApp.AAP, RolLevels.N1);
    handleShowAppsSelect(false);
  }, []);

  useEffect(() => {
    let enableButton = !checkIsNullUndefined(handleFile) && (checkFillOids || checkFillEids);
    setEnableGenerateButton(enableButton);

  }, [checkFillOids, checkFillEids, handleFile]);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    let fileUploaded = event.target.files[0];
    let maxSize = 268435456;

    if (!checkIsNullUndefined(fileUploaded)) {
      // Comprobar tamaño del archivo
      if (fileUploaded.size > maxSize) {
        finishOperation("error", spanishLanguage["uexpFileTooBig"]);
        setHandleFile(null);
        return;
      }
      // Comprobar tipo de archivo
      if (fileUploaded.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        finishOperation("error", spanishLanguage["uexpUnsupportedFileType"]);
        setHandleFile(null);
        return;
      }

      setHandleFile(fileUploaded);
    } else {
      setHandleFile(null);
    }
  };

  const _handleSubmit = () => {
    setCircularProgress(true);
    GetApplicationOidByCode(appCode).then((responseApp) => {
      if (responseApp && !responseApp.Status) {
        PostUsersExport(handleFile, checkFillOids, checkFillEids, responseApp).then((response) => {

          if (!response || response.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {

            let url = URL.createObjectURL(new Blob([response], { type: response.type }));
            const link = document.createElement('a');
            link.href = url;
            let timestamp = Date.now();
            let fileName = `[UPDATED-${timestamp}] - ` + handleFile.name;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setCheckFillOids(false);
            setCheckFillEids(false);
          }
          setCircularProgress(false);
        });
      }
    });
  };

  return (

    <Grid container xs={12}>
      <Grid item
        xs={12} className={classes.marginBottom}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {spanishLanguage["uexpTitle"]}
        </Typography>
        <Typography
          align="center"
          className={commonClasses.hitText}
          style={{ marginTop: "2em" }}
        >
          {spanishLanguage["uexpSubTitle"]}
          <a href="files\UsersExport\plantilla.xlsx" download>
            <CorreosButton
              onClick={() => { }}
            >
              {spanishLanguage["uexpDownloadTemplate"]}
            </CorreosButton>
          </a>
        </Typography>

      </Grid>
      {userGroupActions.includes("AUE") ?
        <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
          <Grid container item xs={12} style={{ marginTop: "1em" }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3} >
              <CorreosButton
                variant="contained"
                color="primary"
                className={commonClasses.rightButton}
                onClick={handleClick}>
                {spanishLanguage["uexpSelectFile"]}
              </CorreosButton>
            </Grid>
            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginLeft: "1em" }}>{checkIsNullUndefined(handleFile) ? spanishLanguage["uexpNoFileSelected"] : handleFile.name}</span>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
              />
            </Grid>
            <Grid item xs={3} ></Grid>
          </Grid>
          <Grid container item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3em" }}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox checked={checkFillOids}
                  onChange={(e) => setCheckFillOids(e.target.checked)} />}
                label={spanishLanguage["uexpFillOids"]}
              />
              <FormControlLabel
                control={<Checkbox checked={checkFillEids}
                  onChange={(e) => setCheckFillEids(e.target.checked)} />}
                label={spanishLanguage["uexpFillOidsEids"]}
              />
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "3em" }}>
            <Grid item xs={3}></Grid>
            {circularProgress
              ?
              <Grid item xs={6}>
                <CircularProgress />
              </Grid>
              :
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  disabled={!enableGenerateButton}
                  onClick={_handleSubmit}>{spanishLanguage["uexpGenerateDocument"]}
                </CorreosButton>
              </Grid>
            }
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
        : null}
    </Grid>

  );
}