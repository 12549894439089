import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Dialog,
  IconButton,
  Container,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import CloseIcon from "@material-ui/icons/Close";
import { AppType, GroupActions } from "../../../commons/Enums";
import { moveToOtherApp } from "../../../services/Commons";

export function AtcGoToOrNavigateDialog(props) {
  const commonClasses = CommonStyles();

  const { open, handleClose, changeStep, isUserCorrect, setMoveToApp, appType } = props;
  const [UserGroupActions, setUserGroupActions] = useState([]);

  useEffect(() => {
    setUserGroupActions(sessionStorage.getItem("UserGroupActions"));
  }, []);

  const handleNext = () => {
    setMoveToApp(false);
    changeStep(5, 0);
  }

  const handleMoveToApp = () => {
    setMoveToApp(true);
    if (!isUserCorrect) {
      changeStep(5, 0);
    } else {
      if (appType === AppType.particular) {
        moveToOtherApp();
      } else {
        changeStep(6, 5);
      }
    }
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      className={commonClasses.dialogWindow}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6" className={commonClasses.dialogTitleText}>
              {spanishLanguage["atcFluxUserDialogOptions"]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="inherit"
              className={commonClasses.dialogClosebutton}
              color="inherit"
              aria-label="menu"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>

      <Grid className={commonClasses.dialogContentText}>
        <Typography
          component="legend"
          className={commonClasses.dialogLegendText}
        >
          {spanishLanguage["atcSelectAcount"]}
        </Typography>
      </Grid>

      <Container className={commonClasses.dialogContainer}>
        <Grid
          container
          item
          xs={12}
          className={commonClasses.dialogButtonContainer}
        >
          <Grid item xs={6}>
            <CorreosButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={commonClasses.dialogButtonLeft}
            >
              {UserGroupActions.includes(GroupActions.EUS)
                ? spanishLanguage["atcFluxUserDialogModify"]
                : spanishLanguage["atcFluxUserDialogSee"]}
            </CorreosButton>
          </Grid>

          <Grid item xs={6}>
            <CorreosButton
              size="small"
              variant="contained"
              color="primary"
              onClick={() => handleMoveToApp()}
              className={commonClasses.dialogButtonRight}
            >
              {spanishLanguage["atcFluxUserDialogGoTo"]}
            </CorreosButton>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
