import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { CorreosButton } from "../../CorreosButton/CorreosButton";

export default function DialogAddName(props) {
    const { handleChangeApp } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [systemUserName, setSystemUserName] = useState("");

    useEffect(() => { }, []);

    const handleCancel = () => {
        setSystemUserName("");
        setOpen(false);
    };

    const handleConfirm = () => {
        handleChangeApp(systemUserName);
        handleCancel();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChangeName = (value) => {
        setSystemUserName(value);
    };

    return (
        <Grid>
            <CorreosButton
                onClick={handleOpen}
                className={dialogClasses.dialogButtonCenter}
            >
                {t("userSysAdminAddName")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userSysAdminAddNameTitle")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                {t("userSysAdminCreateSubtitle")}
                            </Typography>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="idSearcher"
                                    label={t("userSysAdminCreateLabel")}
                                    name="idSearcher"
                                    color="secondary"
                                    onChange={(event) => handleChangeName(event.target.value)}
                                    value={systemUserName}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    color="secondary"
                                    className={dialogClasses.dialogButtonLeft}
                                    onClick={handleCancel}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    className={dialogClasses.dialogButtonRight}
                                    onClick={handleConfirm}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </Grid>
    );
}
