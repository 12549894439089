import React, { useEffect, useState } from "react";
import {
    Tooltip,
    IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { UserAdminStyles } from "../UserAdminStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { PostCancelAccount } from "../../../services/ReasonService";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { BasicDialog } from "../../CommonComponents/BasicDialog";
import { CorreosButton } from "../../CorreosButton/CorreosButton";

export function DialogCancelAccount(props) {
    const { user, handleNext, identifier, finishOperation } = props;
    const classes = UserAdminStyles();
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => { }, []);

    const handleConfirm = () => {
        setCircularProgress(true);
        PostCancelAccount({ userOid: user.userOid }).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("udelSuccessDeleteMessage"));
                handleNext();
                setOpen(false);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            setCircularProgress(false);
        })
    };

    return (
        <>
            <CorreosButton
                variant="outlined"
                color="secondary"
                onClick={() => setOpen(true)}
                disabled={!checkIsNullUndefined(user.fecCancelAccount)}
            >
                <DeleteForeverIcon style={{ marginRight: "0.2em" }} />
                {t("userAdminDelete")}
            </CorreosButton>
            {/* <Tooltip title={t("userAdminDelete")}>
                <IconButton
                    aria-label="menu"
                    style={{ padding: "0px", margin: "auto" }}
                    onClick={() => setOpen(true)}
                    disabled={!checkIsNullUndefined(user.fecCancelAccount)}
                >
                    <DeleteForeverIcon className={classes.iconActionStyle} />
                </IconButton>
            </Tooltip> */}
            <BasicDialog
                open={open}
                title={t("udelConfirmationTitle")}
                text={t("udelDeletionMessage").replace("{user}", identifier)}
                action={handleConfirm}
                circularProgress={circularProgress}
                setOpen={setOpen}
                remoteClose={true}
            ></BasicDialog>
        </>

    );
}
