import React from "react";
import { CommonStyles } from "../../../commons/CommonStyles";
import { BackgroundAtcStyles } from "./BackgroundAtcStyles";
import { Paper, Typography, Grid, Container } from "@material-ui/core";
import { checkIsNullUndefined } from "../../../services/Utilities";
import { Alerts } from "../../Alerts/Alerts";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { AtcLegalDialog } from "../AtcLegalDialog/AtcLegalDialog";

export function BackgroundAtc(props) {
  const commonClasses = CommonStyles();
  const classes = BackgroundAtcStyles();
  const { size, registry, step } = props;
  const [alert, setAlert] = React.useState({
    open: false,
    color: "",
    message: "",
  });
  const [openDialog, setopenDialog] = React.useState(true);

  const _handleClose = () => {
    setopenDialog(false);
  };

  const getTitle = () => {
    switch (step) {
      case 0:
        return spanishLanguage["atcSearchAcount"];
      case 1:
        return spanishLanguage["atcSelectPersonType"];
      case 2:
        return spanishLanguage["atcRadioSelectAccount"];
      case 3:
        return spanishLanguage["atcRegisterAccountTitle"];
      case 4:
        return spanishLanguage["atcSentOtpTitle"];
      case 5:
        return spanishLanguage["atcUserDataTitle"];
      case 6:
        return spanishLanguage["atcEnterprisesFounds"];
      case 7:
        return spanishLanguage["enterpriseDataTitle"];
      default:
        return "";
    }
  };

  return (
    <Grid className={commonClasses.mainDiv}>
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Container
        className={classes.mainDivLogInPaper}
        maxWidth={checkIsNullUndefined(size) ? "sm" : size}
      >
        <Paper
          elevation={0}
          variant="outlined"
          className={
            checkIsNullUndefined(size)
              ? classes.mainPaperLogin
              : classes.mainPaper
          }
        >
          {!checkIsNullUndefined(registry) &&
          (step !== 1 || (step === 1 && registry)) ? (
            <Grid>
              <Typography component="h1" variant="h4" color="secondary">
                {getTitle()}
              </Typography>
            </Grid>
          ) : null}
          {props.children}
        </Paper>
      </Container>

      {step === 0 && openDialog === true ? (
        <AtcLegalDialog open={openDialog} handleClose={_handleClose} />
      ) : null}
    </Grid>
  );
}
