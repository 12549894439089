import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Tooltip,
} from "@material-ui/core";
import {
    GetActions,
    GetActionsByAppCode,
    PostUpdateActions,
} from "../../../services/ReasonService";
import { checkIsNullUndefined, getErrorMessage, isResultOk, showAlert } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { ApplicationAdminStyles } from "../ApplicationAdminStyles";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Searcher } from "../ApplicationAdminCC";

export function EndpointSelection(props) {
    const {
        finishOperation,
        appCode,
        restartActions
    } = props;

    const theme = useTheme();
    const classes = ApplicationAdminStyles(theme);

    const endpointInfo = { controller: "controller", method: "method", url: "url" };

    const [circularProgress, setCircularProgress] = useState(true);
    const [circularProgressConfirmButton, setCircularProgressConfirmButton] = useState(false);
    const [allEndpoints, setAllEndpoints] = useState([]);
    const [appEndpoints, setAppEndpoints] = useState([]);
    const [searchTermAll, setSearchTermAll] = useState("");
    const [searchCriteriaAll, setSearchCriteriaAll] = useState(endpointInfo.controller);
    const [searchTermApp, setSearchTermApp] = useState("");
    const [searchCriteriaApp, setSearchCriteriaApp] = useState(endpointInfo.controller);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (appCode) {
            getActions();
        }
    }, [appCode, restartActions]);

    const getAllOriginActions = () => {
        return allEndpoints;
    }

    const getActions = async () => {
        try {
            setCircularProgress(true);
            const [auxAppActions, auxAllActions] = await Promise.all([
                GetActionsByAppCode(appCode),
                allEndpoints.length == 0 ? GetActions() : getAllOriginActions(),
            ]);
            if (isResultOk([auxAppActions, auxAllActions])) {
                auxAllActions.forEach((endpoint) => {
                    endpoint.owned = false;
                    endpoint.selected = false;
                    auxAppActions.forEach((endpointApp) => {
                        if (checkEndpoint(endpointApp, endpoint)) {
                            endpoint.owned = true;
                        }
                    });
                });
                setAllEndpoints(auxAllActions);
                setCircularProgress(false);
            } else {
                showAlert([auxAllActions, auxAppActions], finishOperation);
                setCircularProgress(false);
            };
        } catch (error) {
            finishOperation("error", error.message);
        }
    };

    const handleSubmit = () => {
        setCircularProgressConfirmButton(true);
        let auxEndpoints = allEndpoints.filter((endpoint) => endpoint.owned);
        PostUpdateActions(appCode, auxEndpoints).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("appAdmEndUpdateEndpointsMessage"));
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgressConfirmButton(false);
            getActions();
        });
    };

    const handleEndpointClick = (endpointSel) => {
        let auxAllEndpoints = JSON.parse(JSON.stringify(allEndpoints));
        auxAllEndpoints.forEach((endpoint) => {
            if (checkEndpoint(endpointSel, endpoint)) {
                endpoint.selected = !endpoint.selected;
            };
        });
        setAllEndpoints(auxAllEndpoints);
    };

    const handleTransfer = (toApp) => {
        let auxAllEndpoints = JSON.parse(JSON.stringify(allEndpoints));
        auxAllEndpoints.forEach((endpoint) => {
            if (endpoint.selected && endpoint.owned !== toApp) {
                endpoint.owned = toApp;
                endpoint.selected = false;
            };
        });
        setAllEndpoints(auxAllEndpoints);
    };

    const filterEndpoints = (endPoints, searchTerm, searchCriteria) => {
        let auxEndPoints = endPoints;
        if (!checkIsNullUndefined(searchTerm)) {
            auxEndPoints = endPoints.filter((endpoint) => {
                let searchField = searchCriteria === endpointInfo.controller
                    ? endpoint.controller
                    : searchCriteria === endpointInfo.method
                        ? endpoint.method
                        : endpoint.url;
                return searchField.toLowerCase().includes(searchTerm.toLowerCase());
            });
        };
        return auxEndPoints;
    };

    const checkEndpoint = (endpoint, auxEndpoint) => {
        return (endpoint.controller === auxEndpoint.controller &&
            endpoint.method === auxEndpoint.method &&
            endpoint.url === auxEndpoint.url);
    };

    const getSearcher = (auxType) => {
        let auxSearchObj = {
            all: {
                textValue: searchTermAll,
                textOnChange: setSearchTermAll,
                criteriaValue: searchCriteriaAll,
                criteriaOnChange: setSearchCriteriaAll
            },
            app: {
                textValue: searchTermApp,
                textOnChange: setSearchTermApp,
                criteriaValue: searchCriteriaApp,
                criteriaOnChange: setSearchCriteriaApp
            }
        }
        let auxEndpointInfo = [
            { value: endpointInfo.controller, text: t("appAdmTableHeadController") },
            { value: endpointInfo.method, text: t("appAdmTableHeadMethod") },
            { value: endpointInfo.url, text: t("appAdmTableHeadUrl") },
        ]
        return (
            <Searcher auxType={auxType} auxSearchObj={auxSearchObj} auxEndpointInfo={auxEndpointInfo} />
        );
    };

    const getEndpointsTable = (auxType) => {
        let owned = auxType === "app";
        let auxEndpoints = allEndpoints.filter((endpoint) => endpoint.owned === owned);
        let auxSearchObj = {
            all: {
                searchTerm: searchTermAll,
                searchCriteria: searchCriteriaAll,
            },
            app: {
                searchTerm: searchTermApp,
                searchCriteria: searchCriteriaApp,
            }
        }
        return (
            <TableContainer component={Paper} key={auxType} className={classes.customPaper}>
                <Table className={classes.customTableRow}>
                    <TableHead className={classes.stickyTableHead}>
                        <TableRow>
                            <TableCell component="div" variant="head" className={classes.tableCellHeader} >
                                {t("appAdmTableHeadController")}
                            </TableCell>
                            <TableCell component="div" variant="head" className={classes.tableCellHeader} >
                                {t("appAdmTableHeadMethod")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterEndpoints(auxEndpoints, auxSearchObj[auxType].searchTerm, auxSearchObj[auxType].searchCriteria).map((endpoint, index) => (
                            <Tooltip title={t("appAdmTableHeadUrl") + ": " + endpoint.url} arrow>
                                <TableRow
                                    key={"endpoint" + index}
                                    onClick={() => handleEndpointClick(endpoint)}
                                    selected={endpoint.selected}
                                    className={`${classes.tableRow} ${endpoint.selected ? classes.selectedTableRow : ""}`}
                                    hover
                                >
                                    <TableCell className={classes.tableCell}>
                                        <Grid className={classes.tableCellText}>{endpoint.controller}</Grid>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Grid className={classes.tableCellText}>{endpoint.method}</Grid>
                                    </TableCell>
                                </TableRow>
                            </Tooltip>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                    {t("appAdmEndTitle")}
                </Typography>
            </Grid>
            {circularProgress ? (
                <Grid item xs={12}>
                    <CircularProgress style={{ marginTop: "2.5em", marginBottom: "2.5em" }} />
                </Grid>
            ) : (
                <Grid container item xs={12}>
                    <Grid container item xs={5}>
                        <Grid item xs={12} className={classes.marginEndpointTitle}>
                            <Typography variant="h6" className={classes.title}>
                                {t("appAdmEndTypeAll")}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            {getSearcher("all")}
                            <Grid item xs={12}>
                                {getEndpointsTable("all")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} style={{ paddingTop: "10em" }} spacing={1}>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                            <CorreosButton onClick={() => handleTransfer(true)} variant="contained" color="primary" width="100%">
                                <ArrowForwardIcon />
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={12}>
                            <CorreosButton onClick={() => handleTransfer(false)} variant="contained" color="primary">
                                <ArrowBackIcon />
                            </CorreosButton>
                        </Grid>
                    </Grid>
                    <Grid container item xs={5}>
                        <Grid item xs={12} className={classes.marginEndpointTitle}>
                            <Typography variant="h6" className={classes.title}>
                                {t("appAdmEndTypeApp")}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            {getSearcher("app")}
                            <Grid item xs={12}>
                                {getEndpointsTable("app")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "2.5em", marginBottom: "2.5em" }}>
                        <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            circularProgress={circularProgressConfirmButton}
                        >
                            {t("confirm")}
                        </CorreosButton>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};