import React, { useEffect } from "react";
import {
  Typography,
  Container,
  Dialog,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../commons/CommonStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { GetExistsUser } from "../../services/ReasonService";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { EnumCountries } from "../../commons/Enums";

export function AddAttributePhone(props) {
  const phoneUtil =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const commonClasses = CommonStyles();
  const {
    open,
    setOpen,
    setNewValues,
    addAttribute,
    checkIfExistsInLocalModel,
  } = props;

  const [data, setData] = React.useState();
  const [country, setCountry] = React.useState({
    code: EnumCountries.ESP,
    id: 67,
    phonePrefix: 34,
  });
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [phoneExists, setPhoneExists] = React.useState(true);
  const [validateData, setValidateData] = React.useState(false);
  const [isCharged, setIsCharged] = React.useState(false);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (isCharged) {
      validatePhoneNumber();
    } else {
      setIsCharged(true);
    }
  }, [data, country]);

  useEffect(() => {
    if (!error && !checkIsNullUndefined(data) && validateData) {
      setError(true);
      //setCircularProgress(true);
      if (!checkIfExistsInLocalModel(data, "MOV")) {
        GetExistsUser(country.phonePrefix + "-" + data).then((response) => {
          if (response === false) {
            setError(false);
            setPhoneExists(false);
            setErrorText("");
          } else if (response === true) {
            setError(true);
            setPhoneExists(true);
            setErrorText(spanishLanguage["verificationsPhoneExists"]);
          } else {
            setError(true);
            setErrorText(getErrorMessage(response));
          }
          setValidateData(false);
        });
      } else {
        setError(true);
        setPhoneExists(true);
        setErrorText(spanishLanguage["verificationsPhoneExists"]);
      }
    }
  }, [validateData]);

  const handleCancel = () => {
    setData("");
    setCountry({ code: EnumCountries.ESP, id: 67, phonePrefix: 34 });
    setError(false);
    setOpen(false);
    setErrorText("");
  };

  const handleChangeData = (value) => {
    const re = /^[0-9\b]+$/;
    if (value.target.value === "" || re.test(value.target.value)) {
      setData(value.target.value);
    }
  };

  const handleSelectorChange = (value) => {
    // masterCountry.forEach((country) => {
    //   if (country.id.toString() === value.toString()) {
        setCountry(value);
  };

  const validatePhoneNumber = () => {
    let error = false;
    let errorText = "";

    if (checkIsNullUndefined(data)) {
      error = false;
      errorText = "";
    } else if (data.toString().length === 1) {
      error = true;
      errorText = spanishLanguage["registerPagePhoneErrorShort"];
    } else if (country.id === 67 && data.toString().length > 9) {
      error = true;
      errorText = spanishLanguage["registerPagePhoneErrorLong"];
    } else if (data.toString().length > 1) {
      let auxCode = phoneUtil.getRegionCodeForCountryCode(country.phonePrefix); 
        if (
          phoneUtil.isValidNumberForRegion(
            phoneUtil.parseAndKeepRawInput(
              data.toString(),
              auxCode
            ),
            auxCode
          )
        )   {
        error = false;
        errorText = "";
        setValidateData(true);
      } else {
        error = true;
        errorText = spanishLanguage["registerPagePhoneError"];
      }
    }

    setError(error);
    setErrorText(errorText);
  };

  const handleAdd = () => {
    setNewValues((nv) => [...nv, { value: data, codeAttribute: "MOV" }]);
    addAttribute({ phone: data, pre: country.id });
    handleCancel();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => handleCancel()}
      className={commonClasses.dialogWindow}
    >
      <Container className={commonClasses.dialogTitle}>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6" className={commonClasses.dialogTitleText}>
              {spanishLanguage["userDataAddPhone"]}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              edge="inherit"
              className={commonClasses.dialogClosebutton}
              color="inherit"
              aria-label="menu"
              onClick={() => handleCancel()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Container className={commonClasses.dialogContainer}>
        <Grid container spacing={1}>
          <Grid item sm={4} xs={4} md={4}>
            <PrefixPhone handleSelectorChange={handleSelectorChange} />
          </Grid>
          <Grid item sm={8} xs={8} md={8}>
            <TextField
              value={data}
              onInput={handleChangeData}
              variant="outlined"
              fullWidth
              id="phone"
              label={spanishLanguage["registerPagePhone"]}
              name="phone"
              color="secondary"
              error={error}
              helperText={error ? errorText : ""}
              required
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={commonClasses.dialogButtonContainer}
            style={{ marginTop: "20px", paddingRight: "6px" }}
          >
            <Grid item md={6} xs={12}>
              <CorreosButton
                onClick={() => handleCancel()}
                className={commonClasses.dialogButtonLeft}
              >
                {spanishLanguage["cancel"]}
              </CorreosButton>
            </Grid>
            <Grid item md={6} xs={12}>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => handleAdd()}
                className={commonClasses.dialogButtonRight}
                disabled={checkIsNullUndefined(data) || error || phoneExists}
              >
                {spanishLanguage["add"]}
              </CorreosButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
