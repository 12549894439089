import React, { useState, useEffect } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import {
    PostAtcOtpSendEmail,
    PostAtcOtpSendPhone,
    PostVerificationValidationOtpEmailWithoutToken,
    PostVerificationValidationOtpPhoneWithoutToken,
    PostVerifyEmailOrPhone
} from "../../../services/ReasonService"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export function DialogVerify(props) {
    const { t, i18n } = useTranslation();
    const { accIdentifier, accIdIsEmail, emailOrPhone, isEmail, handleNext, finishOperation, isCancelled } = props;
    const dialogClasses = DialogStyles();

    const [open, setOpen] = useState(false);
    const [otpAccountId, setOtpAccountId] = useState();
    const [otpEmailOrPhone, setOtpEmailOrPhone] = useState();
    const [error, setError] = useState(false);
    const [helper, setHelper] = useState("");
    const [circularProgressSendOtp, setCircularProgressSendOtp] = useState(false);
    const [circularProgressNext, setCircularProgressNext] = useState(false);
    const [otpSendedAccountId, setOtpSendedAccountId] = useState(false);
    const [otpSendedVerificableData, setOtpSendedVerificableData] = useState(false);
    const [step1, setStep1] = useState(true);


    useEffect(() => { }, []);


    const handleCancel = () => {
        setStep1(true);
        setOtpAccountId("");
        setOtpEmailOrPhone("");
        setError(false);
        setHelper("");
        setOtpSendedAccountId(false);
        setOtpSendedVerificableData(false);
        setOpen(false);
    };



    const handleSendOtp = () => {
        let auxCall = step1 ? accIdIsEmail ? PostAtcOtpSendEmail : PostAtcOtpSendPhone : isEmail ? PostAtcOtpSendEmail : PostAtcOtpSendPhone;
        let auxData = step1 ? accIdentifier : emailOrPhone;
        setCircularProgressSendOtp(true);

        auxCall(auxData).then((response) => {
            if (response && !response.Status) {
                if (step1) {
                    setOtpSendedAccountId(true);
                } else {
                    setOtpSendedVerificableData(true);
                }
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            setCircularProgressSendOtp(false);
        });
    };

    const handleConfirmIdentity = () => {
        setCircularProgressNext(true);
        let auxCall = accIdIsEmail ? PostVerificationValidationOtpEmailWithoutToken : PostVerificationValidationOtpPhoneWithoutToken;
        auxCall(accIdentifier, otpAccountId.trim(), null).then((response) => {
            if (response && !response.Status) {
                setOtpSendedAccountId(false);
                setStep1(false);
            } else {
                setError(true);
                setHelper(t("registerVerifyOtpError"));
            }
            setCircularProgressNext(false);
        });
    };

    const handleConfirmVerifyData = () => {
        setCircularProgressNext(true);
        PostVerifyEmailOrPhone(accIdentifier, emailOrPhone, otpEmailOrPhone).then((response) => {
            if (response && !response.Status) {
                handleCancel();
                handleNext(true);
            } else {
                setError(true);
                setHelper(t("registerVerifyOtpError"));
            }
            setCircularProgressNext(false);
        });
    };

    const handleChangeOtp = (value) => {
        let auxError = false;
        let auxHelper = "";
        let auxSetter = step1 ? setOtpAccountId : setOtpEmailOrPhone;
        if (checkIsNullUndefined(value)) {
            auxError = true;
            auxHelper = t("required");
        };
        setError(auxError);
        setHelper(auxHelper);
        auxSetter(value);
    };

    const mountButtons = () => {
        let auxDisabledValue = step1 ? otpAccountId : otpEmailOrPhone;
        let auxCall = step1 ? handleConfirmIdentity : handleConfirmVerifyData;
        let auxText = step1 ? t("dialogSubscribeAppNext") : t("verificationsVerify");
        return (
            <Grid item xs={6}>
                <CorreosButton
                    variant="contained"
                    color="primary"
                    disabled={checkIsNullUndefined(auxDisabledValue) || circularProgressSendOtp}
                    onClick={auxCall}
                    circularProgress={circularProgressNext}
                    className={dialogClasses.dialogButtonRight}
                >
                    {auxText}
                </CorreosButton>
            </Grid>
        )
    };

    return (
        <>
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                disabled={isCancelled}
            >
                <CheckCircleIcon style={{ marginRight: "0.2em" }} />
                {t("userAdminVerify")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Grid>
                    <Container className={dialogClasses.dialogTitle}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6" className={dialogClasses.dialogTitleText}>
                                    {step1
                                        ? t("userAdminVerifyStep1Title").replace("{dataType}", accIdIsEmail ? t("email") : t("phone"))
                                        : t("userAdminVerifyStep2Title").replace("{dataType}", isEmail ? t("email") : t("phone"))}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Container>
                    <Container className={dialogClasses.dialogContainer}>

                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{ marginBottom: "0.5em" }}>
                                <Typography className={dialogClasses.dialogContentText}>
                                    {step1
                                        ? t("userAdminVerifyStep1Text").replace("{dataType}", accIdIsEmail ? t("email") : t("phone"))
                                        : t("userAdminVerifyStep2Text").replace("{dataType}", isEmail ? t("email") : t("phone"))}
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    label={t("userAdminVerifyIdentity")}
                                    fullWidth
                                    variant="outlined"
                                    value={step1 ? accIdentifier : emailOrPhone}
                                    defaultValue={"val"}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3} style={{ display: "flex", alignItems: "center" }} >
                                <CorreosButton
                                    edge="end"
                                    variant={(step1 ? otpSendedAccountId : otpSendedVerificableData) ? "outlained" : "contained"}
                                    color={(step1 ? otpSendedAccountId : otpSendedVerificableData) ? "secondary" : "primary"}
                                    onClick={() => handleSendOtp(step1 ? accIdentifier : emailOrPhone, step1 ? accIdIsEmail : isEmail, step1)}
                                    disabled={circularProgressNext}
                                    circularProgress={circularProgressSendOtp}
                                    className={dialogClasses.dialogButtonRight}
                                    width="100%"
                                >
                                    {(step1 ? otpSendedAccountId : otpSendedVerificableData) ? t("resend") : t("send")}
                                </CorreosButton>
                            </Grid>
                            {(step1 && otpSendedAccountId) || (!step1 && otpSendedVerificableData) ? (
                                <Grid item xs={12}>
                                    <TextField
                                        color="secondary"
                                        value={step1 ? otpAccountId : otpEmailOrPhone}
                                        onChange={(e) => handleChangeOtp(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        name="Otp"
                                        label={t("registerVerifyOtp")}
                                        type="text"
                                        error={error}
                                        helperText={helper}
                                        required
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    onClick={handleCancel}
                                    className={dialogClasses.dialogButtonLeft}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            {mountButtons()}
                        </Grid>
                    </Container>
                </Grid>
            </Dialog>
        </>

    );
}
