import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PostSubscriptionUserUnsubscribe } from "../../../services/ReasonService";

export function DialogUnsubscribe(props) {
    const { open, setOpen, handleNext, user, finishOperation, getUserSubscriptions } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    const [applications, setApplications] = useState([]);
    const [selectedApplicationCode, setSelectedApplicationCode] = useState("");
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => {
        if (open) {
            setApplications(getUserSubscriptions(user));
        }
    }, [open]);

    const handleCancel = () => {
        setSelectedApplicationCode("");
        setApplications([]);
        setOpen(false);
    };

    const handleConfirm = () => {
        setCircularProgress(true);
        PostSubscriptionUserUnsubscribe(user.userOid, selectedApplicationCode).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("udelUnsubscribeSuccess"));
                handleCancel();
                handleNext();
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgress(false);
        })
    };

    const handleChangeApplication = (auxAppCode) => {
        setSelectedApplicationCode(auxAppCode);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleCancel}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            <Container className={dialogClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={dialogClasses.dialogTitleText}
                        >
                            {t("udelUnsubscribe")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <Container className={dialogClasses.dialogContainer}>
                <Grid container spacing={1}>
                    {applications.length > 0 ? (
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {t("udelUnsubscribeText")}
                            </Typography>
                        </Grid>
                    ) : null}
                    {applications.length > 0 ? (
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth margin="dense" >
                                <InputLabel style={{ color: "#002453" }}>{t("udelUnsubscribeLabel")}</InputLabel>
                                <Select
                                    value={selectedApplicationCode}
                                    onChange={(e) => handleChangeApplication(e.target.value)}
                                    label={t("udelUnsubscribeLabel")}
                                    style={{ color: "#002453" }}
                                >
                                    {applications.map((app) => {
                                        return <MenuItem key={app.value} style={{ color: "#002453" }} value={app.value}>{app.text}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {t("udelUnsubscribeNoSubscriptions")}
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={12}
                        className={dialogClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={dialogClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                onClick={handleConfirm}
                                className={dialogClasses.dialogButtonRight}
                                circularProgress={circularProgress}
                                disabled={checkIsNullUndefined(selectedApplicationCode)}
                            >
                                {t("accept")}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog >
    );
}
