import React, { useEffect, useState } from "react";
import { GetMasterCountry } from "../../../services/ReasonService";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { checkIsNullUndefined, getPhonePrefixCountry } from "../../../services/Utilities";
import { EnumCountries } from "../../../commons/Enums";

export function PrefixPhone(props) {
  const { countryList, handleSelectorChange, country, disabled } = props;

  const [ownCountryList, setOwnCountryList] = useState(countryList);
  const [countrySelected, setCountrySelected] = useState(checkIsNullUndefined(country) ? {
    id: 67,
    code: EnumCountries.ESP,
    name: "España",
    phonePrefix: 34,
  }: country);

  useEffect(() => {

    let auxCountryList = ownCountryList;
    let auxCountry = countrySelected;
    if(!checkIsNullUndefined(country)){
      auxCountry = country;
      setCountrySelected(country);
    }
    

    if(checkIsNullUndefined(auxCountryList) || auxCountryList.length === 0){
      GetMasterCountry().then((response) => {
        //comprobamos que no sea null o error
        if (response && !response.Status) {
          setOwnCountryList(response);
          if (!checkIsNullUndefined(auxCountry) && !checkIsNullUndefined(auxCountry.code) && auxCountry.code != countrySelected.code) {
            auxCountry = getPhonePrefixCountry(response, auxCountry.code, "code");
            setCountrySelected(auxCountry);
            handleSelectorChange(auxCountry);
          }
        }
      });
    }else{
      if (!checkIsNullUndefined(auxCountry) && !checkIsNullUndefined(auxCountry.code) && auxCountry.code != countrySelected.code) {
        auxCountry = getPhonePrefixCountry(countryList, auxCountry.code, "code");
        setCountrySelected(auxCountry);
        handleSelectorChange(auxCountry);
      }
    }
    
  }, [country]);

  //controla el cambio en el selector de prefijo
  const handlePrefixChange = (event) => {
    let country = getPhonePrefixCountry(countryList, event.target.value, "id");
    setCountrySelected(country);
    handleSelectorChange(country);
  };

  return (
    <FormControl variant="outlined" fullWidth color="secondary">
      <InputLabel htmlFor="phoneSelector" id="phoneSelector-label">
        {spanishLanguage.prefixSelector}
      </InputLabel>
      <Select
        native
        labelId="phoneSelector-label"
        id="phoneSelector"
        label={spanishLanguage.prefixSelector}
        value={countrySelected.id}
        onChange={handlePrefixChange}
        disabled={disabled}
      >
        {countryList
          // .sort((a, b) => (a.phonePrefix > b.phonePrefix ? 1 : -1))
          .map((country) => {
            return (
              <option key={country.code} value={country.id}>
                {country.code + " (+" + country.phonePrefix + ")"}
              </option>
            );
          })}
      </Select>
    </FormControl>
  );
}
