import { makeStyles } from "@material-ui/core/styles";

export const RolesStyles = makeStyles((theme) => ({
    cardButton: {
        width: "100%",
    },
    cardStyle: {
      width: "100%",
      height: "17em",
      backgroundColor: "#ffffff",
    },
    cardTextStyle: {
      color: "#002453"
    },
    iconStyle: {
      width: "8em",
      height: "8em",
      margin: "auto",
      color: "#002453",
    },
    centerContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    mainContainer: {
      margin: "auto",
    },
}));
