import { makeStyles } from '@material-ui/core/styles';

export const AtcSendOtpConsentStyles = makeStyles((theme) => ({
  agreeButtons: {
    //  marginLeft:"2em",
    float: "right",
  },
  heightRowButtons: {

    border: "2px solid #FFCD00",
    "&:hover": {
      border: "2px solid #FFCD00",
    },
    height: "100% !important"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    }
  },
  formSelectorControl: {
    minWidth: "10.5vh",
    marginRight: "0.5vh",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  sendIconCenter: {
    marginLeft: "1.7em"
  },
  container: {
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  }, 
  containerNewAccount: {
    // marginLeft:"10em",
    // justifyContent:"left",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    }

  },

}));
