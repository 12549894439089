import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import { checkIsNullUndefined, getDate } from "../../services/Utilities";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { Constants } from "../../commons/Constants";

export function DatePickerAttribute(props) {
  const { attribute, saveGroupsChanges } = props;

  const [value, setValue] = useState("");
  const [current, setCurrent] = useState(getDate(Date().toLocaleString()));
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    let fecha = checkIsNullUndefined(attribute.value)
      ? "yyyy-MM-dd"
      : getDate(attribute.value);
    setValue(fecha);
  }, []);

  const compareDates = (fec) => {
    let flag = false;
    var fechaActual = new Date(current);
    var fechaValor = new Date(fec);
    fechaActual.setHours(0, 0, 0, 0);
    fechaValor.setHours(0, 0, 0, 0);
    if (fechaValor.getTime() > fechaActual.getTime()) {
      flag = true;
    };
    return flag;
  };

  const handleInputChange = (event) => {
    let errorDate = false;
    let errorDateText = "";
    if (attribute.fieldCode === "FNA") {
      errorDate = compareDates(event.target.value);
      if (errorDate) {
        errorDateText = spanishLanguage["userDataFNAError"];
      } else {
        errorDateText = "";
      };
    };

    setValue(event.target.value);
    setCurrent(getDate(Date().toLocaleString()));
    setError(errorDate);
    setErrorText(errorDateText);
    saveGroupsChanges(attribute, event.target.value);
  };

  const handleDates = () => {
    return {
      max: attribute.codeAttribute === "FNA" ? current : Constants.maxDate,
      min: (attribute.codeAttribute === "EDE" || attribute.codeAttribute === "FCA") ? current : Constants.minDate,
    };
  };

  return (
    <Grid item md={12} xs={12}>
      <TextField
        id="date"
        variant="outlined"
        color="secondary"
        label={attribute.nameAttritube}
        disabled={attribute.disabled}
        required={attribute.mandatory}
        type="date"
        value={value}
        error={error}
        helperText={errorText}
        fullWidth
        inputProps={handleDates()}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleInputChange}
      />
    </Grid>
  );
};
