import { makeStyles } from "@material-ui/core/styles";
export const LogInStyles = makeStyles((theme) => ({
  loginTitle: {
    marginBottom: "1rem",
    marginTop: "1.5rem",
  },
  logInSubtitle: {
    marginTop: "1rem",
  },
  loginUserCompleteName: {
    marginTop: "2.5em",
    marginBottom: "1em",
  },
  loginUserNameTypography: {
    color: "#002453",
    cursor: "pointer",
    fontSize: "1rem",
    lineHeight: "1.5",
  },
  loginUnderline: {
    "& .MuiInputBase-input": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  loginRememberMeLabel: {
    fontSize: "0.8rem",
  },
  loginForgotPasswordLink: {
    marginLeft: "auto",
    fontSize: "0.8rem",
    marginTop: "auto",
    marginBottom: "auto",
    color: "#002453",
    textDecoration: "none",
  },
}));
