import jwt_decode from "jwt-decode";
import { secureStorage, SetSecretKey, RemoveSecretKey } from "./SecureStorage";
import { checkIsNullUndefined, getErrorMessage } from "./Utilities";
import { EnumExternalPages } from "../commons/Enums";
import { GetPermissionGroups, GetUrlRedirect, PostCancelToken } from "./ReasonService"
import { v4 as uuidv4 } from "uuid";

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("value") || null;
};

// return the token from the session storage
export const getUserOid = () => {
  return secureStorage.getItem("userOid") || null;
};

// return the token from the session storage
export const getBackOfficeOid = () => {
  return process.env.REACT_APP_APP_OID;
};

// return the token from the session storage
export const getApplicationOid = () => {
  let tmp = secureStorage.getItem("ApplicationOid");

  if (checkIsNullUndefined(tmp)) {
    tmp = process.env.REACT_APP_APP_OID;
  }

  return tmp;
};

export const setApplicationOid = (applicationOid) => {
  secureStorage.setItem("ApplicationOid", applicationOid);
};

// return the token from the session storage
export const getAPI = () => {
  return process.env.REACT_APP_API_URL;
};

export const getAPI_CID = () => {
  return process.env.REACT_APP_API_URL_CID;
};

export const getOktaUrlLogin = () => {
  return process.env.REACT_APP_OKTA_LOGIN_URL;
};

export const getOktaRedirectUri = () => {
  return process.env.REACT_APP_OKTA_REDIRECT_URI;
};

export const getOktaClientId = () => {
  return process.env.REACT_APP_OKTA_CLIENT_ID;
};

// return the application code from the session storage
export const getCodeApp = () => {
  return secureStorage.getItem("codeApp") || "CID";
};

// return the token from the session storage
export const setCodeApp = (codeApp) => {
  return secureStorage.setItem("codeApp", codeApp);
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  secureStorage.clear();
  sessionStorage.clear();
  RemoveSecretKey();
};

// return the token from the session storage
export const getLanguage = () => {
  return sessionStorage.getItem("language") || "es";
};

// set the token and user from the session storage
export const setUserSession = async (loginInfo, appOid) => {
  let json = jwt_decode(loginInfo.id_token);
  sessionStorage.setItem("value", loginInfo.id_token);
  sessionStorage.setItem("refreshToken", loginInfo.refresh_token);
  // sessionStorage.setItem("time", Date.now());
  sessionStorage.setItem("appName", process?.env?.REACT_APP_API_NAME);
  let auxAppOid = checkIsNullUndefined(appOid) ? getBackOfficeOid() : appOid;
  await SetSecretKey(loginInfo.id_token).then(() => {
    //guardamos los datos cifrados en sesión.
    secureStorage.setItem("user", json.sub);
    //secureStorage.setItem("userOid", json.oid);
    secureStorage.setItem("rol", json.roles);
    secureStorage.setItem("iss", json.iss);
    secureStorage.setItem("ApplicationOid", auxAppOid);
    return true;
  });
};

export const getUserGlobalRoles = () => {
  let result = [];

  let userRoles = secureStorage.getItem("rol");
  let isOktaToken = secureStorage.getItem("iss") == process?.env?.REACT_APP_OKTA_TOKEN_ISS;

  if (userRoles && userRoles.length > 0 && Array.isArray(userRoles)) {
    userRoles.forEach((userRol) => {
      if (isOktaToken) {
        userRol = userRol.replace(process?.env?.REACT_APP_OKTA_TOKEN_KEY, "");
      };
      let auxRolCode = userRol.substring(0, 3);
      let auxRolLevel = userRol.substring(3, 5);
      let auxRolApp = userRol.substring(5, 8);
      let auxResult = result.find(x => x.subApp === auxRolCode);
      if (!auxResult) {
        fillResult(result, auxRolCode, auxRolLevel, auxRolApp);
      }
      else if (!auxResult.roles.find(x => x.level === auxRolCode)) {
        let tmpApps = [];

        if (userRol.substring(5, 8) !== "") {
          tmpApps.push(auxRolApp);
        }

        auxResult.roles.push(
          {
            level: auxRolLevel,
            apps: tmpApps
          }
        );
      }
      else if (auxRolApp !== "" &&
        auxResult.roles.find(x => x.level === auxRolLevel).apps.indexOf(auxRolApp) === -1) {
        auxResult.roles.find(x => x.level === auxRolLevel).apps.push(auxRolApp);
      }
    })
  }
  else if (userRoles && userRoles.length) {
    if (isOktaToken) {
      userRoles = userRoles.replace(process?.env?.REACT_APP_OKTA_TOKEN_KEY, "");
    }
    let auxRolCode = userRoles.substring(0, 3);
    let auxRolLevel = userRoles.substring(3, 5);
    let auxRolApp = userRoles.substring(5, 8);
    fillResult(result, auxRolCode, auxRolLevel, auxRolApp);
  }

  return result;
}

const fillResult = (result, auxRolCode, auxRolLevel, auxRolApp) => {
  let tmpApps = [];
  if (auxRolApp !== "") {
    tmpApps.push(auxRolApp);
  }

  result.push(
    {
      rolCode: auxRolCode,
      roles: [
        {
          level: auxRolLevel,
          apps: tmpApps
        }
      ]
    }
  );
}

export const setUserCookies = (user, rememberMe, generateCookie) => {
  if (rememberMe) {
    generateCookie(user);
  }
};

export const goToMain = (history) => {
  let redirect = "/Main";
  history.push(redirect);
};

export const goToAtc = (history) => {
  let redirect = "/Atc";
  history.push(redirect);
};

export const goToLogin = (history) => {
  let redirect = "/Login";
  history.push(redirect);
};

export const backToLogin = () => {
  PostCancelToken(getToken()).then(() => {
    removeUserSession();
    let redirect = getOktaUrlLogin() + "?redirect_uri=" + getOktaRedirectUri() + "/NTI/Atc&response_type=code&state=i7zHd5vJCL&scope=openid groups profile&client_id="+getOktaClientId();
    window.location.href = redirect;
  });
};

export const redirectToOktaLogin = () => {  
  let redirect = getOktaUrlLogin() + "?redirect_uri=" + getOktaRedirectUri() + "/Home&response_type=code&state=" + uuidv4()+ "&scope=openid groups profile&client_id="+getOktaClientId();
  window.location.href = redirect;  
};

export const setSelectedSubApp = (selectedSubApp) => {
  return sessionStorage.setItem("selectedSubApp", selectedSubApp);
};

export const getSelectedSubApp = () => {
  return sessionStorage.getItem("selectedSubApp") || null;
};

export const getVerificationSession = (verification) => {
  sessionStorage.getItem(verification);
};

export const moveToOtherApp = () => {
  GetUrlRedirect(getCodeApp()).then((response) => {
    // Redirección/pasar info por form
    if (response && !response.Status) {
      let form = document.createElement("form");
      document.body.appendChild(form);
      form.method = "post";
      form.action = response;
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = "body";
      input.value = JSON.stringify(getToken());
      form.appendChild(input);
      form.submit();
      document.body.removeChild(form);
      removeUserSession();
    } else {
      //finishOperation?
    }
  });
};

// return the refreshtoken from the session storage
export const getRefreshToken = () => {
  return sessionStorage.getItem("refreshToken") || null;
};

// return the token from the session storage
export const setToken = (token) => {
  return sessionStorage.setItem("value", token);
  //setIsRefreshing(false);
};

// return the refreshtoken from the session storage
export const setRefreshToken = (refreshToken) => {
  return sessionStorage.setItem("refreshToken", refreshToken);
};

export const setIsRefreshing = (isRefreshing) => {
  return sessionStorage.setItem("isRefreshing", isRefreshing);
}

export const getIsRefreshing = () => {
  let response = false;
  let isRefreshing = sessionStorage.getItem("isRefreshing");

  if (!checkIsNullUndefined(isRefreshing) && isRefreshing === "true") {
    response = true;
  }

  return response;
}

export const sleep = async (ms) => {
  return await new Promise(resolve => setTimeout(resolve, ms));
}

export const getUserPermissionGroups = (setUserGroupActions, finishOperation) => {
  GetPermissionGroups().then((response) => {
    if (response && !response.Status) {
      sessionStorage.setItem("UserGroupActions", response);
      setUserGroupActions(response);
    } else {
      finishOperation("error", getErrorMessage(response));
    }
  });
}