import React, { useEffect } from 'react';
import clsx from 'clsx';
import { SideMenuStyles } from "./SideMenuStyles";
import {
  Drawer,
  Grid,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Constants } from '../../../commons/Constants';
import { PageInfo } from '../../../commons/Enums';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function SideMenu(props) {
  const { open, handleSideMenu, selectedPage, availablePages } = props;
  const history = useHistory();
  const classes = SideMenuStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => { }, []);

  const handleRedirect = (page) => {
    history.push(page);
  };

  const mountSideButtons = () => {
    let auxPagesInfo = PageInfo(t, classes.iconStyle);
    return (
      availablePages.map((page) => (
        <ListItem button key={page} onClick={() => handleRedirect(page)} selected={selectedPage === page}>
          <ListItemIcon >{auxPagesInfo[page]?.pageIcon}</ListItemIcon>
          <ListItemText primary={auxPagesInfo[page]?.pageName} style={{ mpaddingLeft: "1em" }} />
        </ListItem>
      ))

    )
  }

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Grid style={{ height: Constants.HeaderHeight }}>
      </Grid>
      <Divider />
      <List>
        {mountSideButtons()}
      </List>
      <Divider />
      <ListItem button key={'arrow'} onClick={() => handleSideMenu()}>
        <ListItemIcon>{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}</ListItemIcon>
        <ListItemText />
      </ListItem>
    </Drawer>
  );
}
