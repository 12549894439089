import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../commons/Constants";

export const MainPageStyles = makeStyles((theme, openSideMenu) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: "auto",
  },
  contentOpen: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: Constants.DrawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  // container: {
  //   marginTop: Constants.MarginTopMobile,
  //   marginLeft: "0em",
  //   marginRight: "0em",
  //   [theme.breakpoints.up("md")]: {
  //     marginTop: Constants.MarginTopApp,
  //     marginLeft: "10em",
  //     marginRight: "10em"
  //   }
  // }
  generalContainer: {
    marginTop: Constants.MarginTop,
    textAlign: "center",
  },
}))
