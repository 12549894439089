import SecureStorage from "secure-web-storage";
import { checkIsNullUndefined } from "./Utilities";

var SECRET_KEY = "";
var CryptoJS = require("crypto-js");

export async function SetSecretKey(token) {
  if (!checkIsNullUndefined(token) && GetSecretKey() === "") {
    return await parseJwt(token).then((response) => {
      if(!checkIsNullUndefined(response.jti)){
        SECRET_KEY = response.jti;
      }else{
        SECRET_KEY = response.at_hash;
      }

      return true;
    });
  } else {
    return true;
  }
}

export function GetSecretKey() {
  return SECRET_KEY;
}

export function RemoveSecretKey() {
  SECRET_KEY = "";
}

export async function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return await JSON.parse(jsonPayload);
}

export var secureStorage = new SecureStorage(sessionStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, GetSecretKey());

    return key.toString();
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, GetSecretKey());
    data = data.toString();

    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, GetSecretKey());
    data = data.toString(CryptoJS.enc.Utf8);

    return data;
  },
});
