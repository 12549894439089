import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../commons/Constants";

export const userDataStyles = makeStyles((theme) => ({
  GridHelperContent: {
    marginTop: "1rem",
    paddingBottom: "1rem",
    padding: theme.spacing(2),
  },
  formContainer:{
    padding: "1em",
  },
}));
