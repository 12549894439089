import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import {
    AppBar,
    Grid,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider
} from "@material-ui/core";
import correosIdLogoBlue from "../../../assets/images/Cornamusa_Azul.svg";
import { FiLogOut } from "react-icons/fi";
import { HeaderStyles } from "./HeaderStyles";
import { backToLogin } from "../../../services/Commons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetApplicationApplicationList, PostResolveApplicationsNames } from "../../../services/ReasonService";
import { checkIsNullUndefined } from "../../../services/Utilities";


export function Header(props) {
    const {
        selectedRolAndLevel,
        setSelectedRolAndLevel,
        showAppsSelect,
        setShowAppsSelect,
        appsSelectOptions,
        applicationName,
        appCode,
        setSelectedApp,
        allApps,
        theme,
        handleSideMenu
    } = props;
    const classes = HeaderStyles(theme);
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const [appName, setAppName] = useState();
    // const [selectedAppName, setSelectedAppName] = useState("");
    const [optionsForSelect, setOptionForSelect] = useState([]);

    useEffect(() => {
        setAppName(process.env.REACT_APP_API_NAME);
        // setSelectedAppName(applicationName);
        if (allApps) {
            GetApplicationApplicationList().then((response) => {
                let tmpApps = [];
                response.forEach((auxApp) => {
                    tmpApps.push({ value: auxApp.codConApplication, name: auxApp.conApplicationName });
                });
                setOptionForSelect(tmpApps);
                let auxInitialApp = tmpApps.find((auxApp) => auxApp.value == "CID");
                setSelectedApp(auxInitialApp);
            })
        } else if (appsSelectOptions && appsSelectOptions.length > 0) {
            PostResolveApplicationsNames(appsSelectOptions).then(resolveResponse => {
                if (resolveResponse && !resolveResponse.Status) {
                    let auxOptionForSelect = [];
                    appsSelectOptions.forEach(auxAppCode => {
                        auxOptionForSelect.push({ value: auxAppCode, name: resolveResponse[auxAppCode] })
                    });
                    setOptionForSelect(auxOptionForSelect);
                    setSelectedApp(appsSelectOptions[0]);
                }
            });
        }
    }, [appsSelectOptions, applicationName, allApps]);

    const handleLogout = () => {
        backToLogin();
    };

    const handleOption = (value) => {
        let auxApp = optionsForSelect.find((app) => app.value == value);
        setSelectedApp(auxApp);
    };

    const goToMain = () => {
        setSelectedRolAndLevel({});
        setShowAppsSelect(false);
        handleSideMenu(false);
        let redirect = "/Main";
        history.push(redirect);
    }

    return (
        // <AppBar position="fixed" className={clsx(classes.appBar, {
        //     [classes.appBarShift]: open,
        // })}>
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Grid container>
                    <Grid item xs={12} className={classes.divBar}>
                        <Typography variant="h6" className={classes.title2} noWrap>
                            {appName}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={11} style={{ display: "-webkit-inline-box" }}>
                            <Grid style={{ width: "4.9em" }}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    style={{ margin: "auto" }}
                                    className={classes.imgIcon}
                                    aria-label="menu"
                                    onClick={goToMain}
                                >
                                    <img
                                        src={correosIdLogoBlue}
                                        alt={appName}
                                        className={classes.imgLogin}
                                    />
                                </IconButton>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            {!checkIsNullUndefined(selectedRolAndLevel.rol) ? (
                                <Typography variant="h6" className={classes.title2} style={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                }} noWrap>
                                    {t("header" + selectedRolAndLevel.rol)}
                                </Typography>
                            ) : null}

                            {showAppsSelect && optionsForSelect && optionsForSelect.length > 0 ? (
                                <Grid style={{ padding: "0.5em", minWidth: "20em", textAlign: "center" }}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel color="secondary">
                                            {t("headerSelectAppTitle")}
                                        </InputLabel>
                                        <Select
                                            color="secondary"
                                            required
                                            id="apps"
                                            label={t("headerSelectAppTitle")}
                                            defaultValue={"CID"}
                                            value={appCode}
                                            onChange={(e) => {
                                                handleOption(e.target.value);
                                            }}
                                        >
                                            {optionsForSelect.map((element) => {
                                                return (
                                                    <MenuItem key={element.value} value={element.value}>{element.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : null}
                            <Grid item xs={6}>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "right" }}>
                            <Tooltip title={t("exit")}>
                                <IconButton
                                    edge="start"
                                    aria-label="menu"
                                    className={classes.imgIcon}
                                    onClick={handleLogout}
                                >
                                    <FiLogOut className={classes.imgLogin} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
