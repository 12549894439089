import React from "react";
import {
    Typography,
    MenuItem,
    Select,
    FormControl
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { LanguageSelectorStyles } from "./LanguageSelectorStyles";
import esFlag from '../../../../assets/images/flags/spanish_flag.svg';
// import enFlag from '../../../../assets/images/flags/english_flag.svg';
import caFlag from '../../../../assets/images/flags/catalan_flag.svg';
import euFlag from '../../../../assets/images/flags/basque_flag.svg';
import glFlag from '../../../../assets/images/flags/galician_flag.svg';
import vaFlag from '../../../../assets/images/flags/valencian_flag.svg';



export function LanguageSelector(props) {
    const { t } = useTranslation();
    const classes = LanguageSelectorStyles();
    const { language, handleLanguageChange } = props;
    return (
        
    <FormControl>  
        <Select
            value={language}
            onChange={handleLanguageChange}
            disableUnderline
        >
            <MenuItem value="es">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={esFlag} alt="es" className={classes.flagIcon}  />
                    {t("atcLegalESLanguage")}
                </Typography>
            </MenuItem>
            {/* <MenuItem value="en">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={enFlag} alt="en" className={classes.flagIcon} />
                    {t("atcLegalENLanguage")}
                </Typography>
            </MenuItem>            */}
            <MenuItem value="ca">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={caFlag} alt="ca" className={classes.flagIcon} />
                    {t("atcLegalCALanguage")}
                </Typography>
            </MenuItem>
            <MenuItem value="eu">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={euFlag} alt="eu" className={classes.flagIcon} />
                    {t("atcLegalEULanguage")}
                </Typography>
            </MenuItem>
            <MenuItem value="gl">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={glFlag} alt="gl" className={classes.flagIcon} />
                    {t("atcLegalGLLanguage")}
                </Typography>
            </MenuItem>
            <MenuItem value="va">
                <Typography variant="body1" className={classes.menuItemText}>
                    <img src={vaFlag} alt="va" className={classes.flagIcon} />
                    {t("atcLegalVALanguage")}
                </Typography>
            </MenuItem>
        </Select>
    </FormControl>

    )
}
