import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { CorreosButton } from "../../../components/CorreosButton/CorreosButton";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { useToolbarStyles, useStyles } from "./EnhancedTableStyles";


const headCells = [
  { id: 'userOid', numeric: false, disablePadding: true, label: spanishLanguage["advcHeadOid"] },
  { id: 'name', numeric: false, disablePadding: false, label: spanishLanguage["advcHeadName"] },
  { id: 'surnames', numeric: false, disablePadding: false, label: spanishLanguage["advcHeadSurnames"] },
  { id: 'email', numeric: false, disablePadding: false, label: spanishLanguage["advcHeadEmail"] },
  { id: 'phone', numeric: true, disablePadding: false, label: spanishLanguage["advcHeadPhone"] },
  { id: 'applications', numeric: false, disablePadding: false, label: spanishLanguage["advcHeadApps"] },
  { id: 'hasAcceptedRgpd', numeric: false, disablePadding: false, label: spanishLanguage["advcHeadRgpds"] },
];

const tableColumns = ['userOid', 'name', 'surnames', 'email', 'phone', 'applications', 'hasAcceptedRgpd'];

function createData(userOid, name, surnames, email, phone, applications, hasAcceptedRgpd) {
  return { userOid, name, surnames, email, phone, applications, hasAcceptedRgpd };
}


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  let stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    let order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': spanishLanguage["advcTableSelectAllUsersLabel"] }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id == "userOid" ? "left" : "right"}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: "120%", fontWeight: "bolder" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}

            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? spanishLanguage["advcTableSortedDesc"] : spanishLanguage["advcTableSortedAsc"]}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleUnacceptRgpds, selected, UserGroupActions } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >

      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected == 1 ? numSelected + " " + spanishLanguage["advcTableSelected"] : numSelected + " " + spanishLanguage["advcTableSelecteds"]}
        </Typography>
      ) : (
        // <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        //   {spanishLanguage["advcTableTitle"]}
        // </Typography>
        null
      )}

      {numSelected > 0 && UserGroupActions.includes("LGP") ? (
        <Tooltip title={spanishLanguage["advcTableUnacceptButtonToolTip"]}>
          <CorreosButton
            variant="contained"
            color="secondary"
            style={{ color: "white", margin: "1em", height: "3em", width: "10em" }}
            onClick={() => handleUnacceptRgpds(selected)}
          >
            {spanishLanguage["advcTableUnacceptButton"]}
          </CorreosButton>
        </Tooltip>
      ) : (
        null
      )}

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
  const { data, handleUnacceptRgpds, UserGroupActions } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dataRows, setDataRows] = React.useState([]);

  useEffect(() => {
    prepareRows(data);
    console.log(dataRows);
  }, [data]);

  const prepareRows = (data) => {
    setDataRows([]);
    let auxData = [];
    data.forEach((elem) => {
      auxData.push(createData(elem.userOid, elem.name, elem.surnames, elem.email, elem.phone, elem.applications, elem.hasAcceptedRgpd));
    });

    //fusionar duplicados
    let removeAndMergeDuplicates = Array.from(
      auxData.reduce((acc, cur) => {
        const user = acc.get(cur.userOid) ?? { ...cur, phone: [], email: [] };
        if (cur.phone != null) user.phone.push(cur.phone);
        if (cur.email != null) user.email.push(cur.email);
        return acc.set(cur.userOid, user);
      }, new Map()).values()
    ).map(user => {
      return {
        ...user,
        phone: user.phone.filter(Boolean).join(', '),
        email: user.email.filter(Boolean).join(', ')
      };
    });

    setDataRows(removeAndMergeDuplicates);

  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getPaginationOptions = (totalSize) => {
    const options = [5, 10, 25, 50, 100];
    const lastIndex = options.findIndex(option => option >= totalSize);
    const result = lastIndex === -1 ? options : options.slice(0, lastIndex + 1);
    if (result[result.length - 1] > totalSize) {
      result[result.length - 1] = totalSize;
    }
    return result;
  };

  const handleSelectAllClick = (event) => {
    event.persist();
    console.log(event.target);
    if (event.target.checked) {
      let newSelecteds = dataRows.map((n) => n.userOid);
      setSelected(newSelecteds);
      return;
    } else {
      let newSelecteds = [];
      setSelected(newSelecteds);
      return;
    }

  };

  const handleClick = (event, userOid) => {
    let selectedIndex = selected.indexOf(userOid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userOid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (userOid) => selected.indexOf(userOid) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataRows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} handleUnacceptRgpds={handleUnacceptRgpds} selected={selected} UserGroupActions={UserGroupActions} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby={spanishLanguage["advcTableTitle"]}
            size={'small'}
            aria-label="tabla de usuarios"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataRows.length}
            />
            <TableBody>
              {stableSort(dataRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.userOid);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.userOid)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.userOid}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>

                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.userOid}
                      </TableCell>
                      {headCells.map((column) => ((column.id != "userOid" && column.id != "hasAcceptedRgpd") ?
                        <TableCell align="right">{row[column.id]}</TableCell> : null
                      ))}
                      <TableCell align="right">{
                        <Button
                          color={row.hasAcceptedRgpd ? "primary" : "secondary"}
                          variant="contained"
                          className={row.hasAcceptedRgpd ? classes.switchButton : classes.switchButtonNoActive}
                          disableRipple
                        >
                          {row.hasAcceptedRgpd ? spanishLanguage["yes"] : spanishLanguage["no"]}
                        </Button>}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={spanishLanguage["advcTableRpp"]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${spanishLanguage["advcTablePaginationOf"]} ${count !== -1 ? count : `${spanishLanguage["advcTablePaginationMoreThan"]} ${to}`}`}
          rowsPerPageOptions={getPaginationOptions(dataRows.length)}
          component="div"
          count={dataRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

    </div>
  );
}