import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { Attributes } from "../../commons/Attributes";
import { checkIsNullUndefined } from "../../services/Utilities";
import { spanishLanguage } from "../../config/language/spanishLanguage";

export function TextBoxAttribute(props) {
  const { attribute, errorTypeDocument, saveGroupsChanges, validateDocumentNumber } = props;
  const validator = require("react-email-validator");
  const [value, setValue] = React.useState("");
  const [mount, setMount] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [errorTypeDoc, setErrorTypeDoc] = React.useState(false);

  useEffect(() => {
    if (!mount) {
      setValue(attribute.value);
      setMount(true);
    } else {
      if (attribute.value !== value) {
        setValue(attribute.value);
      }
      if (attribute.fieldCode === "NDO" && errorTypeDocument !== errorTypeDoc) {
        setError(errorTypeDocument);
        setErrorText(errorTypeDocument ? spanishLanguage["registerPageNDOError"] : "");
        setErrorTypeDoc(errorTypeDocument);
        validateDocumentNumber(value, attribute.fieldCode);
      }
    }
  }, [attribute, value, errorTypeDocument, errorTypeDoc]);

  

  //guarda los datos.
  const handleInputChange = (event) => {
    let error = false;
    let errorText = "";
    let errorTypeDocument = errorTypeDoc;
    let flag = true;
    if (attribute.mandatory) {
      if (checkIsNullUndefined(event.target.value)) {
        error = true;
        errorText = spanishLanguage["required"];
      }
    } else if (attribute.nameAttritube === Attributes.UserEmail) {
      let okEmail = validator.validate(event.target.value);
      if (checkIsNullUndefined(event.target.value)) okEmail = true;
      if (!okEmail) {
        error = true;
        errorText = spanishLanguage["registerPageMailError"];
      }
    } else if (attribute.nameAttritube === Attributes.PersonaTelfMovil) {
      const re = new RegExp(/^([0-9])*$/);
      flag = re.test(event.target.value);
    } else if (attribute.fieldCode === "NDO") {
      let okNDO = validateDocumentNumber(
        event.target.value,
        attribute.fieldCode
      );
      if (!okNDO) {
        error = true;
        errorText = spanishLanguage["registerPageNDOError"];
      } else {
        errorTypeDocument = false;
      }
    }

    if (flag) {
      setValue(event.target.value);
      setError(error);
      setErrorText(errorText);
      setErrorTypeDoc(errorTypeDocument);
      saveAttributesChanges(event.target.value);
    }
  };

  //guarda los cambios en los attributos de datos personales
  const saveAttributesChanges = (nValue) => {
    saveGroupsChanges(attribute, nValue);
  };

  return (
    <Grid item md={12} xs={12}>
      <TextField
        value={value}
        variant="outlined"
        fullWidth
        name="textbox"
        color="secondary"
        label={attribute.nameAttritube}
        required={attribute.mandatory}
        onChange={handleInputChange}
        error={error}
        helperText={errorText}
        disabled={attribute.disabled}
      />
    </Grid>
  );
}
