import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Tabs,
  Tab,
  Divider,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  TextField

} from "@material-ui/core";
import {
  GetApplicationName,
  GetApplicationDescription,
  GetApplicationImages,
  GetApplicationRedirectUrl,
  GetApplicationNotifierUrls,
  PostApplicationName,
  PostApplicationDescription,
  PostApplicationImages,
  PostApplicationRedirectUrl,
  PostApplicationNotifierUrls,
  PostSendIntegrationRequest,
  PostSendTycPrivacyFileUpdateRequest,
  GetApplicationTyc,
  GetApplicationPrivacy,
} from "../../services/ReasonService";
import { LanguageResponseModel, ImagesResponseModel, NotifierUrlsResponseModel, extensionToMimeTypes, TycPrivacyModel } from "./ApplicationModificationObjects";
import { RolLevels, SubApp } from "../../commons/Enums";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { ApplicationModificationStyles } from "./ApplicationModificationStyles";
import esFlag from '../../assets/images/flags/spanish_flag.svg';
import enFlag from '../../assets/images/flags/english_flag.svg';
import caFlag from '../../assets/images/flags/catalan_flag.svg';
import euFlag from '../../assets/images/flags/basque_flag.svg';
import glFlag from '../../assets/images/flags/galician_flag.svg';
import vaFlag from '../../assets/images/flags/valencian_flag.svg';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import HttpIcon from '@material-ui/icons/Http';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InfoIcon from '@material-ui/icons/Info';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SubjectIcon from '@material-ui/icons/Subject';
import { DialogUpdateApplicationData } from "./Dialogs/DialogUpdateApplicationData";
import { DialogShowImage } from "./Dialogs/DialogShowImage";

export function ApplicationModification(props) {


  const {
    finishOperation,
    userGroupActions,
    handleSetAppsSelectOptions,
    handleShowAppsSelect,
  } = props;

  const validator = require("react-email-validator");
  const [circularProgress, setCircularProgress] = useState(false);
  const [circularProgressIntegration, setCircularProgressIntegration] = useState(false);
  const [circularProgressTycs, setCircularProgressTycs] = useState(false);
  const [circularProgressNameTable, setCircularProgressNameTable] = useState(false);
  const [circularProgressDescriptionTable, setCircularProgressDescriptionTable] = useState(false);
  const [circularProgressImagesTable, setCircularProgressImagesTable] = useState(false);
  const [circularProgressRedirectTable, setCircularProgressRedirectTable] = useState(false);
  const [circularProgressNotifierTable, setCircularProgressNotifierTable] = useState(false);
  const [circularProgressTycTable, setCircularProgressTycTable] = useState(false);
  const [tab, setTab] = useState(0);
  const [applicationName, setApplicationName] = useState(LanguageResponseModel);
  const [applicationDescription, setApplicationDescription] = useState(LanguageResponseModel);
  const [applicationImages, setApplicationImages] = useState(ImagesResponseModel);
  const [applicationRedirectUrl, setApplicationRedirectUrl] = useState("");
  const [applicationNotifierUrl, setApplicationNotifierUrl] = useState(NotifierUrlsResponseModel);
  const [applicationTycPrivacy, setApplicationTycPrivacy] = useState(TycPrivacyModel);
  const [openNameDialog, setOpenNameDialog] = useState(false);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const [openImagesDialog, setOpenImagesDialog] = useState(false);
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [openNotifierDialog, setOpenNotifierDialog] = useState(false);
  const [openShowImageDialog, setOpenShowImageDialog] = useState(false);
  const [imageShow, setImageShow] = useState("");
  const hiddenFileInputSingle = useRef(null);
  const hiddenFileInputMultiple = useRef(null);
  const [handleFile, setHandleFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [personType, setPersonType] = useState('1');
  const [isTyc, setIsTyc] = useState('true');
  const [integrationReqContactEmail, setIntegrationReqContactEmail] = useState("");
  const [tycUpdateReqContactEmail, setTycUpdateReqContactEmail] = useState("");
  const [intReqEmailError, setIntReqEmailError] = useState(false);
  const [tycUpdateEmailError, setTycUpdateEmailError] = useState(false);
  const [disableSendButtonIntReq, setDisableSendButtonIntReq] = useState(true);
  const [disableSendButtonTycUpdateReq, setDisableSendButtonTycUpdateReq] = useState(true);

  const commonClasses = CommonStyles();
  const classes = ApplicationModificationStyles();

  const handleContactEmailInput = (event) => {
    if (event.target.name === "tycUpdateReqEmailInput") {
      setTycUpdateReqContactEmail(event.target.value);
    } else if (event.target.name === "intReqEmailInput") {
      setIntegrationReqContactEmail(event.target.value)
    }

  };

  const handleBlur = (event) => {
    const value = event.target.value;
    if (value === "" || checkIsValidEmail(value)) {

      if (event.target.name === "tycUpdateReqEmailInput") {
        setTycUpdateEmailError(false);
        setDisableSendButtonTycUpdateReq(!checkIsNullUndefined(value) ? false : true);

      } else if (event.target.name === "intReqEmailInput") {
        setIntReqEmailError(false);
        setDisableSendButtonIntReq(!checkIsNullUndefined(value) ? false : true);
      }

    } else {
      if (event.target.name === "tycUpdateReqEmailInput") {
        setTycUpdateEmailError(true);
        setDisableSendButtonTycUpdateReq(true);
      } else if (event.target.name === "intReqEmailInput") {
        setIntReqEmailError(true);
        setDisableSendButtonIntReq(true);
      }
    }

  };

  const checkIsValidEmail = (value) => {
    return validator.validate(value);
  };

  useEffect(() => {
    handleSetAppsSelectOptions(SubApp.AAP, RolLevels.N2);
    handleShowAppsSelect(true);
    getApplicationAttributes();
  }, []);


  const getApplicationAttributes = () => {
    setCircularProgressNameTable(true);
    GetApplicationName().then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressNameTable(false);
      } else {
        setApplicationName(response);
        setCircularProgressNameTable(false);
      }
    });

    setCircularProgressDescriptionTable(true);
    GetApplicationDescription().then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressDescriptionTable(false);
      } else {
        setApplicationDescription(response);
        setCircularProgressDescriptionTable(false);
      }
    });

    setCircularProgressImagesTable(true);
    GetApplicationImages().then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressImagesTable(false);
      } else {
        setApplicationImages(response);
        setCircularProgressImagesTable(false);
      }
    });

    setCircularProgressRedirectTable(true);
    GetApplicationRedirectUrl().then((response) => {
      if (response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressRedirectTable(false);
      } else {
        setApplicationRedirectUrl(response);
        setCircularProgressRedirectTable(false);
      }
    });

    setCircularProgressNotifierTable(true);
    GetApplicationNotifierUrls().then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressNotifierTable(false);
      } else {
        setApplicationNotifierUrl(Object.fromEntries(
          Object.keys(NotifierUrlsResponseModel).map(key => [key, response[key]])
        ));
        setCircularProgressNotifierTable(false);
      }
    });

    setCircularProgressTycTable(true);
    GetApplicationTyc().then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressTycTable(false);
      } else {
        setApplicationTycPrivacy(prevState => ({
          ...prevState,
          tyc: response
        }));
        GetApplicationPrivacy().then((response2) => {
          if (!response2 || response2.Status) {
            finishOperation("error", getErrorMessage(response2));
            setCircularProgressTycTable(false);
          } else {
            setApplicationTycPrivacy(prevState => ({
              ...prevState,
              privacy: response2
            }));
            setCircularProgressTycTable(false);
          }
        });
      }
    });
  };


  const confirmUpdateApplicationName = (appNameObject) => {
    setCircularProgress(true);
    PostApplicationName(appNameObject).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodUpdateNameMessage"]);
        setCircularProgressNameTable(true);
        GetApplicationName().then((response1) => {
          if (!response1 || response1.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {
            setApplicationName(response1);
          }
          setCircularProgressNameTable(false);
        });
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };

  const confirmUpdateApplicationDescription = (appDescriptionObject) => {
    setCircularProgress(true);
    PostApplicationDescription(appDescriptionObject).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodUpdateDescriptionMessage"]);
        setCircularProgressDescriptionTable(true);
        GetApplicationDescription().then((response1) => {
          if (!response1 || response1.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {
            setApplicationDescription(response1);
          }
          setCircularProgressDescriptionTable(false);
        });
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };

  const confirmUpdateApplicationImages = (appImagesObject) => {
    setCircularProgress(true);
    PostApplicationImages(appImagesObject).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodUpdateImagesMessage"]);
        setCircularProgressImagesTable(true);
        GetApplicationImages().then((response1) => {
          if (!response1 || response1.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {
            setApplicationImages(response1);
          }
          setCircularProgressImagesTable(false);
        });
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };

  const confirmUpdateApplicationRedirect = (appRedirect) => {
    setCircularProgress(true);

    PostApplicationRedirectUrl(appRedirect).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodUpdateRedirectMessage"]);
        setCircularProgressRedirectTable(true);
        GetApplicationRedirectUrl().then((response1) => {
          if (response1.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {
            setApplicationRedirectUrl(response1);
          }
          setCircularProgressRedirectTable(false);
        });
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };

  const confirmUpdateApplicationNotifier = (appNotifierObject) => {
    setCircularProgress(true);

    PostApplicationNotifierUrls(appNotifierObject).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodUpdateNotifierMessage"]);
        setCircularProgressNotifierTable(true);
        GetApplicationNotifierUrls().then((response1) => {
          if (!response1 || response1.Status) {
            finishOperation("error", getErrorMessage(response));
          } else {
            setApplicationNotifierUrl(Object.fromEntries(
              Object.keys(NotifierUrlsResponseModel).map(key => [key, response1[key]])));
          }
          setCircularProgressNotifierTable(false);
        });
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgress(false);
    });
  };


  const sendIntegrationRequestEmail = (attachment, email) => {
    setHandleFile(null);
    setCircularProgressIntegration(true);
    PostSendIntegrationRequest(attachment, email).then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressIntegration(false);

      } else {
        finishOperation("success", spanishLanguage["amodRequestSentMessage"]);
        setCircularProgressIntegration(false);
      }
    });
  };

  const sendTycPrivacyUpdateRequestEmail = (attachment, email) => {
    setSelectedFiles(null);
    setCircularProgressTycs(true);
    PostSendTycPrivacyFileUpdateRequest(attachment, personType, isTyc, email).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", spanishLanguage["amodRequestSentMessage"]);
        setCircularProgressTycs(false);
      } else {
        finishOperation("error", getErrorMessage(response));
        setCircularProgressTycs(false);
      }
    });
  };

  const handleTabChange = (event, tabValue) => {
    setTab(tabValue);
  };

  const handleClickSingle = event => {
    hiddenFileInputSingle.current.click();
  };

  const handleClickMultiple = event => {
    hiddenFileInputMultiple.current.click();
  };

  const handleSingleFile = (file) => {
    let maxSize = 268435456;

    if (!checkIsNullUndefined(file)) {
      // Comprobar tamaño del archivo
      if (file.size > maxSize) {
        finishOperation("error", spanishLanguage["uexpFileTooBig"]);
        setHandleFile(null);
        return;
      }
      // Comprobar extensión de archivo
      const extension = getFileExtension(file.name);
      if (!extensionToMimeTypes.hasOwnProperty(extension) || (extension !== 'xlsx')) {
        finishOperation("error", spanishLanguage["amodFileNotSupportedMessage"]);
        setHandleFile(null);
        return;
      }
      setHandleFile(file);
    } else {
      setHandleFile(null);
    }
  };

  const handleMultipleFiles = (files) => {
    let maxSize = 268435456;
    let validFiles = [];
    let invalidFiles = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        // Comprobar tamaño del archivo
        if (file.size > maxSize) {
          invalidFiles.push(file);
          continue;
        }

        // Comprobar extensión de archivo
        const extension = getFileExtension(file.name);
        if (extensionToMimeTypes.hasOwnProperty(extension) && (extension === 'html' || extension === 'css')) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      }

      if (invalidFiles.length > 0) {
        // Manejar archivos inválidos (tamaño demasiado grande o extensión no admitida)
        finishOperation("error", spanishLanguage["amodFileNotSupportedMessage"]);

        setSelectedFiles(null);
        return;
      }

      setSelectedFiles(validFiles);
    } else {
      setSelectedFiles(null);
    }
  };

  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  };

  const handleImageClick = (value) => {
    setImageShow(value);
    setOpenShowImageDialog(true);
  };


  const getLabelByIdAttribute = (idAttribute, showLabel = true) => {
    let labelMap = {
      es: { flag: esFlag, label: spanishLanguage["atcLegalESLanguage"] },
      en: { flag: enFlag, label: spanishLanguage["atcLegalENLanguage"] },
      ca: { flag: caFlag, label: spanishLanguage["atcLegalCALanguage"] },
      eu: { flag: euFlag, label: spanishLanguage["atcLegalEULanguage"] },
      gl: { flag: glFlag, label: spanishLanguage["atcLegalGLLanguage"] },
      va: { flag: vaFlag, label: spanishLanguage["atcLegalVALanguage"] },
      urlRedireccion: { icon: <LinkIcon className={classes.flagIcon} />, label: spanishLanguage["amodRedirectLabel"] },
      urlRegistro: { icon: <ImageIcon className={classes.flagIcon} />, label: spanishLanguage["amodRegistryLabel"] },
      urlChangePass: { icon: <ImageIcon className={classes.flagIcon} />, label: spanishLanguage["amodChangePassLabel"] },
      urlLogin: { icon: <ImageIcon className={classes.flagIcon} />, label: spanishLanguage["amodLoginLabel"] },
      urlRegistry: { icon: <HttpIcon className={classes.flagIcon} />, label: spanishLanguage["amodRegistryLabel"] },
      urlDatos: { icon: <HttpIcon className={classes.flagIcon} />, label: spanishLanguage["amodDataLabel"] },
      urlBaja: { icon: <HttpIcon className={classes.flagIcon} />, label: spanishLanguage["amodUnsubscribeLabel"] },
      client_id: { icon: <VpnKeyIcon className={classes.flagIcon} />, label: spanishLanguage["amodClientIdLabel"] },
      client_secret: { icon: <VpnKeyIcon className={classes.flagIcon} />, label: spanishLanguage["amodClientSecretLabel"] },
      contactEmail: { icon: <ContactMailIcon className={classes.flagIcon} />, label: "Email de contacto" },
    };

    let attribute = labelMap[idAttribute];

    if (attribute) {
      return (
        <>
          {attribute.flag && <img src={attribute.flag} alt={attribute.label} className={classes.flagIcon} />}
          {attribute.icon} {showLabel ? " " + attribute.label : ''}
        </>
      );
    }

    return null;
  };



  const personTypeOptions = [
    { value: '1', label: spanishLanguage["amodUserLabel"] },
    { value: '2', label: spanishLanguage["amodEnterpriseLabel"] },
  ];

  const isTycOptions = [
    { value: 'true', label: spanishLanguage["amodTycsLabel"] },
    { value: 'false', label: spanishLanguage["amodPrivacyLabel"] },
  ];

  const handlePersonTypeChange = (event) => {
    setPersonType(event.target.value);
  };

  const handleIsTycChange = (event) => {
    setIsTyc(event.target.value);
  };

  const renderTable = (tableTitle, tableBody, modifyFunction, sendEmail = false, tycFiles = false) => {
    return (
      <Grid item xs={12} className={classes.marginBottom}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#f3f2f2" }}>
                <TableCell >
                  <Typography variant="h6" className={classes.title} style={{ marginTop: "0em" }}>
                    {tableTitle}
                  </Typography>
                </TableCell>
                <TableCell style={{}}>
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    {sendEmail && (
                      <>
                        {!tycFiles ?
                          <a href="files\ApplicationModification\Plantilla_Integracion_CorreosID.xlsx" download>
                            <CorreosButton
                              onClick={() => { }}
                            >
                              <GetAppIcon /> {spanishLanguage["amodDownloadIntegrationTemplate"]}
                            </CorreosButton>
                          </a>
                          : null}
                        <AttachFileIcon className={classes.flagIcon} />
                        {!tycFiles ?
                          <span style={{ cursor: "pointer", marginRight: "0.5em" }}>
                            {checkIsNullUndefined(handleFile) ? (
                              <span style={{ marginRight: "0.5em" }}>
                                {spanishLanguage["uexpNoFileSelected"]}
                              </span>
                            ) : (
                              <Tooltip
                                title={
                                  <ul style={{ margin: 0, paddingInlineStart: "1em" }}>
                                    <li>{handleFile.name}</li>
                                  </ul>
                                }
                              >
                                <span style={{ marginRight: "0.5em" }}>
                                  {handleFile.name.length > 24
                                    ? handleFile.name.slice(0, 24) + '...'
                                    : handleFile.name}
                                </span>
                              </Tooltip>
                            )}
                          </span>
                          :
                          <span style={{ cursor: "pointer", marginRight: "0.5em" }}>
                            {checkIsNullUndefined(selectedFiles) ? (
                              <span style={{ marginRight: "0.5em" }}>
                                {spanishLanguage["uexpNoFileSelected"]}
                              </span>
                            ) : selectedFiles.length === 1 ? (
                              <Tooltip title={selectedFiles[0].name}>
                                <span style={{ marginRight: "0.5em" }}>
                                  {selectedFiles[0].name.length > 24
                                    ? selectedFiles[0].name.slice(0, 24) + '...'
                                    : selectedFiles[0].name}
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <ul style={{ margin: 0, paddingInlineStart: "1em" }}>
                                    {selectedFiles.map((file) => (
                                      <li key={file.name}>{file.name}</li>
                                    ))}
                                  </ul>
                                }
                              >
                                <span style={{ marginRight: "0.5em" }}>
                                  {spanishLanguage["amodMultipleFilesText"]}
                                </span>
                              </Tooltip>
                            )}
                          </span>

                        }
                      </>
                    )}

                    {
                      ((sendEmail && tycFiles) && circularProgressTycs) ? (
                        <CircularProgress size={24} color="primary" />
                      ) : (
                        ((sendEmail && !tycFiles) && circularProgressIntegration) ? (
                          <CircularProgress size={24} color="primary" />
                        ) : (
                          tableTitle !== spanishLanguage["amodAppTycTableHeader"] && (
                            <CorreosButton
                              variant="contained"
                              color="primary"
                              className={commonClasses.dialogButtonRight}
                              onClick={!sendEmail ? modifyFunction : !tycFiles ? () => sendEmail(handleFile, integrationReqContactEmail) : () => sendEmail(selectedFiles, tycUpdateReqContactEmail)}
                              disabled={!sendEmail ? false : !tycFiles
                                ? (disableSendButtonIntReq || checkIsNullUndefined(handleFile))
                                : (disableSendButtonTycUpdateReq || checkIsNullUndefined(selectedFiles))
                              }
                              style={{ marginLeft: "0.5em" }}
                            >
                              {!sendEmail ? spanishLanguage["atcModify"] : spanishLanguage["amodSendEmailText"]}
                            </CorreosButton>
                          )
                        )
                      )
                    }
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };


  const generateRedirectBody = (data) => {
    if (data == null) data = "";
    let truncatedValue = data;

    if (truncatedValue.length > 62) {
      truncatedValue = "..." + truncatedValue.slice(-62);
    }
    return (

      <TableRow>
        <TableCell component="th" scope="row">
          {getLabelByIdAttribute("urlRedireccion")}
        </TableCell>
        <TableCell align="right">
          {data ? (
            <Tooltip title={data} placement="top">
              <span>
                {truncatedValue ? <span style={{ cursor: "pointer" }}>{truncatedValue}</span> : <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>}
              </span>
            </Tooltip>
          ) : (
            <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const generateImageBody = (data) => {
    return (
      Object.entries(data).map(([key, value]) => {

        if (value == null) value = "";
        let isValueEmpty = value === "";
        let truncatedValue = value;

        if (truncatedValue.length > 62) {
          truncatedValue = "..." + truncatedValue.slice(-62);
        }

        return (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              {getLabelByIdAttribute(key)}
            </TableCell>
            <TableCell align="right">
              {value ? (
                <Tooltip title={value} placement="top">
                  <span>
                    {truncatedValue ? <span style={{ cursor: "pointer" }}>{truncatedValue}</span> : <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>}
                  </span>
                </Tooltip>
              ) : (
                <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
              )}

              <VisibilityIcon
                className={isValueEmpty ? classes.disabledIcon : classes.flagIcon}
                style={!isValueEmpty ? { cursor: "pointer" } : null}
                onClick={() => !isValueEmpty && handleImageClick(value)}
              />

            </TableCell>
          </TableRow>
        );
      })
    );
  };

  const generateLanguageBody = (data) => {
    return (
      Object.entries(data).map(([key, value]) => {
        return (
          <TableRow key={key}>
            <TableCell component="th" scope="row" colSpan={2} align="left">
              {getLabelByIdAttribute(key, false)}
              {value && value.length > 32 ? (
                <Tooltip title={value} placement="top">
                  <span style={{ marginLeft: "0.5em", cursor: "pointer" }}>{`${value.slice(0, 32)}...`}</span>
                </Tooltip>
              ) : (
                <span style={{ marginLeft: "0.5em" }} className={!value ? classes.notSetText : null}>
                  {value || spanishLanguage["amodNotSetText"]}
                </span>
              )}

            </TableCell>
          </TableRow>
        );
      })
    );
  };


  const generateEmailBody = (text, isTycs) => {
    return (
      <TableRow>
        <TableCell width="40%">
          {text}
          {isTycs ? <br /> : null}
          {isTycs ? (
            <span className={classes.allowedFileTypesText}>
              {" (" + spanishLanguage["amodAllowedFileTypes"] + ")."}
            </span>
          ) : null}
        </TableCell>

        <TableCell align="right" width="60%">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>

            <TextField
              value={isTycs ? tycUpdateReqContactEmail : integrationReqContactEmail}
              variant="outlined"
              fullWidth
              name={isTycs ? "tycUpdateReqEmailInput" : "intReqEmailInput"}
              onInput={handleContactEmailInput}
              onBlur={handleBlur}
              color="secondary"
              style={{ backgroundColor: "white", width: "20em", marginBottom: "2em" }}
              label={getLabelByIdAttribute("contactEmail")}
              required={true}
              error={isTycs ? tycUpdateEmailError : intReqEmailError}
              disabled={false}
              inputProps={{ maxLength: 1000 }}
            />

            {isTycs && (
              <>
                <RadioGroup name="personType" value={personType} onChange={handlePersonTypeChange} style={{ marginBottom: '1em' }}>
                  {personTypeOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      labelPlacement="start"
                    />
                  ))}
                </RadioGroup>
                <Divider style={{ width: '10em', borderBottom: '1px solid black', marginBottom: '1em' }}></Divider>
                <RadioGroup name="isTyc" value={isTyc} onChange={handleIsTycChange} style={{ marginBottom: '1em' }}>
                  {isTycOptions.map(option => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                      labelPlacement="start"
                    />
                  ))}
                </RadioGroup>
              </>
            )}

            <input
              type="file"
              ref={!isTycs ? hiddenFileInputSingle : hiddenFileInputMultiple}
              onChange={!isTycs ? (event) => handleSingleFile(event.target.files[0]) : (event) => handleMultipleFiles(event.target.files)}
              style={{ display: 'none' }}
              multiple={isTycs} // Permite seleccionar múltiples archivos
            />


            <CorreosButton variant="contained" color="primary" className={commonClasses.rightButton} onClick={!isTycs ? handleClickSingle : handleClickMultiple}>
              {!isTycs ? spanishLanguage["uexpSelectFile"] : spanishLanguage["amodMultipleFilesSelectText"]}
            </CorreosButton>
          </div>
        </TableCell>
      </TableRow>
    );
  };


  const generateBodyApplicationNotifierUrls = (applicationNotifierUrl) => {
    return Object.entries(applicationNotifierUrl).map(([key, value]) => {
      if (value != null) {
        if (key === "userNotifier") {
          return (
            <>
              <TableRow style={{ backgroundColor: "#f3f2f2" }}>
                <TableCell colSpan={2}>
                  <Typography className={classes.subTitle} style={{ marginTop: "0em" }}>
                    {spanishLanguage["amodUserNotifierText"]}
                  </Typography>
                </TableCell>
              </TableRow>
              {Object.entries(value).map(([nestedKey, nestedValue]) => (
                <TableRow >
                  <TableCell component="th" scope="row">
                    {getLabelByIdAttribute(nestedKey)}
                  </TableCell>
                  <TableCell align="right">
                    {nestedValue ? (
                      <Tooltip title={nestedValue} placement="top">
                        <span style={{ cursor: "pointer" }}>{nestedValue.length > 62 ? "..." + nestedValue.slice(-62) : nestedValue}</span>
                      </Tooltip>
                    ) : (
                      <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          );
        } else if (key === "enterpriseNotifier") {
          return (
            <>
              <TableRow style={{ backgroundColor: "#f3f2f2" }}>
                <TableCell colSpan={2}>
                  <Typography className={classes.subTitle} style={{ marginTop: "0em" }}>
                    {spanishLanguage["amodEnterpriseNotifierText"]}
                  </Typography>
                </TableCell>
              </TableRow>
              {Object.entries(value).map(([nestedKey, nestedValue]) => (
                <TableRow >
                  <TableCell component="th" scope="row">
                    {getLabelByIdAttribute(nestedKey)}
                  </TableCell>
                  <TableCell align="right">
                    {nestedValue ? (
                      <Tooltip title={nestedValue} placement="top">
                        <span style={{ cursor: "pointer" }}>{nestedValue.length > 62 ? "..." + nestedValue.slice(-62) : nestedValue}</span>
                      </Tooltip>
                    ) : (
                      <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          );
        } else {
          return (
            <TableRow>
              <TableCell component="th" scope="row">
                {getLabelByIdAttribute(key)}
              </TableCell>
              <TableCell align="right">
                {value ? value : <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>}
              </TableCell>
            </TableRow>
          );
        }
      }
    });
  };

  const generateBodyTycPrivacy = (applicationTycPrivacy) => {
    return Object.entries(applicationTycPrivacy).map(([key, value]) => {
      if (value != null) {
        if (key === "tyc") {
          return (
            <>
              <TableRow style={{ backgroundColor: "#f3f2f2" }}>
                <TableCell colSpan={2}>
                  <Typography className={classes.subTitle} style={{ marginTop: "0em" }}>
                    {spanishLanguage["amodTycText"]}
                  </Typography>
                </TableCell>
              </TableRow>
              {Object.entries(value).map(([nestedKey, nestedValue]) => (
                <TableRow >
                  <TableCell component="th" scope="row">
                    <SubjectIcon className={classes.flagIcon} /> {" " + nestedValue.name}
                  </TableCell>
                  <TableCell align="right">
                    {nestedValue.url ? (
                      <Tooltip title={nestedValue.url} placement="top">
                        <a href={nestedValue.url} target="_blank" rel="noopener noreferrer">
                          <span style={{ cursor: "pointer" }}>{nestedValue.url.length > 62 ? "..." + nestedValue.url.slice(-62) : nestedValue.url}</span>
                        </a>
                      </Tooltip>
                    ) : (
                      <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          );
        } else if (key === "privacy") {
          return (
            <>
              <TableRow style={{ backgroundColor: "#f3f2f2" }}>
                <TableCell colSpan={2}>
                  <Typography className={classes.subTitle} style={{ marginTop: "0em" }}>
                    {spanishLanguage["amodPrivacyText"]}
                  </Typography>
                </TableCell>
              </TableRow>
              {Object.entries(value).map(([nestedKey, nestedValue]) => (
                <TableRow >
                  <TableCell component="th" scope="row">
                    <SubjectIcon className={classes.flagIcon} /> {" " + nestedValue.name}
                  </TableCell>
                  <TableCell align="right">
                    {nestedValue.url ? (
                      <Tooltip title={nestedValue.url} placement="top">
                        <a href={nestedValue.url} target="_blank" rel="noopener noreferrer">
                          <span style={{ cursor: "pointer" }}>{nestedValue.url.length > 62 ? "..." + nestedValue.url.slice(-62) : nestedValue.url}</span>
                        </a>
                      </Tooltip>
                    ) : (
                      <span className={classes.notSetText}>{spanishLanguage["amodNotSetText"]}</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          );
        }
      }
    });
  };


  const _openNameDialog = () => {
    setOpenNameDialog(!openNameDialog);
  }

  const _openDescriptionDialog = () => {
    setOpenDescriptionDialog(!openDescriptionDialog);
  }

  const _openImagesDialog = () => {
    setOpenImagesDialog(!openImagesDialog);
  }

  const _openRedirectDialog = () => {
    setOpenRedirectDialog(!openRedirectDialog);
  }

  const _openNotifierDialog = () => {
    setOpenNotifierDialog(!openNotifierDialog);
  }

  return (

    <Grid container xs={12}>
      <DialogUpdateApplicationData
        open={openNameDialog}
        setOpen={setOpenNameDialog}
        title={spanishLanguage["amodModAppappAdmTableHeadName"]}
        auxFunction={getLabelByIdAttribute}
        model={applicationName}
        action={confirmUpdateApplicationName}
        circularProgress={circularProgress}
      />

      <DialogUpdateApplicationData
        open={openDescriptionDialog}
        setOpen={setOpenDescriptionDialog}
        title={spanishLanguage["amodModAppDescriptionTitle"]}
        auxFunction={getLabelByIdAttribute}
        model={applicationDescription}
        action={confirmUpdateApplicationDescription}
        circularProgress={circularProgress}
      />

      <DialogUpdateApplicationData
        open={openImagesDialog}
        setOpen={setOpenImagesDialog}
        title={spanishLanguage["amodModAppImagesitle"]}
        auxFunction={getLabelByIdAttribute}
        model={applicationImages}
        action={confirmUpdateApplicationImages}
        circularProgress={circularProgress}
      />

      <DialogUpdateApplicationData
        open={openRedirectDialog}
        setOpen={setOpenRedirectDialog}
        title={spanishLanguage["amodModAppRedirectTitle"]}
        auxFunction={getLabelByIdAttribute}
        model={applicationRedirectUrl}
        action={confirmUpdateApplicationRedirect}
        circularProgress={circularProgress}
      />

      <DialogUpdateApplicationData
        open={openNotifierDialog}
        setOpen={setOpenNotifierDialog}
        title={spanishLanguage["amodModAppNotifierTitle"]}
        auxFunction={getLabelByIdAttribute}
        model={applicationNotifierUrl}
        action={confirmUpdateApplicationNotifier}
        circularProgress={circularProgress}
      />

      <DialogShowImage
        open={openShowImageDialog}
        setOpen={setOpenShowImageDialog}
        title={"Previsualizar imagen"}
        image={imageShow}
        circularProgress={circularProgress}
      />

      <Grid item
        xs={12} className={classes.marginBottom}>
        <Typography
          variant="h4"
          className={classes.title}
        >
          {spanishLanguage["amodTitle"]}
        </Typography>
        <Typography
          align="center"
          className={commonClasses.hitText}
          style={{ marginTop: "2em" }}
        >
          {spanishLanguage["amodSubTitle"]}

        </Typography>

      </Grid>
      <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
        {userGroupActions.includes("AMA") ?

          <Card style={{ width: "100%", padding: "1em" }}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Attributes Tab"
              >
                <Tab
                  key={0}
                  label={spanishLanguage["amodDataTabLabel"]}
                  value={0}
                />
                <Tab
                  key={1}
                  label={spanishLanguage["amodRequestTabLabel"]}
                  value={1}
                />
              </Tabs>
              <Divider></Divider>
            </Grid>
            <CardContent style={{ marginTop: "2em" }}>

              {tab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    {circularProgressNameTable ? (
                      <CircularProgress />
                    ) : (
                      renderTable(
                        spanishLanguage["amodAppNameTableHeader"],
                        generateLanguageBody(applicationName),
                        _openNameDialog
                      )
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {circularProgressDescriptionTable ? (
                      <CircularProgress />
                    ) : (
                      renderTable(
                        spanishLanguage["amodAppDescriptionTableHeader"],
                        generateLanguageBody(applicationDescription),
                        _openDescriptionDialog
                      )
                    )}
                  </Grid>
                  {circularProgressImagesTable ? (
                    <CircularProgress />
                  ) : (
                    renderTable(
                      spanishLanguage["amodAppImagesTableHeader"],
                      generateImageBody(applicationImages),
                      _openImagesDialog
                    )
                  )}
                  {circularProgressRedirectTable ? (
                    <CircularProgress />
                  ) : (
                    renderTable(
                      spanishLanguage["amodAppRedirectTableHeader"],
                      generateRedirectBody(applicationRedirectUrl),
                      _openRedirectDialog
                    )
                  )}
                  {circularProgressNotifierTable ? (
                    <CircularProgress />
                  ) : (
                    renderTable(
                      spanishLanguage["amodAppNotifierTableHeader"],
                      generateBodyApplicationNotifierUrls(applicationNotifierUrl),
                      _openNotifierDialog
                    )
                  )}
                  {circularProgressTycTable ? (
                    <CircularProgress />
                  ) : (
                    renderTable(
                      spanishLanguage["amodAppTycTableHeader"],
                      generateBodyTycPrivacy(applicationTycPrivacy),
                      null
                    )
                  )}
                </Grid>
              )}

              {tab === 1 && (
                <>

                  <Typography
                    align="center"
                    className={commonClasses.hitText}
                    style={{
                      marginTop: "2em",
                      marginBottom: "4em",
                      color: "#002453",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap"
                    }}
                  >
                    <InfoIcon style={{ marginRight: "0.5em" }} />
                    {spanishLanguage["amodSupportRequestSubtitle"]}
                  </Typography>
                  {renderTable(
                    spanishLanguage["amodAppIntegrationRequestTableHeader"],
                    generateEmailBody(spanishLanguage["amodAppIntegrationRequestDescription"], false),
                    null,
                    sendIntegrationRequestEmail
                  )
                  }
                  {renderTable(
                    spanishLanguage["amodAppTycUpdateRequestTableHeader"],
                    generateEmailBody(spanishLanguage["amodAppTycUpdateRequestDescription"], true),
                    null,
                    sendTycPrivacyUpdateRequestEmail,
                    true
                  )
                  }
                </>
              )}

            </CardContent>
          </Card>
          : null}
      </Grid>
    </Grid>

  );
}