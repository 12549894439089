import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  Tabs,
  Tab,
  Divider,
  Table,
  TableContainer,
  TableBody,
  Paper,
  IconButton,
  Box,
  Tooltip,
  TextField,
  TableRow,
  TableCell

} from "@material-ui/core";
import {
  GetMasterCountry,
  GetExtendedUserDataByIdentification,
  PostAtcOtpSendEmail,
  PostAtcOtpSendPhone,
} from "../../services/ReasonService";
import { RolLevels, SubApp } from "../../commons/Enums";
import { useTranslation } from "react-i18next";
import { checkIsNullUndefined, getErrorMessage, getPhonePrefixCountry, formatIsoDate } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserInfoStyles } from "./UserInfoStyles";
import { EnumCountries } from "../../commons/Enums";
import { PrefixPhone } from "../TransversalComponents/PrefixPhone/PrefixPhone";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from '@material-ui/icons/Send';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import BusinessIcon from '@material-ui/icons/Business';
import { MdMail, MdPhoneAndroid, MdDateRange } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FaIdCard, FaUser } from "react-icons/fa";
import { AtcModal } from "../Atc/AtcModal/AtcModal";
import { MdDoneAll } from "react-icons/md";
import { MdVpnKey } from "react-icons/md";
import { DialogVerifyEmailOrPhone } from "./DialogVerifyEmailOrPhone";
import { PageHeader } from "../CommonComponents/PageHeader";
import { UserSpecialCard } from "../CommonComponents/UserSpecialCard";
import { UserInfoCard } from "../CommonComponents/UserInfoCard";

export function UserInfo(props) {

  const {
    finishOperation,
    userGroupActions,
    handleShowAppsSelect,
    handleSetAppsSelectOptions
  } = props;

  const validator = require("react-email-validator");
  const { t, i18n } = useTranslation();
  const classes = UserInfoStyles();
  const commonClasses = CommonStyles();
  const [circularProgress, setCircularProgress] = useState(false);
  const [circularProgressOtp, setCircularProgressOtp] = useState(false);
  const [tab, setTab] = useState(0);
  const [identifier, setIdentifier] = useState("");
  const [identifierType, setIdentifierType] = useState("");
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  const [showPrefixes, setShowPrefixes] = useState(false);
  const [prefixPhoneCode, setPrefixPhoneCode] = useState(EnumCountries.ESP);
  const [countryList, setCountryList] = useState(null);
  const [userData, setUserData] = useState(null);
  const [otpData, setOtpData] = useState(null);
  const [expandedAddresses, setExpandedAddresses] = useState([]);
  const [expandedDocuments, setExpandedDocuments] = useState([]);
  const [expandedEmails, setExpandedEmails] = useState([]);
  const [expandedPhones, setExpandedPhones] = useState([]);
  const [expandedSubscriptions, setExpandedSubscriptions] = useState([]);
  const [expandedEnterprises, setExpandedEnterprises] = useState([]);
  const [expandedEnterpriseAddresses, setExpandedEnterpriseAddresses] = useState([]);
  const [expandedEnterpriseSubscriptions, setExpandedEnterpriseSubscriptions] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [option, setOption] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [verificableData, setVerificableData] = useState(false);
  const [verificableDataType, setVerificableDataType] = useState(false);

  useEffect(() => {
    handleSetAppsSelectOptions(SubApp.CAU, RolLevels.N2);
    handleShowAppsSelect(false);
    getCountries();
  }, []);

  const _openDialog = (data, dataType) => {
    setVerificableData(data);
    setVerificableDataType(dataType == 'userEmail' ? true : false);
    setOpenDialog(!openDialog);
  }

  const getCountries = () => {
    GetMasterCountry().then((response) => {
      if (response && !response.Status) {
        setCountryList(response);
      }
    });
  }

  const toggleData = (index, expandedData, setExpandedData) => {
    if (expandedData.includes(index)) {
      setExpandedData((prevExpanded) => prevExpanded.filter((item) => item !== index));
    } else {
      setExpandedData((prevExpanded) => [...prevExpanded, index]);
    }
  };

  const toggleEnterpriseAddress = (enterpriseIndex, addressIndex) => {
    setExpandedEnterpriseAddresses((prevExpanded) => {
      const key = enterpriseIndex + "_" + addressIndex;
      return prevExpanded.includes(key)
        ? prevExpanded.filter((item) => item !== key)
        : [...prevExpanded, key];
    });
  };

  const toggleEnterpriseSubscription = (enterpriseIndex, subscriptionIndex) => {
    setExpandedEnterpriseSubscriptions((prevExpanded) => {
      const key = enterpriseIndex + "_" + subscriptionIndex;
      return prevExpanded.includes(key)
        ? prevExpanded.filter((item) => item !== key)
        : [...prevExpanded, key];
    });
  };

  function filterObjectByProperties(obj, itemType) {

    if (obj === null) {
      return null;
    }
    let propertiesToFilter = []
    switch (itemType) {
      case 'userEmail':
        propertiesToFilter = ["id", "email", "idConTypeVerification", "conTypeVerification", "communication", "lastOtp", "idValidationOtp", "receiver"];
        break;
      case 'userPhone':
        propertiesToFilter = ["id", "idCountry", "prefix", "idConTypeVerification", "conTypeVerification", "phone", "communication"];
        break;
      case 'userDocument':
        propertiesToFilter = ["id", "typeUserDocument", "idConTypeVerification", "conTypeVerification", "number"];
        break;
      case 'userAddress':
      case 'enterpriseAddress':
        propertiesToFilter = ["id"];
        break;
      case 'userSubscription':
      case 'enterpriseSubscription':
        propertiesToFilter = ["idSubscriptionData", "codeApp", "stateSubscription"];
        break;
      default:
        break;

    }
    let filteredEntries = Object.entries(obj).filter(([key]) => !propertiesToFilter.includes(key));
    return Object.fromEntries(filteredEntries);
  }

  const _handleVerfiedData = (value, type) => {
    let verifiedData = [];
    if (type === "identifierDocument") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 7); // Sustituir por Enum??
    }

    if (type === "email") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 2); // Sustituir por Enum??
    }

    if (type === "phone") {
      verifiedData = value.filter((v) => v.idConTypeVerification === 5); // Sustituir por Enum??
    }

    if (verifiedData.length === 0) {
      return [];
    } else {
      return verifiedData;
    }
  };

  const _formatPhone = (value) => {
    return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4");
  };

  // Función cerrar modal
  const _handleClose = () => {
    setOpenModal(false);
  };

  const _handleOpen = (index, x) => {
    let value = parseInt(index);
    if (value >= 0) {
      setSelectedModalData(userData[value]);
    } else {
      setSelectedModalData({});
    }
    setOption(x);
    setOpenModal(true);
  };


  const handleTabChange = (event, tabValue) => {
    setTab(tabValue);
  };


  // controla el cambio en el selector de prefijo
  const handleSelectorChange = (country) => {
    setPrefixPhoneCode(country.code);
  };

  const handleUserDataSelectChange = (index) => {
    let selectedUser = userData[index];
    setSelectedUserData(selectedUser);
  };

  const compareFecCancelAccount = (a, b) => {
    if (a.fecCancelAccount === null && b.fecCancelAccount !== null) {
      return -1;
    } else if (a.fecCancelAccount !== null && b.fecCancelAccount === null) {
      return 1;
    } else {
      return 0;
    }
  }


  const _handleNext = () => {
    setSelectedUserData(null);
    setShowCards(false);
    let identifierAux = identifier;

    if (identifierType === "phoneNumber") {
      let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
      identifierAux = country.phonePrefix + "-" + identifier;
    }
    setCircularProgress(true);
    GetExtendedUserDataByIdentification(identifierAux).then(
      (response) => {
        if (!response || response.Status) {
          finishOperation("error", getErrorMessage(response));
          setCircularProgress(false);
        } else {
          if (response.listExtendedUserData != null) {
            let orderedUserdata = response.listExtendedUserData.sort(compareFecCancelAccount);
            setOtpData(response.lastOtp);
            setUserData(orderedUserdata);
            setShowCards(true);
          }
          setCircularProgress(false);
        }

      }
    );
  };


  // Identifica el tipo de identificador introducido (email, teléfono) y habilita el botón cuando el valor sea válido
  const _handleTypeOfIdentifier = (e) => {

    setShowCards(false);
    setUserData(null);
    setSelectedUserData(null);
    setOtpData(null);

    let auxDisableButtonNext = true;
    let auxShowPrefixes = false;
    let auxIdentifierType = "";
    let value = e.target.value.trim();
    setDisableButtonNext(true);
    setShowPrefixes(false);


    const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (validator.validate(value)) {
      auxIdentifierType = "email";
      auxDisableButtonNext = false;
    } else if (checkPhoneNumber.test(value)) {
      // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
      auxShowPrefixes = true;
      if (value.length >= 9) {
        auxIdentifierType = "phoneNumber";
        auxDisableButtonNext = false;
      }
    }

    setIdentifier(value);
    setIdentifierType(auxIdentifierType);
    setDisableButtonNext(auxDisableButtonNext);
    setShowPrefixes(auxShowPrefixes);
  };

  const _handleSendOtp = () => {
    setCircularProgressOtp(true);

    if (identifierType === "phoneNumber") {

      let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
      let identifierAux = country.phonePrefix + "-" + identifier;

      PostAtcOtpSendPhone(identifierAux).then((response) => {
        if (response && !response.Status) {
          finishOperation("success", t("userInfoOtpSent").replace("{identifier}", identifierAux));
          _handleNext();
          setCircularProgressOtp(false);
        } else {
          finishOperation("error", getErrorMessage(response));
        }
        setCircularProgressOtp(false);
      });
    } else {
      PostAtcOtpSendEmail(identifier).then((response) => {
        if (response && !response.Status) {
          finishOperation("success", t("userInfoOtpSent").replace("{identifier}", identifier));
          setCircularProgressOtp(true);
        } else {
          finishOperation("error", getErrorMessage(response));
        }
        setCircularProgressOtp(false);
      });
    };
  }

  const _handleSendMailOtp = (id) => {
    setCircularProgressOtp(true);

    PostAtcOtpSendEmail(id).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("userInfoOtpSent").replace("{identifier}", identifier));
        setCircularProgressOtp(true);
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgressOtp(false);
    });
  };


  const _handleSendPhoneOtp = (id) => {
    setCircularProgressOtp(true);

    PostAtcOtpSendPhone(id).then((response) => {
      if (response && !response.Status) {
        finishOperation("success", t("userInfoOtpSent").replace("{identifier}", identifier));
        setCircularProgressOtp(true);
      } else {
        finishOperation("error", getErrorMessage(response));
      }
      setCircularProgressOtp(false);
    });
  };


  const formatData = (data) => {
    if (checkIsNullUndefined(data)) {
      return <span className={classes.notSetText}>{t("userInfoNoSeted")}</span>;
    }
    data = data.toString();

    if (data === "false") return "No";
    if (data === "true") return "Sí";
    let isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;
    if (isoDateFormatRegex.test(data)) {
      return formatIsoDate(data);
    }
    return data;
  };

  const renderTable = (tableBody) => {
    return (
      <Grid item xs={12}>
        <TableContainer component={Paper} style={{ marginLeft: "5em", marginRight: "5em", width: "auto" }}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderUserData = (data) => {
    return (
      data.map(([key, value]) => {
        if (key === "fecCancelAccount" && value === null) {
          return null;
        }
        return (
          <TableRow key={key}>
            <TableCell component="th" scope="row">
              <strong>{t("genLabel_" + key)}</strong>
            </TableCell>
            <TableCell align="right">
              {value ? (
                <Tooltip title={value} placement="top">
                  <span style={{ cursor: "pointer" }}>{formatData(value)}</span>
                </Tooltip>
              ) : (
                <span className={classes.notSetText}>{t("userInfoNoSeted")}</span>
              )}
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  const renderItem = (item, itemType) => {
    let filteredItem = filterObjectByProperties(item, itemType);

    if (filteredItem == null) {
      return null;
    }
    return Object.entries(filteredItem).map(([key, value]) => (
      <TableRow key={key}>
        <TableCell component="th" scope="row">
          <strong>{t("genLabel_" + key)}</strong>
        </TableCell>
        <TableCell align="right">
          {value ? (
            <Tooltip title={value} placement="top">
              <span style={{ cursor: "pointer" }}>{formatData(value)}</span>
            </Tooltip>
          ) : (
            <span className={classes.notSetText}>{t("userInfoNoSeted")}</span>
          )}
        </TableCell>
      </TableRow>
    ));
  };

  const renderUserEnterprisesData = (data, expandedItems, setExpandedItems) => {
    return (
      <Grid container xs={12} style={{ display: 'block' }}>
        <Grid item>
          {data.userEnterprises.length > 0 ? (
            data.userEnterprises.map((enterprise, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                <ul style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', listStyle: 'none', padding: 0 }}>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip
                      title={expandedItems.includes(index) ? t('userInfoCollapse') : t('userInfoExpand')}
                      placement="top"
                      arrow
                    >
                      <Typography
                        variant="h6"
                        style={{ cursor: "pointer", display: "flex", alignItems: "center", margin: '0 0.5em' }}
                        onClick={() => toggleData(index, expandedItems, setExpandedItems)}
                      >

                        <BusinessIcon style={{ marginRight: "1em" }} /> {/* Icono para empresas */}
                        {renderDataLabel("enterprise", enterprise.companyName, null, enterprise.nameRol)}

                      </Typography>
                    </Tooltip>
                  </li>
                </ul>
                {expandedItems.includes(index) && (
                  <>
                    {renderTable(renderUserData(Object.entries(enterprise).filter(([key, value]) => !Array.isArray(value))))}
                    {renderEnterpriseObject(enterprise, "enterpriseSubscription", expandedEnterpriseSubscriptions, index, toggleEnterpriseSubscription)}
                    {renderEnterpriseObject(enterprise, "enterpriseAddress", expandedEnterpriseAddresses, index, toggleEnterpriseAddress)}
                  </>
                )}
              </div>
            ))
          ) : (
            <Typography>
              <span className={classes.notSetText}>{t("userInfoNoData")}</span>
            </Typography>
          )}

        </Grid>
      </Grid>
    );
  };




  const renderEnterpriseObject = (enterprise, object, expandedObject, index, toggleFunction) => {
    if (enterprise[object] !== null) {
      return (
        <>
          <Divider style={{ width: "100%", marginBottom: "1em" }}></Divider>
          <Typography variant="h6">
            {object === "enterpriseAddress"
              ? t("userInfoAddresses")
              : object === "enterpriseSubscription"
                ? t("userInfoSubscriptions")
                : null}
          </Typography>
          <Divider style={{ width: "100%", marginTop: "1em" }}></Divider>

          {enterprise[object].map((item, subsIndex) => {
            let isExpanded = expandedObject.includes(index + "_" + subsIndex);
            return (
              <div key={subsIndex} style={{ textAlign: "center" }}>
                <ul style={{ display: "flex", alignItems: "center", justifyContent: "center", listStyle: "none", padding: 0 }}>
                  <li style={{ display: "flex", alignItems: "center" }}>
                    <Tooltip
                      title={isExpanded ? t("userInfoCollapse") : t("userInfoExpand")}
                      placement="top"
                      arrow
                    >
                      <Typography variant="subtitle1" style={{ cursor: "pointer" }} onClick={() => toggleFunction(index, subsIndex)}>
                        {object === "enterpriseSubscription" && (
                          <>
                            <PlaylistAddCheckIcon style={{ marginRight: "1em" }} />
                            <span>{renderDataLabel("subscription", item.nameApp, null, item.stateSubscription)}</span>
                          </>
                        )}

                        {object === "enterpriseAddress" && (
                          <>
                            <HomeIcon style={{ marginRight: "1em" }} />
                            <span>{renderAddressLabel(item.cityName, item.countryName, item.postalCode)}</span>
                          </>
                        )}
                      </Typography>
                    </Tooltip>
                  </li>
                </ul>

                {isExpanded && <>{renderTable(renderItem(item, object))}</>}
              </div>
            );
          })}
        </>
      );
    }
    return null;
  };



  const renderLabel = (label, text, colorClass) => (
    <>
      {label}
      {" ("}
      <span className={colorClass}>
        {text}
      </span>
      {")"}

    </>
  );

  const renderDataLabel = (type, data, auxData, conTypeVerification) => {
    switch (type) {
      case "subscription":
        let subscriptionColorClass =
          conTypeVerification === 'Suscrito' ? classes.greenText :
            conTypeVerification === 'Dado de baja' ? classes.redText : classes.orangeText;
        return renderLabel(data, conTypeVerification, subscriptionColorClass);

      case "email":
        let emailColorClass = conTypeVerification === 'Verificación OTP mail' ? classes.greenText : classes.orangeText;
        return renderLabel(data, conTypeVerification, emailColorClass);

      case "phone":
        let phoneColorClass = conTypeVerification === 'Verificación OTP teléfono' ? classes.greenText : classes.orangeText;
        return renderLabel(`(${auxData}) ${data}`, conTypeVerification, phoneColorClass);

      case "document":
        let documentColorClass = conTypeVerification === 'Onboarding presencial' ? classes.greenText : classes.orangeText;
        return renderLabel(`${auxData}: ${data}`, conTypeVerification, documentColorClass);
      case "enterprise":
        let enterpriseColorClass = conTypeVerification === 'Titular' ? classes.greenText : classes.orangeText;
        return renderLabel(data, conTypeVerification, enterpriseColorClass);
      default:
        return null;
    }
  };

  const renderAddressLabel = (cityName, countryName, postalCode) => {
    let address = cityName ? `${cityName}, ` : '';
    let postal = postalCode ? ` (${postalCode})` : '';
    return (
      <>
        {address}
        {countryName}
        {postal}
      </>
    );
  };

  const renderCompoundData = (data, expandedItems, setExpandedItems, itemType) => {

    return (
      <Grid container xs={12} style={{ display: 'block' }}>
        <Grid item>
          {data[itemType].length > 0 ? (
            data[itemType].map((item, index) => (
              <div key={index} style={{ textAlign: "center" }}>
                <ul style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', listStyle: 'none', padding: 0 }}>
                  <li style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip
                      title={expandedItems.includes(index) ? t('userInfoCollapse') : t('userInfoExpand')}
                      placement="top"
                      arrow
                    >
                      <Typography
                        variant="h6"
                        style={{ cursor: "pointer", display: "flex", alignItems: "center", margin: '0 0.5em' }}
                        onClick={() => toggleData(index, expandedItems, setExpandedItems)}
                      >
                        {itemType === 'userSubscription' && (
                          <>
                            <PlaylistAddCheckIcon style={{ marginRight: "1em" }} />
                            {renderDataLabel("subscription", item.nameApp, null, item.stateSubscription)}
                          </>
                        )}
                        {itemType === 'userEmail' && (
                          <>
                            <AlternateEmailIcon style={{ marginRight: "1em" }} />
                            {renderDataLabel("email", item.email, null, item.conTypeVerification)}
                          </>
                        )}
                        {itemType === 'userPhone' && (
                          <>
                            <PhoneIcon style={{ marginRight: "1em" }} />
                            {renderDataLabel("phone", item.phone, item.prefix, item.conTypeVerification)}
                          </>
                        )}
                        {itemType === 'userDocument' && (
                          <>
                            <FingerprintIcon style={{ marginRight: "1em" }} />
                            {renderDataLabel("document", item.number, item.typeUserDocument, item.conTypeVerification)}
                          </>
                        )}
                        {itemType === 'userAddress' && (
                          <>
                            <HomeIcon style={{ marginRight: "1em" }} />
                            {renderAddressLabel(item.cityName, item.countryName, item.postalCode)}
                          </>
                        )}
                      </Typography>
                    </Tooltip>
                  </li>
                  {(itemType === 'userEmail' || itemType === 'userPhone') && (
                    <li style={{ display: 'flex', alignItems: 'center' }}>
                      {circularProgressOtp ? (
                        <CircularProgress />
                      ) : (
                        itemType === 'userEmail' && item.idConTypeVerification < 2 ? (
                          <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={() => _openDialog(item.email, itemType)}
                          >
                            <SendIcon style={{ marginRight: "0.2em" }} />
                            {t("userInfoVerifyEmail")}
                          </CorreosButton>
                        ) : itemType === 'userPhone' && item.idConTypeVerification < 5 ? (
                          <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={() => _openDialog(item.prefix + "-" + item.phone, itemType)}
                          >
                            <SendIcon style={{ marginRight: "0.2em" }} />
                            {t("userInfoVerifyPhone")}
                          </CorreosButton>
                        ) : (
                          /* Botón original cuando no se cumplen las condiciones adicionales */
                          <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={itemType === 'userEmail' ? () => _handleSendMailOtp(item.email) : () => _handleSendPhoneOtp(item.prefix + "-" + item.phone)}
                          >
                            <SendIcon style={{ marginRight: "0.2em" }} />
                            {t("userInfoSendOtp")}
                          </CorreosButton>)

                      )}
                    </li>
                  )}
                </ul>
                {expandedItems.includes(index) && (
                  <>
                    {renderTable(renderItem(itemType === 'userEmail' ? item.lastOtp : item, itemType))}
                  </>
                )}
              </div>
            ))
          ) : (
            <Typography>
              <span className={classes.notSetText}>{t("userInfoNoData")}</span>
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderCards = (data) => {

    let hasActiveAccounts = data !== null && data.length > 0;
    let showSpecialCard = data === null || !hasActiveAccounts || data.every(account => account.fecCancelAccount !== null);


    return <Grid container>
      {showSpecialCard && (
        <Grid xs={12} md={6} container justifyContent="center" alignItems="center" style={{ margin: data.length >= 2 ? "" : "auto" }}>
          <Card className={`${classes.cardStyles}`}>
            <UserSpecialCard otpData={otpData} identifier={identifier} />
            <CardContent>
              {circularProgressOtp ? (
                <CircularProgress />
              ) : (
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={_handleSendOtp}
                  className={classes.selectAccount}
                >
                  {t("userInfoSendOtp")}
                </CorreosButton>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
      {hasActiveAccounts && (
        data.map((account, index) => (
          <Grid xs={12} md={6} container justifyContent="center" alignItems="center" style={{ margin: data.length >= 2 ? "" : "auto" }}>
            <Card
              className={`${classes.cardStyles} ${selectedCardIndex === index ? classes.selectedCard : ''
                } ${account.fecCancelAccount ? classes.cancelledCard : ''}`}
            >
              {account.fecCancelAccount && (
                <div className={classes.cancelledText}>{t("userInfoCancelledAccount")}</div>
              )}
              <UserInfoCard account={account} countryList={countryList} />
              <CardContent>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUserDataSelectChange(index);
                    setSelectedCardIndex(index);
                  }}
                  className={classes.selectAccount}
                >
                  {t("userInfoSelectAccount")}
                </CorreosButton>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  }

  return (
    <Grid container item xs={12}>
      <PageHeader title={t("userInfoTitle")} text={t("userInfoSubtitle")} />
      <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
        {userGroupActions.includes("CUE") ?
          <Card style={{ width: "100%", padding: "1em", paddingTop: "6em" }}>
            <Grid
              container
              item
              justifyContent="center"
              spacing={1}
              xs={12}
            >
              <Grid container item sm={10} xs={12} spacing={1} className={classes.textFieldRow}>
                {showPrefixes ? (
                  <Grid item sm={2} xs={12}>
                    <PrefixPhone countryList={countryList} handleSelectorChange={handleSelectorChange} />
                  </Grid>
                ) : null}
                <Grid item sm={showPrefixes ? 8 : 10} xs={12} className={classes.textFieldRow}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="idSearcher"
                    label={t("userInfoIdentifierField")}
                    name="idSearcher"
                    color="secondary"
                    required={true}
                    onChange={_handleTypeOfIdentifier}
                    value={identifier}
                  />
                </Grid>
                <Grid item sm={2} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                  <CorreosButton
                    className={classes.buttonSearch}
                    variant="contained"
                    color="primary"
                    onClick={() => _handleNext()}
                    style={{ float: "center", marginLeft: "2em" }}
                    disabled={disableButtonNext}
                  >
                    <SearchIcon style={{ marginRight: "0.2em" }} />
                    {t("search")}
                  </CorreosButton>
                </Grid>
              </Grid>
            </Grid>
            <CardContent style={{ marginTop: "2em" }}>
              {circularProgress ? (
                <CircularProgress />
              ) : (
                <>
                  {showCards && userData && userData != {} && (
                    <>
                      {openModal ? (
                        <AtcModal
                          open={openModal}
                          handleClose={_handleClose}
                          userData={selectedModalData}
                          option={option}
                          countryList={countryList}
                        />
                      ) : null}
                      <Typography
                        variant="h4"
                        className={classes.title}
                      >
                        {identifier + ": " + t("userInfoSelectAnAccount")}
                        <Divider style={{ width: "100%", marginTop: "1em" }}></Divider>
                      </Typography>
                      {renderCards(userData)}
                    </>
                  )}
                  {selectedUserData && (
                    <>
                      <Typography
                        variant="h4"
                        className={classes.title}
                      >
                        {t("userInfoAccountOid") + ": " + selectedUserData.userOid}
                        <Divider style={{ width: "100%", marginTop: "1em" }}></Divider>
                      </Typography>
                      <Grid item xs={12} style={{ marginTop: "2em", padding: "0px" }}>
                        <Box display="flex" justifyContent="center" width="100%">
                          <Tabs value={tab} onChange={handleTabChange} aria-label="Attributes Tab" variant="scrollable"
                            scrollButtons="auto">
                            <Tab key={0} label={t("userInfoDatosTabLabel")} value={0} />
                            <Tab key={1} label={t("userInfoDireccionesTabLabel")} value={1} />
                            <Tab key={2} label={t("userInfoEmailsTabLabel")} value={2} />
                            <Tab key={3} label={t("userInfoTeléfonosTabLabel")} value={3} />
                            <Tab key={4} label={t("userInfoDocumentosTabLabel")} value={4} />
                            <Tab key={5} label={t("userInfoSubscripcionesTabLabel")} value={5} />
                            <Tab key={6} label={t("userInfoEmpresasTabLabel")} value={6} />
                          </Tabs>
                          <Divider />
                        </Box>
                      </Grid>
                      <Grid container component="div" style={{ border: '1px solid #ccc', padding: '12px', borderRadius: '4px', width: '100%' }}>
                        <Grid style={{ width: "100%" }}>
                          <Grid item style={{ textAlign: "center" }} >
                            {tab === 0 && renderTable(renderUserData(Object.entries(selectedUserData).filter(([key, value]) => !Array.isArray(value))))}
                            {tab === 1 && renderCompoundData(selectedUserData, expandedAddresses, setExpandedAddresses, "userAddress")}
                            {tab === 2 && renderCompoundData(selectedUserData, expandedEmails, setExpandedEmails, "userEmail")}
                            {tab === 3 && renderCompoundData(selectedUserData, expandedPhones, setExpandedPhones, "userPhone")}
                            {tab === 4 && renderCompoundData(selectedUserData, expandedDocuments, setExpandedDocuments, "userDocument")}
                            {tab === 5 && renderCompoundData(selectedUserData, expandedSubscriptions, setExpandedSubscriptions, "userSubscription")}
                            {tab === 6 && renderUserEnterprisesData(selectedUserData, expandedEnterprises, setExpandedEnterprises)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </CardContent>
          </Card>
          : null}
      </Grid>
      <DialogVerifyEmailOrPhone
        open={openDialog}
        setOpen={setOpenDialog}
        accIdentifier={identifier}
        accIdIsEmail={identifierType == 'email' ? true : false}
        emailOrPhone={verificableData}
        isEmail={verificableDataType}
        handleNext={_handleNext}
        countryList={countryList}
        handleSelectorChange={handleSelectorChange}

      />
    </Grid >
  );
}
