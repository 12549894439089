import { makeStyles } from "@material-ui/core/styles";

export const UserDeleteStyles = makeStyles((theme) => ({

  marginBottom: {
    marginBottom: "5em",
    color: "#002453",
  },

  title: {
    marginTop: "1em",
    color: "#002453",
  },

  notSetText: {
    fontStyle: "italic",
    color: "#CBCBCB"
  },

  allowedFileTypesText: {
    fontStyle: "italic",
    color: "#002453"
  },

  cardStyles: {
    width: "85%",
    paddingTop: "2em",
    marginTop: "2em",
    marginLeft:"1em",
    position:"relative",

    [theme.breakpoints.down("md")]: {
      height: "20em",
      width: "auto",
      margin:"auto",
      marginLeft:"1em",
      flexDirection:"column",

    },

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "auto",
      marginTop:"1em",
    }
  },

  selectAccount: {
    position: 'absolute',
    bottom:  "1em", 
    right: "1em",
  },

  titleText: {
    textAlign:"left",
    color: "#002453",
    marginLeft:"2em",
  },

  iconButton:{
    float: "left",
    color: "#002453",
  },

  accountDataContainer:{
    alignContent: "center", 
    alignItems: "center", 
    height: "3em"
  },

  accountDataIconGrid:{
    textAlign: "left",
    marginLeft: "2em"
  },
  
}));
