import React, { useState, useEffect } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    InputAdornment
} from "@material-ui/core";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { checkIsNullUndefined } from "../../services/Utilities";
import {
    PostAtcOtpSendEmail,
    PostAtcOtpSendPhone,
    PostVerificationValidationOtpEmailWithoutToken,
    PostVerificationValidationOtpPhoneWithoutToken,
    PostVerifyEmailOrPhone
} from "../../services/ReasonService"
import { spanishLanguage } from "../../config/language/spanishLanguage";

export function DialogVerifyEmailOrPhone(props) {
    const { t, i18n } = useTranslation();
    const { open, setOpen, accIdentifier, accIdIsEmail, emailOrPhone, isEmail, handleNext, countryList, handleSelectorChange } = props;
    const commonClasses = CommonStyles();
    const dialogClasses = DialogStyles();
    const [otpAccountId, setOtpAccountId] = useState();
    const [otpEmailOrPhone, setOtpEmailOrPhone] = useState();
    const [error, setError] = useState(false);
    const [helper, setHelper] = useState("");
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgressNext, setCircularProgressNext] = useState(false);
    const [circularProgress1, setCircularProgress1] = useState(false);
    const [circularProgress2, setCircularProgress2] = useState(false);
    const [otpSendedAccountId, setOtpSendedAccountId] = useState(false);
    const [otpSendedVerificableData, setOtpSendedVerificableData] = useState(false);
    const [otpVerifiedAccount, setOtpVerifiedAccount] = useState(false);
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);

    useEffect(() => {

        setStep1(true);
        setStep2(false);

    }, []);


    const handleCancel = () => {
        setStep1(true);
        setStep2(false);
        setOtpAccountId("");
        setOtpEmailOrPhone("");
        setError(false);
        setCircularProgress(false);
        setCircularProgress1(false);
        setCircularProgress2(false);
        setHelper("");
        setOtpSendedAccountId(false);
        setOtpSendedVerificableData(false);
        setOpen(false);
    };



    const handleSendOtp = (data, isEmail, isAccId) => {
        setCircularProgress(false);
        if (isAccId) {
            setCircularProgress1(true);
        } else {
            setCircularProgress2(true);
        }

        let auxCall = isEmail ? PostAtcOtpSendEmail : PostAtcOtpSendPhone;
        auxCall(data).then((response) => {
            if (response && !response.Status) {
                setCircularProgress(false);
                if (isAccId) {
                    setOtpSendedAccountId(true);

                } else {
                    setOtpSendedVerificableData(true);
                }

                setCircularProgress1(false);
                setCircularProgress2(false);
            } else {
                setCircularProgress(false);
                setCircularProgress1(false);
                setCircularProgress2(false);
            }
        });
    };

    const handleConfirmIdentity = (isEmail) => {
        setCircularProgressNext(true);
        let auxCall = isEmail ? PostVerificationValidationOtpEmailWithoutToken : PostVerificationValidationOtpPhoneWithoutToken;
        auxCall(accIdentifier, otpAccountId.trim(), null).then((response) => {
            if (response && !response.Status) {
                setOtpVerifiedAccount(true);
                setStep1(false);
                setStep2(true);
                setOtpSendedAccountId(false);

            } else {
                setError(true);
                setHelper(spanishLanguage["registerVerifyOtpError"]);
            }
            setCircularProgressNext(false);
        });
    };

    const handleConfirmVerifyData = () => {
        setCircularProgressNext(true);
        PostVerifyEmailOrPhone(accIdentifier, emailOrPhone, otpEmailOrPhone).then((response) => {
            if (response && !response.Status) {
                handleCancel();
                handleNext();
            } else {
                setError(true);
                setHelper(spanishLanguage["registerVerifyOtpError"]);
            }
            setCircularProgressNext(false);
        });
    };

    const handleChangeOtpAccount = (value) => {
        let auxiliarError = false;
        let auxiliarHelper = "";
        if (checkIsNullUndefined(value)) {
            auxiliarError = true;
            auxiliarHelper = spanishLanguage["required"];
        }
        setError(auxiliarError);
        setHelper(auxiliarHelper);
        setOtpAccountId(value);
    };

    const handleChangeOtpVerificableData = (value) => {
        let auxiliarError = false;
        let auxiliarHelper = "";
        if (checkIsNullUndefined(value)) {
            auxiliarError = true;
            auxiliarHelper = spanishLanguage["required"];
        }
        setError(auxiliarError);
        setHelper(auxiliarHelper);
        setOtpEmailOrPhone(value);
    };
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={open}
            onClose={handleCancel}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            <Grid>
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" className={dialogClasses.dialogTitleText}>
                            {step1
                                    ? t("uinfDialogTitleStep1")
                                    : t("uinfDialogTitleStep2")}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText}>
                                {step1
                                    ? t("uinfDialogSubTitleStep1")
                                    : t("uinfDialogSubTitleStep2")}
                            </Typography>
                        </Grid>
                        <Grid item xs={10}>

                            <FormControl fullWidth variant="outlined" color="secondary">
                                <InputLabel>{spanishLanguage["registerVerify"]}</InputLabel>   
                                <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={step1 ? accIdIsEmail ? accIdentifier : accIdentifier.split("-")[1] : isEmail ? emailOrPhone : emailOrPhone.split("-")[1]  }
                                                disabled
                                                InputProps={(step1 && !accIdIsEmail) || (step2 &&!isEmail) ? {
                                                    startAdornment: (
                                                      <InputAdornment position='start' style={{ paddingTop: '2.25em' ,paddingBottom: '2.25em'}}>
                                                        <PrefixPhone countryList={countryList} 
                                                        handleSelectorChange={handleSelectorChange} 
                                                        disabled={true} 
                                                        defaultCode={step1?accIdentifier.split("-")[0]:emailOrPhone.split("-")[0]}/>
                                                      </InputAdornment>
                                                     ),
                                                    }:null}
                                            />  
                              
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} className={commonClasses.gridIdentity} >
                            <CorreosButton
                                edge="end"
                                variant={(step1 ? otpSendedAccountId : otpSendedVerificableData) ? "text" : "contained"}
                                color={(step1 ? otpSendedAccountId : otpSendedVerificableData) ? "secondary" : "primary"}
                                onClick={() => handleSendOtp(step1 ? accIdentifier : emailOrPhone, step1 ? accIdIsEmail : isEmail, step1)}
                                disabled={step1 ? circularProgress1 : circularProgress2}
                                circularProgress={step1 ? circularProgress1 : circularProgress2}
                                className={dialogClasses.dialogButtonRight}
                            >
                                {(step1 ? otpSendedAccountId : otpSendedVerificableData) ? spanishLanguage["resend"] : spanishLanguage["send"]}
                            </CorreosButton>
                        </Grid>
                        {otpSendedAccountId ? (
                            <Grid item xs={10}>
                                <TextField
                                    color="secondary"
                                    value={otpAccountId}
                                    onChange={(e) => handleChangeOtpAccount(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    name="Otp"
                                    label={spanishLanguage["registerVerifyOtp"]}
                                    type="text"
                                    error={error}
                                    helperText={helper}
                                    required
                                />
                            </Grid>
                        ) : null}

                        {otpSendedVerificableData ? (
                            <Grid item xs={10}>
                                <TextField
                                    color="secondary"
                                    value={otpEmailOrPhone}
                                    onChange={(e) => handleChangeOtpVerificableData(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    name="Otp"
                                    label={spanishLanguage["registerVerifyOtp"]}
                                    type="text"
                                    error={error}
                                    helperText={helper}
                                    required
                                />
                            </Grid>
                        ) : null}

                    </Grid>
                    
                    <Grid
                        container
                        item
                        xs={12}
                        className={dialogClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={dialogClasses.dialogButtonLeft}
                            >
                                {t("cancel")}
                            </CorreosButton>
                        </Grid>

                        {step1 ? (
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    disabled={checkIsNullUndefined(otpAccountId) || circularProgress}
                                    onClick={() => handleConfirmIdentity(accIdIsEmail)}
                                    circularProgress={circularProgressNext}
                                    className={dialogClasses.dialogButtonRight}
                                >
                                    {t("dialogSubscribeAppNext")}
                                </CorreosButton>
                            </Grid>
                        ) : null}

                        {step2 ? (
                            <Grid item xs={6} >
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    disabled={(checkIsNullUndefined(otpEmailOrPhone) || circularProgress) && otpVerifiedAccount}
                                    onClick={() => handleConfirmVerifyData(accIdIsEmail)}
                                    circularProgress={circularProgressNext}
                                    className={dialogClasses.dialogButtonRight}
                                >
                                    {t("verificationsVerify")}
                                </CorreosButton>
                            </Grid>
                        ) : null}

                    </Grid>
                </Container>
            </Grid>


        </Dialog>
    );
}
