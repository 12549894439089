import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../commons/Constants";

export const AtcSelectCreateEnterpriseStyles = makeStyles((theme) => ({
  agreeButtons: {
    margin: "2px !important",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  nextButton: {
    margin: "2px !important",
    float: "left",
    [theme.breakpoints.down("sm")]: {
      float: "center",
    },
  },

  gridIdentity: {
    display: "flex",
  },

  formSelectorControl: {
    minWidth: "10.5vh",
    marginRight: "0.5vh",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  cardStyles: {
    height: "18.5em",
    width: "auto",
    marginTop: "1em",
    marginLeft: "1em",
    position: "relative",
  },

  selectAccount: {
    position: "absolute",
    bottom: "1em",
    right: "1em",
  },

  titleText: {
    textAlign: "left",
    color: "#002453",
    paddingLeft: "2em",
    padding: "1em",
  },

  iconButton: {
    float: "left",
    color: "#002453",
  },

  container: {
    alignContent: "center",
    alignItems: "center",
    height: "2em",
    margin: "1em",
  },

  remarcar: {
    fontWeight: "bold !important",
    marginLeft: "1em",
  },
  cardStyle: {
    alignContent: "center",
    alignItems: "center",
    height: "3em",
    padding: "1em",
  },
}));
