import { makeStyles } from '@material-ui/core/styles';
import { Constants } from '../../../commons/Constants';

export const AtcSelectCreateAccountStyles = makeStyles((theme) => ({
  agreeButtons: {
    margin: "2px !important",

    [theme.breakpoints.down("sm")]: {
    
      margin:"auto",

    }

   
  },
  nextButton: {
    margin: "2px !important",
    float: "left",

    [theme.breakpoints.down("sm")]: {
    
      float:"center",
      // flexDirection:"column",

    }

  },
  gridIdentity: {
    display: "flex",
    // margin: "auto", 
  },
  formSelectorControl: {
    minWidth: "10.5vh",
    marginRight: "0.5vh",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },

  cardStyles: {
    height: "18.5em",
    width: "auto",
    paddingTop: "2em",
    marginTop: "2em",
    marginLeft:"1em",
    position:"relative",

    [theme.breakpoints.down("md")]: {
      height: "20em",
      width: "auto",
      margin:"auto",
      marginLeft:"1em",
      flexDirection:"column",

    },

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "auto",
      marginTop:"1em",
    }
  },

  selectAccount: {
    position: 'absolute',
    bottom:  "1em", 
    right: "1em",
  },

  titleText: {
    textAlign:"left",
    color: "#002453",
    marginLeft:"2em",
  },
  iconButton:{
    float: "left",
    color: "#002453",
  },
  container:{
    alignContent:"center",
    alignItems:"center",
    height:"2em",
    margin:"1em",
  

  },
  accountDataContainer:{
    alignContent: "center", 
    alignItems: "center", 
    height: "3em"
  },
  accountDataIconGrid:{
    textAlign: "left",
    marginLeft: "2em"
  }



}));

