import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PutSystemuser } from "../../../services/ReasonService";
import EditIcon from '@material-ui/icons/Edit';

export function DialogEdit(props) {
    const { systemUserData, setSystemUserData, finishOperation } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState("");
    const [systemUserName, setSystemUserName] = useState("");
    const [error, setError] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);

    useEffect(() => {
        setSystemUserName(systemUserData.systemUserName);
    }, [systemUserData]);

    const handleCancel = () => {
        setOpen(false);
    };

    const handleChangeApp = (value) => {
        setSystemUserName(value);
        setError(checkIsNullUndefined(value));
    };

    const handleEdit = () => {
        setCircularProgress(true);
        PutSystemuser(systemUserData.client_id, systemUserName).then((response) => {
            if (response && !response.Status) {
                let auxSystemUserData = JSON.parse(JSON.stringify(systemUserData));
                auxSystemUserData.systemUserName = systemUserName;
                setSystemUserData(auxSystemUserData);
                finishOperation("success", t("userSysAdminEdited"));
                handleCancel();
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgress(false);
        });
    };

    return (
        <>
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
                style={{ float: "center", width: "100%" }}
            >
                <EditIcon style={{ marginRight: "0.2em" }} />
                {t("edit")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userSysAdminEditTitle")}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                {t("userSysAdminEditSubtitle")}
                            </Typography>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="idSearcher"
                                    label={t("userSysAdminCreateLabel")}
                                    name="idSearcher"
                                    color="secondary"
                                    required
                                    onChange={(event) => handleChangeApp(event.target.value)}
                                    value={systemUserName}
                                    error={error}
                                    helperText={error ? t("required") : ""}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    onClick={handleCancel}
                                    className={dialogClasses.dialogButtonLeft}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleEdit}
                                    className={dialogClasses.dialogButtonRight}
                                    circularProgress={circularProgress}
                                    disabled={checkIsNullUndefined(systemUserName)}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>

    );
}
