import { makeStyles } from "@material-ui/core/styles";

export const ApplicationAdminStyles = makeStyles((theme) => ({

  tableRow: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
  },
  tableCell: {
    padding: "0.5em 1em",
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
    color: "#002453",
  },
  tableCellText: {
    display: "block",
    maxWidth: "12em",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "auto",
  },
  tableCellHeader: {
    fontSize: "1.25em",
    padding: "0.5em 1em",
    border: `1px solid ${theme.palette.divider}`,
    textAlign: "center",
    color: "#002453",
    height: "2em",
  },
  selectedTableRow: {
    backgroundColor: '#002453',
  },
  customPaper: {
    maxHeight: "20em",
    height: "20em",
    width: "100%",
    overflow: "auto",
    overflowY: "auto",
    border: "1px solid #ccc",
    borderRadius: "0.25em",
    boxShadow: "0 0.125em 0.25em rgba(0, 0, 0, 0.1)",
    // scrollbarWidth: "thin",
    // scrollbarColor: "#ccc transparent",
    "&::-webkit-scrollbar": {
      display: "none",
      // width: "0.375em",
    },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#ccc",
    //   borderRadius: "0.1875em",
    // },
    // "&::-webkit-scrollbar-thumb:hover": {
    //   backgroundColor: "#aaa",
    // },
  },
  marginBottom: {
    marginBottom: "2em",
    color: "#002453",
  },
  marginEndpointTitle: {
    marginBottom: "1em",
    color: "#002453",
    textAlign: "center",
  },
  title: {
    marginTop: "1em",
    color: "#002453",
  },
  stickyTableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "#ffcd00",
    opacity: 1,
    zIndex: 2,
  },
  customTableRow: {
    borderCollapse: "separate",
  },
  componentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  addIcon: {
    color: "#348F41"
  },
  deleteIcon: {
    color: "#F32735"
  }
}));
