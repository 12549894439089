import './App.css';
import React, { useEffect, useState } from "react";
import PrivateRoute from "./config/routes/PrivateRoute";
import PublicRoute from "./config/routes/PublicRoute";
import AtcRoute from "./config/routes/AtcRoute";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { Switch } from "react-router-dom";
import { MainPage } from "./pages/MainPage/MainPage";
import { ExternalLoginPage } from "./pages/ExternalLoginPage/ExternalLoginPage";
import { SetSecretKey } from './services/SecureStorage';
import { CircularProgress } from "@material-ui/core";
import BackofficeRoute from './config/routes/BackofficeRoute';
import { getToken, setCodeApp, setUserSession } from './services/Commons';
import { checkIsNullUndefined } from './services/Utilities';

function App() {
  const [circularProgress, setCircularProgress] = useState(true);

  useEffect(() => {
    if (process.env.REACT_APP_ENVELOP_ACTIVE_LOCAL_TEST == "true" && checkIsNullUndefined(getToken())) {
      let token = {
        "access_token": "1fXFZnCGdVQ",
        "refresh_token": "K9CZsvsagFh7PlYH0lX0HUYXR0dSOamoYasjOrou",
        "scope": "openid",
        "id_token": "eyJraWQiOiJBM1J4dXhNTTdyVEw2QXNubUxTdHJtLVhya3AwZUk4YzVjalh2bDdtRDVRIiwiYWxnIjoiUlMyNTYifQ.eyJydF9oYXNoIjoiWGtDdk1zZ3dmSDVaOWRUUDlxdTVRdyIsImFwZWxsaWRvcyI6IlVTVUFSSU8gUFJVRUJBUyA0IiwiZXhhbXBsZSI6IjEyMzQ1IiwiZW1haWwiOiItIiwiY29tcGFueU5hbWUiOiJDT1JSRU9TSUQgQkFDS09GRklDRSIsIkFaTl9DUkVEX0FVVEhfRVBPQ0hfVElNRSI6IjE2OTY1ODk5NjYiLCJpYXQiOjE2OTY1ODk5OTMsImlzcyI6Imh0dHBzOi8vcG9jaXNhbS5jb3JyZW9zcHJlLmVzIiwic3ViIjoicDAxOTQ2NSIsImF0X2hhc2giOiJVQlc4N3BNbThMWFVjSV9hRENrT0dRIiwiY29kaXJlZCI6IjY2MDY4OTciLCJVQyI6IlAwMTk0NjUiLCJOb21icmUgY29tcGxldG8iOiJDT1JSRU9TSURCQUNLIFVTVUFSSU8gUFJVRUJBUyA0Iiwicm9sZXMiOiJDT1JSRU9TSURCQUNLX1BSRV9VU19DQVVONU5USSIsIm5vbWJyZSI6IkNPUlJFT1NJREJBQ0siLCJleHAiOjE2OTY2MTg3OTMsImF1ZCI6ImNvcnJlb3NpZGJhY2tvZmZpY2UifQ.m2YmdVtSg5GFOqMlOtZwHrUlh6mjCYKJESiUExxH0eaWU0oNPw3CRY_3QpZbhSP__zzvibDWwtHobH6ET1cmVJT88K0Se8nDQyc-GvpB_liSRE3PdqKN4aikTcCA0BRZifZ3gToSf8j2r76DvozHB4ERB21xmLdAlF1nkdw6j-fOoifA4NQ8KfW5PjMR8sOZgqXQCCAYtgpsaUar8j78myGCnWIhr2yMAkIs1NGm-pwhGhqxTMiZv5RmP1kdya4EUu3Qm-uFmat8s7jp_MCuVj_Vp0W5GUSg4i-mEnCMO7AUdQJFDuJbPG62LDogKGYinqLZ9f8C05CDnyOz8c5m_A",
        "token_type": "bearer",
        "expires_in": 28799
    };
      setUserSession(token, null).then(() => {
        handleSecretKey();
      });
    } else {
      handleSecretKey();
    }
  }, []);

  const handleSecretKey = () => {
    SetSecretKey(getToken()).then(() => {
      if (process.env.REACT_APP_ENVELOP_ACTIVE_LOCAL_TEST == "true") {
        setCodeApp("NTI");
      }
      setCircularProgress(false);
    });
  };

  return (
    <div className="App">
      {circularProgress ?
        <CircularProgress />
        :
        <Switch>
          <PublicRoute exact path="/" component={LoginPage} />
          <PublicRoute exact path="/Login" component={LoginPage} />
          <AtcRoute exact path="/:codeApp/Atc" component={ExternalLoginPage} />
          <AtcRoute exact path="/:codeApp/ExternalLogin" component={ExternalLoginPage} />
          <BackofficeRoute exact path="/Home" component={ExternalLoginPage} />
          <PrivateRoute exact path="/:idPage" component={MainPage} />
        </Switch>
      }
    </div>
  );
}

export default App;
