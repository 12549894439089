import { makeStyles } from "@material-ui/core/styles";

export const CommonComponentsStyles = makeStyles((theme) => ({

    notSetText: {
        fontStyle: "italic",
        color: "#CBCBCB"
    },
    titleText: {
        textAlign: "left",
        color: "#002453",
        marginLeft: "2em",
    },
    iconStyle: {
        width: "2em",
        height: "2em",
        color: "#002453",
        [theme.breakpoints.up("sm")]: {
            width: "1.2em",
            height: "1.2em",
        },
    },
    iconButton:{
        float: "left",
        color: "#002453",
      },
}));
