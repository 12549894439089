import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../commons/Constants";

export const SideMenuStyles = makeStyles((theme) => ({
  drawer: {
    width: Constants.DrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: "auto !important",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: Constants.DrawerWidth  + "!important",
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  iconStyle: {
    color: "#002453",
    marginLeft: "0.25em",
  },
}));
