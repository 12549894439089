import React, { useEffect } from "react";
import {
    Typography,
    Grid,
    Container,
    Paper
} from "@material-ui/core";
import { AdminAppAttributesStyles } from "./AdminAppAttributesStyles";
import { spanishLanguage } from "../../config/language/spanishLanguage";


export function AdminAppAttributes(props) {

    const { isEnterprise, attributeList } = props;
    const classes = AdminAppAttributesStyles();



    return (
        <Grid>
            <Grid container  >
                <Container className={classes.mainContainer}>
                    <Paper>
                        <Grid container xs={12} className={classes.formContainer}>
                            <Grid item xs={12}>
                                <Typography
                                    color="secondary"
                                    align="center"
                                    variant="h6"
                                    className={classes.title}
                                >
                                    {isEnterprise ? spanishLanguage["adminAppInfoEnterpriseAttributeListTitle"] : spanishLanguage["adminAppInfoUserAttributeListTitle"]}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid item container xs={12}
                                    className={classes.tableHeaderUnderline}
                                >
                                    <Grid
                                        direction="column"
                                        xs={4}
                                    >
                                        <Typography
                                            className={classes.tableHeaderText}
                                            align="left"
                                        >
                                            {spanishLanguage["adminAppInfoColumnHeaderAttribute"]}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        direction="column"
                                        xs={4}
                                    >
                                        <Typography
                                            className={classes.tableHeaderText}
                                            align="left"
                                        >
                                            {spanishLanguage["adminAppInfoColumnHeaderMandatory"]}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        direction="column"
                                        xs={4}
                                    >
                                        <Typography
                                            className={classes.tableHeaderText}
                                            align="left"
                                        >
                                            {spanishLanguage["adminAppInfoColumnHeaderVerificationType"]}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {attributeList.length > 0 ? attributeList.map((value) =>
                                    <Grid item container xs={12}>
                                        <Grid xs={4}>
                                            <Typography
                                                className={classes.grisCorreos}
                                                align="left"
                                            >
                                                {value.nameAttritube}
                                            </Typography>
                                        </Grid>

                                        <Grid xs={4}>
                                            <Typography
                                                className={classes.grisCorreos}
                                                align="left"
                                                style={{ paddingLeft: "2em" }}
                                            >
                                                {value.mandatory ? spanishLanguage["yes"] : spanishLanguage["no"]}
                                            </Typography>
                                        </Grid>

                                        <Grid xs={4}>
                                            <Typography
                                                className={value.verificationDescription ? classes.grisCorreos : classes.grisCorreosAux}
                                                align="left"
                                            >
                                                {value.verificationDescription ? value.verificationDescription : "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ) :
                                    <Grid item container xs={12}>
                                        <Typography
                                            className={classes.grisCorreos}
                                            align="left"
                                        >
                                            {spanishLanguage["adminAppInfoApplicationWithOutAttributes"]}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Grid>
        </Grid>
    );
}