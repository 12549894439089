export const LanguageResponseModel = {
    es: null, 
    en: null, 
    gl: null, 
    ca: null, 
    eu: null, 
    va: null
};

export const ImagesResponseModel = {
    urlRegistro: null, 
    urlChangePass: null, 
    urlLogin: null 
};

export const NotifierUrlsResponseModel = {
    client_id: null, 
    client_secret: null, 
    userNotifier: { urlRegistry: null, urlDatos: null, urlBaja: null }, 
    enterpriseNotifier: { urlRegistry: null, urlDatos: null, urlBaja: null }
};

export const TycPrivacyModel = {
    tyc: null, 
    privacy: null,
};

export const extensionToMimeTypes = {
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'htm': 'text/html',
    'html': 'text/html',
    'pdf': 'application/pdf',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'zip': 'application/x-zip-compressed',
    '7z': 'application/x-7z-compressed',
    'css': 'text/css'
  };


