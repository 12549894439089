import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  ButtonGroup,
  Button,
  CardContent,
  Card,
  TextField,
  CircularProgress,
  FormControl,
  Select,
  InputLabel
} from "@material-ui/core";
import {
  GetMasterCountry,
  GetMasterRgpd,
  GetLegalRgpdByEmail,
  GetLegalRgpdByPhone,
  PostLegalRGPD
} from "../../services/ReasonService";
import { RgpdStyles } from "./RgpdStyles"
import { Constants } from "../../commons/Constants";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { Alerts } from "../Alerts/Alerts";
import { getSelectedSubApp } from "../../services/Commons";
import { getErrorMessage } from "../../services/Utilities";
import SearchIcon from "@material-ui/icons/Search";
import { CommonStyles } from "../../commons/CommonStyles";
import { EnumCountries } from "../../commons/Enums";
export function Rgpd(props) {

  // const [auxOpen, setauxOpen] = React.useState(false);
  const [open, setopen] = React.useState(false);
  const { auxOpen, subApplication, handleSubApplication } = props;
  const [identifier, setIdentifier] = React.useState("");
  const [identifierType, setIdentifierType] = React.useState("");
  const [disableButtonNext, setDisableButtonNext] = React.useState(true);
  const [showPrefixes, setShowPrefixes] = React.useState(false);
  const [prefixes, setPrefixes] = React.useState([]);
  const [prefixPhone, setPrefixPhone] = React.useState(null);
  const [selectorValue, setSelectorValue] = React.useState(EnumCountries.ESP);
  const [idSearcherError, setIdSearcherError] = React.useState(false);
  const [idSearcherErrorText, setIdSearcherErrorText] = React.useState("");
  const [originalUserTyC, setOriginalUserTyC] = React.useState([]);
  const [showRgpds, setShowRgpds] = React.useState(false);
  const [circularSearchProgress, setCircularSearchProgress] = React.useState(false);
  const [circularSaveProgress, setCircularSaveProgress] = React.useState(false);
  const [identifierTypeText, setIdentifierTypeText] = React.useState("");
  const [alert, setAlert] = React.useState({
    open: false,
    color: "",
    message: "",
  });
  const commonClasses = CommonStyles();
  const validator = require("react-email-validator");
  const [userTyC, setUserTyC] = React.useState([]);

  const classes = RgpdStyles();
  useEffect(() => {
    obtainPhonePrefixes();

  }, [userTyC]);
  useEffect(() => {
    if (showRgpds == true)      setShowRgpds(false);   

  }, [identifier, prefixPhone]);

  useEffect(() => {
    let subApp;

    if (subApplication) {
      subApp = subApplication;
    }
    else {
      subApp = getSelectedSubApp();
      handleSubApplication(subApp);
    }

  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  // Obtiene los prefijos telefónicos
  const obtainPhonePrefixes = () => {
    GetMasterCountry().then((response) => {
      if (response && !response.status) {
        let prefixes = response;
        setPrefixes(prefixes);
        setPrefixPhone(34);
      }
    });
  };


  // controla el cambio en el selector de prefijo
  const _handleSelectorChange = (event) => {
    let prefixCode = event.target.value;
    //extraemos el prefijo seleccionado del array de prefijos
    let prefixValue = prefixes.find(
      (prefix) => prefix.code === prefixCode
    ).phonePrefix;

    setSelectorValue(prefixCode);

    setPrefixPhone(prefixValue);
  };

  // Identifica el tipo de identificador introducido (email teléfono) y habilita el botón cuando el valor sea válido
  const _handleTypeOfIdentifier = (e) => {
    setDisableButtonNext(true);
    setShowPrefixes(false);
    setIdSearcherError(false);
    setIdentifier("");
    const value = e.target.value.trim();
    const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    setIdentifier(value);

    if (validator.validate(value)) {
      setIdentifierType("email");
      setIdentifierTypeText("email");
      setDisableButtonNext(false);
      return;
    }
    if (checkPhoneNumber.test(value)) {
      // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
      setShowPrefixes(true);

      if (value.length >= 9) {
        setIdentifierType("phoneNumber");
        setIdentifierTypeText("teléfono");
        setDisableButtonNext(false);
      }

      return;
    }
  };

  const getUserRgpd = () => {
    setCircularSearchProgress(true);
    GetMasterRgpd().then((response) => {
      if (response && !response.Status) {

        if (identifierType === "email") {

          GetLegalRgpdByEmail(identifier).then((respon) => {
            if (respon && !respon.Status) {
              let auxiliaryTerms = [];
              response.forEach((elem) => {
                let auxiliarRgpd = respon.filter((rgpd) => {
                  return rgpd.id === elem.id;
                });
                elem.checked = auxiliarRgpd.length > 0 ? 1 : 2;
                auxiliaryTerms.push(elem);
              });
              let auxiliaryCloneTerms = JSON.parse(
                JSON.stringify(auxiliaryTerms)
              );
              setUserTyC(auxiliaryTerms);
              setOriginalUserTyC(auxiliaryCloneTerms);
              setCircularSearchProgress(false);
              setShowRgpds(true);
              setDisableButtonNext(true);
            } else {
              finishOperation("error", getErrorMessage(respon));
              setCircularSearchProgress(false);
            }
          });
        } else if (identifierType === "phoneNumber") {

          GetLegalRgpdByPhone(prefixPhone + "-" + identifier).then((respon) => {
            if (respon && !respon.Status) {
              let auxiliaryTerms = [];
              response.forEach((elem) => {
                let auxiliarRgpd = respon.filter((rgpd) => {
                  return rgpd.id === elem.id;
                });
                elem.checked = auxiliarRgpd.length > 0 ? 1 : 2;
                auxiliaryTerms.push(elem);
              });
              let auxiliaryCloneTerms = JSON.parse(
                JSON.stringify(auxiliaryTerms)
              );
              setUserTyC(auxiliaryTerms);
              setOriginalUserTyC(auxiliaryCloneTerms);
              setCircularSearchProgress(false);
              setShowRgpds(true);
              setDisableButtonNext(true);
            } else {
              finishOperation("error", getErrorMessage(respon));
              setCircularSearchProgress(false);
            }
          });

        }


      } else {
        finishOperation("error", getErrorMessage(response));
        setCircularSearchProgress(false);
      }
    });
  };

  const handleCancel = () => {
    let auxiliaryArray = JSON.parse(JSON.stringify(originalUserTyC));
    setUserTyC(auxiliaryArray);
  };

  const handleSave = () => {
    setCircularSaveProgress(true);
    let auxiliaryArray = [];
    userTyC.forEach((tyc) => {
      auxiliaryArray.push({
        idRgpd: tyc.id,
        accepted: tyc.checked === 1,
      });
    });
    let auxIdentifier = identifier;
    if (identifierType === "phoneNumber") auxIdentifier = prefixPhone + "-" + identifier;
    PostLegalRGPD(auxiliaryArray, auxIdentifier).then((response) => {
      if (!response || response.Status) {
        finishOperation("error", getErrorMessage(response));
        setCircularSaveProgress(false);
      } else {
        finishOperation("success", spanishLanguage["rgpdSuccessChange"]);
        setCircularSaveProgress(false);
        getUserRgpd();
      }
    });
  };

  const handleOptions = (value) => {
    let auxiliaryArray = JSON.parse(JSON.stringify(userTyC));
    auxiliaryArray.forEach((elem) => {
      if (elem.id + Constants.Si === value.currentTarget.name)
        elem.checked = 1;
      if (elem.id + Constants.No === value.currentTarget.name)
        elem.checked = 2;
    });

    setUserTyC(auxiliaryArray);
    setopen(true);
  };

  return (

    <Grid >
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Grid item
        xs={12}>
        <Typography
          variant="h5"
          className={classes.title}
          noWrap
        >
          {spanishLanguage["rgpdappAdmSearchText"]}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center">

        <Grid item md={10} sm={10} xs={12} className={classes.textFieldRow}>
          {showPrefixes ? (
            <FormControl
              variant="outlined"
              className={classes.formSelectorControl}
              color="secondary"
              style={{ marginBottom: "10px" }}
            >

              <InputLabel label={spanishLanguage["atcRegisterPagePrefix"]} color="secondary">
                {spanishLanguage["atcRegisterPagePrefix"]}
              </InputLabel>
              <Select
                native
                id="prefixSelector"
                name="prefixSelector"
                label={spanishLanguage["atcRegisterPagePrefix"]}
                fullWidth
                value={selectorValue}
                onChange={_handleSelectorChange}
              >
                {prefixes
                  .sort((a, b) => (a.prefixPhone > b.prefixPhone ? 1 : -1))
                  .map((prefix) => (
                    <option key={prefix.code} value={prefix.code}>
                      {prefix.code + "(+" + prefix.phonePrefix + ")"}
                    </option>
                  ))}
              </Select>
            </FormControl>
          ) : null}
          <TextField
            variant="outlined"
            fullWidth
            id="idSearcher"
            label={spanishLanguage["rgpdIdentifierField"]}
            name="idSearcher"
            color="secondary"
            required={true}
            onChange={_handleTypeOfIdentifier}
            value={identifier}
            error={idSearcherError}
            helperText={idSearcherError ? idSearcherErrorText : ""}

          />
          <CorreosButton
            className={classes.buttonSearch}
            variant="contained"
            color="primary"
            onClick={() => getUserRgpd()}
            style={{ float: "center" }}
            disabled={disableButtonNext}
            circularProgress={circularSearchProgress}
          >
            <SearchIcon style={{ marginRight: "0.2em" }} />
            {spanishLanguage["search"]}
          </CorreosButton>
        </Grid>
      </Grid>

      {showRgpds ?
        <Card className={classes.cardContainer}>

          <CardContent >

            <Grid>
              <Grid md={12} xs={12} >
                <Grid >
                  <Typography
                    variant="h6"
                    className={classes.title}
                    noWrap
                  >
                    {spanishLanguage["rgpdConfigTitle"]}
                    {
                      showRgpds ? " con " + identifierTypeText + " verificado: " + identifier

                        : ""

                    }

                  </Typography>
                </Grid>
                <Grid >

                  {userTyC.map((element, index) => {
                    let nameSi = element.id + Constants.Si;
                    let nameNo = element.id + Constants.No;
                    return (
                      <Grid
                        item
                        xs={12}
                        style={{ marginBottom: "1em", marginLeft: "15em" }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          alignContent="center"
                          spacing={1}
                        >
                          <Grid item xs={2}  >
                            <ButtonGroup disableElevation>
                              <Button
                                color={
                                  userTyC[index].checked === 1
                                    ? "primary"
                                    : null
                                }
                                variant={
                                  userTyC[index].checked === 1
                                    ? "contained"
                                    : null
                                }
                                className={classes.switchButton}
                                name={nameSi}
                                onClick={(value) =>
                                  handleOptions(value)
                                }
                              >
                                {spanishLanguage["yes"]}
                              </Button>
                              <Button
                                color={
                                  userTyC[index].checked === 2
                                    ? "secondary"
                                    : null
                                }
                                variant={
                                  userTyC[index].checked === 2
                                    ? "contained"
                                    : null
                                }
                                className={
                                  userTyC[index].checked === 2
                                    ? classes.switchButtonNoActive
                                    : classes.switchButton
                                }
                                name={nameNo}
                                onClick={(value) =>
                                  handleOptions(value)
                                }
                              >
                                {spanishLanguage["no"]}
                              </Button>
                            </ButtonGroup>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            className={
                              classes.agreeConditionsTermsConsent
                            }
                          >
                            <span>{element.name}</span>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}


                </Grid>

              </Grid>

              <Grid
                item
                sm={12}
                xs={12}
                style={{ padding: "0em 2em", paddingBottom: "2em" }}
              >
                <CorreosButton
                  onClick={() => handleCancel()}
                  color="secondary"
                  style={{ float: "left" }}
                  className={commonClasses.leftButton}
                >
                  {spanishLanguage["cancel"]}
                </CorreosButton>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleSave()}
                  style={{ float: "right" }}
                  className={commonClasses.rightButton}
                  circularProgress={circularSaveProgress}
                >
                  {spanishLanguage["save"]}
                </CorreosButton>
              </Grid>

            </Grid>

          </CardContent>
        </Card> : null}
    </Grid>
  );
}