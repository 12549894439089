import { makeStyles } from "@material-ui/core/styles";
import { EnumWindowSizeNumbers } from "./Enums";

export const CommonStyles = makeStyles((theme) => ({
  //General
  mainDiv: {
    textAlign: "center",
    display: "flow-root",
    position: "relative",
  },
  gridIdentity: {
    display: "flex",
    margin: "auto",
    // marginLeft:"0.5em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0.3em",
      margin: "auto",
      display: "flex",
    },

    [theme.breakpoints.up("md")]: {
      paddingLeft: "0.3em",
      margin: "auto",
      display: "flex",
    },

  },
  divButtons: {
    marginTop: "1rem",
    marginLeft: "auto",
    marginRight: "auto",
    //textAlign: "right",
  },
  leftButton: {
    float: "left",
    marginRight: "auto",
  },
  rightButton: {
    float: "right",
    marginLeft: "auto",
  },
  container: {
    marginTop: EnumWindowSizeNumbers.MarginTopMobile,
    [theme.breakpoints.up("sm")]: {
      marginTop: EnumWindowSizeNumbers.MarginTopApp,
      margin: "auto",
    },
  },
  containerDialogSubscription: {
    justifyContent: "left",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  mainContainer: {
    margin: "auto",
  },
  marginTop1em: {
    marginTop: "1em",
  },
  marginTop1rem: {
    marginTop: "1rem",
  },
  marginAuto: {
    margin: "auto",
  },

  //DropDown
  formSelectorControlPrefix: {
    minWidth: "10.5vh",
    marginRight: "0.5vh",
    "& .MuiFilledInput-underline": {
      "&:before": {
        border: "1px solid #FFCD00",
      },
      "&:hover:before": {
        border: "2px solid #FFCD00",
      },
      "&:after": {
        border: "2px solid #002453",
      },
    },
  },

  //Dialogs
  dialogWindow: {
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "auto",
    },
  },
  dialogTitle: {
    padding: "1.5em 0em 0em 2.5em",
    color: "#002453",
    width: "98%",
  },
  dialogTitleText: {
    margin: "auto",
    textAlign: "center",
  },
  dialogClosebutton: {
    marginTop: "-0.7em",
    marginRight: "-0.15em",
    float: "right",
    color: "#666666",
  },
  dialogContainer: {
    padding: "1.5em 2em 1em 2em",
    width: "98%",
  },
  dialogContentText: {
    textAlign: "center",
    color: "#666666",
  },
  dialogLegendText: {
    paddingBottom: "2em",
    paddingLeft: "1.6em",
    paddingRight: "1.6em"
  },
  dialogButtonContainer: {
    margin: "1em 0em 1em 0em",
  },
  dialogButtonLeft: {
    float: "left",
    marginRight: "auto",
  },
  dialogButtonRight: {
    float: "right",
    marginLeft: "auto",
  },
  // dialogButtonCenter: {
  //   margin: "auto",
  // },
  // dialogItems: {
  //   marginBottom: "1em",
  // },

  //Icons
  iconActionStyle: {
    width: "2em",
    height: "2em",
    color: "#333333",
    padding: "0.1em",
    [theme.breakpoints.down("sm")]: {
      width: "1em",
      height: "1em",
      padding: "0.1em",
    },
  },
  iconStyle: {
    width: "2em",
    height: "2em",
    color: "#002453",
    [theme.breakpoints.up("sm")]: {
      width: "1.2em",
      height: "1.2em",
    },
  },
  iconButton: {
    padding: "0px",
    margin: "0px",
    color: "#333333",
  },

  listTextStyleShow: {
    width: "100%",
    color: "#666666",
  },

  listStyle: {
    width: "100%",
    textAlign: "left",
    color: "#002453",
  },
  //Popup suscripciones
  details: {
    display: "flex",
    flexDirection: "column",
  },
  media: {
    maxWidth: "10rem",
  },
  imgCardMedia: {
    width: "auto",
    height: "4em",
    float: "left",
  },
  popperContainer: {
    width: "20em",
    height: "7em",
    padding: "1em",
  },
  popperAddContainer: {
    paddingBottom: "1em",
  },
  popperContentText: {
    textAlign: "center",
  },
  hitText: {
    fontSize: "1.1rem",
    marginTop: "1em",
    marginBottom: "1em",
    color: "#666666",
    textAlign: "center",
  },

  gridIdentity1: {
    display: "flex",
    margin: "auto",
    marginLeft: "-1.4em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1em"
    },

  },
  atcContainer: {
    marginTop: "1em",
    paddingRight: "10em",
    paddingLeft: "10em",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "1em",
      paddingLeft: "1em",
      margin: "auto",
    },
  },
  paddingRight: {
    paddingRight: "1em",
  },
  width100: {
    width: "100%",
  },
  marginBottom1_5em: {
    marginBottom: "1.5em",
  },
  grisCorreos: {
    color: "#7b7b7b",
  },
  titleTypo: {
    textAlign: "center",
    color: "#002453",
  },
}));
