import React, { useEffect } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import {
  GetMasterTypeUserDocument,
  GetMasterCountry,
  GetMasterGenders,
  GetMasterProfessionalActivity,
  GetMasterRoadType,
  GetMasterLanguage,
} from "../../services/ReasonService";
import { checkIsNullUndefined } from "../../services/Utilities";
import { CommonStyles } from "../../commons/CommonStyles";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { EnumCountries } from "../../commons/Enums";

export function DropdownAttribute(props) {
  const { attribute, validateDocumentNumber, saveGroupsChanges, setIsControlledCountry,} = props;

  const [dropdownValues, setDropdownValues] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [mounted, setMounted] = React.useState(false);

  useEffect(() => {
    getDropdownValues();
  }, []);

  useEffect(() => {
    if (mounted) {
      if (attribute.value !== value) {
        if (
          (attribute.fieldCode !== "EMA" &&
            attribute.fieldCode !== "MOV" &&
            attribute.fieldCode !== "ALI") ||
          !Array.isArray(attribute.value)
        ) {
          setValue(attribute.value);
        }
      } else if (attribute.options !== options && !checkIsNullUndefined(attribute.options)) {
        let auxiliaryoptions = [];
        auxiliaryoptions.push({code: 0, name: spanishLanguage.userDataSelectOption , id: 0 });
        attribute.options.forEach((elem) => {
          auxiliaryoptions.push({code: elem, name: elem, id: elem });
        });
        setOptions(attribute.options);
        setDropdownValues(auxiliaryoptions);
      }
    }
  }, [value, options, attribute]);

  const handleSetValues = (auxDropdownValues, auxValue) => {
    setDropdownValues(auxDropdownValues);
    if (
      attribute.fieldCode === "PAI" ||
      attribute.fieldCode === "EPI"
    ) {
      setIsControlledCountry(
        auxValue == EnumCountries.ESP || auxValue == EnumCountries.AND || checkIsNullUndefined(auxValue)
      );
    }
    setValue(auxValue);
    setMounted(true);
  };

  //realiza la petición del dropdown que se le pida.
  const getDropdownValues = () => {
    switch (attribute.fieldCode) {
      case "TDO":
        GetMasterTypeUserDocument().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      case "PEX":
      case "NAC":
      case "PAI":
      case "EPI":
      case "ECY":
      case "PRE":
        GetMasterCountry().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      case "GEN":
        GetMasterGenders().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      case "LAN":
        GetMasterLanguage().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      case "EPA":
        GetMasterProfessionalActivity().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      case "VIA":
      case "EVI":
        GetMasterRoadType().then((response) => {
          handleSetValues(response, attribute.value);
        });
        break;
      default:
        let auxiliaryoptions = [];
        if (
          attribute.fieldCode === "EMA" ||
          attribute.fieldCode === "MOV" ||
          attribute.fieldCode === "ALI"
        ) {
          auxiliaryoptions.push({code: 0, name: spanishLanguage.userDataSelectOption, id: 0 });
          attribute.options.forEach((elem) => {
          auxiliaryoptions.push({code: elem, name: elem, id: elem });
          });
        }
        setOptions(attribute.options);
        setDropdownValues(auxiliaryoptions);
        setValue(auxiliaryoptions[0] ? auxiliaryoptions[0].name : null);
        setMounted(true);
        break;
    }
  };

  //guarda los cambios en los attributos de datos personales
  const saveAttributesChanges = (nValue) => {
    let first = false;
    if (attribute.fieldCode === "PAI" || attribute.fieldCode === "EPI") {
      first = true;
      setIsControlledCountry(
        nValue == EnumCountries.ESP || nValue == EnumCountries.AND || checkIsNullUndefined(nValue)
      );
    }
    saveGroupsChanges(attribute, nValue, first);
  };

  const handleDropdownChange = (event) => {
    if (attribute.fieldCode === "TDO") {
      validateDocumentNumber(event.target.value, attribute.fieldCode);
    }
    setValue(event.target.value);
    saveAttributesChanges(event.target.value);
  };

  return (
    <Grid item md={12} xs={12}>
      <FormControl variant="outlined" fullWidth required={attribute.mandatory}>
        <InputLabel label={attribute.nameAttritube} color="secondary">
          {attribute.nameAttritube}
        </InputLabel>
        <Select
          nulleable={false}
          color="secondary"
          value={checkIsNullUndefined(value) ? '' : value}      
          onChange={handleDropdownChange}
          label={attribute.nameAttritube}
          disabled={attribute.disabled}
          fullWidth
          native
          
        >
          { (attribute.fieldCode === "EMA" ||
          attribute.fieldCode === "MOV" ||
          attribute.fieldCode === "ALI") ? null : <option value=" " />}
          
          {dropdownValues
            ? dropdownValues.map((dvalue) => {
                if (attribute.fieldCode === "PRE") {
                  return (
                    <option key={dvalue.code} value={dvalue.id}>
                      {dvalue.code + " (+" + dvalue.phonePrefix + ")"}
                    </option>
                  );
                } else if (dvalue.name === spanishLanguage.userDataSelectOption){
                  return (
                  <option class={CommonStyles.greyColor} key={dvalue.code} value={dvalue.name} selected disabled>
                     {dvalue.name}
                  </option>
                  );
                }else if(attribute.codeAttribute === "PAI" || attribute.codeAttribute === "EPI"){
                  return(
                  <option key={dvalue.code} value={dvalue.code}>
                      {dvalue.name}
                  </option>
                  )
                }else if(attribute.codeAttribute === "VIA" || attribute.codeAttribute === "EVI"
                ){
                  return(
                  <option key={dvalue.viaTypeCode} value={dvalue.viaTypeCode}>
                      {dvalue.name}
                  </option>
                  )
                }else {
                  return (
                    <option key={dvalue.code} value={dvalue.id}>
                      {dvalue.name}
                    </option>
                  );
                }
              })
            : null}
        </Select>
      </FormControl>
    </Grid>
  );
}
