import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { ActivatedEndpointsStyles } from "./ActivatedEndpointsStyles";
import { Visibility } from "@material-ui/icons";
import {
  getSelectedSubApp,
  getUserGlobalRoles,
  getCodeApp,
} from "../../services/Commons";
import { RolLevels } from "../../commons/Enums";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import {
  GetApplicationActions,
  GetApplicationConfiguration,
  GetAttributesEnterprise,
  GetAttributesUser,
} from "../../services/ReasonService";
import { CommonStyles } from "../../commons/CommonStyles";
import { ApiConfig } from "../ApiConfig/ApiConfig";
import { AdminAppAttributes } from "../AdminAppAttributes/AdminAppAttributes";
import { checkIsNullUndefined } from "../../services/Utilities";

export function ActivatedEndpoints(props) {
  const {
    subApplication,
    handleSubApplication,
    handleShowAppsSelect,
    handleSetAppsSelectOptions,
    appCode,
  } = props;
  const classes = ActivatedEndpointsStyles();
  const [actions, setActions] = React.useState([]);
  const [circularProgress, setCircularProgress] = React.useState(true);
  const [tycGestion, setTycGestion] = React.useState("");
  const [appConfig, setAppConfig] = React.useState([]);
  const [userAttributeList, setUserAttributeList] = React.useState([]);
  const [enterpriseAttributeList, setEnterpriseAttributeList] = React.useState(
    []
  );

  useEffect(() => {
    let subApp = subApplication;

    // Necesario para pintar el título de la cabecera si se punsa F5.
    if (!subApplication) {
      subApp = getSelectedSubApp();
      handleSubApplication(subApp);
    }

    let tmpApps = getUserGlobalRoles()
      .find((x) => x.subApp === subApp)
      .roles.find((x) => x.name === RolLevels.N1).apps;
    let selectOptions = [];

    if (tmpApps && tmpApps.length > 0) {
      tmpApps.forEach((x) => {
        selectOptions.push({
          name: x,
          value: x,
        });
      });
    }

    handleShowAppsSelect(true);
    handleSetAppsSelectOptions(selectOptions);

    return () => {
      handleShowAppsSelect(false);
      handleSetAppsSelectOptions([]);
    };
  }, []);

  useEffect(() => {
    if (!checkIsNullUndefined(appCode)) {
      setCircularProgress(true);
      chargePage();
    }
  }, [appCode]);

  const chargePage = () => {
    let count = 0;
    GetApplicationActions(appCode).then((response) => {
      if (response && !response.Status) {
        setActions(response);
      }
      count++;
      handleCircularProgress(count);
    });
    GetApplicationConfiguration(appCode).then((response) => {
      if (response && !response.Status) {
        let config = response;
        setAppConfig(config);
        handleCircularProgress();
      }
      if (response.tycGestion === true) {
        setTycGestion(spanishLanguage["apiGestionYes"]);
      } else {
        setTycGestion(spanishLanguage["apiGestionNo"]);
      }
      count++;
      handleCircularProgress(count);
    });
    GetAttributesUser(appCode).then((response) => {
      if (response && !response.status) {
        setUserAttributeList(response);
      }
      count++;
      handleCircularProgress(count);
    });
    GetAttributesEnterprise(appCode).then((response) => {
      if (response && !response.status) {
        setEnterpriseAttributeList(response);
      }
      count++;
      handleCircularProgress(count);
    });
  };

  const handleCircularProgress = (count) => {
    if (count == 4) {
      setCircularProgress(false);
    }
  };

  return (
    <div>
      {circularProgress ? (
        <CircularProgress />
      ) : (
        <Grid>
          <Grid>
            <ApiConfig tycGestion={tycGestion} appConfig={appConfig} />
          </Grid>

          <Grid>
            <AdminAppAttributes
              isEnterprise={false}
              attributeList={userAttributeList}
            />
          </Grid>
          <Grid>
            <AdminAppAttributes
              isEnterprise={true}
              attributeList={enterpriseAttributeList}
            />
          </Grid>
          <Container className={classes.mainContainer}>
            <Paper>
              <Grid container xs={12} className={classes.formContainer}>
                <Grid item xs={12}>
                  <Typography
                    color="secondary"
                    align="center"
                    variant="h6"
                    className={classes.Title}
                  >
                    {spanishLanguage["apiEndpoints"]}
                  </Typography>
                </Grid>

                <Grid container item xs={12}>
                  {actions.map((value) => (
                    <Grid item container xs={12}>
                      <Grid direction="column" xs={2}>
                        <Typography
                          color="secondary"
                          className={classes.remarcar}
                          align="left"
                        >
                          {spanishLanguage["apiController"]}
                        </Typography>

                        <Typography
                          color="secondary"
                          className={classes.remarcarAux}
                          align="left"
                        >
                          {spanishLanguage["apiURL"]}
                        </Typography>
                      </Grid>

                      <Grid xs={8}>
                        <Typography
                          className={classes.grisCorreos}
                          align="left"
                        >
                          {value.controller}
                        </Typography>
                        <Typography
                          className={classes.grisCorreos}
                          align="left"
                        >
                          {value.url}
                        </Typography>
                      </Grid>

                      <Grid xs={2}>
                        <Visibility className={classes.visibility} />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Grid>
      )}
    </div>
  );
}
