import React, { useState, useEffect } from "react";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import {
  Grid,
  Dialog,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  FormControlLabel,
  Checkbox,
  IconButton,
  TextField,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CommonStyles } from "../../../commons/CommonStyles";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import {
  checkIsNullUndefined,
  getErrorMessage,
} from "../../../services/Utilities";
import {
  PostLegalSendTyCs,
  PostSubscriptionUserApplicationCode,
  PostEnterpriseSubscription,
} from "../../../services/ReasonService";
import { getCodeApp } from "../../../services/Commons";
import { PersonType } from "../../../commons/Enums";

export function AtcDialogSubscribeToApp(props) {
  const {
    open,
    setOpen,
    finishOperation,
    appList,
    selectedOtpEmailOrPhone,
    handleSubscriptionNavigation,
    enterpriseId,
    personType,
    cancelPopup,
  } = props;
  const commonClasses = CommonStyles();
  const [subscriptionApps, setSubscriptionApps] = useState([]);
  const [isAcceptButtonEnabled, setIsAcceptButtonEnabled] =
    useState(false);
  const [circularProgressSendOtp, setCircularProgressSendOtp] =
    useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpSubscribeErrorText, setOtpSubscribeErrorText] = useState("");
  const [otp, setOtp] = useState("");
  const [openOtp, setOpenOtp] = useState(false);
  const [handleSubsNavFlag, setHandleSubsNavFlag] = useState(false);

  useEffect(() => {
    if (open) {
      // Desmarcar las app seleccionadas.
      appList.forEach((service) => {
        service.checked = false;
      });

      setIsAcceptButtonEnabled(false);
      setSubscriptionApps(appList);
    }
  }, [open]);
  useEffect(() => {
    if (handleSubsNavFlag) {
      //setHandleSubsNavFlag(false);
      handleSubscriptionNavigation();
    }
  }, [handleSubsNavFlag]);

  const handleCancel = () => {
    if (openOtp) {
      setOtp("");
    }
    cancelPopup(false);
    setOpen(false);
  };

  const handleNext = () => {
    if (isAcceptButtonEnabled && checkIsNullUndefined(otp)) {
      setOpenOtp(true);
      setOtp("");
      handleSendOtp();
    } else if (isAcceptButtonEnabled) {
      handleValidateAndSubscribe();
    } else {
      // Continuar con el flujo.
      setOpen(false);
      handleSubscriptionNavigation();
    }
  };

  const handleSetSelected = (event, auxService) => {
    let auxSubscriptions = JSON.parse(JSON.stringify(subscriptionApps));
    let tmpEnableAcceptButton = false;
    setOpenOtp(false);
    setOtp("");

    auxSubscriptions.forEach((service) => {
      if (service.destinationAppCode === auxService.destinationAppCode) {
        service.checked = !auxService.checked;
      }

      if (service.checked) {
        tmpEnableAcceptButton = true;
      }
    });

    setIsAcceptButtonEnabled(tmpEnableAcceptButton);
    setSubscriptionApps(auxSubscriptions);
  };

  const handleSendOtp = () => {
    setCircularProgressSendOtp(true);
    let auxSelectedServices = [];

    subscriptionApps.forEach((service) => {
      if (service.checked) {
        auxSelectedServices.push(service.destinationAppCode);
      }
    });

    //LLamada al back para enviar OTP.
    PostLegalSendTyCs(
      selectedOtpEmailOrPhone,
      personType,
      auxSelectedServices,
      enterpriseId
    ).then((postLegalSendTyCsResponse) => {
      if (postLegalSendTyCsResponse && !postLegalSendTyCsResponse.Status) {
        setOpenOtp(true);
        setOtp("");
        setCircularProgressSendOtp(false);
      } else {
        finishOperation("error", getErrorMessage(postLegalSendTyCsResponse));
        setCircularProgressSendOtp(false);
      }
    });
  };

  const handleChangeOtp = (value) => {
    setOtpError(false);
    setOtp(value.target.value.trim());

    if (checkIsNullUndefined(value.target.value)) {
      setOtpError(true);
    }
  };

  const handleValidateAndSubscribe = () => {
    setCircularProgressSendOtp(true);
    setOtpSubscribeErrorText("");
    setOtpError(false);
    let auxSelectedServices = [];

    subscriptionApps.forEach((service) => {
      if (service.checked) {
        auxSelectedServices.push(service.destinationAppCode);
      }
    });

    // Validate Otp and subscribe.
    if (checkIsNullUndefined(enterpriseId)) {
      PostSubscriptionUserApplicationCode(
        getCodeApp(),
        selectedOtpEmailOrPhone,
        otp,
        auxSelectedServices
      ).then((subscriptionUserApplicationCodeResponse) => {
        if (
          subscriptionUserApplicationCodeResponse &&
          !subscriptionUserApplicationCodeResponse.Status
        ) {
          setHandleSubsNavFlag(true);
        } else {
          setOtpError(true);
          setCircularProgressSendOtp(false);
          finishOperation(
            "error",
            getErrorMessage(subscriptionUserApplicationCodeResponse)
          );
        }
      });
    } else {
      PostEnterpriseSubscription(
        getCodeApp(),
        selectedOtpEmailOrPhone,
        otp,
        auxSelectedServices
      ).then((subscriptionEnterpriseApplicationCodeResponse) => {
        if (
          subscriptionEnterpriseApplicationCodeResponse &&
          !subscriptionEnterpriseApplicationCodeResponse.Status
        ) {
          setHandleSubsNavFlag(true);
        } else {
          setOtpError(true);
          setCircularProgressSendOtp(false);
          finishOperation(
            "error",
            getErrorMessage(subscriptionEnterpriseApplicationCodeResponse)
          );
        }
      });
    }
  };

  const mountAppChecks = (service, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Card>
          <Grid container item xs={12} spacing={4}>
            <Grid item xs={5}>
              <CardMedia className={commonClasses.media}>
                <img
                  className={commonClasses.imgCardMedia}
                  src={
                    service.destinationAppLogo == null
                      ? process.env.REACT_APP_CORREOS_ID_LOGO
                      : service.destinationAppLogo
                  }
                  alt="Logo"
                />
              </CardMedia>
            </Grid>
            <Grid item xs={6} className={commonClasses.details}>
              <CardContent className={commonClasses.details}>
                <Typography
                  component="h1"
                  variant="h6"
                  noWrap
                  color="secondary"
                >
                  {service.destinationAppName}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={1}>
              <CardContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      onChange={(value) => handleSetSelected(value, service)}
                      checked={service.checked}
                    />
                  }
                />
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid>
      <Dialog fullWidth open={open} onClose={() => handleCancel()}>
        <Container className={commonClasses.dialogTitle}>
          <Grid container>
            <Grid item xs={11}>
              <Typography
                variant="h6"
                className={commonClasses.dialogTitleText}
              >
                {spanishLanguage.dialogSubscribeAppAskTitle}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="inherit"
                className={commonClasses.dialogClosebutton}
                color="inherit"
                aria-label="menu"
                onClick={() => handleCancel()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={commonClasses.hitText}>
                {spanishLanguage.dialogSubscribeAppAskText}
              </Typography>
            </Grid>
            {subscriptionApps.map((service, index) => {
              return mountAppChecks(service, index);
            })}
          </Grid>
        </Container>
        {openOtp ? (
          <Container className={commonClasses.dialogContainer}>
            <Divider className={commonClasses.marginBottom1_5em}></Divider>
            <Grid
              container
              className={commonClasses.containerDialogSubscription}
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography className={commonClasses.hitText}>
                  {spanishLanguage.dialogSubscribeAppText}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  disabled
                  color="secondary"
                  value={selectedOtpEmailOrPhone.trim()}
                  variant="outlined"
                  fullWidth
                  name="OtpHelp"
                  label={
                    selectedOtpEmailOrPhone.indexOf("@") === -1
                      ? spanishLanguage.dialogSubscribeAppPhone
                      : spanishLanguage.dialogSubscribeAppEmail
                  }
                  type="text"
                  id="OtpHelp"
                />
              </Grid>
              <Grid item xs={3}>
                <CorreosButton
                  className={commonClasses.width100}
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleSendOtp()}
                  circularProgress={circularProgressSendOtp}
                >
                  {spanishLanguage["dialogSubscribeAppResendCode"]}
                </CorreosButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  color="secondary"
                  value={otp.trim()}
                  onChange={handleChangeOtp}
                  variant="outlined"
                  fullWidth
                  name="Otp"
                  autoFocus
                  label={spanishLanguage.dialogSubscribeAppConfirmationCode}
                  type="text"
                  id="Otp"
                  error={otpError}
                  helperText={
                    otpError && otpSubscribeErrorText !== ""
                      ? otpSubscribeErrorText
                      : otpError
                      ? spanishLanguage.dialogSubscribeAppInvalidCode
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Container>
        ) : null}
        <Container className={commonClasses.dialogContainer}>
          <Grid container spacing={1}>
            <Grid
              container
              item
              xs={12}
              className={commonClasses.dialogButtonContainer}
            >
              <Grid item xs={6}>
                <CorreosButton
                  //color="secondary"
                  onClick={() => handleCancel()}
                >
                  {spanishLanguage.dialogSubscribeAppCancel}
                </CorreosButton>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.dialogButtonRight}
                  onClick={() => handleNext()}
                  circularProgress={circularProgressSendOtp}
                  disabled={openOtp && checkIsNullUndefined(otp) ? true : false}
                >
                  {isAcceptButtonEnabled
                    ? spanishLanguage.dialogSubscribeAppAccept
                    : spanishLanguage.dialogSubscribeAppNext}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </Grid>
  );
}
