export const EnterpriseDataObject = {
    id: 0,
    documentNumber: null,
    companyName: null,
    tradeName: null,
    // idEnterpriseType: null,
    idProfessionalActivity: null,
    idCountry: null,
    expirationDate: null,
    enterpriseAddress: []
  }