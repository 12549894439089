import React, { useEffect } from "react";
import {
  GetMasterProvincePostalCode,
  GetMasterPostalCodeProvince,
} from "../../services/ReasonService";
import { Grid, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  checkIsNullUndefined,
  getErrorMessage,
} from "../../services/Utilities";

export default function AutocompleteAttribute(props) {
  const { attribute, saveGroupsChanges, handleChangeAlias, finishOperation, setAuxDisabled } =
    props;
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");



  useEffect(() => {
    getDropdownValues();

  }, [attribute]);

  const getDropdownValues = () => {
    switch (attribute.fieldCode) {
      case "ALI":
      case "EAL":
        if (attribute.actualize) {
          setShowValue(attribute.dropdownValues);
        }
        break;
      default:
        setInputValue(getValue(attribute.value));
        break;
    }
  };

  const setShowValue = (values) => {
    let value = "";
    values.forEach((value1) => {
      if (value1.id === attribute.value) {
        value =
          props.attribute.fieldCode !== "CPO" &&
            props.attribute.fieldCode !== "ECP"
            ? value1.name
            : value1.postalCode;
      }
    });
    setInputValue(value);
  };

  const _handleDropdownChange = (event, nValue) => {
    if (!checkIsNullUndefined(event) && event.type !== "blur") {
      saveAttributesChanges(true, nValue);
    }
  };

  const _handleChange = (nValue) => { };

  const saveAttributesChanges = (first, nValue) => {
    let id = getId(nValue);
    setInputValue(nValue);
    setValue(id);
    if (!checkIsNullUndefined(id)) {
      saveGroupsChanges(props.attribute, id, first);
    } else {
      if (attribute.fieldCode === "ALI" || attribute.fieldCode === "EAL") {
        handleChangeAlias(nValue);
      } else if (checkIsNullUndefined(nValue)) {
        saveGroupsChanges(props.attribute, nValue, first);
      } else if (
        (attribute.fieldCode === "CPO" || attribute.fieldCode === "ECP") &&
        nValue.length === 5
      ) {
        let auxValue = "";
        setAuxDisabled(true);
        GetMasterProvincePostalCode(nValue).then((province) => {
          if (province && !province.Status) {
            let auxProvince = province[0].provinceCode;
            GetMasterPostalCodeProvince(auxProvince).then((postalCodes) => {
              if (postalCodes && !postalCodes.Status) {
                postalCodes.forEach((cp) => {
                  if (cp.postalCode === nValue) {
                    auxValue = cp.postalCode;
                  }
                });
                saveGroupsChanges(props.attribute, auxValue, first);
              } else {
                //Alerta
                finishOperation("error", getErrorMessage(postalCodes));
              }
              setAuxDisabled(false);
            });
          } else {
            //Alerta
            finishOperation("error", getErrorMessage(province));
            setAuxDisabled(false);
          }
        });
      }
    }
  };

  const getId = (value) => {
    let name =
      attribute.fieldCode !== "CPO" && attribute.fieldCode !== "ECP"
        ? "name"
        : "postalCode";
    let auxAttrname =
      (attribute.codeAttribute == "CPO" || attribute.codeAttribute == "ECP")
        ? "postalCode"
        : (attribute.codeAttribute == "POB" || attribute.codeAttribute == "EPO")
          ? "cityCode"
          : (attribute.codeAttribute == "VIA" || attribute.codeAttribute == "EVI")
            ? "viaTypeCode"
            : (attribute.codeAttribute == "PRO" || attribute.codeAttribute == "EPR")
              ? "provinceCode"
              : "code";
    let id;
    let auxdropdowns =
      attribute.fieldCode !== "ALI" && attribute.fieldCode !== "EAL"
        ? attribute.originalValues
        : attribute.dropdownValues;
    if (!checkIsNullUndefined(auxdropdowns)) {
      auxdropdowns.forEach((dropValue) => {
        if (dropValue[name] === value) {
          id = dropValue[auxAttrname];
        }
      });
    }
    return id;
  };

  const getValue = (text) => {
    let name =
      attribute.fieldCode !== "CPO" && attribute.fieldCode !== "ECP"
        ? "name"
        : "postalCode";
    let auxAttrname =
      (attribute.codeAttribute == "CPO" || attribute.codeAttribute == "ECP")
        ? "postalCode"
        : (attribute.codeAttribute == "POB" || attribute.codeAttribute == "EPO")
          ? "cityCode"
          : (attribute.codeAttribute == "VIA" || attribute.codeAttribute == "EVI")
            ? "viaTypeCode"
            : (attribute.codeAttribute == "PRO" || attribute.codeAttribute == "EPR")
              ? "provinceCode"
              : "code";
    let value = "";
    if (!checkIsNullUndefined(attribute.originalValues)) {
      attribute.originalValues.forEach((dropValue) => {
        if (text === dropValue[auxAttrname]) {
          value = dropValue[name];
        }
      });
    }
    return value;
  };

  return (
    <Grid item md={12} xs={12}>
      {/* {attribute.fieldCode === "ALI" || attribute.fieldCode === "EAL" ? <Typography>aliasFilterText</Typography> : null} */}
      <Autocomplete
        freeSolo
        disableClearable
        id={attribute.nameAttritube}
        options={attribute.dropdownValues}
        disabled={attribute.disabled}
        getOptionLabel={(option) =>
          attribute.fieldCode !== "CPO" && attribute.fieldCode !== "ECP"
            ? option.name
            : option.postalCode
        }
        value={value}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) =>
          _handleDropdownChange(event, newInputValue)
        }
        onChange={(event, newValue) => _handleChange(newValue)}
        renderOption={(option) => (
          <React.Fragment>
            {attribute.fieldCode !== "CPO" && attribute.fieldCode !== "ECP"
              ? option.name
              : option.postalCode}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={attribute.mandatory ? attribute.nameAttritube + " *" : attribute.nameAttritube}
            variant="outlined"
            color="secondary"
            InputProps={{ ...params.InputProps, type: "search" }}
          />
        )}
        fullWidth
      />
    </Grid>
  );
}
