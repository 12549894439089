import {
  getToken,
  getAPI,
  getAPI_CID,
  getApplicationOid,
  getRefreshToken,
  setToken,
  setRefreshToken,
  setIsRefreshing,
  getIsRefreshing,
  backToLogin,
  sleep,
  getCodeApp,
  getLanguage,
} from "./Commons";
import { checkIsNullUndefined } from "./Utilities";
import jwt_decode from "jwt-decode";

const fetchMethod = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE'
}
const fetchApi = {
  PostLogin: 'login',
  PostExternalLogin: 'login/from-external-url',
  GetMasterCountry: "master/country",
  GetMasterAttribute: "master/attribute",
  GetMasterFieldType: "master/field-type",
  GetMasterLanguage: "master/language",
  GetMasterProvince: "master/province",
  GetMasterGenders: "master/gender",
  GetMasterRoadType: "master/road-type",
  GetMasterCityPostalCode: "master/city/postal-code/{postalCode}",
  GetMasterCityProvince: "master/city/province/{codeProvince}",
  GetMasterPostalCode: "master/postal-code/{postalCode}",
  GetMasterPostalCodeProvince: "master/postal-code/province/{codeProvince}",
  GetMasterProvincePostalCode: "master/province/postal-code/{postalCode}",
  GetMasterProfessionalActivity: "master/professional-activity",
  GetMasterRgpd: "master/rgpd",
  GetMasterTypeUserDocument: "master/type-user-document",
  GetProfileUser: "user",
  PutProfileUser: "user/{applicationCode}",
  GetLegalRgpdByEmail: "legal/rgpds-by-email/{email}",
  GetLegalRgpdByPhone: "legal/rgpds-by-phone/{phone}",
  PostLegalSendTyCs: "legal/{userIdentifier}/{idPersonType}/send/tycs",
  PostLegalRGPD: "legal/rgpd-by-identifier/{identifier}",
  PostAtcOtpSendEmail: "verification/send-otp-email/{email}",
  PostAtcOtpSendPhone: "verification/send-otp-phone/{phone}",
  PostProfileUserProfileUserByIdentification: "user/by-identification/{userIdentification}",
  PostVerificationValidationOtpEmail: "verification/validation-otp-email",
  PostVerificationValidationOtpPhone: "verification/validation-otp-phone",
  DeleteVerificationUserEmail: "verification/email/{email}",
  DeleteVerificationUserPhone: "verification/phone/{phone}",
  GetUtilitiesCorreosIdConfigApplication: "utilitiescorreosid/config-application/{personType}",
  GetUrlRedirect: "application/url-redirect/{applicationCode}",
  GetExists: "user/check-exist/{userIdentifier}",
  GetExistsUser: "user/exist/{userIdentifier}",
  GetApplication: "application/{applicationCode}",
  GetApplicationAttributesUser: "application/attributes/user/{applicationCode}",
  GetApplicationAttributesEnterprise: "application/attributes/enterprise/{applicationCode}",
  GetEnterprise: "enterprise",
  PostEnterprise: "enterprise/{applicationCode}",
  PutEnterprise: "enterprise/{applicationCode}",
  GetEnterpriseSelect: "enterprise/select/{idEnterprise}",
  GetMustRegistry: "application/must-registry/{applicationCode}",
  GetUserSubscriptionsByOid: "subscription/user/{userOid}",
  PostSendOtpTycEmail: "verification/send-otp-tyc-email/{applicationCode}/{idPersonType}/{email}",
  PostSendOtpTycPhone: "verification/send-otp-tyc-phone/{applicationCode}/{idPersonType}/{phone}",
  GetSubscriptionUser: "subscription/user",
  PostSubscriptionUser: "subscription/user",
  GetSubscriptionEnterprise: "subscription/enterprise",
  GetApplicationUserSubscription: "application/{applicationCode}/user-subscriptions",
  GetApplicationEnterpriseSubscription: "application/{applicationCode}/enterprise-subscriptions",
  GetApplicationUsesEnterpriseData: "application/{applicationCode}/uses-enterprise-data",
  PostRegistryByMail: "user/registry-by-email/{applicationCode}/{flowType}",
  PostRegistryByPhone: "user/registry-by-phone/{applicationCode}/{flowType}",
  PostNotificationWelcomeEmail: "notification/welcome-email/{applicationCode}",
  PostSubscriptionUserApplicationCode: "subscription/user/{applicationCode}",
  GetUserEnterprises: "enterprise/user-owner-enterprises",
  GetEnterpriseSuscription: "subscription/enterprise/{enterpriseId}",
  PostEnterpriseSubscription: "subscription/enterprise/{applicationCode}",
  PostResolveApplicationsNames: "application/resolve-applications-names",
  GetAttributesUser: "application/attributes/user/{applicationCode}",
  GetAttributesEnterprise: "application/attributes/enterprise/{applicationCode}",
  GetApplicationPersonType: "application/person-type/{applicationCode}",
  GetRegistryAttribute: "application/registry-attribute-info/{applicationCode}",
  PostRegistryByEmailAndPhone: "user/registry-by-email-and-phone/{applicationCode}/{flowType}",
  GetPermissionGroups: "login/user-permission-groups",
  GetApplicationConfiguration: "application/application-config/{applicationCode}",
  GetApplicationActions: "application/actions/{applicationCode}",
  PostRefreshToken: "login/refresh-token",
  PostCancelToken: "login/cancel-token",
  GetApplicationOidByCode: "utilitiescorreosid/application-oid/{code}",
  PostVerificationValidationOtpEmailWithoutToken: "verification/validation-otp-email-without-token",
  PostVerificationValidationOtpPhoneWithoutToken: "verification/validation-otp-phone-without-token",
  PostGetPaginatedUsersRgpd: "legal/get-paginated-users-rgpd",
  PostUnacceptRgpds: "legal/unaccept-rgpd",
  PostUserCreateOrUpdateDocument: "user/create-or-update-document",
  PostUsersExport: "utilitiescorreosid/users-export",
  GetApplicationRedirectUrl: "integration/application-redirect-url",
  PostApplicationRedirectUrl: "integration/application-redirect-url",
  GetApplicationDescription: "integration/application-description",
  PostApplicationDescription: "integration/application-description",
  GetApplicationName: "integration/application-name",
  PostApplicationName: "integration/application-name",
  GetApplicationImages: "integration/application-images",
  PostApplicationImages: "integration/application-images",
  GetApplicationNotifierUrls: "integration/application-notifier-urls",
  PostApplicationNotifierUrls: "integration/application-notifier-urls",
  PostSendIntegrationRequest: "integration/send-integration-request",
  PostSendTycPrivacyFileUpdateRequest: "integration/send-tyc-privacy-file-update-request",
  GetApplicationTyc: "integration/application-tyc",
  GetApplicationPrivacy: "integration/application-privacy",
  GetExtendedUserDataByIdentification: "user/extended-by-identification/{userIdentification}",
  PostFirstExternalLogin: "login/external-login",
  GetExtendedUserDataByIdentificationNotCancelled: "user/extended-by-identification-not-cancelled/{userIdentification}",

  GetActions: "master/actions",
  GetActionsByAppCode: "application/actions/{applicationCode}",
  PostUpdateActions: "application/update-actions/{applicationCode}",

  GetAllAttributesByAppCode: "application/attibutes/{applicationCode}",
  PostUpdateAttributes: "application/update-attributes/{applicationCode}",
  GetAllAttribute: "master/attribute-extended",
  GetAllVerificationType: "master/verification-type",

  PostCancelAccount: "user/cancel-account",
  GetApplicationApplicationList: "application/application-list",
  PostVerifyEmailOrPhone: "verification/verify-user-email-phone",

  PostSubscriptionUserUnsubscribe: "subscription/user/unsubscribe/{applicationCode}",
  GetMasterIntegrationBlocks: "master/integration-blocks",
  PostIntegrationAddIntegrationBlock: "integration/add-integration-block",
  PostSystemuser: "systemuser",
  DeleteSystemuser: "systemuser",
  GetSystemuser: "systemuser",
  PutSystemuser: "systemuser",
  PostSystemuserSubscribe: "systemuser/subscribe",
  PutSystemuserUnsubscribe: "systemuser/unsubscribe",
  GetMasterSystemUserApplicationsToSubscribe: "master/system-user/applications-to-subscribe",
  PutSystemuserResetCredentials: "systemuser/reset-credentials",
  PutSystemuserOwner: "systemuser/owner",
}

const buildHeader = (accept, authorization, content, userSelectedOid) => {
  let header = new Headers();
  header.append("ApplicationOid", getApplicationOid());
  header.append("Accept-Language", getLanguage());
  header.append("accept", accept);
  if (authorization) {
    header.append("Authorization", "Bearer ");
  }
  if (content) {
    header.append("Content-Type", content);
  }
  if (!checkIsNullUndefined(userSelectedOid)) {
    header.append("UserSelectedOid", userSelectedOid);
  }
  return header;
};

const PostRefreshToken = async (token, refreshToken) => {
  let body = {
    idToken: token,
    refreshToken: refreshToken,
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostRefreshToken;
  let response = await NewCommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostCancelToken = async (token) => {
  let body = {
    idToken: token
  };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostCancelToken;
  let response = await NewCommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

const checkToken = async () => {
  let token = getToken();
  let refreshToken = getRefreshToken();
  let tokenDecoded = jwt_decode(token);
  let actualDate = Date.parse(new Date()) / 1000;
  let isOkToken = true;

  if (actualDate > tokenDecoded.exp) {
    if (checkIsNullUndefined(tokenDecoded.oid)) {
      isOkToken = false;
    } else {
      setIsRefreshing(true);

      return await PostRefreshToken(token, refreshToken).then((response) => {

        if (!response || response.Status) {
          isOkToken = false;
        }
        else {
          setToken(response.idToken);
          setRefreshToken(response.refreshToken);
        }

        setIsRefreshing(false);

        return isOkToken;
      });
    }
  }

  return isOkToken;
};

const CommonFetch = async function CommonFetch(fetchHeader, fetchBody, method, apiUrl, isAPI3) {

  let isRefreshing = getIsRefreshing();

  if (!checkIsNullUndefined(isRefreshing) && isRefreshing) {
    for (let i = 0; i < 100 && isRefreshing; i++) {
      isRefreshing = getIsRefreshing();
      await sleep(100);
    }
  }

  let okToken = true;

  if (!checkIsNullUndefined(getToken())) {
    okToken = await checkToken();
  }

  if (okToken) {
    let response;
    if (!apiUrl.startsWith(fetchApi.PostUsersExport)) {
      response = await NewCommonFetch(fetchHeader, fetchBody, method, apiUrl, isAPI3);
    }
    else {
      response = await NewCommonFetchDocuments(fetchHeader, fetchBody, method, apiUrl, isAPI3);
    }
    return response;
  }
  else {
    backToLogin();
  }
};

const NewCommonFetch = async function NewCommonFetch(fetchHeader, fetchBody, method, apiUrl, isAPI3) {

  if (!checkIsNullUndefined(fetchHeader.get("Authorization"))) {
    fetchHeader.set("Authorization", "Bearer " + getToken());
  }

  let miInit = {
    method: method,
    headers: fetchHeader,
    mode: "cors",
    cache: "default",
  };


  if (!checkIsNullUndefined(fetchBody)) {

    if (!(fetchBody instanceof FormData)) {
      miInit.body = JSON.stringify(fetchBody);
    } else {
      miInit.body = fetchBody;
    }
  }
  let api = isAPI3 ? getAPI_CID() : getAPI();
  return await fetch(api + apiUrl, miInit)
    .then((res) => {

      return res
        .json()
        .then(

          (respuesta) => {
            //Status que devolvemos a las llamadas que no devuelven error
            if (
              res.status === 202 ||
              res.status === 203 ||
              res.status === 206 ||
              res.status === 209 ||
              res.status === 400) {
              respuesta.Status = res.status;
            }
            return respuesta;
          }
        ).catch((respuesta) => {
          if (res.status === 404) {
            return null;
          } else {
            return true;
          }
        })
    })
    .catch(error => console.error('Error:', error))
    .then(response => {
      return response;
    });
};

const NewCommonFetchDocuments = async function NewCommonFetchDocuments(fetchHeader, fetchBody, method, apiUrl, isAPI3) {

  if (!checkIsNullUndefined(fetchHeader.get("Authorization"))) {
    fetchHeader.set("Authorization", "Bearer " + getToken());
  }

  let miInit = {
    method: method,
    headers: fetchHeader,
    mode: "cors",
    cache: "default",
  };

  miInit.body = fetchBody;

  let api = isAPI3 ? getAPI_CID() : getAPI();


  return await fetch(api + apiUrl, miInit)
    .then((res) => res.blob())
    .catch((error) => console.error("Error:", error))
    .then((response) => {
      return response;
    });
};


const ExternalCommonFetch = async function ExternalCommonFetch(fetchHeader, fetchBody, method, apiUrl) {

  let miInit = {
    method: method,
    headers: fetchHeader,
    mode: "cors",
    cache: "default",
  };
  if (!checkIsNullUndefined(fetchBody)) {
    miInit.body = fetchBody;
  }

  return await fetch(apiUrl, miInit)
    .then((res) => {

      return res
        .json()
        .then(

          (respuesta) => {
            //Status que devolvemos a las llamadas que no devuelven error
            if (
              res.status === 202 ||
              res.status === 203 ||
              res.status === 206 ||
              res.status === 209 ||
              res.status === 400) {
              respuesta.Status = res.status;
            }
            return respuesta;
          }
        ).catch((respuesta) => {
          if (res.status === 404) {
            return null;
          } else {
            return true;
          }
        })
    })
    .catch(error => console.error('Error:', error))
    .then(response => {
      return response;
    });
}


export const PostLogin = async (username, password) => {
  let body = { username: username, password: password };
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.PostLogin;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostFirstExternalLogin = async (code, redirectUrl) => {
    let body = {code: code, redirect_url: encodeURIComponent(redirectUrl)}    
    let header = new Headers();
    header.append("Content-Type", "application/json");
    
    let apiUrl = fetchApi.PostFirstExternalLogin;
     
    let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
    return response;
  };

export const GetMasterCountry = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterCountry + "?id=" + value
    : fetchApi.GetMasterCountry;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetProfileUser = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetProfileUser;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para enviar OTP y TYC ATC por email.
export const PostAtcOtpSendEmail = async (email) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostAtcOtpSendEmail.replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para enviar OTP y TYC ATC por sms.
export const PostAtcOtpSendPhone = async (phone) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostAtcOtpSendPhone.replace("{phone}", phone);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para obtener el perfil de usuario por email, teléfono o dni verificado.
export const PostProfileUserProfileUserByIdentification = async (userIdentification) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostProfileUserProfileUserByIdentification.replace("{userIdentification}", userIdentification) + "/" + getCodeApp();
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return await response;
};

// API 3.0 Petición para verificar OTP email.
export const PostVerificationValidationOtpEmail = async (email, otp, userSelectedOid) => {
  let body = {
    email: email,
    otp: otp,
  };
  let header = buildHeader("*/*", true, "application/json", userSelectedOid);
  let apiUrl = fetchApi.PostVerificationValidationOtpEmail;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para verificar OTP phone.
export const PostVerificationValidationOtpPhone = async (phone, otp, userSelectedOid) => {
  let body = {
    phone: phone,
    otp: otp,
  };
  let header = buildHeader("*/*", true, "application/json", userSelectedOid);
  let apiUrl = fetchApi.PostVerificationValidationOtpPhone;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};



// API 3.0 Petición para borrar verificación de un correo.
export const DeleteVerificationUserEmail = async (email) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.DeleteVerificationUserEmail.replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl, false);
  return response;
};

// API 3.0 Petición para borrar verificación de un teléfono.
export const DeleteVerificationUserPhone = async (phone) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.DeleteVerificationUserPhone.replace("{phone}", phone);
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl, false);
  return response;
};

// Petición para obtener los tipos de atributos del formulario de registro.
export const GetMasterAttribute = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterAttribute + "?id=" + value
    : fetchApi.GetMasterAttribute;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// Petición para obtener los tipos de fieldsSet del formulario de registro.
export const GetMasterFieldType = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterFieldType + "?id=" + value
    : fetchApi.GetMasterFieldType;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// Para API 3.0.
export const GetMasterLanguage = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", true, false);
  let apiUrl = value
    ? fetchApi.GetMasterLanguage + "?id=" + value
    : fetchApi.GetMasterLanguage;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para obtener los datos del formulario de registro.
export const GetUtilitiesCorreosIdConfigApplication = async (personType) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = fetchApi.GetUtilitiesCorreosIdConfigApplication.replace(
    "{personType}",
    personType
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para actualizar usuario.
export const PutProfileUser = async (data, applicationCode, codeConfiguration) => {
  let body = data;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutProfileUser.replace("{applicationCode}", applicationCode);
  if (!checkIsNullUndefined(codeConfiguration)) {
    apiUrl = apiUrl + "?codeConfiguration=" + codeConfiguration;
  }
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

// Para API 3.0 Petición fetch para obtener la ciudad segun codigo postal.
export const GetMasterCityPostalCode = async (postalCode) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = fetchApi.GetMasterCityPostalCode.replace(
    "{postalCode}",
    postalCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// Para API 3.0 Petición fetch para obtener la ciudad segun provincia.
export const GetMasterCityProvince = async (codeProvince) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = fetchApi.GetMasterCityProvince.replace(
    "{codeProvince}",
    codeProvince
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const GetMasterPostalCodeProvince = async (codeProvince) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = fetchApi.GetMasterPostalCodeProvince.replace(
    "{codeProvince}",
    codeProvince
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetMasterRgpd = async (value) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterRgpd + "?id=" + value
    : fetchApi.GetMasterRgpd;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const GetMasterPostalCode = async (postalCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetMasterPostalCode.replace(
    "{postalCode}",
    postalCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const GetMasterProvince = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", true, false);
  let apiUrl = value
    ? fetchApi.GetMasterProvince + "?code=" + value
    : fetchApi.GetMasterProvince;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const GetMasterProvincePostalCode = async (postalCode) => {
  let body = "";
  let header = buildHeader("*/*", false, false);
  let apiUrl = fetchApi.GetMasterProvincePostalCode.replace(
    "{postalCode}",
    postalCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

//API 3.0 Petición para verificar si un email, teléfono o documento existe
export const GetExists = async (user, userSelectedOid) => {
  let body = "";
  let header = buildHeader("*/*", true, false, userSelectedOid);
  let apiUrl = fetchApi.GetExists.replace("{userIdentifier}", user);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

//API 3.0 Petición para verificar si un email, teléfono o documento existe
export const GetExistsUser = async (user) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetExistsUser.replace("{userIdentifier}", user);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};


export const GetMasterGenders = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterGenders + "?id=" + value
    : fetchApi.GetMasterGenders;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const GetMasterProfessionalActivity = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterProfessionalActivity + "?id=" + value
    : fetchApi.GetMasterProfessionalActivity;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para obtener los tipos de fieldsSet del formulario de registro.
export const GetMasterRoadType = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterRoadType + "?code=" + value
    : fetchApi.GetMasterRoadType;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// Para API 3.0.
export const GetMasterTypeUserDocument = async (value) => {
  let body = "";
  let header = buildHeader("text/plain", false, false);
  let apiUrl = value
    ? fetchApi.GetMasterTypeUserDocument + "?id=" + value
    : fetchApi.GetMasterTypeUserDocument;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


export const PostLegalSendTyCs = async (userIdentifier, idPersonType, appArray, idEnterprise) => {
  let body = { applicationCodes: appArray, idEnterprise: idEnterprise };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostLegalSendTyCs.replace("{userIdentifier}", userIdentifier).replace("{idPersonType}", idPersonType);;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplication = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplication.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para obtener los datos del formulario de registro.
export const GetApplicationAttributesUser = async (applicationCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationAttributesUser.replace(
    "{applicationCode}",
    applicationCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetMustRegistry = async (value) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetMustRegistry.replace("{applicationCode}", value);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetSubscriptionUser = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetSubscriptionUser;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetSubscriptionEnterprise = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetSubscriptionEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para obtener los datos del formulario de registro.
export const GetApplicationAttributesEnterprise = async (applicationCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationAttributesEnterprise.replace(
    "{applicationCode}",
    applicationCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetUserSubscriptionsByOid = async (userOid) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetUserSubscriptionsByOid.replace("{userOid}", userOid);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

//API 3.0 Petición para obtener los datos de la empresa
export const GetEnterprise = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetEnterprise;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostSendOtpTycEmail = async (applicationCode, idPersonType, email) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostSendOtpTycEmail
    .replace("{applicationCode}", applicationCode)
    .replace("{idPersonType}", idPersonType)
    .replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

//API 3.0 Petición para añadir una empresa
export const PostEnterprise = async (data, applicationCode, userIdentification, otp) => {
  let body = data;
  data.userIdentification = userIdentification;
  data.otp = otp;
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.PostEnterprise.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSendOtpTycPhone = async (applicationCode, idPersonType, phoneNumber) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.PostSendOtpTycPhone
    .replace("{applicationCode}", applicationCode)
    .replace("{idPersonType}", idPersonType)
    .replace("{phone}", phoneNumber);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para actualizar usuario.
export const PutEnterprise = async (data, applicationCode, codeConfiguration) => {
  let body = data;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutEnterprise.replace("{applicationCode}", applicationCode);
  if (!checkIsNullUndefined(codeConfiguration)) {
    apiUrl = apiUrl + "?codeConfiguration=" + codeConfiguration;
  }
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

//Para API 3.0
export const GetEnterpriseSelect = async (idEnterprise) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetEnterpriseSelect.replace(
    "{idEnterprise}",
    idEnterprise
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationUserSubscription = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationUserSubscription.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationEnterpriseSubscription = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationEnterpriseSubscription.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationUsesEnterpriseData = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationUsesEnterpriseData.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostSubscriptionUser = async (appList) => {
  let body = appList;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostSubscriptionUser;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostRegistryByMail = async (appCode, email, otpValue, flowType) => {
  let body = {
    "email": email,
    "otp": otpValue,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostRegistryByMail.replace("{applicationCode}", appCode).replace("{flowType}", flowType);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostRegistryByPhone = async (appCode, idCountry, phoneNumber, otpValue, userSelectedOid, flowType) => {
  let body = {
    "idCountry": idCountry,
    "phone": phoneNumber,
    "otp": otpValue,
  };
  let header = buildHeader("*/*", true, "application/json", userSelectedOid);
  let apiUrl = fetchApi.PostRegistryByPhone.replace("{applicationCode}", appCode).replace("{flowType}", flowType);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};


export const PostNotificationWelcomeEmail = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostNotificationWelcomeEmail.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSubscriptionUserApplicationCode = async (appCode, userIdentification, otp, codsApp) => {
  let body = {
    "userIdentification": userIdentification,
    "otp": otp,
    "codsApp": codsApp
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostSubscriptionUserApplicationCode.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetUserEnterprises = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetUserEnterprises;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetEnterpriseSuscription = async (enterpriseId) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetEnterpriseSuscription.replace("{enterpriseId}", enterpriseId);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostEnterpriseSubscription = async (appCode, userIdentification, otp, codsApp) => {
  let body = {
    "userIdentification": userIdentification,
    "otp": otp,
    "codsApp": codsApp
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostEnterpriseSubscription.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetUrlRedirect = async (appCode) => {
  let body = "";
  let header = buildHeader("application/json", true, false);
  let apiUrl = fetchApi.GetUrlRedirect.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostResolveApplicationsNames = async (appCodes) => {
  let body = appCodes;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostResolveApplicationsNames;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationPersonType = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationPersonType.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl);
  return response;
};

export const GetRegistryAttribute = async (appCode) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetRegistryAttribute.replace("{applicationCode}", appCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostRegistryByEmailAndPhone = async (appCode, email, otpEmail, idCountry, phone, otpPhone, flowType) => {
  let body = {
    userEmailVerifyOtp: {
      "email": email,
      "otp": otpEmail
    },
    userPhoneVerifyOtp: {
      "idCountry": idCountry,
      "phone": phone,
      "otp": otpPhone
    }
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostRegistryByEmailAndPhone.replace("{applicationCode}", appCode).replace("{flowType}", flowType);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationConfiguration = async (value) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationConfiguration.replace("{applicationCode}", value);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};
export const GetPermissionGroups = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetPermissionGroups;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationActions = async (value) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationActions.replace("{applicationCode}", value);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetAttributesUser = async (applicationCode) => {
  let body = "";
  let header = buildHeader("application/json", true, false);
  let apiUrl = fetchApi.GetAttributesUser.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetAttributesEnterprise = async (applicationCode) => {
  let body = "";
  let header = buildHeader("application/json", true, false);
  let apiUrl = fetchApi.GetAttributesEnterprise.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};



export const GetLegalRgpdByEmail = async (email) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetLegalRgpdByEmail.replace("{email}", email);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetLegalRgpdByPhone = async (phone) => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetLegalRgpdByPhone.replace("{phone}", phone);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};


//API 3.0 Petición para añadir una empresa
export const PostLegalRGPD = async (data, identifier) => {
  let body = data;
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.PostLegalRGPD.replace("{identifier}", identifier);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};


export const GetApplicationOidByCode = async (applicationCode) => {
  let body = "";
  let header = buildHeader("application/json", false, "application/json");
  let apiUrl = fetchApi.GetApplicationOidByCode.replace(
    "{code}",
    applicationCode
  );
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

// API 3.0 Petición para verificar OTP email.
export const PostVerificationValidationOtpEmailWithoutToken = async (email, otp, userSelectedOid) => {
  let body = {
    email: email,
    otp: otp,
  };
  let header = buildHeader("*/*", true, "application/json", userSelectedOid);
  let apiUrl = fetchApi.PostVerificationValidationOtpEmailWithoutToken;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para verificar OTP phone.
export const PostVerificationValidationOtpPhoneWithoutToken = async (phone, otp, userSelectedOid) => {
  let body = {
    phone: phone,
    otp: otp,
  };
  let header = buildHeader("*/*", true, "application/json", userSelectedOid);
  let apiUrl = fetchApi.PostVerificationValidationOtpPhoneWithoutToken;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

// API 3.0 Petición para verificar el documento identificativo.
export const PostUserCreateOrUpdateDocument = async (id, idTypeUserDocument, number, idCountry, expirationDate) => {
  let body = {
    id: id,
    idTypeUserDocument: idTypeUserDocument,
    idCountry: idCountry,
    number: number,
    expirationDate: expirationDate
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostUserCreateOrUpdateDocument;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};


export const PostGetPaginatedUsersRgpd = async (list, currentPage, pageSize) => {
  let body = list;
  let header = buildHeader("*/*", true, "application/json");
  let valueCurrentPage = checkIsNullUndefined(currentPage) ? 1 : currentPage;
  let valuePageSize = checkIsNullUndefined(currentPage) ? 1000 : pageSize;
  let apiUrl = fetchApi.PostGetPaginatedUsersRgpd + "?currentPage=" + valueCurrentPage + "&pageSize=" + valuePageSize;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostUnacceptRgpds = async (list) => {
  let body = list;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostUnacceptRgpds;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostUsersExport = async (file, oids, eids, appOid) => {
  let body = new FormData();
  body.append("file", file);
  let header = new Headers();
  header.append("accept", "*/*");
  header.append("ApplicationOid", appOid);
  header.append("Authorization", "Bearer ");
  let apiUrl = fetchApi.PostUsersExport + "?oids=" + oids + "&eids=" + eids;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;

};

export const GetApplicationRedirectUrl = async () => {
  let body = "";
  let header = buildHeader("application/json", true, "application/json");
  let apiUrl = fetchApi.GetApplicationRedirectUrl;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostApplicationRedirectUrl = async (redirectUrl) => {
  let body = redirectUrl;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostApplicationRedirectUrl;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationDescription = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationDescription;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostApplicationDescription = async (descriptionObject) => {
  let body = descriptionObject;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostApplicationDescription;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationName = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationName;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostApplicationName = async (nameObject) => {
  let body = nameObject;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostApplicationName;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationImages = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationImages;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostApplicationImages = async (imagesObject) => {
  let body = imagesObject;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostApplicationImages;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationNotifierUrls = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationNotifierUrls;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostApplicationNotifierUrls = async (notifierUrlsObject) => {
  let body = notifierUrlsObject;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostApplicationNotifierUrls;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSendIntegrationRequest = async (file, email) => {
  let body = new FormData();
  body.append("file", file);
  let header = new Headers();
  header.append("accept", "*/*");
  header.append("ApplicationOid", getApplicationOid());
  header.append("Authorization", "Bearer ");
  let apiUrl = fetchApi.PostSendIntegrationRequest + "?email=" + email;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSendTycPrivacyFileUpdateRequest = async (files, personType, isTyc, email) => {
  let body = new FormData();
  for (let i = 0; i < files.length; i++) {
    body.append("files", files[i]);
  }
  let header = new Headers();
  header.append("accept", "*/*");
  header.append("ApplicationOid", getApplicationOid());
  header.append("Authorization", "Bearer ");

  let apiUrl = fetchApi.PostSendTycPrivacyFileUpdateRequest + "?personType=" + personType + "&isTyc=" + isTyc + "&email=" + email;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetApplicationTyc = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationTyc;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationPrivacy = async () => {
  let body = "";
  let header = buildHeader("*/*", true, false);
  let apiUrl = fetchApi.GetApplicationPrivacy;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetExtendedUserDataByIdentification = async (userIdentification) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetExtendedUserDataByIdentification.replace("{userIdentification}", userIdentification);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetActions = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetActions;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetActionsByAppCode = async (applicationCode) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetActionsByAppCode.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostUpdateActions = async (applicationCode, actions) => {
  let body = actions;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostUpdateActions.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetAllAttributesByAppCode = async (applicationCode) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetAllAttributesByAppCode.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostUpdateAttributes = async (applicationCode, attributes) => {
  let body = attributes;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostUpdateAttributes.replace("{applicationCode}", applicationCode);
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetAllAttribute = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetAllAttribute;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetAllVerificationType = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetAllVerificationType;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostCancelAccount = async (userOid) => {
  let body = userOid;
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostCancelAccount;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetExtendedUserDataByIdentificationNotCancelled = async (userIdentification) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetExtendedUserDataByIdentificationNotCancelled.replace("{userIdentification}", userIdentification);
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const GetApplicationApplicationList = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetApplicationApplicationList;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostVerifyEmailOrPhone = async (accountIdentifier, emailOrPhone, emailOrPhoneOTP) => {
  let body = {
    accountIdentifier: accountIdentifier,
    emailOrPhone: emailOrPhone,
    emailOrPhoneOTP: emailOrPhoneOTP
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostVerifyEmailOrPhone;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSubscriptionUserUnsubscribe = async (userOid, applicationCode) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json", userOid);
  let apiUrl = fetchApi.PostSubscriptionUserUnsubscribe.replace("{applicationCode}", applicationCode);;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const GetMasterIntegrationBlocks = async () => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.GetMasterIntegrationBlocks;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PostIntegrationAddIntegrationBlock = async (applicationCode, groupCode) => {
  let body = {
    codConApplication: applicationCode,
    codIntegrationBlock: groupCode
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostIntegrationAddIntegrationBlock;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PostSystemuser = async (applicationName, applicationCode, userOid) => {
  let body = {
    applicationName,
    applicationCode,
    userOid,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostSystemuser;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const DeleteSystemuser = async (client_id) => {
  let body = {
    client_id
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.DeleteSystemuser;
  let response = await CommonFetch(header, body, fetchMethod.DELETE, apiUrl, false);
  return response;
};

export const GetSystemuser = async (client_id) => {
  let body = "";
  let header = buildHeader("*/*", true, "application/json");
  header.append("client_id", client_id);
  let apiUrl = fetchApi.GetSystemuser;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PutSystemuser = async (client_id, applicationName) => {
  let body = {
    client_id,
    applicationName,
  };
  let header = buildHeader("*/*", true, "application/json");
  header.append("client_id", client_id);
  let apiUrl = fetchApi.PutSystemuser;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl, false);
  return response;
};

export const PostSystemuserSubscribe = async (client_id, applicationCode) => {
  let body = {
    client_id,
    applicationCode
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PostSystemuserSubscribe;
  let response = await CommonFetch(header, body, fetchMethod.POST, apiUrl, false);
  return response;
};

export const PutSystemuserUnsubscribe = async (client_id, applicationCode) => {
  let body = {
    client_id,
    applicationCode
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutSystemuserUnsubscribe;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

export const GetMasterSystemUserApplicationsToSubscribe = async () => {
  let body = "";
  let header = buildHeader("*/*", false, "application/json");
  let apiUrl = fetchApi.GetMasterSystemUserApplicationsToSubscribe;
  let response = await CommonFetch(header, body, fetchMethod.GET, apiUrl, false);
  return response;
};

export const PutSystemuserResetCredentials = async (client_id) => {
  let body = {
    client_id,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutSystemuserResetCredentials;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};

export const PutSystemuserOwner = async (client_id, userOid) => {
  let body = {
    client_id,
    userOid,
  };
  let header = buildHeader("*/*", true, "application/json");
  let apiUrl = fetchApi.PutSystemuserOwner;
  let response = await CommonFetch(header, body, fetchMethod.PUT, apiUrl);
  return response;
};