import React, { useEffect } from "react";
import {
    Typography,
    Grid,
} from "@material-ui/core";

export function PageHeader(props) {
    const { title, text } = props;

    useEffect(() => { }, []);

    return (
        <Grid item xs={12} style={{ marginBottom: "2em", }}>
          <Typography
            variant="h4"
            align="center"
            style={{color: "#002453", marginBottom: "1em", }}
          >
            {title}
          </Typography>
          <Typography
          variant="h6"
            align="center"
            style={{ color: "#666666", }}
          >
            {text}
          </Typography>
        </Grid>
    );
}
