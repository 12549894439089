import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { AtcUserSearcherStyles } from "./AtcUserSearcherStyles";
import {
  GetMasterCountry,
  PostProfileUserProfileUserByIdentification,
} from "../../../services/ReasonService";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import SearchIcon from "@material-ui/icons/Search";
import { CommonStyles } from "../../../commons/CommonStyles";
import { PrefixPhone } from "../../TransversalComponents/PrefixPhone/PrefixPhone";
import { FormatColorReset } from "@material-ui/icons";
import {
  checkIsNullUndefined,
  getPhoneCountry,
  getPhonePrefixCountry,
} from "../../../services/Utilities";
import { EnumCountries, GroupActions, PathType } from "../../../commons/Enums";

export function AtcUserSearcher(props) {
  const {
    changeStep,
    saveData,
    countryList,
    previousPage,
    groupActions,
    setPathType,
    setRegistryData,
    registryData,
    setUserSelectedData
  } = props;
  const validator = require("react-email-validator");
  const classes = AtcUserSearcherStyles();
  const commonClasses = CommonStyles();
  const [identifier, setIdentifier] = useState("");
  const [identifierType, setIdentifierType] = useState("");
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  const [showPrefixes, setShowPrefixes] = useState(false);
  const [prefixPhoneCode, setPrefixPhoneCode] = useState(EnumCountries.ESP);
  const [idSearcherError, setIdSearcherError] = useState(false);
  const [idSearcherErrorText, setIdSearcherErrorText] = useState("");

  useEffect(() => {
    sessionStorage.removeItem("userSelectedOid");
    if (
      (previousPage === 1 || previousPage === 2) &&
      !checkIsNullUndefined(registryData.identifier)
    ) {
      let auxIdentifier = registryData.identifier;
      if (registryData.identifierType === "phoneNumber") {
        setPrefixPhoneCode(getPhoneCountry(auxIdentifier).code);
        setShowPrefixes(true);
        auxIdentifier = auxIdentifier.split("-")[1];
      }
      setIdentifier(auxIdentifier);
      setIdentifierType(registryData.identifierType);
      setDisableButtonNext(false);
    }
  }, []);

  // controla el cambio en el selector de prefijo
  const handleSelectorChange = (country) => {
    setPrefixPhoneCode(country.code);
  };

  // Cambia al siguiente paso y guarda los datos necesarios para el siguiente paso en "props"
  const _handleNext = () => {
    let identifierAux = identifier;

    if (identifierType === "phoneNumber") {
      let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
      identifierAux = country.phonePrefix + "-" + identifier;
    }

    PostProfileUserProfileUserByIdentification(identifierAux).then(
      (response) => {
        if ((response && !response.Status) && response.length > 0) {
          saveData({
            userData: response,
          });
          setRegistryData({
            identifier: identifierAux,
            identifierType: identifierType,
          })
          changeStep(2, 0);
        }else{
          setIdSearcherError(true);
          setIdSearcherErrorText(spanishLanguage["atcSearchUserError"]);
        }
      }
    );
  };

  // Identifica el tipo de identificador introducido (email, dni/nie, teléfono) y habilita el botón cuando el valor sea válido
  const _handleTypeOfIdentifier = (e) => {
    let auxDisableButtonNext = true;
    let auxShowPrefixes = false;
    let auxIdentifierType = "";
    let value = e.target.value.trim();
    setDisableButtonNext(true);
    setShowPrefixes(false);
    setIdSearcherError(false);
    const checkDni = /^\d{8}[a-zA-Z]$/i;
    const checkNie = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
    const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

    if (validator.validate(value)) {
      auxIdentifierType = "email";
      auxDisableButtonNext = false;
    } else if (checkPhoneNumber.test(value)) {
      // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
      auxShowPrefixes = true;
      if (value.length >= 9) {
        auxIdentifierType = "phoneNumber";
        auxDisableButtonNext = false;
      }
    } else if (checkDni.test(value) || checkNie.test(value)) {
      auxIdentifierType = "identifierDocument";
      auxDisableButtonNext = false;
    }

    setIdentifier(value);
    setIdentifierType(auxIdentifierType);
    setDisableButtonNext(auxDisableButtonNext);
    setShowPrefixes(auxShowPrefixes);
  };

  // Navegar a crear cuenta nueva.
  const _handleNewAccountButton = () => {
    setPathType(PathType.creation);
    saveData({
      userData: [],
    });
    setRegistryData({
      identifier: null,
      identifierType: null,
    });
    setUserSelectedData({});
    changeStep(1, 0);
  };

  return (
    <Grid
      container
      item
      justifyContent="center"
      spacing={1}
      className={commonClasses.atcContainer}
      xs={12}
    >
      <Grid item xs={12}>
        <Typography align="center" className={commonClasses.hitText}>
          {spanishLanguage["atcSearchHint"]}
        </Typography>
      </Grid>
      {groupActions.length > 0 ? (
        groupActions.includes(GroupActions.BUS) ? (
          <Grid container item xs={12} justifyContent="center">
            <Grid
              container
              item
              sm={10}
              xs={12}
              spacing={1}
              className={classes.textFieldRow}
            >
              {showPrefixes ? (
                <Grid item sm={2} xs={12}>
                  <PrefixPhone
                    countryList={countryList}
                    handleSelectorChange={handleSelectorChange}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                sm={showPrefixes ? 8 : 10}
                xs={12}
                className={classes.textFieldRow}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  id="idSearcher"
                  label={spanishLanguage["atcIdentifierField"]}
                  name="idSearcher"
                  color="secondary"
                  required={true}
                  onChange={_handleTypeOfIdentifier}
                  value={identifier}
                  error={idSearcherError}
                  helperText={idSearcherError ? idSearcherErrorText : ""}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <CorreosButton
                  className={classes.buttonSearch}
                  variant="contained"
                  color="primary"
                  onClick={() => _handleNext()}
                  style={{ float: "center" }}
                  disabled={disableButtonNext}
                >
                  <SearchIcon style={{ marginRight: "0.2em" }} />
                  {spanishLanguage["search"]}
                </CorreosButton>
              </Grid>
            </Grid>
          </Grid>
        ) : null
      ) : null}

      {groupActions.length > 0 ? (
        groupActions.includes(GroupActions.RUS) ? (
          <Grid item xs={12}>
            <CorreosButton
              className={commonClasses.rightButton}
              variant="contained"
              color="primary"
              onClick={() => _handleNewAccountButton()}
            >
              {spanishLanguage["atcNewAccount"]}
            </CorreosButton>
          </Grid>
        ) : null
      ) : null}
    </Grid>
  );
}
