import React, { useEffect } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { LoginPageStyles } from "./LoginPageStyles";
import { BackgroundLogin } from "../../components/BackgroundLogin/BackgroundLogin";
import { LogIn } from "../../components/LogIn/LogIn";
import { CorreosAlert } from "../../components/CorreosAlert/CorreosAlert";
import { useParams } from "react-router-dom";
import {
  checkIsNullUndefined,
  getErrorMessage,
} from "../../services/Utilities";
import { PostLogin } from "../../services/ReasonService";
import { useHistory } from "react-router-dom";
import { setUserSession, goToMain, setCodeApp, redirectToOktaLogin } from "../../services/Commons";

export function LoginPage(props) {
  const classes = LoginPageStyles();
  const { codeApp } = useParams();
  const history = useHistory();
  //   const { t, i18n } = useTranslation();

  const [circularProgress, setCircularProgress] = React.useState(true);
  const [alert, setAlert] = React.useState({
    open: false,
    color: "",
    message: "",
  });
  
  useEffect(() => {
    redirectToOktaLogin();
  }, []);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  return (
    <div>
      {circularProgress ? (
        <CircularProgress className={classes.circularProgresLogin} />
      ) : (
        <Grid className={classes.mainDiv}>
          <CorreosAlert alert={alert} setAlert={setAlert}></CorreosAlert>
          <BackgroundLogin login={true}>
            <LogIn finishOperation={finishOperation} />
          </BackgroundLogin>
        </Grid>
      )}
    </div>
  );
}
