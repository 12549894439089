import React, { useEffect } from "react";
import { Grid, CircularProgress, IconButton, Container, Typography } from "@material-ui/core";
import { ExternalLoginPageStyles } from "./ExternalLoginPageStyles";
import { useParams } from "react-router-dom";
import { PostFirstExternalLogin } from "../../services/ReasonService";
import { useHistory } from "react-router-dom";
import { setUserSession, goToAtc, goToLogin, setCodeApp, goToMain } from "../../services/Commons";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { CorreosButton } from "../../components/CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { Constants } from "../../commons/Constants";



export function ExternalLoginPage(props) {
  const classes = ExternalLoginPageStyles();
  const { codeApp } = useParams();
  const history = useHistory();
  const commonClasses = CommonStyles();


  const [circularProgress, setCircularProgress] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [retry, setRetry] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);


  useEffect(() => {
    setCircularProgress(true);
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get("code");
    let state = urlParams.get("state");
    let redirectUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

    if(window.location.pathname === "/Home"){    
        externalLogin("home", code, state, redirectUrl);
    }
    else if (!checkIsNullUndefined(codeApp)) {
        externalLogin("atc", code, state, redirectUrl);
    }else{
      setCircularProgress(false);
      goToLogin(history);
    }
   }, [retry]);

   const externalLogin = (page, code, state, redirectUrl) => {

    if (!checkIsNullUndefined(code) && !checkIsNullUndefined(state)) {
      PostFirstExternalLogin(code, redirectUrl).then((response) => {
        if (response && !response.Status) {
          setUserSession(response).then(() => {              
            setCircularProgress(false);
            if(page === "atc"){
              setCodeApp(codeApp);
              goToAtc(history);
            }else{
              goToMain(history);              
            }
            
          });
        } else {
          setError(true);

          if (retryCount >= Constants.LoginRetryLimit) {
            setErrorMsg(spanishLanguage["retryError"]);
          } else {
            setErrorMsg(getErrorMessage(response));
          }

          setRetryCount(retryCount + 1);
          setCircularProgress(false);
        }
      });
    }
  }
    
  
  return (
    <Grid className={classes.loadingGrid}>
      {circularProgress ? (
        <Grid className={classes.loadingGrid}>
          <CircularProgress className={classes.circularProgresLogin} />
        </Grid>
      ) : (
        <Grid className={classes.mainDiv}>
          <Dialog
            fullWidth
            open={open}
          >
            <Container className={classes.dialogTitle}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={commonClasses.dialogTitleText}
                  >
                    {spanishLanguage["errorTitle"]}
                  </Typography>
                </Grid>

              </Grid>
            </Container>

            <DialogContent >
              {errorMsg}
            </DialogContent>
            <DialogActions className={commonClasses.dialogActions}>
              <Grid item sm={12} xs={12}>
                <CorreosButton
                  variant="contained"
                  color="primary"
                  className={commonClasses.rightButton}
                  disabled={retryCount >= Constants.LoginRetryLimit + 1}
                  onClick={() => setRetry(true)}
                >
                  {spanishLanguage["retry"]}
                </CorreosButton>
              </Grid>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
}
