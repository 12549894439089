export const spanishLanguage = {
  // Generic.
  saveSuccess: "¡Guardado realizado con éxito!",
  saveError: "¡Error al guardar!",
  cancel: "Cancelar",
  accept: "Aceptar",
  genericError: "Se ha producido un error",
  genericPrefixSelector: "Prefijo",
  exit: "Salir",
  next: "Siguiente",
  previous: "Anterior",
  add: "Añadir",
  required: "Este es un campo obligatorio",
  save: "Guardar",
  reset: "Restablecer",
  address: "Dirección",
  delete: "Borrar",
  yes: "Sí",
  no: "No",
  search: "Buscar",
  retry: "Reintentar",
  retryError: "Se ha producido un error, vuelva a intentarlo más adelante",
  errorTitle: "Error",
  send: "Enviar",
  resend: "Reenviar",
  addDirection: "Añadir dirección",
  confirm: "Confirmar",
  remove: "Eliminar",
  close: "Cerrar",
  action: "Acción",
  takeOut: "Quitar",
  email: "email",
  phone: "teléfono",
  edit: "Modificar",
  charging: "Cargando, por favor espere...",

  // Login.
  logInTitle: "Iniciar Sesión",
  logInSubTitle: "Utiliza tu identidad de correos",
  correosIdentityLabel: "Identidad de correos",
  correosPasswordLabel: "Contraseña",
  forgotPasswordButton: "¿Has olvidado tu contraseña?",
  nextButton: "Siguiente",
  logInButton: "Iniciar Sesión",
  userPasswordError: "Usuario o contraseña incorrectos",
  prefixSelector: "Prefijo",
  logInWarningLegal: "Aviso Legal",
  logInPrivacyPolicy: "Política de Privacidad",
  logInCookiesPolicy: "Política de Cookies",
  createAccountButton: "Crear Cuenta",
  rememberme: "Recuérdame",
  welcomeBack: "Hola de nuevo",
  logInDefaultAppName: "BackOffice de CorreosId",
  loginSystemUserError: "Un usuario de sistema no puede loguear en la web",

  //Register
  registerCheckDNI: "Confirmada la fecha de caducidad DNI y su correspondencia con el cliente atendido",
  registerPageInfo: "Registra tu identidad de correos",
  registerPageMail: "Dirección de correo electrónico",
  registerPageMailError: "El email introducido no es correcto",
  registerPagePhoneError: "El teléfono introducido no es correcto",
  registerPageNDOError: "El documento introducido no es correcto",
  registerPageMailErrorExist: "El email introducido ya está registrado",
  registerPagePassword: "Contraseña",
  registerPagePasswordError:
    "8-16 carácteres, que contengan 3 de los 4 siguientes: minúsculas, mayúsculas, dígitos (0-9), y uno o más de los siguientes símbolos: @ # $ % ^ & * - _ + = [ ] { } | \\ : ' , ? / ` ~ ( ) ; . \"",
  registerPageCheckPassword: "Confirmación",
  registerPageCheckPasswordError: "Las contraseñas no son iguales",
  registerPagePhone: "Teléfono",
  registerPagePhoneErrorShort: "El teléfono es demasiado corto",
  registerPagePhoneErrorLong: "El teléfono es demasiado largo",
  registerPagePhoneErrorExist: "El teléfono introducido ya está registrado",
  registerPageTipoPersona: "Tipo de Persona",
  registerPageAgreeConditions: "Términos y Condiciones",
  registerPageAgreeConditionsTerms1: "He leído y acepto ",
  registerPageAgreeConditionsTerms2: "las condiciones de uso ",
  registerPageAgreeConditionsTerms3: "de Correos ID y su ",
  registerPageAgreeConditionsTerms4: "política de protección de datos",
  registerPageAgreeConditionsTermsConsent:
    "De acuerdo con nuestra Política de Protección de datos nos gustaría solicitar su consentimiento para las siguientes actividades:",
  registerPageOfersPromotions: "Ofertas y Promociones",
  registerPageOfersPromotionsAll: "ACEPTO TODOS",
  registerPageOfersPromotionsSome: "ACEPTO ALGUNOS",
  registerPageOfersPromotionsNone: "NO ACEPTO",
  registerPagePersonalizeOptions:
    "Personalice su privacidad seleccionando las siguientes opciones:",
  registerPageIdentityVerify:
    "Para garantizar tu identidad, enviaremos un código de verificación:",
  registerPageIdentityVerifyMailButton: "ENVIAR EMAIL",
  registerPageIdentityVerifyPhoneButton: "ENVIAR SMS",
  registerPageIdentityVerifyVerificable:
    "Debe realizar una de las dos verificaciones obligatoriamente, la otra puede omitirla o aprovechar para realizarla",
  registerPageIdentityVerifyVerificableEmail:
    "La verificación del email es obligatoria",
  registerPageIdentityVerifyVerificablePhone:
    "La verificación del teléfono es obligatoria",
  registerPageIdentityVerifyNoVerificableEmail:
    "La verificación del email es opcional, puede omitir ese punto si quiere",
  registerPageIdentityVerifyNoVerificablePhone:
    "La verificación del teléfono es opcional, puede omitir ese punto si quiere",
  registerPageIdentityVerifyCodeSended: "Código enviado",
  registerPageOtp: "Introduzca Código",
  registerPageOtpError: "El código introducido no es correcto",
  registerPageRegisterError: "Ha habido un problema durante el registro",
  registerPageOfersPromotionsSelected: "Ofertas y promociones aceptadas: ",
  registerPageOfersPromotionsSelectedAll: "Todas",
  registerPageOfersPromotionsSelectedSome: "Algunas",
  registerPageOfersPromotionsSelectedNone: "Ninguna",
  registerPageSeeOfersPromotions: "Abrir ofertas/promociones",
  registerPageSendOTP: "Introduzca el código que le acabamos de enviar",
  registerPageSendOTPEmail: "Código para email",
  registerPageSendOTPPhone: "Código para teléfono",
  registryTitleData: "Datos Registro",
  registerPagePersonType1: "Particular",
  registerPagePersonType2: "Empresa/Autónomo",
  registerPageNoDocuments: "Para este tipo de empresa no se requieren documentos",
  registerParticularType: "Particular",
  registerEnterpriseType: "Empresa",
  registerFreelanceType: "Autónomo",
  registerVerifyIdentityTitle: "Antes de continuar confirma tu identidad",
  registerVerifyIdentityText: "Enviaremos un código de validación al que escojas de entre los {userType}s verificados para esta cuenta",
  registerVerifyEmail: "Email",
  registerVerifyPhone: "Teléfono",
  registerVerifyOtp: "Código de validación",
  registerVerifyOtpError: "Código de validación incorrecto",

  // Header
  headerMainTitle: "Bienvenido al BackOffice de CorreosID",
  headerKYB: "KyB",
  headerAAP: "Administrador",
  headerCAU: "CAU",
  headerLEG: "Legal",
  headerN1: "Nivel 1",
  headerN2: "Nivel 2",
  headerN3: "Nivel 3",
  headerN4: "Nivel 4",
  headerN5: "Nivel 5",
  headerSelectAppTitle: "Selecciona una aplicación",
  headerSelectAppLabel: "Aplicación",

  // CorreosIdStepper.
  correosIdStepperUserIdentify: "Introducir usuario",
  correosIdStepperSendOTP: "Enviar código",
  correosIdStepperCodeConfirm: "Confirmar código",
  correosIdStepperUserData: "Datos de usuario",
  correosIdStepperEnterpriseData: "Datos de empresa",
  correosIdStepperEnterpriseDocuments: "Añadir documentos",
  correosIdStepperAtcSearchUser: "Buscar usuario",
  correosIdStepperAtcSelectAccount: "Seleccionar/Crear cuenta",
  correosIdStepperAtcUserVerify: "Verificar usuario",
  correosIdStepperAtcUserData: "Datos usuario",

  // Roles.
  rolesSlectAction: "Selecionar acción",
  rolesAapConsultInfo: "Consultar info API",
  rolesAapModifyInfo: "Modificar info API",
  rolesLegConsultApps: "Consultar aplicaciones de usuario",

  // Atc.
  atcSearchUser: "Buscar usuario",
  atcSelectPersonTypeTitle: "Seleccione el tipo de cuenta",
  atcSelectAccount: "Seleccionar/Crear cuenta",
  atcUserVerify: "Verificar usuario",
  atcUserData: "Datos usuario",
  atcIdentifierField: "Introduce: Documento de identidad, Teléfono o Email",
  atcOtpText: "Para garantizar la identidad del usuario, necesitamos que confirme su cuenta. Para ello se le enviará un código numérico al email y/o teléfono del usuario, que luego tendrás que introducir en esta pantalla:",
  atcSendEmailButton: "ENVIAR EMAIL",
  atcResendEmailButton: "REENVIAR EMAIL",
  atcSendSmsButton: "ENVIAR SMS",
  atcResendSmsButton: "REENVIAR SMS",
  atcRegisterPagePrefix: "Prefijo",
  atcRegisterPagePhone: "Teléfono",
  atcRegisterPageEmail: "Email",
  atcRegisterPageDniNie: "Documento de identidad",
  atcRegisterPageSendOTP: "Introduzca el código enviado",
  atcRegisterPageAcceptTyc: "ACEPTAR TYC",
  atcCheckOtpCode: "COMPROBAR CÓDIGO",
  atcCreateAccount: "CREAR CUENTA",
  atcUserAdvice: "*Confirme que el usuario acepta los términos y condiciones de uso antes de crear la cuenta.",
  atcAccept: "ACEPTAR",
  atcClose: "CERRAR",
  atcCancel: "CANCELAR",
  atcCreate: "CREAR",
  atcModify: "MODIFICAR",
  atcVerificationSoltiTitle: "Verificación Solti",
  atcVerifyDocumentIdentifier: "VERIFICAR DNI/PASAPORTE",
  atcPhoneError: "El teléfono no tiene un formato válido",
  atcPhoneMandatoryError: "El teléfono es obligatorio",
  atcIdentifierDocumentError: "El documento de identidad no tiene un formato válido para el tipo seleccionado",
  atcIdentifierDocumentMandatoryError: "El documento de identidad es obligatorio",
  atcIdentifierDocumentTypeError: "Debe indicar el tipo de documento",
  atcEmailError: "El email no tiene un formato válido",
  atcEmailMandatoryError: "El email es obligatorio",
  atcNewAccount: "Crear nueva cuenta",
  atcRadioSelectAccount: 'Seleccione su cuenta o la opción "Crear Nueva Cuenta"',
  atcModalTitle: "Datos de contacto",
  atcModalSubtitle: "Por favor, seleccione su cuenta",
  atcModalEmailTitle: "Direcciones de Email:",
  atcModalPhoneTitle: "Números de teléfono:",
  atcModalCreateAccountTitle: "Formulario de registro",
  atcSearchAcount: "Busca la cuenta del usuario",
  atcSelectPersonType: "Selecciona un tipo de usuario",
  atcAccountsFounds: "Estas son las cuentas que se han identificado por tu campo de búsqueda",
  atcAccountsFounds2: "Selecciona la cuenta correspondiente a la del usuario, si no corresponde ninguna selecciona Crear Nueva Cuenta",
  atcShowEmail: "Mostrar emails",
  atcShowPhone: "Mostrar teléfonos",
  atcSelectAcount: "Al seleccionar cuenta puede hacer: ",
  atcSearchUserError: "No se ha encontrado ninguna cuenta por ese campo. Por favor inténtelo de nuevo por email, teléfono móvil, DNI o crear una nueva cuenta.",
  atcSearchHint: "Para poder encontrar la cuenta del usuario dentro del sistema, se deberá buscar por DNI/NIE, email o teléfono. Si el usuario no tiene cuenta en CorreosID, se puede crear una cuenta pulsando en CREAR NUEVA CUENTA",
  atcUserDataHint: "Introduce o modifica los datos personales del usuario.",
  atcSentOtpTitle: "Envío código verificación usuario",
  atcUserDataTitle: "Datos personales del usuario",
  atcEnterprisesFounds: "Listado de empresas del usuario",
  atcSelectCreateEnterpriseCant: 'No tiene permisos para ver las empresas del usuario',
  atcSelectCreateEnterpriseBoth: 'Selecciona una empresa o la opción "Crear Nueva Empresa"',
  atcSelectCreateEnterpriseSel: 'Selecciona una empresa"',
  atcSelectCreateEnterpriseCre: 'Aquí puedes ver las empresas y puedes también seleccionar "Crear Nueva Empresa"',
  atcSelectCreateEnterpriseNone: 'Aquí puedes ver las empresas del usuario',
  atcCreateEnterprise: "CREAR NUEVA EMPRESA",
  atcRegisterAccountTitle: "Creación/modificación de cuenta de usuario",
  atcRegisterAccountText: "Para crear/modificar la cuenta de usuario, se deberán introducir los datos obligatorios requeridos por la aplicación a la cual se está registrando.",
  atcLegalDialogText1: "Le informo que Correos, como responsable del tratamiento, utilizará su correo electrónico y/o número de teléfono con la finalidad de verificar si usted está registrado en la cuenta de Correos ID, así como para enviarle un SMS que incluirá un enlace a los Términos y Condiciones y la Política de Protección de datos del servicio por usted solicitado, para su lectura y aceptación.",
  atcLegalDialogText2: "Puede ejercitar sus derechos de protección de datos en ",
  atcLegalDialogText2Email: "derechos.protecciondatos.correos@correos.com",
  atcLegalDialogText2End: ". Puede obtener más información sobre la protección de sus datos preguntando en la oficina de Correos.",
  atcLegalDialogText3: "Instrucciones: Si el cliente solicita más información en protección de datos, acceder al siguiente enlace, imprimir y entregársela",
  atcLegalWarningAccept: "Aceptar",
  atcSelectCreateEnterpriseName: "Nombre:",
  atcSelectCreateEnterpriseRason: "Razón social:",
  atcSelectCreateEnterpriseDoc: "CIF:",
  atcSelectCreateEnterpriseSelect: "Seleccionar empresa",
  atcSelectCreateAccountSelect: "Seleccionar cuenta",
  atcSelectCreateEnterpriseData: "Datos de empresa",
  atcUserVerifiedData: "Datos verificados del usuario",
  atcFluxUserDialogOptions: "Opciones de cuenta",
  atcFluxUserDialogModify: "Modificar datos",
  atcFluxUserDialogSee: "Ver datos",
  atcFluxUserDialogGoTo: "Ir a la aplicación",
  atcLegalWarning: "Aviso legal",
  atcLegalWarningRead: "Instrucciones: Leer al usuario este texto antes de solicitarse sus datos personales",
  atcLegalESLanguage: "Castellano",
  atcLegalENLanguage: "Inglés",
  atcLegalCALanguage: "Catalán",
  atcLegalEULanguage: "Euskera",
  atcLegalGLLanguage: "Gallego",
  atcLegalVALanguage: "Valenciano",
  //Terms and Conditions
  tycHeaderTitle: "Terminos y Condiciones",

  //WarningLegalTyC Subtittles
  tycWarningLegalSubtittle1:
    "DERECHOS DE PROPIEDAD INTELECTUAL Y DE PROPIEDAD INDUSTRIAL",
  tycWarningLegalSubtittle2: "PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL",
  tycWarningLegalSubtittle3: "MEDIDAS DE SEGURIDAD",
  tycWarningLegalSubtittle4: "RESPONSABILIDADES DE CORREOS",
  tycWarningLegalSubtittle5: "RESPONSABILIDAD DEL USUARIO",
  tycWarningLegalSubtittle6: "LEY APLICABLE Y JURISDICCIÓN",

  //WarningLegalTyC texts
  tycWarningLegalText1:
    "En cumplimiento de lo establecido en el Art. 10 de la Ley " +
    "34/2002, de 11 de julio, de Servicios de la Sociedad de la " +
    "Información y Comercio Electrónico (LSSI-CE), se informa que " +
    "esta página web es titularidad de Correos.",
  tycWarningLegalText2:
    "El presente documento contiene el Aviso legal de “Correos ID” " +
    "proporcionados por la Sociedad Estatal Correos y Telégrafos, " +
    "S.A (en adelante, Correos), con domicilio social en Vía " +
    "Dublín nº 7 (Campo de las Naciones) 28070 Madrid (España), " +
    "CIF número A83052407.",
  tycWarningLegalText3:
    "El contacto para la atención al " +
    "cliente del servicio es por medio telefónico a través del " +
    "número de Atención al Cliente de Correos: 902.197.197 o " +
    "página web de Correos.",
  tycWarningLegalText4:
    "Tanto el diseño del Portal y sus códigos fuente, como los logos, " +
    "marcas, y demás signos distintivos que aparecen en el mismo, " +
    "pertenecen a Correos y están protegidos por los correspondientes " +
    "derechos de propiedad intelectual e industrial.",
  tycWarningLegalText5:
    "Su uso, reproducción, distribución, comunicación pública, " +
    "transformación o cualquier otra actividad similar o análoga, " +
    "queda totalmente prohibida salvo que medie expresa " +
    "autorización por escrito de Correos.",
  tycWarningLegalText6:
    "Los contenidos del Portal están igualmente protegidos por derechos " +
    "de propiedad intelectual de Correos. El uso de los contenidos elaborados " +
    "por Correos o por alguna de las entidades de su Grupo será permitido " +
    "únicamente en el ámbito de una relación contractual con la misma o con dichas entidades.",
  tycWarningLegalText7:
    "Correos declara su respeto a los derechos de propiedad intelectual e industrial de terceros; " +
    "por ello, si considera que este sitio pudiera estar violando sus derechos, rogamos se ponga en " +
    "contacto con Correos en el siguiente número de teléfono: 902.197.197.",
  tycWarningLegalText8_1:
    "La navegación a través de nuestra página web no requiere su " +
    "registro previo. No obstante, cuando usted visita nuestra " +
    "página web, nuestros servidores web almacenan, de forma " +
    "estándar, información como la dirección IP y el dominio " +
    "desde el que se obtiene el acceso, la fecha y la hora de la " +
    "visita, etc. Por otro lado, ciertas funcionalidades de " +
    "nuestra página web exigen que facilite información adicional " +
    "a través del correspondiente formulario (p.ej. Su nombre y " +
    "apellidos, dirección postal o electrónica de contacto, " +
    "teléfono…). Correos tratará sus datos de carácter personal " +
    "conforme las condiciones publicadas en la ",
  tycWarningLegalText8_B: "Política de Privacidad Web ",
  tycWarningLegalText8_2:
    "y, en su caso, a la " +
    "Política de Protección de Datos que resulte aplicable a los " +
    "distintos servicios solicitados.",
  tycWarningLegalText9:
    "Le informamos que han sido adoptadas todas las medidas de " +
    "seguridad de índole técnica, organizativa y de seguridad " +
    "informática necesarias para la protección de datos de " +
    "carácter personal para evitar su alteración, pérdida, " +
    "tratamiento y/ o acceso no autorizado, todo ello de acuerdo " +
    "a lo establecido en la normativa aplicable.",
  tycWarningLegalText10:
    "Uso incorrecto del Portal: Correos ha creado el Portal para " +
    "la promoción de sus productos y para facilitar el acceso a " +
    "sus servicios, pero no puede controlar la utilización del " +
    "mismo de forma distinta a la prevista en el presente Aviso " +
    "Legal; por tanto, el acceso al Portal y el uso correcto de " +
    "la información contenida en el mismo son responsabilidad de " +
    "quien realiza estas acciones, no siendo responsable Correos " +
    "por el uso incorrecto, ilícito o negligente que del mismo " +
    "pudiere hacer el Usuario.",
  tycWarningLegalText11:
    "Utilización de los contenidos: Correos facilita todos los " +
    "contenidos de su Portal (Correos ID) de buena fe y realizará " +
    "sus mejores esfuerzos para que los mismos estén " +
    "permanentemente actualizados y vigentes; no obstante, " +
    "Correos no puede asumir responsabilidad alguna respecto al " +
    "uso o acceso que realicen los Usuarios fuera del ámbito al " +
    "que se dirige el Portal, ni de las consecuencias que pudiera " +
    "acarrear la aplicación práctica de las opiniones, " +
    "recomendaciones o estudios a que se pueda acceder a través " +
    "del Portal, cuya responsabilidad final recaerá siempre sobre " +
    "el Usuario. Asimismo, Correos no va a poder controlar los " +
    "contenidos que no hayan sido elaborados por ella o por " +
    "terceros cumpliendo su encargo y, por tanto, no responderá " +
    "en ningún caso de los daños que pudieran causarse por dichos " +
    "contenidos de terceros.",
  tycWarningLegalText12:
    "Publicidad: Correos incluirá en el Portal publicidad propia " +
    "o de terceros para ofrecerle productos o servicios que " +
    "entienda que pueden ser de su interés. Sin embargo, Correos " +
    "no puede controlar la apariencia de dicha publicidad, ni la " +
    "calidad y adecuación de dichos productos o servicios y, en " +
    "consecuencia, Correos no responderá de ningún daño que se " +
    "pudiera generar al usuario por dichas causas.",
  tycWarningLegalText13:
    "Virus: Correos se compromete a aplicar todas las medidas " +
    "necesarias para intentar garantizar al Usuario la ausencia " +
    "de virus informáticos y elementos similares en su Portal. No " +
    "obstante, estas medidas no son infalibles y, por ello, " +
    "Correos no puede asegurar totalmente la ausencia de dichos " +
    "elementos nocivos. En consecuencia, Correos no será " +
    "responsable de los daños que los mismos pudieran producir al " +
    "Usuario.",
  tycWarningLegalText14:
    "Fallos tecnológicos: Correos ha concluido todos los " +
    "contratos necesarios para la continuidad de su Portal y " +
    "realizará sus mejores esfuerzos para que el mismo no sufra " +
    "interrupciones, pero no puede garantizar la ausencia de " +
    "fallos tecnológicos, ni la permanente disponibilidad del " +
    "Portal y de los productos o servicios contenidos en él y, en " +
    "consecuencia, no asume responsabilidad alguna por los daños " +
    "y perjuicios que puedan generarse por la falta de " +
    "disponibilidad y por los fallos en el acceso ocasionados por " +
    "desconexiones, averías, sobrecargas o caídas de la red no " +
    "imputables a Correos.",
  tycWarningLegalText15:
    "Intercambio de información: el Portal puede contener " +
    "determinadas zonas pensadas para que los distintos Usuarios " +
    "puedan poner en común sus experiencias y para fomentar el " +
    "intercambio de información. Sin embargo, Correos únicamente " +
    "actúa en estos foros como lugar de reunión y no controla las " +
    "expresiones vertidas en los mismos, por lo que no asume " +
    "responsabilidad alguna por la veracidad o pertinencia de las " +
    "manifestaciones efectuadas en ellos por los Usuarios.",
  tycWarningLegalText16:
    "El Usuario se compromete a utilizar los servicios del Portal " +
    "de acuerdo con los términos expresados en el presente Aviso " +
    "Legal y la mera utilización de los mismos implicará la " +
    "aceptación de éste.",
  tycWarningLegalText18:
    "El Usuario que actúe contra la imagen, buen nombre o " +
    "reputación de Correos, así como quien utilice ilícita o " +
    "fraudulentamente los diseños, logos o contenidos del Portal, " +
    "será responsable frente a Correos de su actuación.",
  tycWarningLegalText19:
    "La ley aplicable en caso de disputa o conflicto de " +
    "interpretación de los términos que conforman este Aviso " +
    "Legal, así como cualquier cuestión relacionada con los " +
    "productos o servicios del presente Portal, será la ley " +
    "española, salvo que las normas de protección de los " +
    "consumidores y usuarios establezcan la aplicabilidad de una " +
    "legislación diferente.",
  tycWarningLegalText20:
    "Para la resolución departes se someten a la jurisdicción de " +
    "los Juzgados y Tribunales de la capital de provincia de " +
    "España que corresponda.",

  //PrivacyPoliticyTyC Subtittles
  tycPrivacyPoliticyTyCSubtittle1: "Responsable de tratamiento",
  tycPrivacyPoliticyTyCSubtittle2: "Finalidad del tratamiento y legitimación",
  tycPrivacyPoliticyTyCSubtittle3: "Tipo de datos tratados",
  tycPrivacyPoliticyTyCSubtittle4: "Plazo de conservación de los datos",
  tycPrivacyPoliticyTyCSubtittle5: "Sus derechos",
  tycPrivacyPoliticyTyCSubtittle6: "Acceso a terceros",

  //PrivacyPoliticyTyC list tittles
  tycPrivacyPoliticyTyCListTittle1: "Prestación del servicio Correos ID",
  tycPrivacyPoliticyTyCListTittle2:
    "Envío de ofertas y promociones de productos y servicios de Correos",
  tycPrivacyPoliticyTyCListTittle3:
    "Envío de ofertas y promociones de otras empresas del Grupo Correos y de terceras empresas",
  tycPrivacyPoliticyTyCListTittle4:
    "Comunicación de datos a otras empresas del Grupo Correos y a terceras empresas",
  tycPrivacyPoliticyTyCListTittle5: "Cumplir con las obligaciones legales",
  tycPrivacyPoliticyTyCListTittle6:
    "Información estadística y encuestas de satisfacción",
  tycPrivacyPoliticyTyCListTittle7: "Enriquecer tu información",
  tycPrivacyPoliticyTyCListTittle8: "Datos proporcionados de forma directa:",
  tycPrivacyPoliticyTyCListTittle9: "Datos recabados de forma indirecta:",
  tycPrivacyPoliticyTyCListTittle10:
    "Datos derivados de la propia prestación del servicio:",

  //PrivacyPoliticyTyC texts
  tycPrivacyPoliticyTyCText1:
    "Para todas las empresas del Grupo Correos respetar su " +
    "privacidad y cumplir con la normativa de protección de datos " +
    "es un aspecto de vital importancia.",
  tycPrivacyPoliticyTyCText2_1:
    "Nuestro deseo es que en todo momento conozca para qué " +
    "utilizamos, o queremos utilizar, sus datos y cuáles son sus " +
    "derechos. Con este objetivo, hemos redactado la siguiente " +
    "Política de Protección de Datos que regula el tratamiento de " +
    "datos derivado del servicio Correos ID, así como de la " +
    "navegación a través de la página web ",
  tycPrivacyPoliticyTyCText2_B1: "id.correos.post",
  tycPrivacyPoliticyTyCText2_2: " (con anterioridad ",
  tycPrivacyPoliticyTyCText2_B2: "emiidentidad.correos.post",
  tycPrivacyPoliticyTyCText2_3:
    " ) y de cualquiera de sus subdominios o de otras webs de " +
    "Correos en cuyo footer se muestre esta Política de " +
    "Privacidad",

  tycPrivacyPoliticyTyCText3:
    "Lo siguiente podrá ser objeto de modificación para cualquier " +
    "adaptación legal a futuras novedades legislativas o " +
    "jurisprudenciales, así como prácticas de la industria. En " +
    "cualquier caso, dicha modificación será comunicada al " +
    "Usuario mediante cualquier vía de comunicación válida.",

  tycPrivacyPoliticyTyCText4:
    "El responsable del tratamiento es “Sociedad Estatal Correos " +
    "y Telégrafos, S.A., S.M.E” (en adelante, “Correos”), con CIF " +
    "A-83052407 y domicilio social en Vía Dublín nº 7 (Campo de " +
    "las Naciones) 28070 Madrid (España).",
  tycPrivacyPoliticyTyCText5:
    "Para garantizar la adecuada gestión de dicho tratamiento, " +
    "Correos tiene designado un Delegado de Protección de Datos " +
    "(DPD o DPO), a quién puede dirigirse en para cualquier " +
    "cuestión que precise con relación a los mismos a través de " +
    "la siguiente dirección de correo electrónico: ",
  tycPrivacyPoliticyTyCText6:
    "El tratamiento de sus datos persigue las siguientes finalidades:",
  tycPrivacyPoliticyTyCText7: "Sus datos serán tratados para",
  tycPrivacyPoliticyTyCText8:
    "Tramitar su registro como usuario y proceder a su alta " +
    "a través del formulario establecido a tal efecto.",
  tycPrivacyPoliticyTyCText9:
    "Para darte de alta en algunos de los servicios " +
    "digitales a través de Correos ID es posible que se " +
    "solicite información adicional mediante un formulario " +
    "web establecido al efecto.",
  tycPrivacyPoliticyTyCText10:
    "Prestar los servicios de “Correos ID”, de conformidad " +
    "con las Condiciones Particulares del Servicio.",
  tycPrivacyPoliticyTyCText11:
    "En este sentido, Correos ID es un gestor centralizado " +
    "de identidad digital que te permite al usuario " +
    "autentificarse ante los distintos servicios digitales " +
    "de Correos y/o de otros servicios de terceros que " +
    "acepten este método como sistema de identificación; es " +
    "decir, de forma resumida, al usuario acceder a " +
    "distintos servicios a través de una sola instancia de " +
    "identificación digital, evitando tener que disponer de " +
    "una contraseña para cada uno de estos servicios.",
  tycPrivacyPoliticyTyCText12:
    "En caso de que el servicio Correos ID se utilice como " +
    "plataforma digital de identificación ante servicios " +
    "que presten otras empresas del Grupo Correos o " +
    "terceros colaboradores es necesario que los datos del " +
    "usuario se comuniquen a estas empresas para poder " +
    "llevar a cabo la verificación de identidad que ha sido " +
    "solicitada.",
  tycPrivacyPoliticyTyCText13:
    "Dar respuesta a sus dudas, consultas o reclamaciones.",
  tycPrivacyPoliticyTyCText14:
    "Tramitar la verificación de su identidad, derivado de " +
    "un acuerdo de colaboración y/o prestación de servicios " +
    "que Correos haya suscrito con un tercero.",
  tycPrivacyPoliticyTyCText15:
    "Asimismo, a través del gestor centralizado de " +
    "identidad digital se podrá tramitar la verificación de " +
    "su identidad, derivada de un acuerdo de colaboración " +
    "y/o prestación de servicio suscritos entre Correos y " +
    "un tercero.",
  tycPrivacyPoliticyTyCText16:
    "En caso de que preste su consentimiento, Correos podrá " +
    "remitirle por cualquier canal electrónico o no electrónico " +
    "información comercial relacionada con la prestación, " +
    "mejora y actualización de nuestros productos y servicios, " +
    "nuevas tarifas y descuentos.",
  tycPrivacyPoliticyTyCText17:
    "Esta información comercial podrá ser genérica o " +
    "personalizada. En este último supuesto implicará el previo " +
    "tratamiento de sus datos con el objetivo de poder proceder " +
    "a su perfilado y carterización, determinar gustos, " +
    "preferencias y necesidades y, en base a éstas, identificar " +
    "las ofertas que puedan resultar más interesantes.",

  tycPrivacyPoliticyTyCText18:
    "En caso de que preste su consentimiento, Correos podrá " +
    "remitirle por cualquier canal electrónico o no electrónico " +
    "ofertas y promociones de:",
  tycPrivacyPoliticyTyCText19:
    "Otras empresas del Grupo Correos que prestan servicios " +
    "relacionados con los sectores de: que pertenecen a las " +
    "actividades de servicios de paquetería, de valor añadido " +
    "al servicio postal, logística, marketing y de " +
    "telecomunicación",
  tycPrivacyPoliticyTyCText20:
    "Otras empresas con las que Correos haya suscrito " +
    "distintos convenios de colaboración y que pueden " +
    "pertenecer a sectores como telecomunicaciones, " +
    "financiero, gran consumo, sanidad y ONG´S.",
  tycPrivacyPoliticyTyCText21:
    "Esta información comercial podrá ser genérica o " +
    "personalizada. En este último supuesto implicará el previo " +
    "tratamiento de sus datos con el objetivo de poder proceder " +
    "a su perfilado y carterización, determinar gustos, " +
    "preferencias y necesidades y, en base a éstas, identificar " +
    "las ofertas que puedan resultar más interesantes.",

  tycPrivacyPoliticyTyCText22:
    "En caso de que preste su consentimiento, Correos podrá " +
    "comunicar sus datos a las empresas del Grupo Correos y a " +
    "terceras empresas cuyos sectores se detallan en la " +
    "finalidad anterior con el objetivo de que estas empresas " +
    "puedan hacerle llegar, de forma directa y sin la " +
    "intermediación de Correos, ofertas y promociones relativas " +
    "a sus productos y servicios.",
  tycPrivacyPoliticyTyCText23:
    "Dentro de las distintas obligaciones legales que asume " +
    "Correos se encuentran las derivadas de la aplicación de la " +
    "normativa postal (Ley 43/2010 del Servicio Postal " +
    "Universal, de los derechos de los usuarios y del mercado " +
    "postal) y el deber de secreto de las comunicaciones (art. " +
    "18 de la Constitución), así como la normativa tributaria.",
  tycPrivacyPoliticyTyCText24:
    "En el cumplimiento de estas obligaciones, Correos podrá " +
    "comunicar sus datos a las Administraciones Públicas y " +
    "tribunales, siempre que tal información sea requerida " +
    "conforme a los procesos legales establecidos.",

  tycPrivacyPoliticyTyCText25:
    "Correos tiene un interés legítimo en conocer tanto la " +
    "opinión de sus clientes con respecto a sus productos y " +
    "servicios como sus hábitos de consumo (p.ej. la " +
    "frecuencia/recurrencia en la contratación de los distintos " +
    "servicios) con el objetivo de poder ofrecer productos y " +
    "servicios más ajustados a sus necesidades y mejorar tanto " +
    "los productos y servicios actuales como su proceso de " +
    "contratación.",
  tycPrivacyPoliticyTyCText26:
    "Para lograr el objetivo expuesto en el párrafo anterior, " +
    "Correos podrá tratar sus datos y:",
  tycPrivacyPoliticyTyCText27:
    "Realizar encuestas de satisfacción tanto telefónicas " +
    "como por medios electrónicos, incluso una vez deje de " +
    "ser cliente, en este último caso para conocer los " +
    "motivos de su marcha.",
  tycPrivacyPoliticyTyCText28:
    "En caso de que durante la encuesta de satisfacción el " +
    "cliente manifieste su opinión sobre los productos y " +
    "servicios de alguna de las empresas del Grupo Correos, " +
    "el resultado de la encuesta y los datos " +
    "identificativos del participante podrán ser " +
    "comunicados a la empresa del Grupo Correos cuyos " +
    "servicios se valoran.",
  tycPrivacyPoliticyTyCText29:
    "Proceder a su anonimización, de forma que no pueda " +
    "volver a vincularse la información de sus hábitos de " +
    "consumo con su persona y, tras ello, realizar estudios " +
    "estadísticos y econométricos (p.ej. conocer el ciclo de " +
    "vida del cliente y/o identificar modelos de propensión " +
    "al abandono).",
  tycPrivacyPoliticyTyCText30:
    "En caso de que prestes tu consentimiento, Correos podrá " +
    "obtener una mayor información sobre tus características, " +
    "gustos y necesidades a través de la información " +
    "proporcionada por distintas fuentes externas tales como " +
    "Registros Públicos, Catastro, Redes Sociales, herramientas " +
    "de geomarketing, bases de datos de información económica " +
    "como INFORMA, ficheros de información crediticia como " +
    "ASNEF, BADEXCUG… con el objetivo de introducir mejoras en " +
    "nuestros productos y servicios y/o adaptar o escoger las " +
    "ofertas de dichos productos y servicios que mejor se " +
    "ajusten a tu concreto perfil.",
  tycPrivacyPoliticyTyCText31:
    "Para las finalidades expuestas en el apartado anterior se " +
    "trata un conjunto de datos de carácter personal que podemos " +
    "dividir en las siguientes fuentes y categorías:",
  tycPrivacyPoliticyTyCText32:
    "En el momento del registro el usuario debe proporcionar " +
    "una información mínima que resulta necesaria para su " +
    "verificación y alta, por lo que, salvo que se advierta de " +
    "lo contrario, la falta de cumplimentación impedirá la " +
    "prestación del servicio. Ciertas funcionalidades (e.j " +
    "acceso a determinados servicios) exigen que facilites " +
    "información adicional a través del propio servicio.",
  tycPrivacyPoliticyTyCText33:
    "Asimismo, junto con la información proporcionada a través " +
    "del servicio se tratarán datos recabados por el servidor, " +
    "como la dirección IP desde la que accede, el dominio desde " +
    "el que se obtiene el acceso, la fecha y la hora de la " +
    "visita, la cookie de sesión (esta última se elimina al " +
    "cerrar el navegador), etc.",
  tycPrivacyPoliticyTyCText34:
    "El cliente garantiza que, los datos facilitados a Correos " +
    "para la prestación de los servicios solicitados responden " +
    "con veracidad a la situación real y que comunicará " +
    "cualquier modificación que afecte a los mismos. En " +
    "consecuencia, el cliente responderá frente a Correos y " +
    "terceros de cualesquiera daños o perjuicios ocasionados " +
    "como consecuencia del incumplimiento de las obligaciones " +
    "asumidas en esta cláusula.",
  tycPrivacyPoliticyTyCText35_1:
    "Todos aquellos datos que, en caso de haber prestado su " +
    "consentimiento, Correos recaba de la tipología de fuentes " +
    'externas enumerada en la finalidad "H" del apartado ',
  tycPrivacyPoliticyTyCText35_B: '"Finalidad del tratamiento y legitimación".',
  tycPrivacyPoliticyTyCText36:
    "Además de en el caso anterior, terceras entidades, como " +
    "Administraciones públicas, la empresa en la que trabajas u " +
    "otras instituciones con la que Correos tenga suscrito un " +
    "acuerdo de colaboración y/ o prestación de servicios, y " +
    "que estén interesados en contratar este método como " +
    "sistema de identificación, pueden comunicarnos o darnos " +
    "acceso a tus datos, con el fin de que podamos verificar tu " +
    "identidad. Las entidades con las que Correos haya suscrito " +
    "acuerdos de colaboración y/ o prestación de servicios " +
    "podrán, en su caso, inhabilitar el sistema de " +
    "identificación, o aplicar condiciones distintas o " +
    "adicionales de acuerdo con lo recogido en los acuerdos de " +
    "colaboración y/o de prestación de servicios suscritos.",
  tycPrivacyPoliticyTyCText37:
    "Dentro de esta información se incluyen aspectos como: (i) " +
    "El histórico de productos y servicios contratados con " +
    "Correos, el tipo de servicio y sus características (p.ej. " +
    "Servicios digitales de Correos en los que se encuentra " +
    "dado de alta, frecuencia de uso de estos servicios, uso de " +
    "otros servicios de Correos como envíos postales, " +
    "paquetería, Citypaq…); (ii) Reclamaciones o solicitudes de " +
    "información, etc. (iii) Información sobre su navegación a " +
    "través de las cookies que pueden instalarse en su " +
    "dispositivo, previo su consentimiento -de ser necesario- " +
    "de conformidad con lo establecido en nuestra Política de " +
    "Cookies.",
  tycPrivacyPoliticyTyCText38:
    "Tus datos personales serán conservados mientras sean " +
    "necesarios para la prestación del servicio de Correos ID, " +
    "salvo que solicites su supresión. El proceso de eliminación " +
    "de Identidad implica un bloqueo o restricción de uso de tu " +
    "Identidad y datos durante un periodo de treinta (30) días, " +
    "tras el cual se procederá a su efectiva eliminación. Durante " +
    "el periodo de restricción del uso de la Identidad, podrás " +
    "recuperar tu Identidad siguiendo el proceso de Correos ID " +
    "para ello.",
  tycPrivacyPoliticyTyCText39:
    "Correos procederá al bloqueo de aquellos usuarios que se " +
    "encuentren inactivos -es decir, que no accedan a ninguno de " +
    "los servicios digitales de Correos o de otras empresas que " +
    "usen Correos ID como plataforma de identificación- por un " +
    "plazo de 5 años.",
  tycPrivacyPoliticyTyCText40:
    "Cuando ya no sea necesario el tratamiento de tus datos, se " +
    "suprimirán conforme a lo dispuesto en la normativa de " +
    "protección de datos lo que implica su bloqueo, estando " +
    "disponibles tan solo a solicitud de Jueces y tribunales, el " +
    "Ministerio Fiscal o las Administraciones Públicas " +
    "competentes, durante el plazo de prescripción de las " +
    "acciones que pudieran derivar, para ser posteriormente " +
    "eliminados. Generalmente, serán borrados una vez " +
    "transcurridos 3 años.",
  tycPrivacyPoliticyTyCText41:
    "Nuestra normativa de protección de datos le confiere una " +
    "serie de derechos en relación con el tratamiento de datos " +
    "que implican nuestros servicios que podemos resumir en los " +
    "siguientes:",
  tycPrivacyPoliticyTyCText42:
    "- Derecho de acceso: Conocer qué tipo de datos estamos " +
    "tratando y las características del tratamiento que estamos " +
    "llevando a cabo.",
  tycPrivacyPoliticyTyCText43:
    "- Derecho de rectificación: Poder solicitar la " +
    "modificación de sus datos por ser éstos inexactos o no " +
    "veraces.",
  tycPrivacyPoliticyTyCText44:
    "- Derecho de portabilidad: Poder obtener una copia en un " +
    "formato interoperable de los datos que estén siendo " +
    "tratados.",
  tycPrivacyPoliticyTyCText45:
    "- Derecho a la limitación del tratamiento en los casos " +
    "recogidos en la Ley.",
  tycPrivacyPoliticyTyCText46:
    "- Derecho de supresión: Solicitar la supresión de sus " +
    "datos cuando el tratamiento ya no resulte necesario.",
  tycPrivacyPoliticyTyCText47:
    "- Derecho de oposición: Este derecho le permite, por " +
    "ejemplo, solicitar el cese en el envío de comunicaciones " +
    "comerciales.",
  tycPrivacyPoliticyTyCText48:
    "- Derecho a revocar el consentimiento prestado.",
  tycPrivacyPoliticyTyCText49:
    "Puede ejercitar sus derechos a través de alguno de los siguientes canales:",
  tycPrivacyPoliticyTyCText50:
    "- Dirección Postal: Vía Dublín nº 7 (Campo de las Naciones) 28070 Madrid (España)",
  tycPrivacyPoliticyTyCText51: "-Correo Electrónico: ",
  tycPrivacyPoliticyTyCText52:
    "Asimismo, todas las comunicaciones comerciales electrónicas " +
    "incluirán un mecanismo de baja o similar que le permitirá, " +
    "si así lo desea, no recibir nuevas comunicaciones.",
  tycPrivacyPoliticyTyCText53:
    "En la página web de la Agencia Española de Protección de " +
    "Datos (AEPD) puede encontrar una serie de modelos que le " +
    "ayudarán en el ejercicio de sus derechos. Asimismo, le " +
    "informamos tiene derecho a interponer una reclamación ante " +
    "la autoridad de control (en España, la AEPD) en caso de que " +
    "considere infringidos sus derechos.",
  tycPrivacyPoliticyTyCText54:
    "Para la prestación de determinados servicios puede ser " +
    "preciso que nos facilite los datos personales y de contacto " +
    "de terceras personas como, p.ej., el nombre, apellidos, " +
    "dirección postal, dirección de correo electrónico, teléfono " +
    "de contacto y otros datos o medios equivalentes de contacto " +
    "del destinatario de una comunicación o envío.",
  tycPrivacyPoliticyTyCText55:
    "Adicionalmente, con el objetivo de la mejor prestación del " +
    "servicio, el número de teléfono o la dirección de correo " +
    "electrónico podrán ser utilizados con el objetivo de " +
    "informar al destinatario sobre la prestación del servicio y " +
    "tratar cualquier incidencia que pudiera afectar al mismo.",
  tycPrivacyPoliticyTyCText56:
    "En este sentido, el cliente garantiza la veracidad y " +
    "actualización de los datos del destinatario y, en su caso, " +
    "asegura haber cumplido con todas aquellas obligaciones que, " +
    "en materia de protección de datos, le resulten atribuibles, " +
    "habiendo obtenido el consentimiento del destinatario a que " +
    "Correos lleve a cabo el tratamiento expuesto en los párrafos " +
    "precedentes, en caso de ser tal consentimiento necesario.",
  tycPrivacyPoliticyTyCText57:
    "Por su parte, en caso de que Correos acceda a datos " +
    "personales que resulten necesarios para la prestación del " +
    "servicio de verificación de identidad, bajo la " +
    "responsabilidad de una empresa o profesional. Correos se " +
    "compromete a cumplir todas las obligaciones inherentes a su " +
    "posición como encargado de tratamiento y, en este sentido:",
  tycPrivacyPoliticyTyCText58:
    "Accederá y tratará los datos a los que tenga acceso sujeto " +
    "a sus instrucciones, no utilizándolos para finalidades " +
    "distintas de la prestación del servicio. Para ello, podrá " +
    "contar con los servicios de otros terceros " +
    "(subencargados), si resulta necesario para la prestación " +
    "del servicio y operaciones del contrato y/o para su " +
    "conservación y siempre que (i) el nuevo encargado quede " +
    "sujeto a las mismas condiciones (instrucciones, " +
    "obligaciones, medidas de seguridad…) y con los mismos " +
    "requisitos formales que él, en lo referente al adecuado " +
    "tratamiento de los datos personales y a la garantía de los " +
    "derechos de las personas afectadas; (ii) Correos a " +
    "solicitud expresa ponga a su disposición un listado en el " +
    " que se identifiquen los servicios subencargados y la " +
    "identidad de los subencargados; (iii) En caso de " +
    "incumplimiento por parte del subencargado, Correos " +
    "continúe siendo plenamente responsable.",
  tycPrivacyPoliticyTyCText59:
    "Implementará las medidas de seguridad legales, técnicas y " +
    "organizativas apropiadas para garantizar un nivel de " +
    "seguridad adecuado al riesgo, conforme a lo expuesto en el " +
    "art. 32 del RGPD y, a su solicitud, le informará de la " +
    "metodología de análisis de riesgo utilizada.",
  tycPrivacyPoliticyTyCText60:
    "Cumplida la prestación contractual, destruirá o devolverá " +
    "los datos tratados, así como también cualesquiera soportes " +
    "o documentos en que consten datos objeto del tratamiento, " +
    "sin perjuicio de la posibilidad de conservar estos datos, " +
    "debidamente bloqueados, en los términos previstos por " +
    "nuestra normativa de protección de datos.",
  tycPrivacyPoliticyTyCText61:
    "Asegurará el cumplimiento del resto de obligaciones " +
    "recogidas en la normativa de protección de datos.",

  //Cookies Politicy TyC Tittles

  tycCookiesPolicityTittle1: "¿Qué son las cookies?",
  tycCookiesPolicityTittle2: "¿Qué tipo de cookies existen?",
  tycCookiesPolicityTittle3: "¿Qué cookies concretas utiliza este sitio web?",
  tycCookiesPolicityTittle4: "¿Cómo modifico la configuración de las cookies?",

  //Cookies Politicy TyC Texts
  tycCookiesPolicityText1:
    "La Sociedad Estatal Correos y Telégrafos, S.A. (en adelante " +
    "“Correos”) te informa como usuario acerca de las cookies en " +
    "ésta página web. La presente Política de Cookies podrá ser " +
    "objeto de modificaciones, por lo que te recomendamos " +
    "consultarla cada vez que accedes.",
  tycCookiesPolicityText2:
    "Una cookie es un archivo o dispositivo que se descarga en tu " +
    "ordenador/smartphone/tablet al acceder a determinadas " +
    "páginas web para almacenar y recuperar información del " +
    "equipo terminal. Entre otras funciones, permite almacenar y " +
    "recuperar información sobre tus hábitos de navegación con el " +
    "fin de mejorar el servicio ofrecido. Una cookie es un " +
    "pequeño fichero de texto que un sitio web coloca en su PC, " +
    "teléfono o cualquier otro dispositivo, con información sobre " +
    "su navegación en dicho sitio. Las cookies son necesarias " +
    "para facilitar la navegación y hacerla más amigable, y no " +
    "dañan su ordenador.",
  tycCookiesPolicityText3:
    "Existen distintos tipos de cookies que se pueden clasificar según:",
  tycCookiesPolicityText4:
    "La entidad que gestiona el dominio desde donde se envían las cookies " +
    "y tratan los datos",
  tycCookiesPolicityText5:
    "Cookies propias: se envían a tu equipo terminal desde " +
    "nuestros equipos o dominios propios.",
  tycCookiesPolicityText6:
    "Cookies de terceros: se envían a tu equipo terminal desde " +
    "un equipo o dominio de otra entidad colaboradora.",
  tycCookiesPolicityText7:
    "El plazo de tiempo que permanecen almacenadas en el navegador",
  tycCookiesPolicityText8:
    "Cookies de sesión: se activan mientras accedes a la página " +
    "web o para la prestación del servicio solicitado.",
  tycCookiesPolicityText9:
    "Cookies persistentes: se almacenan por un tiempo " +
    "determinado en tu equipo terminal. El responsable tiene " +
    "acceso cuando te conectas a su página web.",
  tycCookiesPolicityText10:
    "La finalidad del tratamiento de los datos obtenidos",
  tycCookiesPolicityText11:
    "Cookies técnicas: son necesarias para el uso del sitio web " +
    "y para la prestación de servicios.",
  tycCookiesPolicityText12:
    "Cookies de personalización: permiten acceder al servicio " +
    "con unas algunas características de carácter general " +
    "predefinidas en función de una serie de criterios en tu " +
    "terminal (idioma, tipo de navegador…).",
  tycCookiesPolicityText13:
    "Cookies de análisis: permiten el seguimiento y análisis " +
    "estadístico del comportamiento del conjunto de los " +
    "usuarios de los sitios web a los que están vinculadas.",
  tycCookiesPolicityText14:
    "Cookies publicitarias: permiten la gestión de los espacios " +
    "publicitarios en la página web, aplicación o plataforma " +
    "desde la que se presta el servicio solicitado.",
  tycCookiesPolicityText15:
    "Cookies de publicidad comportamental: almacenan " +
    "información de tu comportamiento obtenida a través de los " +
    "hábitos de navegación, lo que permite desarrollar un " +
    "perfil específico para mostrar publicidad.",
  tycCookiesPolicityText16:
    "A continuación se identifican las cookies utilizadas en " +
    "nuestro sitio web así como su descripción, la duración de " +
    "almacenamiento y su titularidad.",
  tycCookiesPolicityText17:
    "Puedes prestar tu consentimiento al uso de cookies o " +
    "revocarlo en cualquier momento a través del enlace ",
  tycCookiesPolicityText18_B: "Configuración de Cookies.",
  tycCookiesPolicityText18:
    "También puedes hacerlo utilizando tu navegador, por el que " +
    "puedes permitir, restringir, bloquear o borrar las cookies " +
    "utilizadas desde nuestro Sitio Web.",
  tycCookiesPolicityText19:
    "La forma de hacerlo será diferente en función del tipo de " +
    "navegador utilizado durante la navegación. En los siguientes " +
    "enlaces tienes a tu disposición toda la información para " +
    "configurar o deshabilitar las cookies en cada navegador.",
  tycCookiesPolicityText20:
    "Internet Explorer: " +
    "microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=»ie-10″",
  tycCookiesPolicityText21: "Firefox: mozilla.org/es/kb/Borrar%20cookies",
  tycCookiesPolicityText22: "Chrome: google.com/chrome/answer/95647?hl=»es»",
  tycCookiesPolicityText23: "Safari: apple.com/es/privacy/use-of-cookies/",

  //Cookies Politicy TyC Tables Texts
  tycCookiesPolicityTableHead1: "Denominación",
  tycCookiesPolicityTableHead2: "Finalidad",
  tycCookiesPolicityTableHead3: "Duración",
  tycCookiesPolicityTableHead4: "Titular",
  //Table Tittles
  tycCookiesPolicityTableTittle1: "Cookies técnicas y de personalización",
  tycCookiesPolicityTableTittle2: "Cookies analíticas",
  tycCookiesPolicityTableTittle3: "Cookies de publicidad comportamental",
  //Table Text
  session: "Sesión",
  persistent: "Persistente",
  //Tale 1
  tycCookiesPolicityTable1_Text1:
    "Se utilizan para identificar y autenticar al " +
    "usuario. Contiene además datos técnicos de la " +
    "sesión de usuario como, por ejemplo, tiempo de " +
    "espera de onexión, identificador de sesión, etc.",
  tycCookiesPolicityTable1_Text2:
    "Identifica la sesión http del usuario. Es común " +
    "en todas las aplicaciones web para identificar " +
    "peticiones de un usuario en una sesión",
  tycCookiesPolicityTable1_Text3:
    "Permite identificar en qué estado de navegación " +
    "se encuentra el usuario (inicio de la sesión, " +
    "primera página, primer acceso, estado de un " +
    "scroll, estado de una votación, etc.).",
  tycCookiesPolicityTable1_Text4:
    "Almacenan los valores de sesión seleccionados " +
    "por el usuario tales como la tienda, el idioma, " +
    "la moneda, los productos, la talla, etc.",
  //Table 2 and 3
  tycCookiesPolicityDoesntExist: "No existen en Correos ID",

  // RGPDS
  rgpdIdentifierField: "Introduce: Teléfono o Email",
  rgpdSuccessChange: "Rgpds cambiados con éxito",
  rgpdappAdmSearchText: "Búsqueda de configuración de protección de datos por email o teléfono verificados",
  rgpdConfigTitle: "Protección de datos del usuario",
  // Advertising Cancellations  
  advcHeadOid: "Oid",
  advcHeadName: "Nombre",
  advcHeadSurnames: "Apellidos",
  advcHeadEmail: "Email",
  advcHeadPhone: "Teléfono",
  advcHeadApps: "Aplicaciones",
  advcHeadRgpds: "Rgpds Aceptados",
  advcTitle: "Bajas a efectos publicitarios",
  advcSubTitle: "Introduce un listado de emails/teléfonos para consultar la información referente a efectos publicitarios.",
  advcSubTitle2: "En el listado, los emails/teléfonos deben ir separados por punto y coma. Para los teléfonos, solo se introducirá el número sin prefijo (Ejemplo: 600600600). Puedes generar el listado utilizando la plantilla que tienes para descargar.",
  advcTableTitle: "Usuarios",
  advcTableRpp: "Usuarios por página",
  advcTableUnacceptButton: "BAJA RGPDS",
  advcTableUnacceptButtonToolTip: "Da de baja los RGPD de los usuarios seleccionados",
  advcTablePaginationOf: "de",
  advcTablePaginationMoreThan: "más que",
  advcTableSelected: "seleccionado",
  advcTableSelecteds: "seleccionados",
  advcTableSortedAsc: "ordenados ascendientemente",
  advcTableSortedDesc: "ordenados descendientemente",
  advcTableSelectAllUsersLabel: "selecciona todos los usuarios",
  advcTextareaPH: "Ejemplo: email@prueba.com;999999999;otro-email@prueba.com;888888888",
  advcDownload: "Descargar plantilla",

  // Users Export
  uexpTitle: "Exportación de usuarios",
  uexpSubTitle: "Puedes cargar un documento para rellenar los oid/eid de los usuarios incluidos en él. El formato aceptado será un archivo excel (*.xlsx) que puede contener varias hojas con una cabecera determinada. Puedes descargar la plantilla ",
  uexpFillOids: "Usuarios",
  uexpFillOidsEids: "Empresas",
  uexpSelectFile: "Selecciona el archivo a subir",
  uexpNoFileSelected: "Ningún archivo seleccionado",
  uexpGenerateDocument: "Generar Documento",
  uexpFileTooBig: "Archivo demasiado grande",
  uexpUnsupportedFileType: "Tipo de archivo no soportado",
  uexpDownloadTemplate: "aquí.",

  //ApplicationAdmin
  appAdmTitle: "Administración de la aplicación",
  appAdmSubtitle: "En esta pantalla se pueden establecer los endpoints de CorreosID para la aplicación, así como los atributos.",
  appAdmEndTitle: "Selección de endpoints individuales",
  appAdmEndTypeAll: "Lista de todos los endpoints",
  appAdmEndTypeApp: "Lista de endpoints de la aplicación",
  appAdmTableHeadController: "Controlador",
  appAdmTableHeadMethod: "Método",
  appAdmTableHeadUrl: "URL",
  appAdmSearchCriteriaTitle: "Criterio de búsqueda",
  appAdmSearchText: "Escribe aquí para realizar la búsqueda...",
  appAdmEndUpdateEndpointsMessage: "Se actualizaron los endpoints activos para la aplicación",
  appAdmEndGroupSelTitle: "Selección de endpoints por grupos",
  appAdmEndGroupSelSubtitle: "Selecciona un grupo de endpoints para asignarlos a la aplicación",
  appAdmEndGroupSelLabel: "Selecciona un grupo",
  appAdmEndGroupPopupTitle: "Confirma tu selección",
  appAdmEndGroupPopupText: "Vas a añadir el grupo de atributos '{group}' a {app}",
  appAdmEndGroupSuccess: "Grupo de endpoints añadido con éxito",

  //AttributeSelection
  appAdmAttrTitle: "Selección de atributos",
  appAdmAttrTypeApp: "Lista de atributos de la aplicación",
  appAdmAttrTypeAll: "Todos los atributos",
  appAdmAttrTypeIncl: "Atributos incluidos",
  appAdmAttrTypeNotIncl: "Atributos no incluidos",
  appAdmTableHeadName: "Nombre",
  appAdmTableHeadMandatory: "Obligatorio",
  appAdmTableHeadVerification: "Verificación",
  appAdmTableHeadRegistration: "Registro",
  appAdmTableHeadPersonType: "Tipo persona",
  appAdmTableSuccess: "Se actualizaron los atributos activos para la aplicación",

  //Verifitacions Page
  verificationsVerify: "Verificar",
  verificated: "Verificado",
  notVerificated: "No Verificado",
  pendingVerificated: "Pendiente",
  UserEmail: "Email",
  PersonaTelfMovil: "Teléfono",
  PersonaNumDocID: "Nº Documento",
  PersonaDireccion: "Dirección",
  verificationsSendCode: "Enviaremos un código de verificación a: ",
  verificationsConfirmCode: "Introduzca el código de verificación",
  verificationsVerificationType: "Tipo de verificación",
  verificationsConfirmPostCard:
    "Vamos a enviarle el código por correo postal, ¿seguro que quiere realizar este tipo de verificación?",
  verificationsCreate: "Introduzca los datos para añadir ",
  verificationsAlias: "Alias",
  verificationsCountry: "País o Región",
  verificationsDirection: "Dirección",
  verificationsPostalCode: "Código Postal",
  verificationsRegion: "Provincia/Región",
  verificationsTown: "Población",
  verificatinosDeleteElement: "¿Eliminar elemento?",
  verificationsVerifiedData: "Datos verificados",
  verificationsUserEmail: "Emails verificados",
  verificationsPersonaTelfMovil: "Teléfonos verificados",
  verificationsPersonaNumDocID: "Documentos verificados",
  verificationsPersonaDireccion: "Direcciones verificadas",
  verificationsEmpty: "Ninguno",
  verificationsAddEmail: "Email añadido correctamente",
  verificationsVerifyEmail: "Email verificado correctamente",
  verificationsDeleteEmail: "Email eliminado correctamente",
  verificationsAddPhone: "Teléfono añadido correctamente",
  verificationsVerifyPhone: "Teléfono verificado correctamente",
  verificationsDeletePhone: "Teléfono eliminado correctamente",
  verificationsDocuments: "Documentación",
  verificationsemail: "Email",
  verificationsphone: "Teléfono",
  verificationsaddress: "Dirección",
  verificationsnumber: "Documento identificativo",
  verificationsByOtp: "Verificado por código",
  verificationsByForm: "Verificado por formato",
  verificationsReSendOtp: "Código reenviado",
  verificationDisableAccountTitle: "Al darse de baja de Correos ID dejará de tener acceso a todos los servicios que se muestran a continuación:",
  verificationDisableAccountList: [
    "Borraremos tu cuenta*.",
  ],
  verificationDisableAccountP1: "Te aconsejamos que antes de eliminar tu cuenta de Correos ID solicites y finalices la baja en los servicios en los que estás registrado. Para ello, selecciona la opción Cancelar. Si aun así sigues prefiriendo eliminar ahora tu cuenta de Correos ID ahora, pincha en Dar de baja.",
  verificationDisableAccountP2: "*Tranquilo, tienes {disableAccDays} días para recuperar tu cuenta de Correos ID por si te arrepientes. Podrás recuperar la cuenta de Correos ID, pero el resto de servicios pueden no guardar tus datos durante estos {disableAccDays} días.",
  verificationDisableAccountP3: "Correos ID comunicará la eliminación de tu cuenta a los servicios asociados pero la eliminación de tu cuenta en cada servicio no es responsabilidad de Correos ID",
  verificationDisableAccountConfirm: "Dar baja",
  verificationsWrongTipeDocument: "El tipo de documento no es válido",
  verificationsUploadSuccess: "Documento subido con éxito",
  verificationsDeleteSuccess: "Documento eliminado con éxito",
  verificationsNoUploaded: "No subido",
  verificationsEmailExists: "El email introducido ya existe",
  verificationsPhoneExists: "El teléfono introducido ya existe",
  verificationsDocumentExists: "El documento introducido ya existe",

  //UserData
  userDataInfo:
    "Se ha realizado el registro correctamente en CorreosID, para completar el registro en la aplicación será necesario completar todos los datos obligatorios.",
  userDataVerifyEmailOk: "El email ha sido verificado correctamente",
  userDataVerifyEmailKo: "Ha habido un problema con la verificación del email",
  userDataVerifyPhoneOk: "El teléfono ha sido verificado correctamente",
  userDataVerifyPhoneKo: "Ha habido un problema con la verificación del teléfono",
  userDataInfoQuestion: "¿Seguro que desea cancelar la inserción de datos?",
  userDataRegister: "Cuenta creada correctamente",
  userDataExitInfo:
    "El usuario ya ha sido registrado, si sale sin informar los datos se le volverán a pedir cuando vuelva a acceder",
  userDataCopyDirection: "Copiar dirección",
  userDataSuccessUpdate: "Datos de usuario actualizados con éxito",
  userDataAddEmail: "Añadir email",
  userDataAddPhone: "Añadir teléfono",
  userDataSaveWarning: "Recuerde guardar los datos para que este cambio tenga efecto",
  userDataAddDirection: "¿Quiere añadir una dirección con los datos actuales?",
  userDataAddDirectionMandatorie: "Para poder añadir una dirección debe rellenar todos los campos obligatorios",
  userDataRequiredDirection: "El Alias y la Dirección son campos obligatorios",
  userDataAddDirectionSuccess: "Dirección añadida con éxito, no olvide guardar los cambios",
  userDataWarningMessage: "Recuerde que para guardar una nueva dirección antes debe añadirla a la lista, si no lo ha hecho todavía dele a Cancelar y añada la nueva dirección antes de continuar",
  userDataIncomplete: "Faltan datos obligatorios. Por favor, rellene los datos obligatorios que faltan.",
  userDataFNAError: "La fecha de nacimiento no puede ser posterior al día de hoy.",
  usersaveData: "Antes de guardar",
  userDataSelectOption: "Selecciona opción",
  userDataAddressUpdateWarning: "Es necesario actualizar esta dirección porque sus datos no son correctos",

  //Dialog subscribeapp
  dialogSubscribeAppCancel: "Cancelar",
  dialogSubscribeAppAccept: "Aceptar",
  dialogSubscribeAppNext: "Continuar",
  dialogSubscribeAppText: "Introduzca el código enviado para confirmar que el usuario acepta los TyC's, la Política de privacidad y que se suscriba a las aplicaciones seleccionadas",
  dialogSubscribeAppAskTitle: "Suscripciones adicionales a otras aplicaciones",
  dialogSubscribeAppAskText: "Para suscribir seleccione las aplicaciones deseadas y luego pulse en ACEPTAR, en caso contrario puede CONTINUAR",
  dialogSubscribeAppConfirmationCode: "Código de confirmación",
  dialogSubscribeAppInvalidCode: "Código no válido",
  dialogSubscribeAppErrorText: "Error al suscribirse a la/s aplicacion/es",
  dialogSubscribeAppResendCode: "REENVIAR",
  dialogSubscribeAppPhone: "Teléfono",
  dialogSubscribeAppEmail: "Email",

  //EnterpriseData
  enterpriseDataAddress: "Dirección",
  enterpriseDataAddWarning: "Dirección añadida con éxito, no olvide guardar los cambios",
  enterpriseDataAddSuccess: "Datos de empresa guardados con éxito",
  enterpriseDataConfirmIdentity: "Confirma tu identidad",
  enterpriseDataCreateConfirmOtp: "Confirme el código de verificación que le hemos enviado para crear la empresa",
  enterpriseDataSelectedUser: "Usuario",
  enterpriseDataOtpCode: "Código de validación",
  enterpriseDataCreate: "Crear",
  enterpriseDataTitle: "Datos de la empresa",
  enterpriseDataHint: "Introduce o modifica los datos de la empresa",

  //AdminAppInfo
  adminAppInfoUserAttributeListTitle: "Lista de atributos de usuario",
  adminAppInfoEnterpriseAttributeListTitle: "Lista de atributos de empresa",
  adminAppInfoColumnHeaderAttribute: "Atributo",
  adminAppInfoColumnHeaderMandatory: "Obligatorio",
  adminAppInfoColumnHeaderVerificationType: "Tipo de verificación",
  adminAppInfoApplicationWithOutAttributes: "Aplicación sin atributos configurados",


  //API Info

  apiConfig: "Configuración de aplicación",
  apiData: "Datos configurados por aplicación",
  apiEndpoints: "Endpoints configurados por aplicación",
  apiTypeApp: "Tipo aplicación:",
  apiTypeRegistry: "Tipo registro:",
  apiEditTyC: "Editar",
  apiHandleTyC: "Gestión TyC´s:",
  apiGestionYes: "Si",
  apiGestionNO: "No",
  apiController: "Controlador:",
  apiURL: "URL:",

  // Application Modification
  amodTitle: "Modificación de la aplicación",
  amodSubTitle: "Puedes modificar los datos de la aplicación tales como el nombre, la descripción, las imagenes de las pantallas de Correos ID, así como las Urls de redirección y del notificador. También puedes crear solicitudes de Integración, o de actualización de Términos y Condiciones/Privacidad, que serán enviadas al email de soporte de Correos ID.",
  amodUpdateNameMessage: "Se actualizó el nombre de la aplicación",
  amodUpdateDescriptionMessage: "Se actualizó la descripción de la aplicación",
  amodUpdateImagesMessage: "Se actualizaron las imágenes de la aplicación",
  amodUpdateRedirectMessage: "Se actualizó la URL de redirección de la aplicación",
  amodUpdateNotifierMessage: "Se actualizó la configuración del notificador de la aplicación",
  amodRequestSentMessage: "Se envió correctamente la solicitud al soporte de Correos ID",
  amodFileNotSupportedMessage: "El tipo de archivo no es válido",
  amodRedirectLabel: "Redireccion",
  amodRegistryLabel: "Registro",
  amodChangePassLabel: "Cambio de contraseña",
  amodLoginLabel: "Login",
  amodDataLabel: "Datos",
  amodUnsubscribeLabel: "Baja",
  amodClientIdLabel: "Client ID",
  amodClientSecretLabel: "Client Secret",
  amodUserLabel: "Particular",
  amodEnterpriseLabel: "Empresa",
  amodTycsLabel: "TyCs",
  amodPrivacyLabel: "Privacidad",
  amodMultipleFilesText: "Multiples Archivos",
  amodSendEmailText: "Enviar email",
  amodNotSetText: "(no establecido)",
  amodMultipleFilesSelectText: "Selecciona el/los archivo/s a subir",
  amodUserNotifierText: "Notificador de Usuario",
  amodEnterpriseNotifierText: "Notificador de Empresa",
  amodTycText: "Tycs",
  amodPrivacyText: "Privacidad",
  amodModAppappAdmTableHeadName: "Modificar nombre de la aplicación",
  amodModAppDescriptionTitle: "Modificar descripción de la aplicación",
  amodModAppImagesitle: "Modificar imágenes de la aplicación",
  amodModAppRedirectTitle: "Modificar url de redirección de la aplicación",
  amodModAppNotifierTitle: "Modificar la configuración del notificador",
  amodDataTabLabel: "Datos de la aplicación",
  amodRequestTabLabel: "Solicitudes a CorreosID",
  amodAppNameTableHeader: "Nombre de la aplicación",
  amodAppDescriptionTableHeader: "Descripción de la aplicación",
  amodAppImagesTableHeader: "Imágenes de la aplicación",
  amodAppRedirectTableHeader: "Enlace de redirección a la aplicación",
  amodAppNotifierTableHeader: "Configuración del notificador",
  amodAppTycTableHeader: "Tycs y Privacidad de la aplicación",
  amodAppIntegrationRequestTableHeader: "Solicitud de Integración",
  amodAppTycUpdateRequestTableHeader: "Solicitud de Cambio de Tycs/Privacidad",
  amodAppIntegrationRequestDescription: "Envia una solicitud de integración al soporte de Correos ID. Es necesario adjuntar el excel con los datos de integración",
  amodAppTycUpdateRequestDescription: "Envia una solicitud al soporte de CorreosID para actualizar los archivos de Términos y Condiciones y/o privacidad. Selecciona el tipo de persona, el tipo de archivos que se requieren actualizar (Tycs o Privacidad), y selecciona el/los archivos necesarios.",
  amodAllowedFileTypes: "Archivos permitidos: '*.html', '*.css'",
  amodRequiredFieldText: "Este campo es obligatorio",
  amodSupportRequestSubtitle: "Las solicitudes al soporte de CorreosID no se procesan de manera inmediata ya que es un proceso manual. Debes incluir un email de contacto donde se responderá a la solicitud.",
  amodDownloadIntegrationTemplate: "DESCARGAR PLANTILLA",

  // User Info

  userInfoTitle: "Búsqueda de usuario y verificación OTP",
  userInfoSubtitle: "Puedes buscar un usuario por email o teléfono, siendo este dato verificado o no, para obtener información extendida en el caso de que el dato esté verificado, o la fecha del último código OTP enviado, y la posibilidad de enviar un nuevo OTP en el caso de no estar verificado",
  userInfoOtpSent: "Se ha enviado el código OTP a {identifier}",
  userInfoNoSeted: "(no establecido)",
  userInfoDatosTabLabel: "Datos del usuario",
  userInfoDireccionesTabLabel: "Direcciones",
  userInfoEmailsTabLabel: "Emails",
  userInfoTeléfonosTabLabel: "Teléfonos",
  userInfoDocumentosTabLabel: "Documentos de identidad",
  userInfoSubscripcionesTabLabel: "Suscripciones",
  userInfoEmpresasTabLabel: "Empresas",
  userInfoCollapse: "Contraer",
  userInfoExpand: "Expandir",
  userInfoNoData: "Sin datos",
  userInfoAddresses: "Direcciones",
  userInfoSubscriptions: "Subscripciones",
  userInfoVerifyEmail: "Verificar Email",
  userInfoVerifyPhone: "Verificar Teléfono",
  userInfoSendOtp: "Enviar OTP",
  userInfoNoActiveAccounts: "Sin cuentas activas",
  userInfoFecOtpGeneration: "Fecha del último OTP",
  userInfoOtpCode: "Código OTP",
  userInfoUsed: "Usado",
  userInfoCancelledAccount: "Cuenta cancelada",
  userInfoVerifiedData: "Datos verificados del usuario",
  userInfoShowEmail: "Mostrar emails",
  userInfoShowPhone: "Mostrar teléfonos",
  userInfoSelectAccount: "Seleccionar cuenta",
  userInfoIdentifierField: "Introduce: teléfono o email",
  userInfoSelectAnAccount: "Selecciona una cuenta",
  userInfoAccountOid: "Datos de cuenta OID",
  userInfoPopupTitle: "Datos de contacto",
  userInfoPopupEmailTooltip: "Ver emails",
  userInfoPopupPhoneTooltip: "Ver teléfonos",
  userInfoPopupDocTooltip: "Ver documentos",
  
  // UserInfo

  uinfDialogTitle: "Verifica el dato {verificableData}",
  uinfDialogText: "Enviaremos un código de validación al identificador de la cuenta {accIdentifier}, y al dato a identificar {verificableData}",
  uinfVerifyEmail: "Verificar Email",
  uinfVerifyPhone: "Verificar Teléfono",

  // Administrador de usuarios

  userAdminTitle: "Administración de usuarios",
  userAdminText: "En esta pantalla se pueden administrar los usuarios. Busca el usuario que quieres a partir de su email o teléfono y selecciona la tarjeta de usuario que te interesa.",
  userAdminDelete: "Eliminar cuenta",
  userAdminUnsubscribe: "Cancelar",
  userAdminVerifyIdentity: "Dato a validar",
  userAdminVerify: "Verificar",
  userAdminVerifyStep1Title: "PASO 1/2: Confirmar identidad del usuario",
  userAdminVerifyStep1Text: "Enviaremos un código de validación al {dataType} de la búsqueda para verificar la identidad del usuario",
  userAdminVerifyStep2Title: "PASO 2/2: Verificación de dato",
  userAdminVerifyStep2Text: "Enviaremos un código de validación al {dataType} para confirmar que pertenece al usuario",

  // General Labels

  genLabel_id: "ID",
  genLabel_conTypeVerification: "Tipo de verificación",
  genLabel_country: "País",
  genLabel_userOid: "OID del usuario",
  genLabel_name: "Nombre", //Nombre
  genLabel_surname1: "Primer apellido", //Apellido1
  genLabel_surname2: "Segundo apellido", //Apellido2
  genLabel_birthDate: "Fecha de nacimiento", //fecha nacimiento
  genLabel_nationality: "Nacionalidad", //Nacionalidad
  genLabel_gender: "Género", //Sexo
  genLabel_fecCancelAccount: "Fecha de cancelación de cuenta",
  genLabel_language: "Idioma", //Observaciones
  genLabel_address: "Dirección",
  genLabel_address1: "Dirección 1",
  genLabel_alias: "Alias",
  genLabel_countryName: "País",
  genLabel_provinceName: "Provincia",
  genLabel_cityName: "Ciudad",
  genLabel_postalCode: "Código postal",
  genLabel_roadTypeName: "Tipo de calle",
  genLabel_favourite: "Favorito",
  genLabel_comment: "Comentario",
  genLabel_email: "Email",
  genLabel_communication: "Comunicación",
  genLabel_prefix: "Prefijo",
  genLabel_phone: "Teléfono",
  genLabel_document: "Documento",
  genLabel_typeUserDocument: "Tipo de documento",
  genLabel_number: "Número",
  genLabel_expirationDate: "Fecha de vencimiento",
  genLabel_subscription: "Suscripción",
  genLabel_idSubscriptionData: "ID de suscripción",
  genLabel_stateSubscription: "Estado de suscripción",
  genLabel_codeApp: "Código de aplicación",
  genLabel_nameApp: "Nombre de aplicación",
  genLabel_fecCreated: "Fecha de creación",
  genLabel_fecUpdated: "Fecha de actualización",
  genLabel_documentNumber: "Número de documento",
  genLabel_companyName: "Nombre de la empresa",
  genLabel_tradeName: "Nombre comercial",
  genLabel_professionalActivity: "Actividad profesional",
  genLabel_nameRol: "Nombre de rol",
  genLabel_codOtp: "Código OTP",
  genLabel_fecOtpGeneration: "Fecha del último OTP",
  genLabel_receiver: "Receptor",
  genLabel_used: "Usado",
  genLabel_userIdentity: "Identidad del usuario",

  // General Messages

  genMsg_noUser: "No se ha encontrado ninguna cuenta por ese campo",
  genMsg_noUserName: "El usuario no tiene nombre",
};
