import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Grid,
  Container,
  FormControl,
  Select,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { AtcSelectCreateAccountStyles } from "../AtcSelectCreateAccount/AtcSelectCreateAccountStyles";
import {
  GetMustRegistry,
  GetMasterTypeUserDocument,
  GetExists,
  GetRegistryAttribute,
} from "../../../services/ReasonService";
import { checkIsNullUndefined, validatePhone } from "../../../services/Utilities";
import { CommonStyles } from "../../../commons/CommonStyles";
import { getCodeApp } from "../../../services/Commons";
import { PrefixPhone } from "../../TransversalComponents/PrefixPhone/PrefixPhone";
import { EnumCountries, GroupActions, PathType } from "../../../commons/Enums";
import { DialogIdentityVerify } from "./components/DialogIdentityVerify";

export function AtcRegisterAccount(props) {
    
  const [country, setCountry] = useState({
    code: EnumCountries.ESP,
    id: 67,
    phonePrefix: 34,
  });
  const classes = AtcSelectCreateAccountStyles();
  const commonClasses = CommonStyles();
  const {
    data,
    changeStep,
    checkDNI,
    setCheckDNI,
    mustAttribute,
    setMustAttribute,
    countryList,
    previousPage,
    pathType,
    userSelectedData,
    setRegistryData,
    registryData
  } = props;
  const validator = require("react-email-validator");
  const [docTypes, setDocTypes] = useState([]);
  // Valor del campo email
  const [email, setEmail] = useState("");
  // valor del campo phoneNumber
  const [phoneNumber, setPhoneNumber] = useState("");
  // Valor del campo identiFierDocument (dni o pasaporte);
  const [identifierDocument, setIdentifierDocument] = useState("");
  // Flag para habilitar/deshabilitar el botón siguiente
  const [disableButtonNext, setDisableButtonNext] = useState(true);
  // Flag para controlar errores del campo Email
  const [manageEmailError, setManageEmailError] = useState();
  const [manageEmailErrorText, setManageEmailErrorText] = useState("");
  const [manageEmailDisabled, setManageEmailDisabled] = useState(false);
  // Flag para controlar errores del campo Teléfono
  const [managePhoneError, setManagePhoneError] = useState();
  const [managePhoneErrorText, setManagePhoneErrorText] = useState("");
  const [managePhoneDisabled, setManagePhoneDisabled] = useState(false);
  // Flag para controlar errores del campo IdentifierDocument
  const [manageIdentifierDocumentError, setManageIdentifierDocumentError] =
    useState();
  const [
    manageIdentifierDocumentErrorText,
    setManageIdentifierDocumentErrorText,
  ] = useState("");
  const [
    manageIdentifierDocumentDisabled,
    setManageIdentifierDocumentDisabled,
  ] = useState(false);
  //Flags para controlar que atributos son obligatorios
  const [mandatoryEmail, setMandatoryEmail] = useState(false);
  const [mandatoryPhone, setMandatoryPhone] = useState(false);
  const [mandatoryIdentifierDocument, setMandatoryIdentifierDocument] =
    useState(false);
  const [identifierDocumentType, setIdentifierDocumentType] = useState(
    !checkIsNullUndefined(data.identifierDocumentType)
      ? data.identifierDocumentType
      : "1"
  );
  const [circularProgress, setCircularProgress] = useState(true);
  const [emailExist, setEmailExist] = useState(false);
  const [phoneExist, setPhoneExist] = useState(false);
  const [documentExist, setDocumentExist] = useState(false);
  const [emailExistChecked, setEmailExistChecked] = useState(false);
  const [phoneExistChecked, setPhoneExistChecked] = useState(false);
  const [documentExistChecked, setDocumentExistChecked] = useState(false);
  const [UserGroupActions, setUserGroupActions] = useState([]);
  const [auxPreviousPage, setAuxPreviousPage] = useState();
  const [needsVerification, setNeedsVerification] = useState("");
  const [verificatedValues, setVerificatedValues] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setUserGroupActions(sessionStorage.getItem("UserGroupActions"));
  }, []);

  useEffect(() => {
    setAuxPreviousPage(previousPage);
    let getRegistryAttribute = GetRegistryAttribute(getCodeApp());
    let getMasterTypeUserDocument = GetMasterTypeUserDocument();
    let getMustRegistry = GetMustRegistry(getCodeApp());
    Promise.all([getRegistryAttribute, getMasterTypeUserDocument, getMustRegistry]).then(
      (results) => {
        let registryAttribute = results[0];
        let masterTypeUserDocument = results[1];
        let mustRegistry = results[2];
        setMustAttribute(registryAttribute);
        setDocTypes(masterTypeUserDocument);
        _manageMandatoryAttributes(mustRegistry);
      });
  }, []);

  useEffect(() => {
    if (emailExistChecked && phoneExistChecked && documentExistChecked) {
      if (!emailExist && !phoneExist && !documentExist) {
        if (!checkIsNullUndefined(needsVerification)) {
          setOpen(true);
        } else {
          continueSteps();
        }
      } else {
        setDisableButtonNext(true);
      }
      setEmailExistChecked(false);
      setPhoneExistChecked(false);
      setDocumentExistChecked(false);
    }
  }, [emailExistChecked, phoneExistChecked, documentExistChecked]);

  useEffect(() => {
    _handleButtonNextState();
  }, [
    email,
    phoneNumber,
    country,
    identifierDocument,
    identifierDocumentType,
    checkDNI,
  ]);

  const continueSteps = () => {
    setCheckDNI(false);
    changeStep(4, 3);
  }

  
  const _manageMandatoryAttributes = (auxAttributtes) => {
    let attEmail = auxAttributtes.find((attr) => attr.code === "EMA");
    let attPhone = auxAttributtes.find((attr) => attr.code === "MOV");
    let attIdnDoc = auxAttributtes.find((attr) => attr.code === "NDO");

    let mandatoryEmail = !checkIsNullUndefined(attEmail);
    let mandatoryPhone = !checkIsNullUndefined(attPhone);
    let mandatoryIdentifierDocument = !checkIsNullUndefined(attIdnDoc);

    setMandatoryEmail(mandatoryEmail);
    setMandatoryPhone(mandatoryPhone);
    setMandatoryIdentifierDocument(mandatoryIdentifierDocument);

    _manageFields(
      mandatoryEmail,
      mandatoryPhone,
      mandatoryIdentifierDocument,
    );
  };

  // Función para manejar si se muestran los campos o no
  const _manageFields = (
    mandatoryEmail,
    mandatoryPhone,
    mandatoryIdentifierDocument
  ) => {
    setIdentifierDocument("");
    setEmail("");
    setPhoneNumber("");

    let disabledByEmail = false;
    let disabledByPhone = false;
    let disabledByDocument = false;

    //Miramos si viene de modificar cuenta
    if (pathType !== PathType.creation) {
      let auxEmail = "";
      let auxPhone = "";
      let auxIdCountry = 67;
      let auxDocument = "";
      let auxIdDocType = "1";

      let verifiedEmails = checkIsNullUndefined(userSelectedData.userEmail)
        ? null
        : userSelectedData.userEmail.filter(
          (userEmail) => userEmail.idConTypeVerification === 2
        );
      let verifiedPhones = checkIsNullUndefined(userSelectedData.userPhone)
        ? null
        : userSelectedData.userPhone.filter(
          (userPhone) => userPhone.idConTypeVerification === 5
        );
      let verifiedDocuments = checkIsNullUndefined(
        userSelectedData.userDocument
      )
        ? null
        : userSelectedData.userDocument.filter(
          (userDocument) => userDocument.idConTypeVerification === 7
        );

      let auxUserEmail = null;
      let auxUserPhone = null;
      let auxUserDocument = null;
      let auxEmailHasVerified = !checkIsNullUndefined(verifiedEmails) && verifiedEmails.length > 0;
      let auxPhoneHasVerified = !checkIsNullUndefined(verifiedPhones) && verifiedPhones.length > 0;
      let auxIdDocHasVerified = !checkIsNullUndefined(verifiedDocuments) &&
        verifiedDocuments.length > 0;

      setManageEmailDisabled(auxEmailHasVerified);
      setManagePhoneDisabled(auxPhoneHasVerified);
      setManageIdentifierDocumentDisabled(auxIdDocHasVerified);
      setCheckDNI(auxIdDocHasVerified);
      if (auxEmailHasVerified && !auxPhoneHasVerified && !mandatoryEmail && mandatoryPhone) {
        setNeedsVerification("Email");
        setVerificatedValues(verifiedEmails);
      } else if (!auxEmailHasVerified && auxPhoneHasVerified && mandatoryEmail && !mandatoryPhone) {
        setNeedsVerification("Phone");
        setVerificatedValues(verifiedPhones);
      }
      //Miramos las cuentas de email verificadas del usuario
      //Miramos una serie de condiciones para establecer el email
      //Si no tiene ningun email verificado y el email es obligatorio deshabilitaremos el boton de siguente
      if (auxEmailHasVerified) {
        auxUserEmail = verifiedEmails.find(
          (userEmail) => userEmail.communication === true
        );

        //Comprobamos la cuenta principal
        if (!checkIsNullUndefined(auxUserEmail)) {
          auxEmail = auxUserEmail.email;
        } else {
          //Miramos si la cuenta por la que ha buscado la tiene verificada
          if (
            registryData.identifierType === "email" &&
            !checkIsNullUndefined(registryData.identifier)
          ) {
            auxUserEmail = verifiedEmails.find(
              (userEmail) => userEmail.email === registryData.identifier
            );

            if (!checkIsNullUndefined(auxUserEmail)) {
              auxEmail = auxUserEmail.email;
            } else {
              auxEmail = verifiedEmails[0].email;
            }
          } else {
            auxEmail = verifiedEmails[0].email;
          }
        }

        setEmail(auxEmail);
        setManageEmailError(false);
      } else {
        if (
          !checkIsNullUndefined(userSelectedData.userEmail) &&
          userSelectedData.userEmail.length > 0
        ) {
          if (
            registryData.identifierType === "email" &&
            !checkIsNullUndefined(registryData.identifier)
          ) {
            auxUserEmail = userSelectedData.userEmail.find(
              (userEmail) => userEmail.email === registryData.identifier
            );

            if (!checkIsNullUndefined(auxUserEmail)) {
              auxEmail = auxUserEmail.email;
            } else {
              auxEmail = userSelectedData.userEmail[0].email;
            }
          } else {
            auxEmail = userSelectedData.userEmail[0].email;
          }

          setEmail(auxEmail);
          setManageEmailError(false);
        } else {
          if (mandatoryEmail) {
            disabledByEmail = true;
          }
        }
      }

      //Repetimos el proceso del email para el telefono
      if (auxPhoneHasVerified) {
        auxUserPhone = verifiedPhones.find(
          (userPhone) => userPhone.communication === true
        );

        //Comprobamos la cuenta principal
        if (!checkIsNullUndefined(auxUserPhone)) {
          auxPhone = auxUserPhone.phone;
          auxIdCountry = auxUserPhone.idCountry;
        } else {
          //Miramos si la cuenta por la que ha buscado la tiene verificada
          if (
            registryData.identifierType === "phoneNumber" &&
            !checkIsNullUndefined(registryData.identifier)
          ) {
            auxUserPhone = verifiedPhones.find(
              (userPhone) => userPhone.phone === registryData.identifier
            );

            if (!checkIsNullUndefined(auxUserPhone)) {
              auxPhone = auxUserPhone.phone;
              auxIdCountry = auxUserPhone.idCountry;
            } else {
              auxPhone = verifiedPhones[0].phone;
              auxIdCountry = verifiedPhones[0].idCountry;
            }
          } else {
            auxPhone = verifiedPhones[0].phone;
            auxIdCountry = verifiedPhones[0].idCountry;
          }
        }

        setPhoneNumber(auxPhone);
        setManagePhoneError(false);

        let auxCountry = countryList.find((c) => c.id == auxIdCountry);
        setCountry(auxCountry);
      } else {
        if (
          !checkIsNullUndefined(userSelectedData.userPhone) &&
          userSelectedData.userPhone.length > 0
        ) {
          if (
            registryData.identifierType === "phoneNumber" &&
            !checkIsNullUndefined(registryData.identifier)
          ) {
            auxUserPhone = userSelectedData.userPhone.find(
              (userPhone) => userPhone.phone === registryData.identifier
            );

            if (!checkIsNullUndefined(auxUserPhone)) {
              auxPhone = auxUserPhone.phone;
              auxIdCountry = auxUserPhone.idCountry;
            } else {
              auxPhone = userSelectedData.userPhone[0].phone;
              auxIdCountry = userSelectedData.userPhone[0].idCountry;
            }
          } else {
            auxPhone = userSelectedData.userPhone[0].phone;
            auxIdCountry = userSelectedData.userPhone[0].idCountry;
          }

          setPhoneNumber(auxPhone);
          setManagePhoneError(false);

          let auxCountry = countryList.find((c) => c.id == auxIdCountry);
          setCountry(auxCountry);
        } else {
          if (mandatoryPhone) {
            disabledByPhone = true;
          }
        }
      }

      //Repetimos el proceso del telefono para el documento
      if (auxIdDocHasVerified) {
        //Miramos si la cuenta por la que ha buscado la tiene verificada
        if (
          registryData.identifierType === "identifierDocument" &&
          !checkIsNullUndefined(registryData.identifier)
        ) {
          auxUserDocument = verifiedDocuments.find(
            (userDocument) => userDocument.number === registryData.identifier
          );

          if (!checkIsNullUndefined(auxUserDocument)) {
            auxDocument = auxUserDocument.number;
            auxIdDocType = auxUserDocument.idTypeUserDocument;
          } else {
            auxDocument = verifiedDocuments[0].number;
            auxIdDocType = verifiedDocuments[0].idTypeUserDocument;
          }
        } else {
          auxDocument = verifiedDocuments[0].number;
          auxIdDocType = verifiedDocuments[0].idTypeUserDocument;
        }

        setIdentifierDocument(auxDocument);
        setIdentifierDocumentType(auxIdDocType);
        setDocumentExistChecked(true);
      } else {
        if (
          !checkIsNullUndefined(userSelectedData.userDocument) &&
          userSelectedData.userDocument.length > 0
        ) {
          if (
            registryData.identifierType === "identifierDocument" &&
            !checkIsNullUndefined(registryData.identifier)
          ) {
            auxUserDocument = verifiedDocuments.find(
              (userDocument) => userDocument.number === registryData.identifier
            );

            if (!checkIsNullUndefined(auxUserDocument)) {
              auxDocument = auxUserDocument.number;
              auxIdDocType = auxUserDocument.idTypeUserDocument;
            } else {
              auxDocument = userSelectedData.userDocument[0].number;
              auxIdDocType =
                userSelectedData.userDocument[0].idTypeUserDocument;
            }
          } else {
            auxDocument = userSelectedData.userDocument[0].number;
            auxIdDocType =
              userSelectedData.userDocument[0].idTypeUserDocument;
          }

          setIdentifierDocument(auxDocument);
          setIdentifierDocumentType(auxIdDocType);
        } else {
          if (mandatoryIdentifierDocument) {
            disabledByDocument = true;
          }
        }
      }
      checkInitialValues(auxEmail, mandatoryEmail, auxPhone, mandatoryPhone, auxDocument, mandatoryIdentifierDocument, auxIdDocHasVerified);
    } else {
      //Si no viene de modificar cuenta miramos el dato que ha buscado y lo deshabilitamos
      if (
        registryData.identifierType === "identifierDocument" &&
        !checkIsNullUndefined(registryData.identifier)
      ) {
        setIdentifierDocument(registryData.identifier);
        setManageIdentifierDocumentError(false);
      } else {
        if (mandatoryIdentifierDocument) {
          disabledByDocument = true;
        }
      }

      if (registryData.identifierType === "email" && !checkIsNullUndefined(registryData.identifier)) {
        setEmail(registryData.identifier);
        setManageEmailError(false);
      } else {
        if (mandatoryEmail) {
          disabledByEmail = true;
        }
      }

      if (
        registryData.identifierType === "phoneNumber" &&
        !checkIsNullUndefined(registryData.identifier)
      ) {
        setPhoneNumber(registryData.identifier);
        setManagePhoneError(false);
      } else {
        if (mandatoryPhone) {
          disabledByPhone = true;
        }
      }
      let disabledButton = true;
      if (!disabledByPhone && !disabledByDocument && !disabledByEmail) {
        disabledButton = false;
      }
      if (mandatoryIdentifierDocument) {
        disabledButton = true;
      }
      setDisableButtonNext(disabledButton);
    }
    setCircularProgress(false);
  };

  const checkInitialValues = (auxEmail, auxMandEmail, auxPhone, auxMandPhone, auxDoc, auxMandDoc, auxCheckDoc) => {
    let emailOk = auxMandEmail ? !checkIsNullUndefined(auxEmail) : true;
    let phoneOk = auxMandPhone ? !checkIsNullUndefined(auxPhone) : true;
    let docOk = auxMandDoc ? (!checkIsNullUndefined(auxDoc) && auxCheckDoc) : true;
    if (emailOk && phoneOk && docOk) {
      setDisableButtonNext(false);
    }
  }

  const _handleEmailExists = () => {
    let exists = false;
    let emailErrorText = "";

    if (!checkIsNullUndefined(email)) {
      GetExists(email, userSelectedData.oid).then((response) => {
        if (response) {
          exists = true;
          emailErrorText = spanishLanguage["verificationsEmailExists"];
        }

        setEmailExist(exists);
        setManageEmailError(exists);
        setManageEmailErrorText(emailErrorText);
        setEmailExistChecked(true);
      });
    } else {
      setEmailExist(exists);
      setManageEmailError(exists);
      setManageEmailErrorText(emailErrorText);
      setEmailExistChecked(true);
    }
  };

  const _handlePhoneExists = () => {
    let exists = false;
    let phoneErrorText = "";

    if (!checkIsNullUndefined(phoneNumber)) {
      let phone = country.phonePrefix + "-" + phoneNumber;
      GetExists(phone, userSelectedData.oid).then((response) => {
        if (response) {
          exists = true;
          phoneErrorText = spanishLanguage["verificationsPhoneExists"];
        }

        setPhoneExist(exists);
        setManagePhoneError(exists);
        setManagePhoneErrorText(phoneErrorText);
        setPhoneExistChecked(true);
      });
    } else {
      setPhoneExist(exists);
      setManagePhoneError(exists);
      setManagePhoneErrorText(phoneErrorText);
      setPhoneExistChecked(true);
    }
  };

  const _handleDocumentExists = () => {
    let exists = false;
    let docErrorText = "";
    if (!checkIsNullUndefined(identifierDocument)) {
      GetExists(identifierDocument, userSelectedData.oid).then((response) => {
        if (response) {
          exists = true;
          docErrorText = spanishLanguage["verificationsDocumentExists"];
        }
        setDocumentExist(exists);
        setManageIdentifierDocumentError(exists);
        setManageIdentifierDocumentErrorText(docErrorText);
        setDocumentExistChecked(true);
      });
    } else {
      setDocumentExist(exists);
      setManageIdentifierDocumentError(exists);
      setManageIdentifierDocumentErrorText(docErrorText);
      setDocumentExistChecked(true);
    }
  };

  const _handleEmail = (event) => {
    let auxEmail = event.target.value;
    let emailError = false;
    let emailErrorText = "";
    if (!checkIsNullUndefined(auxEmail)) {
      if (!validator.validate(auxEmail)) {
        emailError = true;
        emailErrorText = spanishLanguage["atcEmailError"];
      }
    } else if (mandatoryEmail) {
      emailError = true;
      emailErrorText = spanishLanguage["atcEmailMandatoryError"];
    }
    setEmailExistChecked(false);
    setManageEmailError(emailError);
    setManageEmailErrorText(emailErrorText);
    setEmail(auxEmail);
  };

  const _handleSelectorDocTypeChange = (event) => {
    validateDocumentNumber("type", null, event.target.value);
  };

  const _handleIdentifierDocument = (event) => {
    validateDocumentNumber("number", event.target.value, null);
  };

  const validateDocumentNumber = (
    validationType,
    recivedDocument,
    recivedDocumentType
  ) => {
    let isDocumentChange = validationType === "number";
    let auxDocumentNumber = isDocumentChange
      ? recivedDocument
      : identifierDocument;
    let auxDocumentType = !isDocumentChange
      ? recivedDocumentType
      : identifierDocumentType;
    let error = false;
    let message = "";
    let match = true;
    let auxCheckedDocument = false;
    if (
      mandatoryIdentifierDocument &&
      checkIsNullUndefined(auxDocumentNumber)
    ) {
      error = true;
      message = spanishLanguage["atcIdentifierDocumentMandatoryError"];
    } else if (
      !checkIsNullUndefined(auxDocumentNumber) &&
      checkIsNullUndefined(auxDocumentType)
    ) {
      error = true;
      message = spanishLanguage["atcIdentifierDocumentTypeError"];
    } else if (
      !(
        checkIsNullUndefined(auxDocumentType) ||
        checkIsNullUndefined(auxDocumentNumber)
      )
    ) {
      switch (auxDocumentType) {
        case "1":
        case 1:
          const checkDNI = new RegExp(/^[0-9]{8,8}[A-Za-z]$/);
          match = checkDNI.test(auxDocumentNumber);
          break;
        case "2":
        case 2:
          if (
            !(auxDocumentNumber.length < 20 && auxDocumentNumber.length > 0)
          ) {
            match = false;
          }
          break;
        case "3":
        case 3:
          const checkCIF = new RegExp(/^[XYZ]\d{7,8}[A-Z]$/);
          match = checkCIF.test(auxDocumentNumber);
          break;
        default:
          break;
      }
    }
    if (!match) {
      error = true;
      message = spanishLanguage["atcIdentifierDocumentError"];
    }
    if (isDocumentChange) {
      setIdentifierDocument(auxDocumentNumber);
    } else {
      let auxDocNumber = "";
      if(!checkIsNullUndefined(userSelectedData.userDocument)){
        let auxDocument = userSelectedData.userDocument.filter((doc) => doc.idTypeUserDocument == auxDocumentType);
        auxDocNumber = auxDocument.length == 0 ? "" : auxDocument[0].number;
        auxCheckedDocument = auxDocument.length > 0 && auxDocument[0].idConTypeVerification > 3;
        error = auxDocument.length == 0 ? true : false;
        message = auxDocument.length == 0 ? spanishLanguage["atcIdentifierDocumentMandatoryError"] : "";
      }else{
        error = true;
        message = spanishLanguage["atcIdentifierDocumentMandatoryError"];
      }
      setIdentifierDocumentType(auxDocumentType);
      setIdentifierDocument(auxDocNumber);
      setManageIdentifierDocumentDisabled(auxCheckedDocument);
    }
    setDocumentExistChecked(auxCheckedDocument);
    setManageIdentifierDocumentError(error);
    setManageIdentifierDocumentErrorText(message);
  };

  // controla el cambio en el selector de prefijo
  const handleSelectorChange = (auxCountry) => {
    _validatePhoneNumber("prefix", null, auxCountry);
  };

  const _handlePhoneNumber = (e) => {
    _validatePhoneNumber("phone", e.target.value, null);
  };

  // Maneja el valor del campo phoneNumber
  const _validatePhoneNumber = (
    validationType,
    recivedPhone,
    recivedCountry
  ) => {
    let isPhoneChange = validationType === "phone";
    let auxPhone = isPhoneChange ? recivedPhone : phoneNumber;
    let auxCountry = !isPhoneChange ? recivedCountry : country;
    let phoneError = false;
    let phoneErrorText = "";

    if (!checkIsNullUndefined(auxPhone)) {
      if (auxPhone.toString().length === 1) {
        phoneError = true;
        phoneErrorText = spanishLanguage["registerPagePhoneErrorShort"];
      } else if (auxCountry.id === 67 && auxPhone.toString().length > 9) {
        phoneError = true;
        phoneErrorText = spanishLanguage["registerPagePhoneErrorLong"];
      } else if (auxPhone.toString().length > 1) {
        if (validatePhone(auxPhone, auxCountry)) {
          phoneError = false;
          phoneErrorText = "";
        } else {
          phoneError = true;
          phoneErrorText = spanishLanguage["registerPagePhoneError"];
        }
      }
    } else if (mandatoryPhone) {
      phoneError = true;
      phoneErrorText = spanishLanguage["atcPhoneMandatoryError"];
    }
    setPhoneExistChecked(false);
    setManagePhoneError(phoneError);
    setManagePhoneErrorText(phoneErrorText);
    if (isPhoneChange) {
      setPhoneNumber(auxPhone);
    } else {
      setCountry(auxCountry);
    }
  };

  // Método que habilita/deshabilita el botón Siguiente
  const _handleButtonNextState = () => {
    let disabled = false;
    if (
      manageEmailError ||
      managePhoneError ||
      manageIdentifierDocumentError ||
      (mandatoryIdentifierDocument && !checkDNI)
    ) {
      disabled = true;
    }
    if (
      !disabled &&
      ((mandatoryEmail && checkIsNullUndefined(email)) ||
        (mandatoryPhone && checkIsNullUndefined(phoneNumber)) ||
        (mandatoryIdentifierDocument &&
          checkIsNullUndefined(identifierDocument)))
    ) {
      disabled = true;
    }
    setDisableButtonNext(disabled);
  };

  // Cambia al siguiente paso y guarda los datos necesarios para el siguiente paso en "props"
  const _handleNext = () => {
    let auxRegistryData = JSON.parse(JSON.stringify(registryData));
    auxRegistryData.prefixPhoneCountry = country;
    auxRegistryData.phoneNumber = phoneNumber;
    auxRegistryData.identifierDocument = identifierDocument;
    auxRegistryData.identifierDocumentType = identifierDocumentType;
    auxRegistryData.email = email;
    setRegistryData(auxRegistryData);
    _handleEmailExists();
    _handlePhoneExists();
    _handleDocumentExists();
  };

  const handlePrevious = () => {
    let goToPage = auxPreviousPage;
    if (auxPreviousPage !== 2 && auxPreviousPage !== 0) {
      goToPage = 0;
    }
    setCheckDNI(false);
    changeStep(goToPage, 0);
  };

  const handleCheckDNI = () => {
    let checkedDNI = !checkDNI;
    setCheckDNI(checkedDNI);
  };

  return (
    <Grid>
      {circularProgress ? (
        <CircularProgress />
      ) : (
        <Grid>
          <Container style={{ marginTop: "2em" }}>
            <Grid
              style={{ margin: "0 auto" }}
              container
              item
              justify="center"
              spacing={1}
              md={8}
              sm={9}
              xs={12}
            >
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{ marginBottom: "3vh" }}
              >
                <Typography style={{ color: "#666666" }}>
                  {spanishLanguage["atcRegisterAccountText"]}
                </Typography>
              </Grid>
              {mandatoryEmail || manageEmailDisabled ? <Grid container item md={12} sm={12} xs={12} spacing={1}> <Grid item md={12} sm={12} xs={12}>
                <TextField
                  autoComplete="nope"
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  label={spanishLanguage["atcRegisterPageEmail"]}
                  color="secondary"
                  onChange={_handleEmail}
                  value={email}
                  disabled={manageEmailDisabled}
                  required={mandatoryEmail}
                  error={manageEmailError}
                  helperText={manageEmailError ? manageEmailErrorText : ""}
                />
              </Grid></Grid> : null}

              {mandatoryPhone || managePhoneDisabled ? <Grid container item md={12} sm={12} xs={12} spacing={1}>
                <Grid item md={3} sm={4} xs={12}>
                  <PrefixPhone
                    country={country}
                    countryList={countryList}
                    handleSelectorChange={handleSelectorChange}
                    disabled={managePhoneDisabled}
                  />
                </Grid>

                <Grid item md={9} sm={8} xs={12}>
                  <TextField
                    className={classes.number}
                    autoComplete="nope"
                    variant="outlined"
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label={spanishLanguage["atcRegisterPagePhone"]}
                    color="secondary"
                    type="number"
                    value={phoneNumber}
                    onChange={_handlePhoneNumber}
                    disabled={managePhoneDisabled}
                    required={mandatoryPhone}
                    error={managePhoneError}
                    helperText={managePhoneError ? managePhoneErrorText : ""}
                  />
                </Grid>
              </Grid> : null}

              {mandatoryIdentifierDocument || manageIdentifierDocumentDisabled ? <Grid container item md={12} sm={12} xs={12} spacing={1}><Grid item md={3} sm={4} xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formSelectorControl}
                  color="secondary"
                  fullWidth
                >
                  <Select
                    native
                    id="documentTypeSelector"
                    name="documentTypeSelector"
                    fullWidth
                    value={identifierDocumentType}
                    onChange={_handleSelectorDocTypeChange}
                    disabled={manageIdentifierDocumentDisabled}
                  >
                    {docTypes
                      .sort((a, b) => (a.id > b.id ? 1 : -1))
                      .map((docType) => (
                        <option key={docType.code} value={docType.id}>
                          {docType.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

                <Grid item md={9} sm={8} xs={12}>
                  <TextField
                    autoComplete="nope"
                    variant="outlined"
                    fullWidth
                    id="identifierDocument"
                    name="identifierDocument"
                    label={spanishLanguage["atcRegisterPageDniNie"]}
                    color="secondary"
                    value={identifierDocument}
                    onChange={_handleIdentifierDocument}
                    disabled={manageIdentifierDocumentDisabled}
                    required={mandatoryIdentifierDocument}
                    error={manageIdentifierDocumentError}
                    helperText={
                      manageIdentifierDocumentError
                        ? manageIdentifierDocumentErrorText
                        : ""
                    }
                  />
                </Grid></Grid> : null}

              {mustAttribute.document.mustRegistry ? (
                <Grid>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkDNI}
                          onChange={handleCheckDNI}
                          name="checkDNI"
                        />
                      }
                      label={spanishLanguage["registerCheckDNI"]}
                    />
                  </FormGroup>
                </Grid>
              ) : null}
            </Grid>

            <Grid className={commonClasses.divButtons} item xs={12}>
              {/* {UserGroupActions.includes(GroupActions.RUS) ? ( */}
              <CorreosButton
                className={commonClasses.rightButton}
                variant="contained"
                color="primary"
                onClick={_handleNext}
                disabled={disableButtonNext}
              >
                {spanishLanguage["next"]}
              </CorreosButton>
              {/* ) : null} */}

              <CorreosButton
                onClick={handlePrevious}
                className={classes.nextButton}
              >
                {spanishLanguage["previous"]}
              </CorreosButton>
            </Grid>
          </Container>
        </Grid>
      )}
      <DialogIdentityVerify open={open} setOpen={setOpen} otpType={needsVerification} verificatedValues={verificatedValues} continueSteps={continueSteps} userOid={userSelectedData.oid} />
    </Grid>
  );
}
