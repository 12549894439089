import React, { useEffect, useState } from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { spanishLanguage } from "../../../config/language/spanishLanguage";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { EnterpriseData } from "../../EnterpriseData/EnterpriseData";

export function AtcEnterpriseProfile(props) {
  const commonClasses = CommonStyles();
  const {
    enterpriseId,
    setEnterpriseId,
    changeStep,
    previousPage,
    personType,
    pathType,
    registryData,
  } = props;

  const [allOk, setAllOk] = useState(false);
  const [next, setNext] = useState(false);

  useEffect(() => {}, []);

  const handleNext = (value) => {
    setNext(value);
  };

  const handlePrevious = () => {
    if (previousPage === 6) {
      changeStep(6, 5);
    } else {
      changeStep(5, 0);
    }
  };

  return (
    <Container>
      <Grid
        style={{ margin: "0 auto" }}
        container
        item
        justifyContent="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={12}>
          <Typography
            color="secondary"
            align="center"
            className={commonClasses.hitText}
          >
            {spanishLanguage["enterpriseDataHint"]}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <EnterpriseData
            enterpriseId={enterpriseId}
            setEnterpriseId={setEnterpriseId}
            setAllOk={setAllOk}
            handleNext={handleNext}
            next={next}
            personType={personType}
            pathType={pathType}
            registryData={registryData}
          ></EnterpriseData>
        </Grid>
      </Grid>
      <Grid
        style={{ margin: "auto" }}
        container
        item
        justifyContent="center"
        spacing={1}
        xs={12}
      >
        <Grid item xs={6} style={{ marginTop: "3vh" }}>
          <CorreosButton
            onClick={handlePrevious}
            className={commonClasses.leftButton}
          >
            {spanishLanguage["atcCancel"]}
          </CorreosButton>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "3vh" }}>
          <CorreosButton
            variant="contained"
            color="primary"
            onClick={() => handleNext(true)}
            className={commonClasses.rightButton}
            disabled={!allOk}
            circularProgress={next}
          >
            {spanishLanguage["save"]}
          </CorreosButton>
        </Grid>
      </Grid>
    </Container>
  );
}
