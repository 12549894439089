import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PutSystemuserOwner } from "../../../services/ReasonService";
import { UserSearcher } from "../../CommonComponents/UserSearcher";
import AutorenewIcon from '@material-ui/icons/Autorenew';

export function DialogChangeRelated(props) {
    const { finishOperation, systemUser, handleRelatedChange } = props;
    const dialogClasses = DialogStyles();
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const [circularProgress, setCircularProgress] = useState(false);
    const [relatedUser, setRelatedUser] = useState(null);
    const [relatedUserError, setRelatedUserError] = useState("");
    const [auxIdentifier, setAuxIdentifier] = useState("");
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => { }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setRelatedUser(null);
        setAuxIdentifier("");
        setIsChanged(false);
        setOpen(false);
    };

    const handleChange = () => {
        setCircularProgress(true);
        let userOid = relatedUser.oid;
        PutSystemuserOwner(systemUser.client_id, userOid).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("userSysAdminChangeRelatedSuccess"));
                handleRelatedChange(auxIdentifier);
                setIsChanged(true);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            setCircularProgress(false);
        });
    };

    const handleSetUser = (user, identifier, identifierType) => {
        let auxUserRelated = null;
        let auxError = false;
        let auxUserRelatedList = [];
        let auxUserList = JSON.parse(JSON.stringify(user));
        let conTypeVerification = { userEmail: 2, userPhone: 5, userDocument: 7 }
        let attrName = { userEmail: "email", userPhone: "phone", userDocument: "number" }
        auxUserList.forEach((auxUser) => {
            let auxObj = auxUser[identifierType].find((auxIdentifierObject) => auxIdentifierObject[attrName[identifierType]] == identifier);
            if (!checkIsNullUndefined(auxObj) && auxObj.idConTypeVerification == conTypeVerification[identifierType]) {
                auxUserRelatedList.push(auxUser);
            };
        });
        if (auxUserRelatedList.length == 1) {
            auxUserRelated = auxUserRelatedList[0];
        } else {
            auxError = true;
        };
        setAuxIdentifier(identifier);
        setRelatedUser(auxUserRelated);
        setRelatedUserError(auxError);
    };

    const getRelatedUserName = () => {
        let auxUserName = relatedUser.name;
        auxUserName += !checkIsNullUndefined(relatedUser.surname1) ? (" " + relatedUser.surname1) : "";
        auxUserName += !checkIsNullUndefined(relatedUser.surname2) ? (" " + relatedUser.surname2) : "";
        return auxUserName;
    };

    return (
        <>
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={handleOpen}
                style={{ float: "center", width: "100%" }}
            >
                <AutorenewIcon style={{ marginRight: "0.2em" }} />
                {t("userSysAdminChangeRelated")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userSysAdminChangeRelatedTitle")}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid item xs={12}>
                                    <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                        {t("userSysAdminChangeRelatedSubtitle")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} style={{ marginBottom: "1em" }}>
                                    <UserSearcher handleSetUser={handleSetUser} />
                                </Grid>
                                {checkIsNullUndefined(relatedUser) ? null : (
                                    <Grid item xs={12}>
                                        <Typography className={dialogClasses.dialogContentText}>
                                            <b>{t("userSysAdminCreateRelatedUser2") + ": "}</b><span>{getRelatedUserName()}</span>
                                        </Typography>
                                    </Grid>
                                )}
                                {!relatedUserError ? null : (
                                    <Grid item xs={12}>
                                        <Typography className={dialogClasses.dialogContentText}>
                                            {t("userSysAdminCreateRelatedUserError")}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    onClick={handleCancel}
                                    className={dialogClasses.dialogButtonLeft}
                                >
                                    {isChanged ? t("close") : t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleChange}
                                    className={dialogClasses.dialogButtonRight}
                                    circularProgress={circularProgress}
                                    disabled={checkIsNullUndefined(relatedUser)}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>
    );
};
