import React, { useRef, useEffect, useState } from "react";
import {
  Typography,
  Container,
  Grid,
  Paper,
  Button,
  Tooltip,
  IconButton,
  Popper,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import {
  DeleteVerificationUserEmail,
  DeleteVerificationUserPhone,
  GetMasterCountry,
} from "../../../services/ReasonService";
import DeleteIcon from "@material-ui/icons/Delete";
import { getErrorMessage, useOutsideAlerter } from "../../../services/Utilities";
import { spanishLanguage } from "../../../config/language/spanishLanguage";

export function DialogDelete(props) {
  const {
    finishOperation,
    data,
    removeAttributes,
    handleOpenDelete,
    attribute,
    disableDeleteEmailBtn,
    disableDeleteTlfBtn,
  } = props;
  const commonClasses = CommonStyles();
  const wrapperRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {}, []);

  const handleDelete = (event) => {
    handleOpenDelete(attribute.fieldCode)
    setAnchorEl(event.currentTarget);
    setOpenDelete(true);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    switch (data.type) {
      case "EMA":
        if (data.isNew === true) {
          removeAttributes(data);
          finishOperation(
            "success",
            spanishLanguage["verificationsDeleteEmail"]
          );
          handleCancel();
        } else {
          DeleteVerificationUserEmail(data.value).then((response) => {
            if (response && !response.Status) {
              removeAttributes(data);
              finishOperation(
                "success",
                spanishLanguage["verificationsDeleteEmail"]
              );
              handleCancel();
            } else {
              finishOperation("error", getErrorMessage(response));
            }
          });
        }
        break;
      case "MOV":
        if (data.isNew === true) {
          removeAttributes(data);
          finishOperation(
            "success",
            spanishLanguage["verificationsDeletePhone"]
          );
          handleCancel();
        } else {
          GetMasterCountry().then((response) => {
            if (response && !response.Status) {
              let auxPrefix;
              response.forEach((country) => {
                if (country.id === data.idCountry) {
                  auxPrefix = country.phonePrefix;
                }
              });
              DeleteVerificationUserPhone(auxPrefix + "-" + data.value).then(
                (response) => {
                  if (response && !response.Status) {
                    removeAttributes(data);
                    finishOperation(
                      "success",
                      spanishLanguage["verificationsDeletePhone"]
                    );
                    handleCancel();
                  } else {
                    finishOperation("error", getErrorMessage(response));
                  }
                }
              );
            }
          });
        }
        break;
      default:
        break;
    }
  };

  useOutsideAlerter(wrapperRef, handleCancel);

  return (
    <Grid>
      <Tooltip title={spanishLanguage["delete"]}>
        <IconButton
          edge="inherit"
          className={commonClasses.iconActionStyle}
          color="inherit"
          aria-label="menu"
          disabled={
            attribute.fieldCode === "EMA"
              ? disableDeleteEmailBtn
              : disableDeleteTlfBtn
          }
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Popper
        id={"deletePopper"}
        open={openDelete}
        anchorEl={anchorEl}
        onClose={handleCancel}
        placement={"top"}
        style={{ zIndex: 100 }}
        ref={wrapperRef}
      >
        <Paper className={commonClasses.popperContainer}>
          <Container>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                <Typography className={commonClasses.popperContentText}>
                  {spanishLanguage["verificatinosDeleteElement"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  onClick={() => handleCancel()}
                  className={commonClasses.leftButton}
                  id={"dialogDeleteUserData"}
                >
                  {spanishLanguage["no"]}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <CorreosButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleConfirm()}
                  className={commonClasses.rightButton}
                >
                  {spanishLanguage["yes"]}
                </CorreosButton>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Popper>
    </Grid>
  );
}
