import React from "react";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { StylesCorreosButton } from "./StylesCorreosButton";

export function CorreosButton(props) {
  const classes = StylesCorreosButton();
  const { circularProgress, children, className, width, ...other } = props;
  return (
    <Grid style={{ width: width }}>
      {circularProgress ? (
        <CircularProgress className={className ? className : ""} />
      ) : (
        <Button
          className={classes.correosButton + " " + (className ? className : "")}
          {...other}
        >
          {children}
        </Button>
      )}
    </Grid>
  );
}
