import { makeStyles } from "@material-ui/core/styles";

export const UserAdminStyles = makeStyles((theme) => ({

  marginBottom: {
    marginBottom: "5em",
    color: "#002453",
  },

  title: {
    marginTop: "1em",
    color: "#002453",
    marginLeft: "auto",
    marginRight: "auto",
  },

  buttonSearch: {
    float: "center",
    marginLeft: "2em"
  },

  notSetText: {
    fontStyle: "italic",
    color: "#CBCBCB"
  },

  allowedFileTypesText: {
    fontStyle: "italic",
    color: "#002453"
  },

  cardStyles: {
    width: "85%",
    paddingTop: "2em",
    paddingBottom: "2em",
    marginTop: "2em",
    // marginLeft: "1em",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      height: "20em",
      width: "auto",
      margin: "auto",
      // marginLeft: "1em",
      flexDirection: "column",

    },

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "auto",
      marginTop: "1em",
    }
  },

  selectAccount: {
    position: 'absolute',
    bottom: "1em",
    right: "1em",
  },

  titleText: {
    textAlign: "left",
    color: "#002453",
    marginLeft: "2em",
  },

  iconButton: {
    float: "left",
    color: "#002453",
  },

  accountDataContainer: {
    alignContent: "center",
    alignItems: "center",
    height: "3em"
  },

  accountDataIconGrid: {
    textAlign: "left",
    marginLeft: "2em"
  },

  cancelledText: {
    /* Estilos para el texto de "Cuenta Cancelada" en la esquina superior derecha */
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#F32735',
    color: 'white',
    padding: '5px 10px',
    opacity: 0.7,
  },

  greenText: {
    color: "#348F41",
    fontWeight: "bold",
  },

  orangeText: {
    color: "#DBB000",
    fontWeight: "bold",
  },

  redText: {
    color: "#F32735",
    fontWeight: "bold",
  },

  rowAlign: {
    display: "flex",
    alignItems: "center",
    padding: "1em 2em",
    width: "100%",
    borderBottom: "1px solid #E0E0E0",
    borderTop: "1px solid #E0E0E0",
  },

  rowTitle: {
    color: "#002453",
    fontSize: "1.2em",
  },

  iconActionStyle: {
    color: "#333333",
  },
}));
