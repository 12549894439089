import {
  GetMasterProvince,
  GetMasterCityProvince,
  GetMasterPostalCodeProvince,
  GetMasterProvincePostalCode,
  GetMasterCityPostalCode,
  GetMasterPostalCode,
} from "../services/ReasonService.js";
import { checkIsNullUndefined } from "../services/Utilities";
import { EnumCountries } from "../commons/Enums";
import { spanishLanguage } from "../config/language/spanishLanguage";

//Funcionamiento conjunto al introducir algún valor
export const ManageAddressRelation = (
  editableDirections,
  activeDirection,
  usableModel,
  trigger,
  setAll,
  value,
  finishOperation
) => {
  let auxCountry = { value: editableDirections[activeDirection].countryCode };
  let auxProv = { value: editableDirections[activeDirection].provinceCode };
  let auxCity = { value: editableDirections[activeDirection].cityCode };
  let auxPoCo = { value: editableDirections[activeDirection].postalCode };
  usableModel[1].forEach((attribute) => {
    if (attribute.fieldCode === "PRO" || attribute.fieldCode === "EPR") {
      auxProv.dropdownValues = attribute.dropdownValues;
      auxProv.originalValues = attribute.originalValues;
    }
    if (attribute.fieldCode === "POB" || attribute.fieldCode === "EPO") {
      auxCity.dropdownValues = attribute.dropdownValues;
      auxCity.originalValues = attribute.originalValues;
    }
    if (attribute.fieldCode === "CPO" || attribute.fieldCode === "ECP") {
      auxPoCo.dropdownValues = attribute.dropdownValues;
      auxPoCo.originalValues = attribute.originalValues;
    }
  });
  if (
    !checkIsNullUndefined(value) &&
    (((trigger !== "PAI" && trigger !== "EPI") && auxCountry.value == EnumCountries.ESP || auxCountry.value == EnumCountries.AND) ||
      ((trigger === "PAI" || trigger === "EPI") && value == EnumCountries.ESP || value == EnumCountries.AND))
  ) {
    if (trigger === "PAI" || trigger === "EPI") {
      auxCountry.value = value;
      auxProv.value = "";
      auxProv.dropdownValues = [];
      auxProv.originalValues = [];
      auxCity.value = "";
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
      auxPoCo.value = "";
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
      GetMasterProvince().then((provinces) => {
        let auxprovince = provinces;
        if (auxCountry.value == EnumCountries.AND) {
          auxprovince = provinces.filter((province) => province.provinceCode == "00");
        } else if (auxCountry.value == EnumCountries.ESP) {
          auxprovince = provinces.filter((province) => province.provinceCode != "00");
        }
        auxProv.dropdownValues = auxprovince;
        auxProv.originalValues = auxprovince;
        setAll(auxCountry, auxProv, auxCity, auxPoCo);
      });
    } else if (trigger === "PRO" || trigger === "EPR") {
      auxProv.value = value;
      GetMasterCityProvince(value, true).then((cities) => {
        auxCity.value = "";
        auxCity.dropdownValues = cities;
        auxCity.originalValues = cities;
        GetMasterPostalCodeProvince(value).then((postalCodes) => {
          let auxDropdownValues = postalCodes.filter(
            (postalCode, index, self) =>
              index ===
              self.findIndex(
                (item) => item.postalCode === postalCode.postalCode
              )
          );
          auxPoCo.value = "";
          auxPoCo.dropdownValues = auxDropdownValues;
          auxPoCo.originalValues = postalCodes;
          setAll(auxCountry, auxProv, auxCity, auxPoCo);
        });
      });
    } else if (trigger === "POB" || trigger === "EPO") {
      auxCity.value = value;
      let auxCityPostalCodes = auxPoCo.originalValues.filter((postalCode) => {
        return postalCode.cityCode == value;
      });
      auxPoCo.dropdownValues = auxCityPostalCodes;
      setAll(auxCountry, auxProv, auxCity, auxPoCo);
    } else if (trigger === "CPO" || trigger === "ECP") {
      auxPoCo.value = value;
      mandatoryPostalCode(false, auxCountry, auxProv, auxCity, auxPoCo, setAll);
    }
  } else {
    if (trigger === "PAI" || trigger === "EPI") {
      auxCountry.value = value;
      auxProv.value = "";
      auxProv.dropdownValues = [];
      auxProv.originalValues = [];
      auxCity.value = "";
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
      auxPoCo.value = "";
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    } else if (trigger === "PRO" || trigger === "EPR") {
      auxProv.value = value;
      auxCity.value = "";
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
      auxPoCo.value = "";
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    } else if (trigger === "POB" || trigger === "EPO") {
      auxCity.value = value;
      auxPoCo.dropdownValues = auxPoCo.originalValues;
    } else if (trigger === "CPO" || trigger === "ECP") {
      auxPoCo.value = value;
      auxCity.dropdownValues = auxCity.originalValues;
    }
    setAll(auxCountry, auxProv, auxCity, auxPoCo);
  }
};

//Para cargar por primera vez los valores que vienen prerrellenados o vacios
export const ManageAddressFirstIteration = (
  usableModel,
  setAll,
  auxEditableDir,
  auxFavourite,
  setIsControlledCountry,
  finishOperation
) => {
  let auxCountry = {};
  let auxProv = {};
  let auxCity = {};
  let auxPoCo = {};
  usableModel[1].forEach((attribute) => {
    if (attribute.fieldCode === "PAI" || attribute.fieldCode === "EPI") {
      auxCountry.value = attribute.value;
    }
    if (attribute.fieldCode === "PRO" || attribute.fieldCode === "EPR") {
      auxProv.value = attribute.value;
      auxProv.dropdownValues = [];
      auxProv.originalValues = [];
    }
    if (attribute.fieldCode === "POB" || attribute.fieldCode === "EPO") {
      auxCity.value = attribute.value;
      auxCity.dropdownValues = [];
      auxCity.originalValues = [];
    }
    if (attribute.fieldCode === "CPO" || attribute.fieldCode === "ECP") {
      auxPoCo.value = attribute.value;
      auxPoCo.dropdownValues = [];
      auxPoCo.originalValues = [];
    }
  });
  if (auxCountry.value == EnumCountries.ESP || auxCountry.value == EnumCountries.AND) {
    if (!checkIsNullUndefined(setIsControlledCountry)) {
      setIsControlledCountry(true);
    }
    GetMasterProvince().then((provinces) => {
      let auxprovince = provinces;
      if (auxCountry.value == EnumCountries.AND) {
        auxprovince = provinces.filter((province) => province.provinceCode == "00");
      } else if (auxCountry.value == EnumCountries.ESP) {
        auxprovince = provinces.filter((province) => province.provinceCode != "00");
      }
      auxProv.dropdownValues = auxprovince;
      auxProv.originalValues = auxprovince;
      if (!checkIsNullUndefined(auxPoCo.value)) {
        mandatoryPostalCode(
          true,
          auxCountry,
          auxProv,
          auxCity,
          auxPoCo,
          setAll,
          auxEditableDir,
          auxFavourite,
          usableModel,
          finishOperation
        );
      } else if (!checkIsNullUndefined(auxCity.value)) {
        if (!checkIsNullUndefined(auxProv.value)) {
          GetMasterCityProvince(auxProv.value).then((cities) => {
            auxCity.dropdownValues = cities;
            auxCity.originalValues = cities;
            GetMasterPostalCodeProvince(auxProv.value).then((postalCodes) => {
              auxPoCo.originalValues = postalCodes;
              let auxCityPostalCodes = auxPoCo.originalValues.filter(
                (postalCode) => {
                  return postalCode.cityCode == auxCity.value;
                }
              );
              auxPoCo.dropdownValues = auxCityPostalCodes;
              if (auxPoCo.dropdownValues.length === 1) {
                auxPoCo.value = auxPoCo.dropdownValues[0].postalCode;
              }
              setAll(
                auxCountry,
                auxProv,
                auxCity,
                auxPoCo,
                auxEditableDir,
                auxFavourite,
                usableModel
              );
            });
          });
        } else {
          setAll(
            auxCountry,
            auxProv,
            auxCity,
            auxPoCo,
            auxEditableDir,
            auxFavourite,
            usableModel
          );
        }
      } else {
        setAll(
          auxCountry,
          auxProv,
          auxCity,
          auxPoCo,
          auxEditableDir,
          auxFavourite,
          usableModel
        );
      }
    });
  } else {
    if (!checkIsNullUndefined(setIsControlledCountry)) {
      setIsControlledCountry(false);
    }
    setAll(
      auxCountry,
      auxProv,
      auxCity,
      auxPoCo,
      auxEditableDir,
      auxFavourite,
      usableModel
    );
  }
};

//Cuando el campo introducido es el código psotal
const mandatoryPostalCode = (
  first,
  auxCountry,
  auxProv,
  auxCity,
  auxPoCo,
  setAll,
  auxEditableDir,
  auxFavourite,
  usableModel,
  finishOperation
) => {
  GetMasterPostalCode(auxPoCo.value, true).then((postalCode) => {
    let auxPostalCode = postalCode[0].postalCode;
    GetMasterProvincePostalCode(auxPostalCode, true).then((province) => {
      let auxProvince = province[0].provinceCode;
      if (auxProv.value !== auxProvince || first) {
        auxProv.value = auxProvince;
        GetMasterPostalCodeProvince(auxProvince).then((postalCodes) => {
          let auxDropdownValues = postalCodes.filter(
            (postalCode, index, self) =>
              index ===
              self.findIndex(
                (item) => item.postalCode === postalCode.postalCode
              )
          );
          auxPoCo.dropdownValues = auxDropdownValues;
          auxPoCo.originalValues = postalCodes;
          GetMasterCityProvince(auxProvince).then((cities) => {
            auxCity.originalValues = cities;
            GetMasterCityPostalCode(auxPostalCode).then((cities1) => {


              cities1 = cities1.filter(e => e.cityCode === auxCity.value);
              auxCity.dropdownValues = cities1;
              checkCityExists(cities, auxCity, auxPoCo, auxProv, finishOperation);

              if(!checkIsNullUndefined(auxCity.value)){                
                let cityExists = cities.filter(x=> x.cityCode === auxCity.value).length == 1;
                if (cities1.length === 1 && cityExists) {
                  auxCity.value = cities1[0].cityCode;
                  let auxCityPostalCodes = [];
                  auxPoCo.originalValues.forEach((postalCode) => {
                    if (postalCode.cityCode === auxCity.value) {
                      auxCityPostalCodes.push(postalCode);
                    }
                  });
                  auxPoCo.dropdownValues = auxCityPostalCodes;
                }
              }
              setAll(
                auxCountry,
                auxProv,
                auxCity,
                auxPoCo,
                auxEditableDir,
                auxFavourite,
                usableModel
              );
            });
          });
        });
      } else {
        GetMasterCityPostalCode(auxPostalCode).then((cities) => {
          auxCity.dropdownValues = cities;
          if (cities.length === 1) {
            auxCity.value = cities[0].cityCode;
          }
          let auxCityPostalCodes = [];
          auxPoCo.originalValues.forEach((postalCode) => {
            cities.forEach((city) => {
              if (city.cityCode === postalCode.cityCode) {
                auxCityPostalCodes.push(postalCode);
              }
            });
          });
          auxPoCo.dropdownValues = auxCityPostalCodes;
          setAll(
            auxCountry,
            auxProv,
            auxCity,
            auxPoCo,
            auxEditableDir,
            auxFavourite,
            usableModel
          );
        });
      }
    });
  });
};

const checkCityExists = (cities, auxCity, auxPoCo, auxProvince, finishOperation) => {

  if(!checkIsNullUndefined(auxCity.value)){   

    if(cities.filter( x=> x.cityCode === auxCity.value).length < 1){
      auxCity.value = null;
      auxPoCo.value = null;
      auxProvince.value = null;
      finishOperation("warning", spanishLanguage["userDataAddressUpdateWarning"]);
    }
  }
}
