import React, { useEffect, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Divider,
  CircularProgress,
  Backdrop,
  Typography,
} from "@material-ui/core";
import {
  ManageAddressFirstIteration,
  ManageAddressRelation,
} from "../../commons/AddressManager";
import { CommonStyles } from "../../commons/CommonStyles";
import { EnterpriseDataStyles } from "./EnterpriseDataStyles";
import { TextBoxAttribute } from "../BuildInputs/TextBoxAttribute";
import { DatePickerAttribute } from "../BuildInputs/DatePicker";
import { DropdownAttribute } from "../BuildInputs/Dropdown";
import AutocompleteAttribute from "../BuildInputs/AutocompleteDropdown";
import {
  checkIsNullUndefined,
  getErrorMessage,
} from "../../services/Utilities";
import {
  GetApplicationAttributesEnterprise,
  GetProfileUser,
  GetEnterprise,
  GetMasterAttribute,
  GetMasterFieldType,
  PostEnterprise,
  PutEnterprise,
  GetEnterpriseSelect,
  PostNotificationWelcomeEmail,
  GetSubscriptionEnterprise,
  GetApplicationEnterpriseSubscription,
} from "../../services/ReasonService";
import { Attributes } from "../../commons/Attributes";
import {
  getApplicationOid,
  setUserSession,
  getCodeApp,
  moveToOtherApp,
} from "../../services/Commons";
import { Alerts } from "../Alerts/Alerts";
import { DialogConfirmAdd } from "../UserData/components/DialogConfirmAdd";
import { DialogConfirmNext } from "../UserData/components/DialogConfirmNext";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { EnterpriseDataObject } from "../../commons/EnterpriseDataObject";
import { AtcDialogSubscribeToApp } from "../Atc/AtcDialogSubscribeToApp/AtcDialogSubscribeToApp";
import { GroupActions, PathType, PersonType, EnumCountries } from "../../commons/Enums";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`attributes-tabpanel-${index}`}
      aria-labelledby={`attributes-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `attributes-tab-${index}`,
    "aria-controls": `attributes-tabpanel-${index}`,
  };
}

export function EnterpriseData(props) {
  const {
    enterpriseId,
    setEnterpriseId,
    setAllOk,
    handleNext,
    next,
    personType,
    pathType,
    registryData,
  } = props;
  const commonClasses = CommonStyles();
  const classes = EnterpriseDataStyles();

  const [originalEnterprise, setOriginalEnterprise] = useState({});
  // const [newIdEnterprise, setNewIdEnterprise] = useState(null);
  const [usableModel, setUsableModel] = useState([]);
  const [formGroups, setFormGroups] = useState([]);
  const [editableDirections, setEditableDirections] = useState([]);
  const [activeDirection, setActiveDirection] = useState();
  // const [openExitDialog, setOpenExitDialog] = useState(false);
  const [tab, setTab] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    color: "",
    message: "",
  });
  const [circularProgress, setCircularProgress] = useState(false);
  const [emptyDirection, setEmptyDirection] = useState();
  const [openAddDirection, setOpenAddDirection] = useState(false);
  const [showMessageInfo, setShowMessageInfo] = useState(false);
  const [openDialogAskSubscribeApp, setOpenDialogAskSubscribeApp] =
    useState(false);
  const [appListDialogAskSubscribeApp, setAppListDialogAskSubscribeApp] =
    useState([]);
  // const [requiredDocuments, setRequiredDocuments] = useState([]);
  const [UserGroupActions, setUserGroupActions] = useState([]);
  // const [disabledButtonAddDirEnterprise, setDisabledButtonAddDirEnterprise] = useState("Enterprise");
  const [needsAddress, setNeedsAddress] = useState(false);
  const [hassAddress, setHasAddress] = useState(false);
  const [isControlledCountry, setIsControlledCountry] = useState(true);
  const [auxDisabled, setAuxDisabled] = useState(false);

  useEffect(() => {
    preparePage();
    setUserGroupActions(sessionStorage.getItem("UserGroupActions"));
  }, []);


  useEffect(() => {
    checkAllOk();
  }, [editableDirections, usableModel]);


  useEffect(() => {

    if (next === true) {
      if (checkIsNullUndefined(enterpriseId)) {
        handleCreateEnterprise();
      } else {
        handleNextStep();
      }
    }
  }, [next]);

  const finishOperation = (color, message) => {
    setAlert({
      open: true,
      color,
      message,
    });
  };

  const preparePage = () => {
    let cont = 0;
    let auxiliarAttributeType = [];
    let auxiliarFieldType = [];
    setCircularProgress(true);
    //Obtenemos los campos necesarios a rellenar
    GetMasterAttribute().then((response) => {
      cont++;
      if (response && !response.Status) {
        auxiliarAttributeType = response;
        if (cont === 2) {
          chargeEnterpriseData(auxiliarAttributeType, auxiliarFieldType);
        }
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response));
        setCircularProgress(false);
      }
    });
    //Obtenemos los tipos de inputs que hay
    GetMasterFieldType().then((response) => {
      cont++;
      if (response && !response.Status) {
        auxiliarFieldType = response;
        if (cont === 2) {
          chargeEnterpriseData(auxiliarAttributeType, auxiliarFieldType);
        }
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response));
        setCircularProgress(false);
      }
    });
  };

  const chargeEnterpriseData = (auxiliarAttributeType, auxiliarFieldType) => {
    //Para empresa es 2
    GetApplicationAttributesEnterprise(getCodeApp()).then((response) => {
      // GetUtilitiesCorreosIdConfigApplication(2).then((response) => {
      if (response && !response.Status) {
        let completeForm = response;
        if (!checkIsNullUndefined(enterpriseId)) {
          // GetEnterpriseDataRole(idEnterprise).then((response2) => {
          //   if (response2 && !response2.Status) {
          //     //Comprobamos si el usuario es el titular (1)
          //     setIsTitular(response2 == 1);
          GetEnterprise().then((response1) => {
            if (response1 && !response1.Status) {
              // handleDirections(response1.enterpriseAddress, response1);
              setOriginalEnterprise(response1);
              prepareData(
                completeForm,
                auxiliarAttributeType,
                auxiliarFieldType,
                response1
              );
              setData(completeForm, response1);
            } else {
              //Alerta
              finishOperation("error", getErrorMessage(response1));
              setCircularProgress(false);
            }
          });
        } else {
          //autonomo
          if (personType === "freelance" && checkIsNullUndefined(enterpriseId)) {
            var freelanceData = EnterpriseDataObject;

            GetProfileUser().then((response1) => {
              if (response1 && !response1.Status) {
                //Nro Doc
                freelanceData.documentNumber = response1.userDocument[0].number;
                //Nombre empresa (nombre + apellidos)
                freelanceData.companyName =
                  response1.name +
                  " " +
                  response1.surname1 +
                  " " +
                  response1.surname2;
                //Fecha expiración doc
                freelanceData.expirationDate =
                  response1.userDocument[0].expirationDate;
                //País de expedición doc
                freelanceData.idCountry = response1.userDocument[0].idCountry;
                //Direcciones
                freelanceData.enterpriseAddress = response1.userAddress;
                //Pais a partir del pais de la dirección favorita
                let dirs = JSON.parse(JSON.stringify(response1.userAddress));
                let favDir = getFavouriteDirection(dirs);
                freelanceData.countryCode = dirs[favDir].countryCode;


                setOriginalEnterprise(freelanceData);
                prepareData(
                  completeForm,
                  auxiliarAttributeType,
                  auxiliarFieldType,
                  freelanceData
                );
                setData(completeForm, freelanceData);
                setCircularProgress(false);
              } else {
                setCircularProgress(false);
                //Alerta
                finishOperation("error", getErrorMessage(response1));
              }
            });
          } else {
            setOriginalEnterprise(EnterpriseDataObject);
            prepareData(
              completeForm,
              auxiliarAttributeType,
              auxiliarFieldType,
              null
            );
            setData(completeForm, {});
          }
        }
      } else {
        //Alerta
        finishOperation("error", getErrorMessage(response));
        setCircularProgress(false);
      }
    });
  };

  const prepareData = (
    completeForm,
    auxiliarAttributeType,
    auxiliarFieldType,
    enterpriseObject
  ) => {
    completeForm.forEach((attribute) => {
      let fieldInfo = obtainFieldType(
        attribute.codeAttribute,
        auxiliarAttributeType,
        auxiliarFieldType
      );
      attribute.fieldType = fieldInfo.fieldTypeName;
      attribute.fieldCode = fieldInfo.fieldTypeCode;
      fillValues(attribute, enterpriseObject);
      attribute.position = Attributes[fieldInfo.fieldTypeCode].position;
      attribute.disabled = pathType !== PathType.navigation
        ? false
        : !sessionStorage.getItem("UserGroupActions").includes(GroupActions.EEP)
          ? true
          : false;
    });
  };

  const setData = (completeForm, enterpriseObject) => {
    let orderAttributes = completeForm.sort(function (a, b) {
      return a.position - b.position;
    });
    //Guardamos un array con los distintos grupos
    var finalGroups = getGroups(orderAttributes);
    //Creamos un modelo que tiene los campos separados por grupos
    var finalModel = getFinalModel(orderAttributes, finalGroups);
    let auxModel = JSON.parse(JSON.stringify(finalModel));
    setUsableModel(JSON.parse(JSON.stringify(finalModel)));
    setFormGroups(finalGroups);

    if (!checkIsNullUndefined(finalModel[1])) {

      //Modelo de Direcciones
      let auxEnterpriseDirectionModel = finalModel[1];
      auxEnterpriseDirectionModel.sort(function (a, b) {
        return (
          Attributes[a.codeAttribute].position -
          Attributes[b.codeAttribute].position
        );
      });
      let auxAddressMandatorys = auxEnterpriseDirectionModel.filter(
        (attr) => attr.mandatory
      );

      let auxNeedAddress = auxAddressMandatorys.length > 0;
      setNeedsAddress(auxNeedAddress);

      if (auxNeedAddress && !checkIsNullUndefined(enterpriseObject.enterpriseAddress) && enterpriseObject.enterpriseAddress.length !== 0) {
        setHasAddress(true);
      }

      let auxEditableAddress = handleDirections(
        enterpriseObject.enterpriseAddress,
        auxEnterpriseDirectionModel
      );
      setAuxDisabled(true);
      ManageAddressFirstIteration(
        auxModel,
        setAll,
        auxEditableAddress,
        getFavouriteDirection(auxEditableAddress),
        null,
        finishOperation
      );
    }

    setCircularProgress(false);
  };

  const handleDirections = (addresses, addressElements) => {
    let auxiliaryAdresses = checkIsNullUndefined(addresses)
      ? []
      : JSON.parse(JSON.stringify(addresses));
    //Creamos una dirección vacía para los add
    let auxEmptyDirection = {};
    addressElements.forEach((value) => {
      auxEmptyDirection[Attributes[value.fieldCode].attributeName] = "";
    });
    auxEmptyDirection.id = 0;
    setEmptyDirection(auxEmptyDirection);
    auxiliaryAdresses.unshift(auxEmptyDirection);
    setEditableDirections(auxiliaryAdresses);
    setActiveDirection(getFavouriteDirection(auxiliaryAdresses));
    return auxiliaryAdresses;
  };

  const getFavouriteDirection = (addresses) => {
    let position = 0;
    if (addresses.length > 0) {
      addresses.forEach((address, index) => {
        if (address.favourite) {
          position = index;
        }
      });
    }
    return position;
  };

  const fillValues = (attribute, enterpriseObject) => {
    if (checkIsNullUndefined(enterpriseObject)) {
      if (attribute.fieldCode === "EAL") {
        attribute.options = [];
        attribute.dropdownValues = [];
        attribute.originalValues = [];
        attribute.actualize = true;
      }
      attribute.value = "";
    } else {
      let attributeName = Attributes[attribute.fieldCode].attributeName;
      switch (attribute.fieldCode) {
        case "EDR":
        case "ED1":
        case "EAL":
        case "EPI":
        case "EPR":
        case "EPO":
        case "ECP":
        case "EVI":
        case "ECM":
          fillMultiple(
            attribute,
            enterpriseObject,
            attributeName,
            "enterpriseAddress"
          );
          break;
        default:
          attribute.value =
            enterpriseObject[Attributes[attribute.fieldCode].attributeName];
          break;
      }
    }
  };

  const fillMultiple = (attribute, enterpriseObject, attributeName, option) => {
    if (attribute.fieldCode === "EAL") {
      attribute.options = enterpriseObject[option].map((attrib) => {
        return checkIsNullUndefined(attrib[attributeName])
          ? ""
          : attrib[attributeName];
      });
      let auxiliaryoptions = attribute.options.map((elem) => {
        return { code: elem, name: elem, id: elem };
      });
      attribute.dropdownValues = auxiliaryoptions;
      attribute.originalValues = auxiliaryoptions;
      attribute.actualize = true;
    }
    if (option === "enterpriseAddress") {
      let pos = getFavouriteDirection(enterpriseObject.enterpriseAddress);
      let auxiliarFavDirection = enterpriseObject.enterpriseAddress[pos];
      attribute.value = checkIsNullUndefined(auxiliarFavDirection)
        ? ""
        : auxiliarFavDirection[attributeName];
    } else {
      attribute.value =
        enterpriseObject[option].length > 0
          ? enterpriseObject[option][0][attributeName]
          : "";
    }
  };

  const obtainFieldType = (code, auxiliarAttributeType, auxiliarFieldType) => {
    let attributeTypeArray = auxiliarAttributeType.filter(
      (attributes) => attributes.code === code
    );
    let fieldTypeArray = auxiliarFieldType.filter(
      (types) => types.id === attributeTypeArray[0].idFieldType
    );
    let auxFieldTypeName = code === "EPO" ? "DropDown" : fieldTypeArray[0].name;
    return {
      fieldTypeName: auxFieldTypeName,
      fieldTypeCode: code,
    };
  };

  const getGroups = (model) => {
    var auxiliarGroups = [];
    model.forEach((element) => {
      if (auxiliarGroups.indexOf(element.nameGroup) === -1) {
        auxiliarGroups.push(element.nameGroup);
      }
    });
    return auxiliarGroups;
  };

  const getFinalModel = (model, groups) => {
    var auxiliarModel = [];
    groups.forEach((group, index) => {
      var auxiliarGroup = [];
      model.forEach((element) => {
        if (element.nameGroup === group) {
          element.auxiliarGroup = index;
          auxiliarGroup.push(element);
        }
      });
      auxiliarModel.push(auxiliarGroup);
    });
    return auxiliarModel;
  };

  const selectGenerator = (attribute) => {
    switch (attribute.fieldType) {
      case "Textbox":
      case "Numeric":
        return generateTextbox(attribute);
      case "DropDown":
        return generateDropdowns(attribute);
      case "Date":
        return generateDatePicker(attribute);
      default:
        break;
    }
  };

  const generateTextbox = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    return (
      <Grid
        key={attribute.nameGroup + "_" + attribute.nameAttritube}
        item
        md={fieldSize}
        xs={12}
      >
        <TextBoxAttribute
          attribute={attribute}
          saveGroupsChanges={saveGroupsChanges}
        />
      </Grid>
    );
  };

  const generateDropdowns = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    if (
      attribute.fieldCode === "ECP" ||
      attribute.fieldCode === "EPO" ||
      attribute.fieldCode === "EPR"
    ) {
      if (isControlledCountry) {
        return (
          <Grid key={attribute.nameAttritube} item md={fieldSize} xs={12}>
            <AutocompleteAttribute
              attribute={attribute}
              saveGroupsChanges={saveGroupsChanges}
              finishOperation={finishOperation}
              setAuxDisabled={setAuxDisabled}
            />
          </Grid>
        );
      }
    } else if (attribute.fieldCode === "EAL") {
      return (
        <Grid key={attribute.nameAttritube} item md={fieldSize} xs={12}>
          <AutocompleteAttribute
            attribute={attribute}
            saveGroupsChanges={saveGroupsChanges}
            handleChangeAlias={handleChangeAlias}
            finishOperation={finishOperation}
            setAuxDisabled={setAuxDisabled}
          />
        </Grid>
      );
    } else {
      return (
        <Grid key={attribute.nameAttritube} item md={fieldSize} xs={12}>
          <DropdownAttribute
            attribute={attribute}
            saveGroupsChanges={saveGroupsChanges}
            setIsControlledCountry={attribute.fieldCode === "EPI" ? setIsControlledCountry : null}
          />
        </Grid>
      );
    }
  };

  const generateDatePicker = (attribute) => {
    var fieldSize = Attributes[attribute.fieldCode].size;
    return (
      <Grid item key={attribute.attributeName} md={fieldSize} xs={12}>
        <DatePickerAttribute
          attribute={attribute}
          saveGroupsChanges={saveGroupsChanges}
        />
      </Grid>
    );
  };

  const saveGroupsChanges = (attribute, idValue, first) => {
    //Tiene que ser comillas simples por defecto para que funcione el input correctamente
    let auxValue = checkIsNullUndefined(idValue) ? "" : idValue;
    if (
      (attribute.fieldCode === "EPI" ||
        attribute.fieldCode === "EPR" ||
        attribute.fieldCode === "EPO" ||
        attribute.fieldCode === "ECP") &&
      first
    ) {
      setAuxDisabled(true);
      ManageAddressRelation(
        editableDirections,
        activeDirection,
        usableModel,
        attribute.fieldCode,
        setAll,
        idValue,
        finishOperation
      );
    } else if (attribute.fieldCode === "EAL") {
      if (!checkIsNullUndefined(idValue)) {
        let auxPosotion = changeDirectionsInfo(auxValue);
        usableModel[1].forEach((attribute) => {
          if (attribute.fieldCode === "EAL") {
            attribute.value = auxValue;
            attribute.actualize = true;
          } else {
            attribute.value = auxValue
              ? checkIsNullUndefined(
                editableDirections[auxPosotion][
                Attributes[attribute.fieldCode].attributeName
                ]
              )
                ? ""
                : editableDirections[auxPosotion][
                Attributes[attribute.fieldCode].attributeName
                ]
              : "";
          }
        });
        setAuxDisabled(true);
        ManageAddressFirstIteration(
          usableModel,
          setAll,
          editableDirections,
          auxPosotion,
          setIsControlledCountry,
          finishOperation
        );
      }
    } else {
      let auxiliarModel = JSON.parse(JSON.stringify(usableModel));
      auxiliarModel[attribute.auxiliarGroup].forEach((element) => {
        if (attribute.fieldCode === element.fieldCode) {
          element.value = auxValue;
        }
      });
      if (attribute.auxiliarGroup === 1) {
        //Guardamos los datos en objeto de direcciones editable
        let auxiliarEditableDirections = JSON.parse(
          JSON.stringify(editableDirections)
        );
        auxiliarEditableDirections[activeDirection][
          Attributes[attribute.fieldCode].attributeName
        ] = auxValue;
        setEditableDirections(auxiliarEditableDirections);
      }

      setUsableModel(auxiliarModel);

    }
  };

  const changeDirectionsInfo = (alias) => {
    let position = 0;
    editableDirections.forEach((direction, index) => {
      if (direction.alias === alias) {
        position = index;
      }
    });
    setActiveDirection(position);
    return position;
  };

  const handleChangeAlias = (alias) => {
    usableModel[1].forEach((attribute) => {
      if (attribute.fieldCode === "EAL") {
        attribute.value = alias;
        attribute.actualize = false;
      }
    });
    let auxEditableAddress = JSON.parse(JSON.stringify(editableDirections));
    auxEditableAddress[0] = editableDirections[activeDirection];
    auxEditableAddress[0].alias = alias;
    auxEditableAddress[0].id = 0;
    setEditableDirections(auxEditableAddress);
    setActiveDirection(0);
  };

  const handleAddDirection = () => {
    let auxiliarEditableDirections = JSON.parse(
      JSON.stringify(editableDirections)
    );
    let auxiliarModel = JSON.parse(JSON.stringify(usableModel));
    let auxAlias = checkIsNullUndefined(auxiliarEditableDirections[0].alias)
      ? spanishLanguage.enterpriseDataAddress +
      auxiliarEditableDirections.length
      : auxiliarEditableDirections[0].alias;
    auxiliarEditableDirections[0].alias = auxAlias;
    auxiliarModel[1].forEach((attribute) => {
      if (attribute.fieldCode === "EAL") {
        attribute.value = auxAlias;
        attribute.options.push(auxAlias);
        attribute.actualize = true;
        attribute.dropdownValues.push({
          code: auxAlias,
          name: auxAlias,
          id: auxAlias,
        });
      } else {
        auxiliarEditableDirections[0][Attributes[attribute.fieldCode].attributeName] = attribute.value;
      }
    });
    // setNoDirection(false);
    auxiliarEditableDirections.push(auxiliarEditableDirections[0]);
    let newActiveDirection = auxiliarEditableDirections.length - 1;
    setActiveDirection(newActiveDirection);
    auxiliarEditableDirections[0] = emptyDirection;
    setEditableDirections(auxiliarEditableDirections);
    setUsableModel(auxiliarModel);
    finishOperation("warning", spanishLanguage.enterpriseDataAddWarning);

    setHasAddress(true);
  };

  const mountEnterpriseObject = (auxEnterpriseId) => {
    let auxiliarAdresses = mountEnterpriseDirections();
    let enterpriseObject = originalEnterprise;
    usableModel[0].forEach((attribute) => {
      if (
        Attributes.PlaneEnterpriseAttributes.indexOf(attribute.fieldCode) >= 0
      ) {
        let value = checkIsNullUndefined(attribute.value)
          ? null
          : attribute.value;
        if (attribute.fieldCode === "EPA" || attribute.fieldCode === "ECY") {
          value = parseInt(value);
        }
        enterpriseObject[Attributes[attribute.fieldCode].attributeName] = value;
      }
    });
    enterpriseObject.enterpriseAddress = auxiliarAdresses;

    if (!checkIsNullUndefined(enterpriseId) || !checkIsNullUndefined(auxEnterpriseId)) {

      if (!checkIsNullUndefined(enterpriseId)) enterpriseObject.id = parseInt(enterpriseId);
      else if (!checkIsNullUndefined(auxEnterpriseId)) enterpriseObject.id = parseInt(auxEnterpriseId);
    }
    return enterpriseObject;
  };

  const mountEnterpriseDirections = () => {
    let auxiliarAdresses = editableDirections.map((auxiliarAdress) => {
      let address = {
        id: auxiliarAdress.id,
        address: auxiliarAdress.address,
        address1: auxiliarAdress.address1,
        alias: auxiliarAdress.alias,
        idCountry: getId(auxiliarAdress.idCountry),
        countryCode: checkIsNullUndefined(auxiliarAdress.countryCode) ? null : auxiliarAdress.countryCode,
        idProvince: getId(auxiliarAdress.idProvince),
        provinceCode: checkIsNullUndefined(auxiliarAdress.provinceCode) ? null : auxiliarAdress.provinceCode,
        idCity: getId(auxiliarAdress.idCity),
        cityCode: checkIsNullUndefined(auxiliarAdress.cityCode) ? null : auxiliarAdress.cityCode,
        idPostalCode: getId(auxiliarAdress.idPostalCode),
        postalCode: checkIsNullUndefined(auxiliarAdress.postalCode) ? null : auxiliarAdress.postalCode,
        idRoadType: getId(auxiliarAdress.idRoadType),
        roadTypeCode: checkIsNullUndefined(auxiliarAdress.roadTypeCode) ? null : auxiliarAdress.roadTypeCode,
        favourite: checkIsNullUndefined(auxiliarAdress.favourite)
          ? true
          : auxiliarAdress.favourite,
        comment: auxiliarAdress.comment,
      };
      return address;
    });
    //Quitamos la dirección vacia editable
    auxiliarAdresses.shift();
    return auxiliarAdresses;
  };

  const getId = (value) => {
    return checkIsNullUndefined(value) ? null : parseInt(value);
  };

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  const checkDirectionsAdded = () => {
    //Comprobamos si ha rellenado una dirección y no la ha añadido
    let auxShowWarning = false;
    let auxAliasList = [];
    let auxAlias = editableDirections[0] ? editableDirections[0].alias : null;
    if (!checkIsNullUndefined(auxAlias)) {
      usableModel[1].forEach((attribute) => {
        if (attribute.fieldCode === "EAL") {
          attribute.dropdownValues.forEach((value) => {
            auxAliasList.push(value.name);
          });
        }
      });
      if (auxAliasList.length > 0) {
        auxShowWarning = auxAliasList.indexOf(auxAlias) === -1;
      } else if (auxAliasList.length === 0) {
        auxShowWarning = true;
      }
    } else {
      auxShowWarning = true;
    }
    if (!auxShowWarning) {
      handleNextStep();
    } else {
      setShowMessageInfo(true);
    }
  };

  const handleCreateEnterprise = () => {
    let auxiliarData = mountEnterpriseObject();
    // handleNext(true);
    PostEnterprise(
      auxiliarData,
      getCodeApp(),
      registryData.identifier
    ).then((response) => {
      if (response && !response.Status) {
        setEnterpriseId(response);
        GetEnterpriseSelect(response).then((response1) => {
          if (response1 && !response.Status) {
            let appOid = getApplicationOid();
            setUserSession(response1, appOid).then(() => {
              finishOperation(
                "success",
                spanishLanguage.enterpriseDataAddSuccess
              );
              handleNextStep(response);
            });
          } else {
            handleNext(false);
            finishOperation("error", getErrorMessage(response));
          }
        });
      } else {
        //Alerta
        handleNext(false);
        finishOperation("error", getErrorMessage(response));
      }
    });
  };

  const handleNextStep = (auxEnterpriseId) => {
    // handleNext(true);
    if (UserGroupActions.includes(GroupActions.EEP)) {
      let auxiliarData = mountEnterpriseObject(auxEnterpriseId);
      PutEnterprise(auxiliarData, getCodeApp(), "true").then((response) => {
        if (response && (!response.Status || response.Status == 202)) {
          checkNextStep();
        } else {
          //Alerta
          finishOperation("error", getErrorMessage(response));
          handleNext(false);
        }
      });
    } else {
      checkNextStep();
    }
  };

  const checkNextStep = () => {
    // GetEnterpriseRequiredDocumentsApplication(getCodeApp()).then(
    //   (reqDocResponse) => {
    //     if (reqDocResponse && !reqDocResponse.Status) {
    //setIdEnterprise(eid); // TODO: Revisar esto si es necesario.
    // setRequiredDocuments(reqDocResponse);

    // Obtener las suscripciones de la empresa.
    if (UserGroupActions.includes(GroupActions.SUE)) {
      GetSubscriptionEnterprise().then((enterpriseSubsResponse) => {
        if (enterpriseSubsResponse && !enterpriseSubsResponse.Status) {
          // Obtener las aplicaciones al las que se puede suscribir.
          GetApplicationEnterpriseSubscription(getCodeApp()).then(
            (appSubsResponse) => {
              if (appSubsResponse && !appSubsResponse.Status) {
                let tmpAppList = [];

                // Comprobar a que aplicaciones no está suscrito o le falta firmar los TyC's.
                appSubsResponse.forEach((a) => {
                  if (
                    checkIsNullUndefined(
                      enterpriseSubsResponse.find(
                        (e) => e.applicationCode === a.destinationAppCode
                      )
                    ) ||
                    !checkIsNullUndefined(
                      enterpriseSubsResponse.find(
                        (e) =>
                          e.applicationCode === a.destinationAppCode &&
                          (e.idState === 1 || e.idState === 3) &&
                          !e.signedTyc
                      )
                    ) ||
                    !checkIsNullUndefined(
                      enterpriseSubsResponse.find(
                        (e) =>
                          e.applicationCode === a.destinationAppCode &&
                          e.idState === 2
                      )
                    )
                  ) {
                    tmpAppList.push(a);
                  }
                });

                if (tmpAppList.length > 0) {
                  setAppListDialogAskSubscribeApp(tmpAppList);
                  setOpenDialogAskSubscribeApp(true);
                } else {
                  handleSubscriptionNavigation();
                }
              } else {
                finishOperation("error", getErrorMessage(appSubsResponse));
                setCircularProgress(false);
                handleNext(false);
              }
            }
          );
        } else {
          finishOperation("error", getErrorMessage(enterpriseSubsResponse));
          setCircularProgress(false);
          handleNext(false);
        }
      });
    } else {
      handleSubscriptionNavigation();
    }
  };

  const handleSubscriptionNavigation = () => {
    if (pathType !== PathType.navigation) {
      // Mandar correo de bienvenida y navegar a applicación de usuario.
      PostNotificationWelcomeEmail(getCodeApp()).then(() => { });
    }
    moveToOtherApp();
  };

  const checkAllOk = () => {
    if (usableModel.length > 0) {

      let flag = true;

      usableModel[0].forEach((attribute) => {
        if (attribute.mandatory) {
          if (checkIsNullUndefined(attribute.value)) {
            flag = false;
          }
        }
      });

      if (flag && !checkIsNullUndefined(usableModel[1])) {
        let aliasAttribute = usableModel[1].find((attribute) => attribute.codeAttribute == "EAL");
        let countryAttribute = usableModel[1].find((attribute) => attribute.codeAttribute == "EPI");
        let isControlledCountry = countryAttribute.value == EnumCountries.ESP || countryAttribute.value == EnumCountries.AND;
        if (aliasAttribute.options.indexOf(aliasAttribute.value) != -1) {
          flag = checkMandatoriesAddress(isControlledCountry);
        }
      }

      if (flag && needsAddress && !hassAddress) {
        flag = false;
      }

      setAllOk(flag);
    }
  };

  const checkMandatoriesAddress = (isControlledCountry) => {
    let addressOk = true;
    // Para comprobar en direcciones si faltan campos obligatorios
    usableModel[1].forEach((element) => {
      if (element.mandatory && checkIsNullUndefined(element.value)) {
        if (isControlledCountry || (element.codeAttribute != "EPR" && element.codeAttribute != "EPO" && element.codeAttribute != "ECP")) {
          addressOk = false;
        }
      }
    });
    return addressOk;
  };

  const setAll = (
    auxCountry,
    auxProv,
    auxCity,
    auxPoCo,
    auxEditableDir,
    auxFavourite,
    auxUsableMod
  ) => {
    let editDirect = checkIsNullUndefined(auxEditableDir)
      ? editableDirections
      : auxEditableDir;
    let actDirect = checkIsNullUndefined(auxFavourite)
      ? activeDirection
      : auxFavourite;
    let usaMod = checkIsNullUndefined(auxUsableMod)
      ? usableModel
      : auxUsableMod;
    //Guardamos los datos en objeto de direcciones editable
    let auxiliarEditableDirections = JSON.parse(JSON.stringify(editDirect));
    auxiliarEditableDirections[actDirect][Attributes["EPI"].attributeName] =
      checkIsNullUndefined(auxCountry.value) ? "" : auxCountry.value;
    auxiliarEditableDirections[actDirect][Attributes["EPR"].attributeName] =
      checkIsNullUndefined(auxProv.value) ? "" : auxProv.value;
    auxiliarEditableDirections[actDirect][Attributes["EPO"].attributeName] =
      checkIsNullUndefined(auxCity.value) ? "" : auxCity.value;
    auxiliarEditableDirections[actDirect][Attributes["ECP"].attributeName] =
      checkIsNullUndefined(auxPoCo.value) ? "" : auxPoCo.value;
    setEditableDirections(auxiliarEditableDirections);
    let auxiliarModel = JSON.parse(JSON.stringify(usaMod));
    auxiliarModel[1].forEach((element) => {
      if (element.fieldCode === "EPI") {
        element.value = auxCountry.value;
      }
      if (element.fieldCode === "EPR") {
        element.value = checkIsNullUndefined(auxProv.value)
          ? ""
          : auxProv.value;
        element.dropdownValues = auxProv.dropdownValues;
        element.originalValues = auxProv.originalValues;
      }
      if (element.fieldCode === "EPO") {
        element.value = checkIsNullUndefined(auxCity.value)
          ? ""
          : auxCity.value;
        element.dropdownValues = auxCity.dropdownValues;
        element.originalValues = auxCity.originalValues;
      }
      if (element.fieldCode === "ECP") {
        element.value = checkIsNullUndefined(auxPoCo.value)
          ? ""
          : auxPoCo.value;
        element.dropdownValues = auxPoCo.dropdownValues;
        element.originalValues = auxPoCo.originalValues;
      }
    });

    setUsableModel(auxiliarModel);
    setAuxDisabled(false);
    setCircularProgress(false);
  };

  const getLastGrid = (group) => {
    let totalSize = 0;
    group.forEach((attribute) => {
      totalSize += Attributes[attribute.fieldCode].size;
    });
    let offSideSize = totalSize % 12;
    return <Grid item xs={offSideSize}></Grid>;
  };

  const mountTabText = (group, index) => {
    return index == 1 && needsAddress ? group + " *" : group;
  };

  return (
    <Grid item xs={12}>
      <Backdrop style={{zIndex:100, color: '#fff'}} open={auxDisabled}>
        <div>
          <CircularProgress color="inherit" />
          <Typography variant="h6" style={{ margin: "auto" }}>
            {spanishLanguage["charging"]}
          </Typography>
        </div>
      </Backdrop>
      {/* <Grid style={{ marginTop: "20px" }}>
        <Dialog
          maxWidth="xs"
          open={openExitDialog}
          onClose={() => setOpenExitDialog(false)}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle>
            <Typography>{t("enterpriseDataInfoQuestion")}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>{t("enterpriseDataExitInfo")}</Typography>
          </DialogContent>
          <DialogActions style={{ padding: "24px" }}>
            <Grid item sm={12} xs={12}>
              <CorreosButton
                onClick={() => setOpenExitDialog(false)}
                color="secondary"
                style={{ float: "left" }}
              >
                {t("cancel")}
              </CorreosButton>
              <CorreosButton
                variant="contained"
                color="primary"
                onClick={goToLogin}
                style={{ float: "right" }}
              >
                {t("accept")}
              </CorreosButton>
            </Grid>
          </DialogActions>
        </Dialog>
      </Grid> */}
      <DialogConfirmNext
        showMessageInfo={showMessageInfo}
        setShowMessageInfo={setShowMessageInfo}
        handleNext={handleNext}
      />
      <AtcDialogSubscribeToApp
        open={openDialogAskSubscribeApp}
        setOpen={setOpenDialogAskSubscribeApp}
        finishOperation={finishOperation}
        appList={appListDialogAskSubscribeApp}
        selectedOtpEmailOrPhone={registryData.identifier}
        handleSubscriptionNavigation={handleSubscriptionNavigation}
        enterpriseId={enterpriseId}
        personType={PersonType.EMPRESA}
        cancelPopup={handleNext}
      />
      <Alerts alert={alert} setAlert={setAlert}></Alerts>
      <Grid className={commonClasses.mainContainer}>
        {circularProgress ? (
          <CircularProgress className={commonClasses.mainContainer} />
        ) : (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Attributes Tab"
              >
                {formGroups.map((groupName, index) => {
                  return (
                    <Tab
                      key={"Tab" + groupName}
                      label={mountTabText(groupName, index)}
                      {...a11yProps(index)}
                      value={index}
                    />
                  );
                })}
              </Tabs>
            </Grid>
            <Divider></Divider>
            {usableModel.map((group, index) => {
              group = group.sort(function (a, b) {
                return a.position - b.position;
              });
              return (
                <TabPanel
                  key={"Panel" + group[0].nameGroup}
                  value={tab}
                  index={index}
                >
                  <Box style={{ marginTop: "2em" }}>
                    <Grid
                      container
                      item
                      justify="center"
                      spacing={1}
                      md={12}
                      xs={12}
                      className={classes.GridAttributes}
                    >
                      {group.map((attr) => {
                        return selectGenerator(attr);
                      })}
                      {getLastGrid(group)}
                    </Grid>
                  </Box>
                </TabPanel>
              );
            })}
            <Grid container item xs={12} className={commonClasses.marginTop1em}>
              <Grid item sm={4} xs={12}></Grid>
              <Grid item sm={4} xs={12}>
                {tab !== 0 ? (
                  <DialogConfirmAdd
                    addDirection={handleAddDirection}
                    usableModel={usableModel}
                    openAddDirection={openAddDirection}
                    setOpenAddDirection={setOpenAddDirection}
                    personTypeButtonAddDirection={"enterprise"}
                  />
                ) : null}
              </Grid>
              <Grid md={4} xs={12}></Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
