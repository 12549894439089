import React, { useEffect, useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
  Box
} from "@material-ui/core";
import { MainStyles } from "./MainStyles";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { setSelectedSubApp } from "../../services/Commons";
import { PageInfo } from "../../commons/Enums";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Main(props) {
  const { availablePages } = props;
  const history = useHistory();
  const classes = MainStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSelectedSubApp(null);
    if (availablePages.length == 1) {
      goToPage(availablePages[0]);
    }
  }, []);

  const goToPage = (page) => {
    history.push(page);
  };

  const mountButtons = () => {

    let auxPagesInfo = PageInfo(t, classes.iconStyle);

    return availablePages.map((page) =>
      <Grid container item md={4} key={page}>
        <Grid item xs={12}>
          <Button
            className={classes.cardButton}
            onClick={() => goToPage(page)}
          >
            <Card className={classes.cardStyle} elevation={4}>
              <CardContent className={classes.centerContent}>
                {auxPagesInfo[page]?.pageIcon}
                <Box className={classes.cardTextBoxStyle}>
                  <Typography className={classes.cardTextStyle}>
                    {auxPagesInfo[page]?.pageName}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid className={classes.container}>
      <Grid styles={{ margin: "auto" }}>
        <Container maxWidth={"lg"} className={classes.mainContainer} >
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ margin: "auto", textAlign: "center" }}>
              <Typography
                variant="h3"
                className={classes.mainContainerTitle}
              >
                {spanishLanguage.homeMainTitle}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              {mountButtons()}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};
