import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { TableStyles } from "../../../commons/CommonStyles/TableStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { PostSystemuser } from "../../../services/ReasonService";
import DialogAddName from "./DialogAddName";
import DialogAddSubscriptions from "./DialogAddSubscriptions";
import { UserSearcher } from "../../CommonComponents/UserSearcher";

export function DialogCreate(props) {
    const { openCreate, setOpenCreate, finishOperation, handleSearh, allPossibleApps } = props;
    const dialogClasses = DialogStyles();
    const tableClasses = TableStyles();
    const { t, i18n } = useTranslation();

    const [systemUserName, setSystemUserName] = useState("");
    const [circularProgress, setCircularProgress] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [systemUserData, setSystemUserData] = useState({});
    const [ownAllPossibleApps, setOwnAllPossibleApps] = useState([]);
    const [relatedUser, setRelatedUser] = useState(null);
    const [relatedUserError, setRelatedUserError] = useState("");

    useEffect(() => {
        let auxOwnAllPossibleApps = JSON.parse(JSON.stringify(allPossibleApps));
        auxOwnAllPossibleApps.forEach(app => {
            app.selected = false;
        });
        setOwnAllPossibleApps(auxOwnAllPossibleApps);
    }, [allPossibleApps]);

    const handleCancel = () => {
        setSystemUserName("");
        setSystemUserData({});
        setIsCreated(false);
        setOpenCreate(false);
        setRelatedUser(null);
    };

    const handleChangeApp = (value) => {
        setSystemUserName(value);
    };

    const handleCreate = () => {
        setCircularProgress(true);
        let auxSelectedApps = ownAllPossibleApps.filter((app) => app.selected);
        let auxSelectedAppsCodes = auxSelectedApps.map((app) => app.code);
        let userOid = relatedUser.oid;
        PostSystemuser(systemUserName, auxSelectedAppsCodes, userOid).then((response) => {
            if (response && !response.Status) {
                setSystemUserData(response);
                handleSearh(response.client_id);
                finishOperation("success", t("userSysAdminCreated"));
                setIsCreated(true);
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgress(false);
        });
    };

    const handleCreateAnother = () => {
        setIsCreated(false);
        setSystemUserName("");
        setRelatedUser(null);
    };

    const handleSubscribeToApps = (appList) => {
        setOwnAllPossibleApps(appList);
    };

    const handleSetUser = (user, identifier, identifierType) => {
        let auxUserRelated = null;
        let auxError = false;
        let auxUserRelatedList = [];
        let auxUserList = JSON.parse(JSON.stringify(user));
        let conTypeVerification = { userEmail: 2, userPhone: 5, userDocument: 7 }
        let attrName = { userEmail: "email", userPhone: "phone", userDocument: "number" }
        auxUserList.forEach((auxUser) => {
            let auxObj = auxUser[identifierType].find((auxIdentifierObject) => auxIdentifierObject[attrName[identifierType]] == identifier);
            if (!checkIsNullUndefined(auxObj) && auxObj.idConTypeVerification == conTypeVerification[identifierType]) {
                auxUserRelatedList.push(auxUser);
            };
        });
        if (auxUserRelatedList.length == 1) {
            auxUserRelated = auxUserRelatedList[0];
        } else {
            auxError = true;
        };
        setRelatedUser(auxUserRelated);
        setRelatedUserError(auxError);
    };

    const getRelatedUserName = () => {
        let auxUserName = relatedUser.name;
        auxUserName += !checkIsNullUndefined(relatedUser.surname1) ? (" " + relatedUser.surname1) : "";
        auxUserName += !checkIsNullUndefined(relatedUser.surname2) ? (" " + relatedUser.surname2) : "";
        return checkIsNullUndefined(auxUserName) ? t("genMsg_noUserName") : auxUserName;
    };

    const filterSelectedApps = () => {
        let auxSelectedApps = ownAllPossibleApps.filter((app) => app.selected);
        return auxSelectedApps;
    };

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={openCreate}
            onClose={handleCancel}
            PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
        >
            <Container className={dialogClasses.dialogTitle}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={dialogClasses.dialogTitleText}
                        >
                            {t("userSysAdminCreateTitle")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
            <Container className={dialogClasses.dialogContainer}>
                <Grid container spacing={1}>
                    {isCreated ? (
                        <Grid item xs={12}>
                            <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                {t("userSysAdminCreateData")}
                            </Typography>
                            <Grid item xs={12}>
                                <Typography className={dialogClasses.dialogContentText}>
                                    <b>{t("userSysAdminCreateClientId")}</b><span>{systemUserData.client_id}</span>
                                </Typography>
                                <Typography className={dialogClasses.dialogContentText}>
                                    <b>{t("userSysAdminCreateClientSecret")}</b><span>{systemUserData.client_secret}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container item xs={12}>
                            <Grid
                                container
                                item
                                xs={12}
                                style={{ marginBottom: "1em" }}
                            >
                                <Grid item xs={12}>
                                    <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                        {t("userSysAdminCreateGenSubtitle")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <DialogAddName handleChangeApp={handleChangeApp} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DialogAddSubscriptions allPossibleApps={allPossibleApps} handleSubscribeToApps={handleSubscribeToApps} />
                                </Grid>
                                {checkIsNullUndefined(systemUserName) ? null : (
                                    <Grid item xs={12} style={{ marginTop: "1em" }}>
                                        <Typography className={dialogClasses.dialogContentText}>
                                            <b>{t("userSysAdminCreateLabel") + ": "}</b><span>{systemUserName}</span>
                                        </Typography>
                                    </Grid>
                                )}
                                {filterSelectedApps().length == 0 ? null : (
                                    <Grid item xs={12} style={{ marginTop: "1em" }}>
                                        <TableContainer component={Paper} key={"TableShow"} className={tableClasses.customPaper}>
                                            <Table className={tableClasses.customTableRow}>
                                                <TableHead className={tableClasses.stickyTableHead}>
                                                    <TableRow >
                                                        <TableCell className={tableClasses.tableCellHeader} key={"headerShow"}>
                                                            {t("userSysAdminSubscribe")}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filterSelectedApps().map((app, index) => {
                                                        return (
                                                            <TableRow
                                                                key={"endpoint" + index}
                                                                className={tableClasses.tableRow}
                                                                component="div"
                                                            >
                                                                <TableCell className={tableClasses.tableCell}>
                                                                    <Grid className={tableClasses.tableCellText}>{app.name}</Grid>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                            >
                                <Grid item xs={12}>
                                    <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em" }}>
                                        {t("userSysAdminCreateRelatedUser")}
                                    </Typography>
                                </Grid>
                                <Grid container item xs={12} style={{ marginBottom: "1em" }}>
                                    <UserSearcher handleSetUser={handleSetUser} />
                                </Grid>
                                {checkIsNullUndefined(relatedUser) ? null : (
                                    <Grid item xs={12}>
                                        <Typography className={dialogClasses.dialogContentText}>
                                            <b>{t("userSysAdminCreateRelatedUser2") + ": "}</b><span>{getRelatedUserName()}</span>
                                        </Typography>
                                    </Grid>
                                )}
                                {!relatedUserError ? null : (
                                    <Grid item xs={12}>
                                        <Typography className={dialogClasses.dialogContentText}>
                                            {t("userSysAdminCreateRelatedUserError")}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={12}
                        className={dialogClasses.dialogButtonContainer}
                    >
                        <Grid item xs={6}>
                            <CorreosButton
                                onClick={handleCancel}
                                className={dialogClasses.dialogButtonLeft}
                            >
                                {isCreated ? t("close") : t("cancel")}
                            </CorreosButton>
                        </Grid>
                        <Grid item xs={6}>
                            {isCreated ? (
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateAnother}
                                    className={dialogClasses.dialogButtonRight}
                                >
                                    {"Crear otro"}
                                </CorreosButton>

                            ) : (
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreate}
                                    className={dialogClasses.dialogButtonRight}
                                    circularProgress={circularProgress}
                                    disabled={checkIsNullUndefined(relatedUser)}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    );
}
