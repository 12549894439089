import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    List,
    ListItemText,
    Tooltip,
    IconButton
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../CommonComponents/Dialogs/DialogStyles";
import { CommonComponentsStyles } from "./CommonComponentsStyles";
import { getPhonePrefixCountry } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { MdMail } from "react-icons/md";
import { AiOutlineFileSearch } from "react-icons/ai";

export function UserInfoPopup(props) {
    const { verifiedData, dataType, countryList } = props;
    const dialogClasses = DialogStyles();
    const classes = CommonComponentsStyles();
    const { t, i18n } = useTranslation();

    const textFromType = { email: t("genLabel_email"), phone: t("genLabel_phone"), identifierDocument: t("genLabel_document") };
    const tooltipFromType = { email: t("userInfoPopupEmailTooltip"), phone: t("userInfoPopupPhoneTooltip"), identifierDocument: t("userInfoPopupDocTooltip") };

    const [open, setOpen] = useState(false);

    useEffect(() => { }, []);

    const ofuscateData = (value, mode) => {
        let ofuscatedPart = "";
        let number = 3;

        if (mode === "email") {
            number = value.length - (value.indexOf("@") + 1);
        }
        if (mode === "phone") {
            value = value.toString();
        }

        for (let i = 1; i <= number; i++) {
            ofuscatedPart = ofuscatedPart + "*";
        }

        // const ofuscatedText =
        //     value.substring(0, value.length - number) + ofuscatedPart;
        return value;

    };

    const getDataText = (value, mode, countryPhoneId) => {
        let auxOfuscated = ofuscateData(value, mode);
        if (mode === "phone") {
            auxOfuscated = '+' + getPhonePrefixCountry(countryList, countryPhoneId, "id").phonePrefix + ' ' + formatPhone(auxOfuscated).toString();
        };
        return auxOfuscated;
    };

    const formatPhone = (value) => {
        return value.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3-$4');
    };

    return (
        <Grid>
            <Tooltip title={tooltipFromType[dataType]}>
                <IconButton className={classes.iconButton}>
                    <AiOutlineFileSearch
                        onClick={() => {
                            setOpen(true);
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => { setOpen(false) }}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userInfoPopupTitle")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={1} align="left" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <MdMail className={classes.iconStyle} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="h6">
                                        {textFromType[dataType]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <List className={classes.listItem}>
                                {verifiedData.map((data, index) => (
                                    <ListItemText key={"key_" + index} secondary={getDataText(data[dataType], dataType, data.idCountry)} />
                                ))}
                            </List>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={12}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { setOpen(false) }}
                                    className={dialogClasses.dialogButtonCenter}
                                >
                                    {t("close")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </Grid>
    );
}
