import React, { useEffect } from "react";
import { RolesStyles } from "./RolesStyles";
import { useHistory } from "react-router-dom";
import {
    Typography,
    Card,
    Button,
    Grid,
    Container,
    CardContent
} from "@material-ui/core";
import { FaUserTie } from "react-icons/fa";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { getUserGlobalRoles, getSelectedSubApp } from "../../services/Commons";
import { EnumPages, RolLevels } from "../../commons/Enums";

export function Roles(props) {

    const { subApplication, handleSubApplication } = props;
    const [buttonsToShow, setButtonsToShow] = React.useState([]);

    useEffect(() => {
        let subApp;

        if (subApplication) {
            subApp = subApplication;
        }
        else {
            subApp = getSelectedSubApp();
            handleSubApplication(subApp);
        }

        setButtonsToShow(mountButtons(subApp));
    }, []);

    const classes = RolesStyles();
    const history = useHistory();

    const goToEndpoints = () => {
        history.push(EnumPages.Endpoints);
    }

    const goToAppControl = () => {
        history.push(EnumPages.AppControl);
    }

    const goToHome = () => {
        history.push("/Home");
    }

    const mountButtons = (selectedSubApp) => {

        let result = [];
        const tmpSubAppsAndRoles = getUserGlobalRoles();

        if (tmpSubAppsAndRoles.find(x => x.subApp === selectedSubApp)) {
            tmpSubAppsAndRoles.find(x => x.subApp === selectedSubApp).roles.forEach(x => {
                let img;
                let goTo = goToHome;
                let text = "No Literal";

                switch (selectedSubApp) {
                    case "AAP":
                        img = <FaUserTie className={classes.iconStyle} />;
                        switch (x.name) {
                            case RolLevels.N1:
                                goTo = goToEndpoints;
                                text = spanishLanguage["rolesAapConsultInfo"];
                                break;

                            case RolLevels.N2:
                                goTo = goToAppControl;
                                text = spanishLanguage["rolesAapModifyInfo"];
                                break;

                            case RolLevels.N3:
                                text = "N3 text";
                                break;

                            default: break;
                        }

                        break;

                    default: break;
                }

                if (result.findIndex(i => i.name === text) === -1) {
                    result.push(
                        {
                            name: text,
                            icon: img,
                            clickEvent: goTo
                        }
                    );
                }
            });

            if (result.length === 0) {
                goToHome();
            }
            else if (result.length === 1) {
                result[0].clickEvent();
            }
        }
        else {
            goToHome();
        }

        return result;
    };

    return (
        <Grid styles = {{margin: "auto"}}>
          <Container maxWidth={"lg"} className={classes.mainContainer} >
            <Grid container spacing={3}>
                <Grid item xs={12} style={{margin:"auto", textAlign: "center"}}>
                    <Typography variant="h3" style={{ color: "#002453", margin: "15px" }} >
                        {spanishLanguage.rolesSlectAction}
                    </Typography>
                </Grid>
                <Grid container item xs={12}>
                    {buttonsToShow.map((value) => {
                        return (
                            <Grid item md={3} xs={12} style={{ margin: "auto" }}>
                                <Button
                                    className={classes.cardButton}
                                    onClick ={() => value.clickEvent()}
                                >
                                    <Card className={classes.cardStyle} elevation={4}>
                                        <CardContent className={classes.centerContent}>
                                            {value.icon}
                                            <Typography className={classes.cardTextStyle}>
                                                {value.name}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
          </Container>
        </Grid>
    );
}