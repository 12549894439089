import { makeStyles } from "@material-ui/core/styles";

export const LanguageSelectorStyles = makeStyles((theme) => ({

  menuItemText: {
    display: "flex", 
    alignItems: "center"
  }, 
  flagIcon: {
    width: "30px",
    height:" 20px",
    marginRight: "1em",
    marginLeft:"0.5em"
  }

}));
