import { makeStyles } from "@material-ui/styles";
export const AdminAppAttributesStyles = makeStyles((theme) => ({

    mainContainer: {
        margin: "auto",
        marginBottom: "1em",
    },
    title: {
        padding: "0em 0em 2em 0em",
        color: "#002453",
        fontWeight: "bold !important",
        float: "center"
    },  
    formContainer: {
        padding: "1em",
    },    
    grisCorreos: {
        color: "#7b7b7b",
        marginLeft: "5em"
    },   
    grisCorreosAux: {
        color: "#7b7b7b",
        marginLeft: "5em",
        paddingLeft:"4em",

    },
    tableHeaderUnderline:{
        borderBottom: "2px solid #002453",
        marginBottom: "1vh"
    },
    tableHeaderText: {
        color: "#002453",
        fontWeight: "bold !important",
        marginLeft: "5em"
    },   
    
    remarcar: {
        fontWeight: "bold !important",
        marginLeft: "4em"
    },

}));