import React, { useEffect, useState } from "react";
import {
    Grid,
    Card,
    Typography,
    TextField,
    Divider,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableBody,
    Paper,
    Tooltip,
} from "@material-ui/core";
import {
    GetMasterCountry,
    //   GetExtendedUserDataByIdentificationNotCancelled,
    GetExtendedUserDataByIdentification,
    PostAtcOtpSendEmail,
    PostAtcOtpSendPhone,
} from "../../services/ReasonService";
import { checkIsNullUndefined, getErrorMessage, getPhonePrefixCountry } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { CommonStyles } from "../../commons/CommonStyles";
import { UserAdminStyles } from "./UserAdminStyles";
import { EnumCountries, RolLevels, SubApp } from "../../commons/Enums";
import { PrefixPhone } from "../TransversalComponents/PrefixPhone/PrefixPhone";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import { PageHeader } from "../CommonComponents/PageHeader";
import { UserInfoCard } from "../CommonComponents/UserInfoCard";
import { UserSpecialCard } from "../CommonComponents/UserSpecialCard";
import { RenderAttrInfo, renderItem } from "./Components/RenderAttrInfo";
import { DialogCancelAccount } from "./Components/DialogCancelAccount";

export function UserAdmin(props) {

    const { finishOperation, userGroupActions, handleSetAppsSelectOptions, handleShowAppsSelect } = props;

    const { t, i18n } = useTranslation();
    const validator = require("react-email-validator");
    const classes = UserAdminStyles();
    const commonClasses = CommonStyles();
    const [circularProgress, setCircularProgress] = useState(false);
    const [circularProgressOtp, setCircularProgressOtp] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [identifierType, setIdentifierType] = useState("");
    const [disableButtonNext, setDisableButtonNext] = useState(true);
    const [showPrefixes, setShowPrefixes] = useState(false);
    const [prefixPhoneCode, setPrefixPhoneCode] = useState(EnumCountries.ESP);
    const [countryList, setCountryList] = useState([]);
    const [userData, setUserData] = useState(null);
    const [otpData, setOtpData] = useState(null);
    const [showCards, setShowCards] = useState(false);
    // Ver información
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [tab, setTab] = useState("userData");

    const labelObjects = [{ labelKey: "userData", labelText: "userInfoDatosTabLabel" },
    { labelKey: "userAddress", labelText: "userInfoDireccionesTabLabel" },
    { labelKey: "userEmail", labelText: "userInfoEmailsTabLabel" },
    { labelKey: "userPhone", labelText: "userInfoTeléfonosTabLabel" },
    { labelKey: "userDocument", labelText: "userInfoDocumentosTabLabel" },
    { labelKey: "userSubscription", labelText: "userInfoSubscripcionesTabLabel" },
    { labelKey: "userEnterprises", labelText: "userInfoEmpresasTabLabel" },]

    useEffect(() => {
        handleSetAppsSelectOptions(SubApp.CAU, RolLevels.N5);
        handleShowAppsSelect(false);
        getCountries();
    }, []);

    const getCountries = () => {
        GetMasterCountry().then((response) => {
            if (response && !response.Status) {
                setCountryList(response);
            }
        });
    };

    // controla el cambio en el selector de prefijo
    const handleSelectorChange = (country) => {
        setPrefixPhoneCode(country.code);
    };

    const compareFecCancelAccount = (a, b) => {
        if (a.fecCancelAccount === null && b.fecCancelAccount !== null) {
            return -1;
        } else if (a.fecCancelAccount !== null && b.fecCancelAccount === null) {
            return 1;
        } else {
            return 0;
        }
    };

    const handleNext = (reset) => {
        let auxSelectedAccountOid = reset ? selectedAccount.userOid : null;
        setCircularProgress(true);
        setShowCards(false);
        setSelectedAccount(null);
        let identifierAux = identifier;
        if (identifierType === "userPhone") {
            let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
            identifierAux = country.phonePrefix + "-" + identifier;
        }
        GetExtendedUserDataByIdentification(identifierAux).then(
            (response) => {
                if (!response || response.Status) {
                    finishOperation("error", getErrorMessage(response));
                } else if (response.listExtendedUserData != null) {
                    let auxUserData = response.listExtendedUserData.sort(compareFecCancelAccount);
                    setOtpData(response.lastOtp);
                    setUserData(auxUserData);
                    setShowCards(true);
                    if (reset) {
                        let auxAccount = auxUserData.find((account) => account.userOid == auxSelectedAccountOid);
                        setSelectedAccount(auxAccount);
                    }
                };
                setCircularProgress(false);
            }
        );
    };

    // Identifica el tipo de identificador introducido (email, teléfono) y habilita el botón cuando el valor sea válido
    const _handleTypeOfIdentifier = (e) => {
        setShowCards(false);
        setUserData(null);
        setOtpData(null);
        setSelectedAccount(null);

        let auxDisableButtonNext = true;
        let auxShowPrefixes = false;
        let auxIdentifierType = "";
        let value = e.target.value.trim();

        const checkPhoneNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

        if (validator.validate(value)) {
            auxIdentifierType = "userEmail";
            auxDisableButtonNext = false;
        } else if (checkPhoneNumber.test(value)) {
            // Mientras el valor pueda ser un teléfono se mostrarán los prefijos.
            auxShowPrefixes = true;
            if (value.length >= 9) {
                auxIdentifierType = "userPhone";
                auxDisableButtonNext = false;
            };
        };
        setIdentifier(value);
        setIdentifierType(auxIdentifierType);
        setDisableButtonNext(auxDisableButtonNext);
        setShowPrefixes(auxShowPrefixes);
    };

    const renderCards = (data) => {
        let hasActiveAccounts = data !== null && data.length > 0;
        let showSpecialCard = data === null || !hasActiveAccounts || data.every(account => account.fecCancelAccount !== null);

        return <Grid container item xs={12}>
            {showSpecialCard ? (
                <Grid container item xs={12} md={6} justifyContent="center" alignItems="center" style={{ margin: "auto" }}>
                    <Card className={classes.cardStyles}>
                        <UserSpecialCard otpData={otpData} identifier={identifier} />
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ padding: "2em", bottom: 0 }}
                        >
                            <Grid item xs={12}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendOtp}
                                    className={commonClasses.dialogButtonRight}
                                    circularProgress={circularProgressOtp}
                                    size={"small"}
                                >
                                    {t("userInfoSendOtp")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ) : null}
            {hasActiveAccounts ? (
                data.map((account, index) => (
                    <Grid container item key={index} xs={12} md={6}
                        justifyContent="center"
                        alignItems="center"
                        style={{ margin: "auto" }}
                    >
                        <Tooltip title={t("userInfoSelectAccount")} placement="top">
                            <Card
                                className={classes.cardStyles}
                                style={{ backgroundColor: account.userOid == selectedAccount?.userOid ? "#00000014" : null, cursor: "pointer" }}
                                onClick={() => { handleSelectAccount(account) }}
                            >
                                {account.fecCancelAccount && (
                                    <Grid className={classes.cancelledText}>{t("userInfoCancelledAccount")}</Grid>
                                )}
                                <UserInfoCard account={account} countryList={countryList} />
                            </Card>
                        </Tooltip>
                    </Grid>
                ))
            ) : null}
        </Grid >
    }

    const handleSendOtp = (auxilIdentifier, auxilIdentifierType) => {
        let auxIdentifier = checkIsNullUndefined(auxilIdentifier) ? identifier : auxilIdentifier[auxilIdentifierType].user;
        let auxIdentifierType = checkIsNullUndefined(auxilIdentifierType) ? identifierType : auxilIdentifierType;
        let auxFunction = auxIdentifierType === "userPhone" ? PostAtcOtpSendPhone : PostAtcOtpSendEmail;
        setCircularProgressOtp(true);

        if (auxIdentifier === "userPhone" && checkIsNullUndefined(auxilIdentifier)) {
            let country = getPhonePrefixCountry(countryList, prefixPhoneCode, "code");
            auxIdentifier = country.phonePrefix + "-" + auxIdentifier;
        }

        auxFunction(auxIdentifier).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("userInfoOtpSent").replace("{identifier}", auxIdentifier));
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgressOtp(false);
        });
    };

    const handleSelectAccount = (account) => {
        setSelectedAccount(account);
    };

    const handleTabChange = (tabValue) => {
        setTab(tabValue);
    };

    const renderUserData = () => {
        return <Grid container item xs={12}>
            {renderItem("userData", t, selectedAccount, classes)}
        </Grid>
    };

    const getTab = () => {
        let auxReturn;
        if (tab === "userData") {
            auxReturn = renderUserData();
        } else {
            auxReturn = renderCompoundData();
        };
        return auxReturn;
    };

    const renderCompoundData = () => {
        let dataList = selectedAccount[tab];
        if (tab === "userSubscription") {
            dataList = dataList.filter((subsApp) => subsApp.codeApp != "CID");
        };
        return (
            <Grid container item xs={12} style={{ display: 'block' }}>
                <Grid container item xs={12}>
                    {dataList.length > 0 ? (
                        <Paper style={{ width: "100%" }}>
                            {dataList.map((item, index) => (
                                <RenderAttrInfo
                                    index={index}
                                    itemType={tab}
                                    item={item}
                                    handleSendOtp={handleSendOtp}
                                    identifier={identifier}
                                    user={selectedAccount}
                                    finishOperation={finishOperation}
                                    handleNext={handleNext}
                                />
                            ))}
                        </Paper>
                    ) : (
                        <Typography>
                            <span className={classes.notSetText}>{t("userInfoNoData")}</span>
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    return (
        userGroupActions.includes("CID") ?
            <Grid container item xs={12}>
                <PageHeader title={t("userAdminTitle")} text={t("userAdminText")} />
                <Grid container item xs={12} justifyContent="center" className={classes.marginBottom}>
                    <Grid
                        container
                        item
                        justifyContent="center"
                        spacing={1}
                        xs={12}
                    >
                        <Card style={{ width: "100%", padding: "3em" }}>
                            <Grid container item sm={10} xs={12} spacing={1} className={classes.textFieldRow} style={{ margin: "auto" }}>
                                {showPrefixes ? (
                                    <Grid item sm={2} xs={12}>
                                        <PrefixPhone countryList={countryList} handleSelectorChange={handleSelectorChange} />
                                    </Grid>
                                ) : null}
                                <Grid item sm={showPrefixes ? 8 : 10} xs={12} className={classes.textFieldRow}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="idSearcher"
                                        label={t("udelIdentifierField")}
                                        name="idSearcher"
                                        color="secondary"
                                        required={true}
                                        onChange={_handleTypeOfIdentifier}
                                        value={identifier}
                                    />
                                </Grid>
                                <Grid item sm={2} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <CorreosButton
                                        className={classes.buttonSearch}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleNext()}
                                        disabled={disableButtonNext}
                                        circularProgress={circularProgress}
                                    >
                                        <SearchIcon style={{ marginRight: "0.2em" }} />
                                        {t("search")}
                                    </CorreosButton>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: "2em", width: "100%" }}>
                        {showCards && userData ? (
                            <Grid container item xs={12}>
                                {renderCards(userData)}
                            </Grid>
                        ) : (null)}
                    </Grid>
                    {!checkIsNullUndefined(selectedAccount) ? (
                        <Grid container item xs={12} style={{ marginTop: "2em", maxWidth: "75em", textAlign: "center", display: "flex", alignItems: "center" }}>
                            <Grid item xs={9}>
                                <Typography
                                    variant="h5"
                                // className={classes.title}
                                >
                                    {t("userInfoAccountOid") + ": " + selectedAccount.userOid}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <DialogCancelAccount user={selectedAccount} handleNext={handleNext} identifier={identifier} finishOperation={finishOperation} />
                            </Grid>
                            <Divider style={{ width: "100%", marginTop: "1em" }}></Divider>
                            <Grid item xs={12} style={{ marginTop: "2em", padding: "0px" }}>
                                <Box display="flex" justifyContent="center" width={"100%"}>
                                    <Tabs value={tab} aria-label="Attributes Tab" variant="scrollable" scrollButtons="auto">
                                        {labelObjects.map((label, index) =>
                                            <Tab key={index} label={t(label.labelText)} value={label.labelKey} onClick={() => handleTabChange(label.labelKey)} />
                                        )}
                                    </Tabs>
                                    <Divider />
                                </Box>
                            </Grid>
                            <Grid container item xs={12} style={{ border: '1px solid #ccc', padding: '2em', borderRadius: '4px' }}>
                                {getTab()}
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid >
            : null
    );
}
