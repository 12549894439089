import { makeStyles } from "@material-ui/core/styles";

export const ApplicationModificationStyles = makeStyles((theme) => ({

  mainContainer: {
    margin: "auto",
  },

  cardContainer: {
    marginTop: "2em",
    marginLeft: "2em",
    marginRight: "2em",

  },
  marginBottom: {
    marginBottom: "5em",
    color: "#002453",
  },
  title: {
    marginTop: "1em",
    color: "#002453",
  },
  subTitle: {
    fontSize:"120%",
    color: "#002453",
  },

  flagIcon: {
    width: "30px",
    height:" 20px",
    verticalAlign: "middle", 
    borderRadius:"0.25em"
    
  },
  notSetText: {
    fontStyle: "italic",
    fontWeight: "bold",
    color: "#CBCBCB"
  },
  disabledIcon: {   
    width: "30px",
    height:" 20px",
    verticalAlign: "middle", 
    borderRadius:"0.25em", 
    color: "#CBCBCB"
  },

  
  allowedFileTypesText: {
    fontStyle: "italic",
    color: "#002453"
  }
}));
