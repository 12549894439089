import { makeStyles } from "@material-ui/styles";
export const ApiConfigStyles = makeStyles((theme) => ({


    remarcar: {
        fontWeight: "bold !important",
        marginLeft: "4em"
    },
    remarcarAux: {
        fontWeight: "bold !important",
        padding: "0em 0em 2em 0em",
        marginLeft: "4em"
    },

    grisCorreos: {
        color: "#7b7b7b",
        marginLeft: "3em"
    },

    Title: {
        padding: "0em 0em 2em 0em",
        color: "#002453",
        // width: "100%",
        fontWeight: "bold !important",
        float: "center"
    },

    mainContainer: {
        margin: "auto",
        marginBottom:"1em",
    },

    formContainer: {
        padding: "1em 1em 1em 1.6em",
    },

    mainDiv: {
        textAlign: "center",
        minHeight: "60vh",
        position: "relative",
    },
    visibility: {
        marginTop: "0.55em"
    }
}));