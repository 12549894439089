import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@material-ui/core";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { ApplicationAdminStyles } from "../ApplicationAdminStyles";
import { useTranslation } from "react-i18next";
import { BasicDialog } from "../../CommonComponents/BasicDialog";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { GetMasterIntegrationBlocks, PostIntegrationAddIntegrationBlock } from "../../../services/ReasonService";

export function EndpointGroupSelection(props) {
    const {
        finishOperation,
        selectedApp,
        restartActions
    } = props;
    const commonClasses = CommonStyles();
    const classes = ApplicationAdminStyles();
    const [selectedGroup, setSelectedGroup] = useState("");
    const [circularProgress, setCircularProgress] = useState(true);
    const [circularProgressConfirmButton, setCircularProgressConfirmButton] = useState(false);
    const [allGroups, setAllGroups] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getGroups();
    }, []);

    const getGroups = () => {
        setCircularProgress(true);
        GetMasterIntegrationBlocks().then((response) => {
            if (response && !response.Status) {
                setAllGroups(response);
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgress(false);
        })
    };

    const handleChangeGroup = (auxGroup) => {
        setSelectedGroup(auxGroup);
    }

    const handleSubmit = () => {
        setCircularProgressConfirmButton(true);
        PostIntegrationAddIntegrationBlock(selectedApp.value, selectedGroup).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("appAdmEndGroupSuccess"));
                restartActions();
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgressConfirmButton(false);
        });
    }

    const getDialogText = () => {
        let auxGroup = allGroups.find((group)=> group.code == selectedGroup);
        return t("appAdmEndGroupPopupText").replace("{group}", auxGroup?.name).replace("{app}", selectedApp.name)
    }

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                    {t("appAdmEndGroupSelTitle")}
                </Typography>
            </Grid>
            {circularProgress ? (
                <Grid item xs={12}>
                    <CircularProgress style={{ marginTop: "2.5em", marginBottom: "2.5em" }} />
                </Grid>
            ) : (
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" className={commonClasses.hitText}>
                            {t("appAdmEndGroupSelSubtitle")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.marginEndpointTitle}>
                        <FormControl variant="outlined" fullWidth margin="dense" >
                            <InputLabel style={{ color: "#002453" }}>{t("appAdmEndGroupSelLabel")}</InputLabel>
                            <Select
                                value={selectedGroup}
                                onChange={(e) => handleChangeGroup(e.target.value)}
                                label={t("appAdmEndGroupSelLabel")}
                                style={{ color: "#002453" }}
                            >
                                {allGroups.map((group) => {
                                    return <MenuItem key={group.code} style={{ color: "#002453" }} value={group.code}> {group.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "2.5em", marginBottom: "2.5em" }}>
                        <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenDialog(true)}
                            circularProgress={circularProgressConfirmButton}
                            disabled={checkIsNullUndefined(selectedGroup)}
                        >
                            {t("confirm")}
                        </CorreosButton>
                    </Grid>
                </Grid>
            )}
            <BasicDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title={t("appAdmEndGroupPopupTitle")}
                text={getDialogText()}
                action={handleSubmit}
            />
        </Grid>
    );

}