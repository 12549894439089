import React, { useEffect, useState } from "react";
import {
    Typography,
    Container,
    Dialog,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    Paper,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogStyles } from "../../CommonComponents/Dialogs/DialogStyles";
import { TableStyles } from "../../../commons/CommonStyles/TableStyles";
import { CorreosButton } from "../../CorreosButton/CorreosButton";

export default function DialogAddSubscriptions(props) {
    const { allPossibleApps, handleSubscribeToApps } = props;
    const dialogClasses = DialogStyles();
    const tableClasses = TableStyles();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedApps, setSelectedApps] = useState([]);

    useEffect(() => {
        let auxAllPossibleApps = JSON.parse(JSON.stringify(allPossibleApps));
        switchToNotSelected(auxAllPossibleApps);
        setSelectedApps(auxAllPossibleApps);
    }, [allPossibleApps]);

    const switchToNotSelected = (appsList) => {
        appsList.forEach(app => {
            app.selected = false;
        });
    };

    const handleCancel = () => {
        let auxAppList = JSON.parse(JSON.stringify(selectedApps));
        switchToNotSelected(auxAppList);
        setSelectedApps(auxAppList);
        setOpen(false);
    };

    const handleConfirm = () => {
        handleSubscribeToApps(selectedApps);
        handleCancel();
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleSelect = (app) => {
        let auxAppList = JSON.parse(JSON.stringify(selectedApps));
        auxAppList.forEach((auxApp) => {
            if (auxApp.code == app.code) {
                auxApp.selected = !auxApp.selected;
            };
        });
        setSelectedApps(auxAppList);
    };

    return (
        <Grid>
            <CorreosButton
                onClick={handleOpen}
                className={dialogClasses.dialogButtonRight}
            >
                {t("userSysAdminAddSubscriptions")}
            </CorreosButton>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleCancel}
                PaperProps={{ classes: { root: dialogClasses.dialogWindow } }}
            >
                <Container className={dialogClasses.dialogTitle}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                className={dialogClasses.dialogTitleText}
                            >
                                {t("userSysAdminAddSubscriptionsTitle")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={dialogClasses.dialogContainer}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12}>
                            <Typography className={dialogClasses.dialogContentText} style={{ marginBottom: "1em", marginTop: "1em" }}>
                                {t("userSysAdminAddSubscriptionsSubtitle")}
                            </Typography>
                            <Grid item xs={12}>
                                <TableContainer component={Paper} key={"TableCreate"} className={tableClasses.customPaper}>
                                    <Table className={tableClasses.customTableRow}>
                                        <TableHead className={tableClasses.stickyTableHead}>
                                            <TableRow >
                                                <TableCell className={tableClasses.tableCellHeader} key={"headerCreate"}>
                                                    {t("userSysAdminHeaderApp")}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedApps.map((app, index) => {
                                                return (
                                                    <TableRow
                                                        key={"endpoint" + index}
                                                        className={tableClasses.tableRow}
                                                        component="div"
                                                        onClick={() => handleSelect(app)}
                                                        selected={app.selected}
                                                    >
                                                        <TableCell className={tableClasses.tableCell}>
                                                            <Grid className={tableClasses.tableCellText}>{app.name}</Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            className={dialogClasses.dialogButtonContainer}
                        >
                            <Grid item xs={6}>
                                <CorreosButton
                                    color="secondary"
                                    className={dialogClasses.dialogButtonLeft}
                                    onClick={handleCancel}
                                >
                                    {t("cancel")}
                                </CorreosButton>
                            </Grid>
                            <Grid item xs={6}>
                                <CorreosButton
                                    variant="contained"
                                    color="primary"
                                    className={dialogClasses.dialogButtonRight}
                                    onClick={handleConfirm}
                                >
                                    {t("accept")}
                                </CorreosButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </Grid>
    );
}
