import React, { useEffect } from "react";
import { 
    Grid, 
    Card,
    CardMedia,
    CardContent,
    Typography,
    Container}
from "@material-ui/core";
import {AppControlStyles} from "./AppControlStyles";
import { getSelectedSubApp, getUserGlobalRoles } from "../../services/Commons";
import { RolLevels } from "../../commons/Enums";


export function AppControl(props) {

    const { subApplication, handleSubApplication, handleShowAppsSelect, handleSetAppsSelectOptions } = props;
    // const [ applicationOid, setapplicationOid] = React.useState();
    // const [ idApplicationType, setidApplicationType ] = React.useState();
    const [ applicationLogo, setapplicationLogo ] = React.useState();
    const [ applicationName, setapplicationName ] = React.useState();
    const [ applicationUrl, setapplicationUrl ] = React.useState();

    const classes = AppControlStyles();
 
    useEffect(() => {
        let subApp = subApplication;

        // Necesario para pintar el título de la cabecera si se punsa F5.
        if (!subApplication) {
            subApp = getSelectedSubApp();
            handleSubApplication(subApp);
        }

        let tmpApps = getUserGlobalRoles().find(x => x.subApp === subApp).roles.find(x => x.name === RolLevels.N1).apps;
        let selectOptions = [];

        if (tmpApps && tmpApps.length > 0) {
            tmpApps.forEach(x => {
                selectOptions.push({
                    name: x,
                    value: x
                });
            });
        }

        handleShowAppsSelect(true);
        handleSetAppsSelectOptions(selectOptions);

        // llamadaBack().then((response) => {

        let response = {
            applicationOid: "647ca686-7414-462b-85e3-0477ca491646" ,
            idApplicationType: "3",
            applicationLogo: "https://azamdcorreosidsta001.blob.core.windows.net/b2cdev/correosid/images/LOGO-WEB_Cabecera_CorreosPrepago.png",
            applicationName: "Correos Prepago",
            applicationUrl: "https://correos-prepayws-dev.mo2o.com/v1/admon/login/notification",
        }
        //setapplicationOid(response.applicationOid);
        //setidApplicationType(response.idApplicationType);
        setapplicationLogo(response.applicationLogo);
        setapplicationName(response.applicationName);
        setapplicationUrl(response.applicationUrl);
        

        // }

        return () => {
            handleShowAppsSelect(false);
            handleSetAppsSelectOptions([]);
        }
    }, []);

    return(
        <Container xl={6} lg={6} xs={12} >
            <Card >
                <Grid container xs={12} >
                    <Grid container item xs={12} style = {{display: "flex",marginBottom: "1em", marginTop:"2em" }}>
                        <Grid item xs={4}
                            noWrap
                            container
                        >
                            <Typography
                                className={classes.remarcar}
                                color="secondary"
                            >
                                {"Logo: "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <CardMedia className={classes.media}>
                                <img
                                    className={classes.imgCardMedia}
                                    src={
                                    applicationLogo == null
                                        ? process.env.REACT_APP_CORREOS_ID_LOGO
                                        : applicationLogo
                                    }
                                    alt="Logo"
                                />
                            </CardMedia>
                        </Grid>
                    </Grid>
                
                    <Grid container item xs = {12} style = {{display: "flex",margin: "auto"}}>
                        <Grid 
                            noWrap
                            item
                            xs = {4}
                        >
                            <Typography
                                className={classes.remarcar}
                                color="secondary"
                            >
                                {"Nombre: "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent >
                                <Typography className={classes.details}>
                                    {applicationName}
                                </Typography>
                            </CardContent>

                        </Grid>
                    </Grid>

                    <Grid container item xs = {12} style = {{display: "flex",margin: "auto"}}>
                        <Grid  xs={4}
                            item
                            noWrap
                        >
                            <Typography className={classes.remarcar} color="secondary">
                                {"Url: "}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <CardContent>
                                <Typography className={classes.details}>
                                    {applicationUrl}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid> 
                </Grid>
            </Card>
        </Container>
    );
}