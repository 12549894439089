import { makeStyles } from "@material-ui/core/styles";

export const UserInfoStyles = makeStyles((theme) => ({

  mainContainer: {
    margin: "auto",
  },

  cardContainer: {
    marginTop: "2em",
    marginLeft: "2em",
    marginRight: "2em",

  },
  marginBottom: {
    marginBottom: "5em",
    color: "#002453",
  },
  title: {
    marginTop: "1em",
    color: "#002453",
  },
  subTitle: {
    fontSize:"120%",
    color: "#002453",
  },

  flagIcon: {
    width: "30px",
    height:" 20px",
    verticalAlign: "middle", 
    borderRadius:"0.25em"
    
  },
  notSetText: {
    fontStyle: "italic",
    color: "#CBCBCB"
  },
  disabledIcon: {   
    width: "30px",
    height:" 20px",
    verticalAlign: "middle", 
    borderRadius:"0.25em", 
    color: "#CBCBCB"
  },

  
  allowedFileTypesText: {
    fontStyle: "italic",
    color: "#002453"
  },

  formLabelRoot: { // must provide all of formLabelRoot && '&$formLabelFocused' && formLabelFocused
    '&$formLabelFocused': { color: theme.palette.secondary.main },
  },
  formLabelFocused: {
    // color: 'green', // won't affect anything
  },
  appBar: {
    overflowX: 'auto', // Hace que las Tabs sean scrollables en pantallas pequeñas
  },

  greenText: {
    color: "#348F41",
    fontWeight: "bold",
  },

  orangeText: {
    color: "#DBB000",
    fontWeight: "bold",
  },

  redText: {
    color: "#F32735",
    fontWeight: "bold",
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        "&:has(> input:-webkit-autofill)": {
          backgroundColor: "blue",
        },
      },
    },
  },

  cardStyles: {
    height: "18.5em",
    width: "85%",
    paddingTop: "2em",
    marginTop: "2em",
    marginLeft:"1em",
    position:"relative",

    [theme.breakpoints.down("md")]: {
      height: "20em",
      width: "auto",
      margin:"auto",
      marginLeft:"1em",
      flexDirection:"column",

    },

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "auto",
      marginTop:"1em",
    }
  },

  selectedCard: {
    border: "2px solid #002453",
    height: "18.5em",
    width: "85%",
    paddingTop: "2em",
    marginTop: "2em",
    marginLeft:"1em",
    position:"relative",

    [theme.breakpoints.down("md")]: {
      height: "20em",
      width: "auto",
      margin:"auto",
      marginLeft:"1em",
      flexDirection:"column",

    },

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      width: "auto",
      marginTop:"1em",
    }
  },
  cancelledCard: {
    position: 'relative', 
  },
  cancelledText: {
    /* Estilos para el texto de "Cuenta Cancelada" en la esquina superior derecha */
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#F32735',
    color: 'white',
    padding: '5px 10px',
    opacity: 0.7,
  },


  selectAccount: {
    position: 'absolute',
    bottom:  "1em", 
    right: "1em",
  },

  titleText: {
    textAlign:"left",
    color: "#002453",
    marginLeft:"2em",
  },
  iconButton:{
    float: "left",
    color: "#002453",
  },

  accountDataContainer:{
    alignContent: "center", 
    alignItems: "center", 
    height: "3em"
  },
  accountDataIconGrid:{
    textAlign: "left",
    marginLeft: "2em"
  }





}));
