import React, { useEffect } from "react";
import { CommonStyles } from "../../commons/CommonStyles";
import { BackgroundLoginStyles } from "./BackgroundLoginStyles";
import { Paper, Typography, Grid, Container } from "@material-ui/core";
import correosIdLogoYellow from "../../assets/images/Cornamusa_Azul_FondoAmarillo.png";
import { EnumTyC } from "../../commons/Enums";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { checkIsNullUndefined } from "../../services/Utilities";

const hrefTyCPage = "https://identidad.correospre.es/TyC/";

export function BackgroundLogin(props) {
  const commonClasses = CommonStyles();
  const classes = BackgroundLoginStyles();
  const [appName, setAppName] = React.useState();

  useEffect(() => {
    setAppName(
      checkIsNullUndefined(sessionStorage.getItem("appName"))
        ? spanishLanguage.logInDefaultAppName
        : sessionStorage.getItem("appName")
    );
  }, []);

  return (
    <div className={commonClasses.mainDiv}>
      <div className={classes.imgLoginDiv}>
        <img
          src={correosIdLogoYellow}
          alt={appName}
          className={classes.imgLogin}
        />
      </div>
      <Container className={classes.mainDivLogInPaper} maxWidth={"sm"}>
        <Paper
          elevation={0}
          variant="outlined"
          className={classes.mainPaperLogin}
        >
          <Typography component="h1" variant="h4">
            {appName}
          </Typography>
          <Grid className={commonClasses.marginTop1rem}>
            <Typography>
              <span>{spanishLanguage.logInSubTitle}</span>
            </Typography>
          </Grid>
          {props.children}
        </Paper>
      </Container>
      <Grid>
        <Typography className={classes.legalTypography}>
          <a
            className={classes.legalUrl}
            href={hrefTyCPage + EnumTyC.WarninLegal}
            target="_blank"
          >
            {spanishLanguage.logInWarningLegal}
          </a>
          <span> | </span>
          <a
            className={classes.legalUrl}
            href={hrefTyCPage + EnumTyC.PrivacyPolicy}
            target="_blank"
          >
            {spanishLanguage.logInPrivacyPolicy}
          </a>
          <span> | </span>
          <a
            className={classes.legalUrl}
            href={hrefTyCPage + EnumTyC.Cookies}
            target="_blank"
          >
            {spanishLanguage.logInCookiesPolicy}
          </a>
        </Typography>
      </Grid>
    </div>
  );
}
