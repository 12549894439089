import { makeStyles } from "@material-ui/core/styles";

export const BackgroundLoginStyles = makeStyles((theme) => ({
  mainPaperLogin: {
    marginTop: "3rem",
    maxWidth: "28rem",
    minHeight: "20rem",
    margin: "auto",
    padding: "3.5rem 3rem 2rem 3rem",
    display: "flow-root",
  },

  mainPaper: {
    marginTop: "3rem",
    minHeight: "20rem",
    margin: "auto",
    padding: "3.5rem 2rem 2rem 2.4rem",
    display: "flow-root",
  },

  imgLoginDiv: {
    position: "absolute",
    width: "100%",
  },

  imgLogin: {
    maxWidth: "6rem",
    marginLeft: "auto",
    marginRight: "auto",
  },

  legalTypography: {
    marginTop: "1.5vh",
  },

  legalUrl: {
    textDecoration: "none",
    color: "black",
    fontSize: "small",
  },
}));
