import { makeStyles } from "@material-ui/core/styles";

export const StylesServiceList = makeStyles((theme) => ({
    details: {
        display: "flex",
        flexDirection: "column",
        color: "#7b7b7b",
    },
    media: {
        maxWidth: "10em",
    },
    imgCardMedia: {
        width: "auto",
        height: "5em",
        float: "left",
    },

    cardStyles: {
        margin: "auto",
    },
    fit: {
        maxWidth: "fit-content",
    },

    remarcar: {
        fontWeight: "bold !important",
        padding: "1.2em 0.5em 0em 0em",
        marginLeft :"1em",
        textAlign: 'justify',
    },

    card: {
        marginTop: "3em",
    },

    root: {
        paddingTop: "1rem"
    },
    mainContainer: {
        [theme.breakpoints.down("sm")]: {
          marginTop: "4.5em",
        },
        [theme.breakpoints.up("sm")]: {
          marginTop: "10em",
        },
    },

    ButtonRight: {
        textAlign: "center",
        float: "right",
    },

    GridButtons: {
        padding: "0px 1rem 3rem 0px",
    }
}));
