import React, { useEffect } from "react";
import {
    Typography,
    Grid,
    Container,
    Paper
} from "@material-ui/core";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { CommonStyles } from "../../commons/CommonStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { ApiConfigStyles } from "./ApiConfigStyles";
import { EnumPages } from "../../commons/Enums";
import { useHistory } from "react-router-dom";


export function ApiConfig(props) {


    const { tycGestion, appConfig } = props;
    const commonClasses = CommonStyles();
    const classes = ApiConfigStyles();
    const history = useHistory();


    const goToControl = () => {
        history.push(EnumPages.AppControl);
    };

    return (
        <Grid>
            <Container className={classes.mainContainer} >
                <Paper>
                    <Grid container xs={12} className={classes.formContainer}>
                        <Grid item xs={12}>
                            <Typography
                                color="secondary"
                                align="center"
                                variant="h6"
                                className={classes.Title}
                            >
                                {spanishLanguage["apiConfig"]}
                            </Typography>
                        </Grid>
                        <Grid
                            direction="column"
                            xs={2}
                        >
                            <Typography
                                color="secondary"
                                className={classes.remarcar}
                                align="left"
                            >
                                {spanishLanguage["apiTypeApp"]}
                            </Typography>

                            <Typography
                                color="secondary"
                                className={classes.remarcarAux}
                                align="left"
                            >
                                {spanishLanguage["apiTypeRegistry"]}
                            </Typography>
                        </Grid>
                        <Grid
                            direction="column"
                            xs={4}
                        >
                            <Typography
                                color="secondary"
                                className={classes.grisCorreos}
                                align="left"
                            >
                                {appConfig.personType}
                            </Typography>

                            <Typography
                                color="secondary"
                                className={classes.grisCorreos}
                                align="left"
                            >
                                {appConfig.name}
                            </Typography>
                        </Grid>

                        <Grid
                            direction="column"
                            xs={2}
                            style={{ borderLeft: "2px solid #002453" }}
                        >
                            <Typography
                                color="secondary"
                                className={classes.remarcar}
                                align="right"
                            >
                                {spanishLanguage["apiHandleTyC"]}
                            </Typography>
                        </Grid>

                        <Grid
                            direction="column"
                            xs={1}
                        >
                            <Typography
                                color="secondary"
                                className={classes.grisCorreos}
                                align="left"
                            >
                                {tycGestion}
                            </Typography>
                        </Grid>

                        <Grid
                            direction="column"
                            style={{ justifyContent: "center" }}
                            xs={2}
                        >
                            <CorreosButton
                                variant="contained"
                                color="primary"
                                align="left"
                                onClick={() => goToControl()}
                                className={commonClasses.dialogButtonRight}
                            >
                                {spanishLanguage["apiEditTyC"]}
                            </CorreosButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Grid>
    );
}