import { makeStyles } from "@material-ui/core/styles";

export const EnterpriseDataStyles = makeStyles((theme) => ({
    leftButton: {
        float: "left",
        marginRight: "auto",
        [theme.breakpoints.down("sm")]: {
            marginTop: "1em",
            margin: "auto",
            float: "none",
          },
      },
      rightButton: {
        float: "right",
        marginLeft: "auto",
        [theme.breakpoints.down("sm")]: {
            marginTop: "1em",
            margin: "auto",
            float: "none",
          },
      },
      textAdjust: {
        marginTop: "0em",
        [theme.breakpoints.up("sm")]: {
          marginTop: "1.7em",
        },
      },

      


}));
