import React from "react";
import {
    FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select, TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ApplicationAdminStyles } from "./ApplicationAdminStyles";
import ClearIcon from '@material-ui/icons/Clear';

export function Searcher(props) {
    const { auxType, auxSearchObj, auxEndpointInfo } = props;
    const classes = ApplicationAdminStyles();
    const { t, i18n } = useTranslation();

    return (
        <Grid item xs={12} key={auxType} className={classes.marginEndpointTitle}>
            <TextField
                type="text"
                value={auxSearchObj[auxType].textValue}
                onChange={(e) => auxSearchObj[auxType].textOnChange(e.target.value)}
                label={t("appAdmSearchText")}
                variant="outlined"
                fullWidth
                margin="dense"
                InputLabelProps={{
                    style: { color: "#002453" }
                }}
                inputProps={{
                    style: { color: "#002453" }
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            aria-label="clear"
                            onClick={() => auxSearchObj[auxType].textOnChange("")}
                            edge="end"
                        >
                            <ClearIcon />
                        </IconButton>
                    )
                }}
            />
            <FormControl variant="outlined" fullWidth margin="dense" >
                <InputLabel style={{ color: "#002453" }}>{t("appAdmSearchCriteriaTitle")}</InputLabel>
                <Select
                    value={auxSearchObj[auxType].criteriaValue}
                    onChange={(e) => auxSearchObj[auxType].criteriaOnChange(e.target.value)}
                    label={t("appAdmSearchCriteriaTitle")}
                    style={{ color: "#002453" }}
                >
                    {auxEndpointInfo.map((endpoint) => {
                        return <MenuItem key={endpoint.value} style={{ color: "#002453" }} value={endpoint.value}>{endpoint.text}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </Grid>
    );
}
