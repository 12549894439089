import React from "react";
import {
    Typography,
    Grid,
    Dialog,
    Container,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles";
import { AtcLegalDialogStyles } from "./AtcLegalDialogStyles";
import { LanguageSelector } from "./LanguageSelector/LanguageSelector";



export function AtcLegalDialog(props) {

    const { t, i18n } = useTranslation();
    const commonClasses = CommonStyles();
    const classes = AtcLegalDialogStyles();

    const { open, handleClose } = props;


    const handleLanguageChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <Dialog
            fullWidth
            open={open}
            className={commonClasses.dialogWindow}
        // PaperProps={{ classes: { root: commonClasses.dialogWindow } }}
        >
            <Container className={commonClasses.dialogTitle} >
                <Grid container>
                    <Grid item xs={12}>
                        <LanguageSelector language={i18n.language} handleLanguageChange={handleLanguageChange}></LanguageSelector>
                    </Grid>
                </Grid>
            </Container>
            {/* <Grid container className={commonClasses.dialogTitle} style={{ paddingLeft: "inherit" }}>
                <Grid item xs={12}>
                    <Typography
                        variant="h4"
                        className={commonClasses.dialogTitleText}
                    >
                        {t("atcLegalWarning")}
                    </Typography>
                </Grid>
            </Grid> */}
            <Grid className={commonClasses.dialogContentText} style={{ marginTop: "1em", paddingLeft: "2.5em", paddingRight: "2.5em" }}>
                <Typography
                    variant="h6"
                    className={commonClasses.dialogTitleText}
                    style={{ color: "#F32735" }}
                >
                    {t("atcLegalWarningRead")}
                </Typography>
                <Typography component="legend" className={commonClasses.dialogLegendText}>
                    <p>{t("atcLegalDialogText1")}</p>
                    <p>{t("atcLegalDialogText2")}
                        <a
                            href="#"
                        >
                            {t("atcLegalDialogText2Email")}
                        </a>
                        {t("atcLegalDialogText2End")}
                    </p>
                    <p style={{ color: "#F32735" }}>{t("atcLegalDialogText3")}</p>
                    <a
                        href={process?.env?.REACT_APP_URL_LEGAL_DATA_PROTECTION}
                        target="_blank"
                    >
                        {process?.env?.REACT_APP_URL_LEGAL_DATA_PROTECTION}
                    </a>
                </Typography>
            </Grid>
            <Grid item xs={12} className={commonClasses.marginAuto}>
                <CorreosButton
                    className={classes.agreeButtons}
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}
                >
                    {t("atcLegalWarningAccept")}
                </CorreosButton>
            </Grid>
        </Dialog>
    );
}
