import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../commons/Constants";

export const HeaderStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffffff",
    height: Constants.HeaderHeight,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: "#ffffff",
    height: Constants.HeaderHeight,
    marginLeft: Constants.DrawerWidth,
    width: `calc(100% - ${Constants.DrawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  buttonClass: {
    color: "#002453",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    textAlign: "right",
  },
  imgIcon: {
    marginRight: theme.spacing(1),
    width: "2.8em",
    height: "2.8em",
  },
  imgLogin: {
    maxWidth: "2.0em",
    height: "auto",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
    color: "#002453"
  },
  title2: {
    textAlign: "left",
    color: "#002453",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "medium"
  },
  logoutButton: {
    color: "#002453",
  },
  searcher: {
    marginRight: "18px",
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    padding: "0px 8px 0px 8px",
  },
  divBar: {
    backgroundColor: "#FFCD00",
    display: "flex",
    position: "relative",
    alignItems: "center",
    height: "2.8em",

  },
  verticalLine: {
    borderLeft: "0.5px solid #CBCBCB",
    paddingLeft: theme.spacing(2),
    heigth: "100%"
  },
  selectGrid: {
    paddingLeft: theme.spacing(3),
  }
}));
