import { makeStyles } from "@material-ui/core/styles";

export const DialogStyles = makeStyles((theme) => ({

  gridIdentity: {
    display: "flex",
    margin: "auto",
  },  
  // Logos
  media: {
    margin: "auto",    
    maxWidth: "fit-content"
  },
  imgCardMedia: {
    width: "auto",
    height: "4em",    
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "1em"
  },
  mediaSubOk: {
    margin: "auto",    
    maxWidth: "fit-content"
  },
  imgCardMediaSubOk: {
    width: "auto",
    height: "12em",    
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "1em"
  },

  //Dialogs
  dialogWindow: {
    marginTop: "0",
    borderRadius: "3em",
    marginLeft:"2.5em",
    marginRight: "2.5em",
    padding: "1em",
  },  
  dialogWindowAux: {
    marginTop: "0%",
    
  },
  dialogTitle: {
    paddingTop: "2.5em",
    color: "#002453",
    width: "100%",
  },
  dialogTitleText: {
    margin: "auto",
    textAlign: "center",
  },
  dialogContainer: {
    padding: "1.5em 2em 1em 2em",
    width: "98%",
   
  },
  dialogClosebutton: {
    marginTop: "-0.7em",
    marginRight: "-0.15em",
    float: "right",
    color: "#666666",
  },
  dialogContentText: {
    color: "#666666",
  },
  dialogButtonContainer: {
    margin: "2em 0em 1em 0em",
  },
  dialogTextContainer:{
    margin: "0em 0em 2em 0em",
  },
  dialogButtonLeft: {
    float: "left",
    marginRight: "auto",
  },
  dialogButtonRight: {
    float: "right",
    marginLeft: "auto",
  },
  dialogButtonCenter: {
    margin: "auto",
  },
  dialogItems: {
    marginBottom: "1em",
  },

  
}));
