import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Card,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    Paper,
    IconButton,
} from "@material-ui/core";
import {
    GetSystemuser,
    GetMasterSystemUserApplicationsToSubscribe,
    PostSystemuserSubscribe,
    PutSystemuserUnsubscribe,
} from "../../services/ReasonService";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { SystemUserAdminStyles } from "./SystemUserAdminStyles";
import { TableStyles } from "../../commons/CommonStyles/TableStyles";
import { RolLevels, SubApp } from "../../commons/Enums";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useTranslation } from "react-i18next";
import { DialogCreate } from "./Components/DialogCreate";
import { PageHeader } from "../CommonComponents/PageHeader";
import { DialogEdit } from "./Components/DialogEdit";
import { DialogDelete } from "./Components/DialogDelete";
import { DialogChangeSecret } from "./Components/DIalogChangeSecret";
import { DialogChangeRelated } from "./Components/DialogChangeRelated";

export function SystemUserAdmin(props) {

    const { finishOperation, userGroupActions, handleSetAppsSelectOptions, handleShowAppsSelect, theme } = props;

    const { t, i18n } = useTranslation();
    const classes = SystemUserAdminStyles(theme);
    const tableClasses = TableStyles(theme);

    const [circularProgress, setCircularProgress] = useState(false);
    const [userOid, setUserOid] = useState("");
    const [userData, setUserData] = useState(null);
    const [allPossibleApps, setAllPossibleApps] = useState([]);
    const [searchSubCriteria, setSearchSubCriteria] = useState("");
    const [searchNoSubCriteria, setSearchNoSubCriteria] = useState("");
    const [circularProgressSubscribe, setCircularProgressSubscribe] = useState(false);
    const [circularProgressUnSubscribe, setCircularProgressUnSubscribe] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);

    const tableHeaders = [t("userSysAdminHeaderApp")];

    useEffect(() => {
        handleSetAppsSelectOptions(SubApp.CAU, RolLevels.N4);
        handleShowAppsSelect(false);
        //Llamada al back para obtener todas las aplicaciones
        GetMasterSystemUserApplicationsToSubscribe().then((response) => {
            if (response && !response.Status) {
                setAllPossibleApps(response);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
        });
    }, []);

    const handleCHange = (value) => {
        setUserOid(value);
        handleClearAll();
    };

    const handleClearAll = () => {
        setUserData(null);
        setSearchSubCriteria("");
        setSearchNoSubCriteria("");
    };

    const handleSearh = (createdUserOid) => {
        setCircularProgress(true);
        let auxUserOid = userOid;
        if (!checkIsNullUndefined(createdUserOid)) {
            setUserOid(createdUserOid);
            auxUserOid = createdUserOid;
        };
        //Llamada al back
        GetSystemuser(auxUserOid).then(
            (response) => {
                if (response && !response.Status) {
                    setUserData(response);
                    prepareAllApps(response.systemUserSubscriptions);
                } else {
                    finishOperation("error", getErrorMessage(response));
                };
                setCircularProgress(false);
            }
        );
    };

    const prepareAllApps = (systemUserSubscriptions) => {
        let auxSubsAppCodes = systemUserSubscriptions.map((app) => app.code);
        let auxAllPossibleApps = JSON.parse(JSON.stringify(allPossibleApps));
        auxAllPossibleApps.forEach((app) => {
            app.subscribed = auxSubsAppCodes.includes(app.code);
        });
        setAllPossibleApps(auxAllPossibleApps);
    };

    const getTableHeaders = () => {
        return (
            tableHeaders.map((header, index) => (
                <TableCell className={tableClasses.tableCellHeader} key={"header" + index}>
                    {t(header)}
                </TableCell>
            ))
        );
    };

    const handleSubscribe = (subscribed) => {
        let auxCircularProgressFunction = subscribed ? setCircularProgressUnSubscribe : setCircularProgressSubscribe;
        auxCircularProgressFunction(true);
        let auxSelectedApps = allPossibleApps.filter((app) => (app.subscribed == subscribed) && app.selected);
        let auxSelectedAppsCodes = auxSelectedApps.map((app) => app.code);
        let auxFunction = subscribed ? PutSystemuserUnsubscribe : PostSystemuserSubscribe;
        auxFunction(userOid, auxSelectedAppsCodes).then((response) => {
            if (response && !response.Status) {
                let auxAllPossibleApps = JSON.parse(JSON.stringify(allPossibleApps));
                let auxSelAppsCodes = auxSelectedApps.map((app) => app.code);
                auxAllPossibleApps.forEach((app) => {
                    if (auxSelAppsCodes.includes(app.code)) {
                        app.subscribed = !subscribed;
                        app.selected = false;
                    };
                });
                setAllPossibleApps(auxAllPossibleApps);
            } else {
                finishOperation("error", getErrorMessage(response));
            };
            auxCircularProgressFunction(false);
        });
    };

    const getApplicationsTable = (subscribed) => {
        return (
            <TableContainer component={Paper} key={"Table" + subscribed} className={tableClasses.customPaper}>
                <Table className={tableClasses.customTableRow}>
                    <TableHead className={tableClasses.stickyTableHead}>
                        <TableRow >
                            {getTableHeaders()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filterArrays(subscribed).map((app, index) => {
                            return (
                                <TableRow
                                    key={"endpoint" + index}
                                    className={tableClasses.tableRow}
                                    component="div"
                                    onClick={() => handleSelect(app)}
                                    selected={app.selected}
                                >
                                    <TableCell className={tableClasses.tableCell}>
                                        <Grid className={tableClasses.tableCellText}>{app.name}</Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const handleSelect = (app) => {
        let auxAllPossibleApps = JSON.parse(JSON.stringify(allPossibleApps));
        auxAllPossibleApps.forEach((auxApp) => {
            if (auxApp.code == app.code) {
                auxApp.selected = !auxApp.selected;
            };
        });
        setAllPossibleApps(auxAllPossibleApps);
    };

    const getSearcher = (subscribed) => {
        let auxValue = !subscribed ? searchNoSubCriteria : searchSubCriteria;
        let auxSetCriteria = !subscribed ? setSearchNoSubCriteria : setSearchSubCriteria;
        return (
            <Grid item xs={12} key={subscribed} style={{ marginBottom: "1em" }}>
                <TextField
                    type="text"
                    value={auxValue}
                    onChange={(e) => auxSetCriteria(e.target.value)}
                    label={t("appAdmSearchText")}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={{
                        style: { color: "#002453" }
                    }}
                    inputProps={{
                        style: { color: "#002453" }
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="clear"
                                onClick={() => auxSetCriteria("")}
                                edge="end"
                            >
                                <ClearIcon />
                            </IconButton>
                        )
                    }}
                />
            </Grid>
        );
    };

    const filterArrays = (subscribed) => {
        let auxSubsArray = allPossibleApps.filter((app) => app?.subscribed == subscribed);
        let auxCritera = subscribed ? searchSubCriteria : searchNoSubCriteria;
        let auxFilteredSubsApps = auxSubsArray.filter((app) => app?.name?.includes(auxCritera));
        return auxFilteredSubsApps;
    };

    const manageTables = (subscribed) => {
        let auxTitleText = subscribed ? t("userSysAdminSubscribedApps") : t("userSysAdminNoSubscribedApps");
        let auxEmptyText = subscribed ? t("userSysAdminSubscribedAppsEmpty") : t("userSysAdminNoSubscribedAppsEmpty");
        let auxAppsArray = allPossibleApps.filter((app) => app?.subscribed == subscribed);
        return (
            <Card style={{ width: "100%", padding: "1em" }}>
                <Grid item xs={12}>
                    <Typography
                        variant="h5"
                        className={tableClasses.titleTypo}
                        style={{ color: "#002453" }}
                    >
                        {auxTitleText}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {auxAppsArray.length == 0 ? (
                        <Typography
                            variant="h6"
                            className={tableClasses.hitText}
                            style={{ color: "#666666" }}
                        >
                            {auxEmptyText}
                        </Typography>
                    ) : (
                        <Grid container item xs={12}>
                            {getSearcher(subscribed)}
                            <Grid item xs={12}>
                                {getApplicationsTable(subscribed)}
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: "1em" }}>
                                {getButton(subscribed)}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Card>
        );
    };

    const getNameText = () => {
        let text = t("userSysAdminName") + ": ";
        let name = checkIsNullUndefined(userData.systemUserName) ? "-" : userData.systemUserName;
        return (
            <>
                <span style={{ color: "#002453" }}>{text}</span><span style={{ color: "#666666" }}>{name}</span>
            </>
        );
    };

    const getRelatedText = () => {
        let text = t("userSysAdminUserRelated") + ": ";
        let name = !checkIsNullUndefined(userData.userEmail) ? userData.userEmail :
            !checkIsNullUndefined(userData.userPhone) ? userData.userPhone : "-";
        return (
            <>
                <span style={{ color: "#002453" }}>{text}</span><span style={{ color: "#666666" }}>{name}</span>
            </>
        );
    };

    const getButton = (subscribed) => {
        let auxText = subscribed ? t("userSysAdminUnsubscribe") : t("userSysAdminSubscribe");
        let auxCircularProgress = subscribed ? circularProgressUnSubscribe : circularProgressSubscribe;
        let auxSelectedApps = allPossibleApps.filter((app) => (app.subscribed == subscribed) && app.selected);
        let auxDisabled = auxSelectedApps.length == 0;
        return (
            <CorreosButton
                variant="contained"
                color="primary"
                onClick={() => handleSubscribe(subscribed)}
                disabled={auxDisabled}
                circularProgress={auxCircularProgress}
            >
                {auxText}
            </CorreosButton>
        );
    };

    const handleRelatedChange = (userRelatedIdentifier) => {
        let auxUserData = JSON.parse(JSON.stringify(userData));
        auxUserData.userEmail = userRelatedIdentifier;
        setUserData(auxUserData);
    };

    return (
        userGroupActions.includes("CID") ?
            <Grid container item xs={12}>
                <PageHeader title={t("userSysAdminTitle")} text={t("userSysAdminSubtitle")} />
                <Grid container item xs={12} justifyContent="center">
                    <Grid container item xs={12}>
                        <Grid
                            container
                            item
                            justifyContent="center"
                            xs={12}
                        >
                            <Card style={{ width: "100%", padding: "2em", marginBottom: "2em" }}>
                                <Grid container item xs={12} spacing={1} style={{ margin: "auto" }}>
                                    <Grid item md={8} xs={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="idSearcher"
                                            label={t("userSysAdminSearcher")}
                                            name="idSearcher"
                                            color="secondary"
                                            required={true}
                                            onChange={(event) => handleCHange(event.target.value)}
                                            value={userOid}
                                        />
                                    </Grid>
                                    <Grid item md={2} xs={6} style={{ margin: 'auto' }}>
                                        <CorreosButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSearh()}
                                            disabled={checkIsNullUndefined(userOid)}
                                            circularProgress={circularProgress}
                                            style={{ float: "center", width: "100%" }}
                                        >
                                            <SearchIcon style={{ marginRight: "0.2em" }} />
                                            {t("search")}
                                        </CorreosButton>
                                    </Grid>
                                    <Grid item md={2} xs={6} style={{ margin: 'auto' }}>
                                        <CorreosButton
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setOpenCreate(true)}
                                            style={{ float: "center", width: "100%" }}
                                        >
                                            <AddBoxIcon style={{ marginRight: "0.2em" }} />
                                            {t("create")}
                                        </CorreosButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    {userData && !circularProgress ? (
                        <Grid container item xs={12}>
                            <Grid
                                container
                                item
                                justifyContent="center"
                                xs={12}
                            >
                                <Card style={{ width: "100%", padding: "1.5em 2em 1.5em 2em", marginBottom: "2em" }}>
                                    <Grid container item xs={12} spacing={1} style={{ margin: "auto" }}>
                                        <Grid item md={8} xs={12}>
                                            <Typography
                                                variant="h5"
                                            >
                                                {getRelatedText()}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={4} xs={12} style={{ margin: "auto" }}>
                                            <DialogChangeRelated
                                                systemUser={userData}
                                                handleRelatedChange={handleRelatedChange}
                                                finishOperation={finishOperation}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid
                                container
                                item
                                justifyContent="center"
                                xs={12}
                            >
                                <Card style={{ width: "100%", padding: "1.5em 2em 1.5em 2em", marginBottom: "2em" }}>
                                    <Grid container item xs={12} spacing={1} style={{ margin: "auto" }}>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h5"
                                            >
                                                {getNameText()}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={2} xs={12} style={{ margin: "auto" }}>
                                            <DialogEdit
                                                systemUserData={userData}
                                                setSystemUserData={setUserData}
                                                finishOperation={finishOperation}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12} style={{ margin: "auto" }}>
                                            <DialogChangeSecret
                                                systemUserData={userData}
                                                finishOperation={finishOperation}
                                            />
                                        </Grid>
                                        <Grid item md={2} xs={12} style={{ margin: "auto" }}>
                                            <DialogDelete
                                                systemUser={userData}
                                                handleCHange={handleCHange}
                                                finishOperation={finishOperation}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                            <Grid container item sm={6} xs={12} className={classes.customTableLeft} justifyContent="center">
                                {manageTables(false)}
                            </Grid>
                            <Grid container item sm={6} xs={12} className={classes.customTableRight} justifyContent="center">
                                {manageTables(true)}
                            </Grid>
                        </Grid>

                    ) : null}
                </Grid >
                <DialogCreate
                    openCreate={openCreate}
                    setOpenCreate={setOpenCreate}
                    finishOperation={finishOperation}
                    handleSearh={handleSearh}
                    allPossibleApps={allPossibleApps}
                />
            </Grid >
            : null
    );
}
