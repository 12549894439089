import React, { useEffect } from "react";
import { GetMasterCountry } from "../../services/ReasonService";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { EnumCountries } from "../../commons/Enums";
import { checkIsNullUndefined } from "../../services/Utilities";


export function PrefixPhone(props) {

  const { disabled, defaultCode } = props;
  const [countryList, setCountryList] = React.useState([]);
  const [countrySelected, setCountrySelected] = React.useState({id:""});

  useEffect(() => {
      GetMasterCountry().then((response) => {
        //comprobamos que no sea null o error
        if (response && !response.Status) {
          setCountryList(response);
          response.forEach((country) => {

            if(!checkIsNullUndefined(defaultCode)){
              if (country.phonePrefix == defaultCode) {
                setCountrySelected(country);
                props.handleSelectorChange(country);
              }
            }else{
              if (country.code === EnumCountries.ESP) {
                setCountrySelected(country);
                props.handleSelectorChange(country);
              }
            }
            
          });
        }
      });
  }, []);

  //controla el cambio en el selector de prefijo
  const handlePrefixChange = (event) => {
    countryList.forEach((country) => {
      if (country.id == event.target.value) {
        setCountrySelected(country);
        props.handleSelectorChange(country);
      }
    });
    };

  return (
    <FormControl variant="outlined" fullWidth color="secondary">
      <InputLabel htmlFor="phoneSelector" id="phoneSelector-label">
        {spanishLanguage.prefixSelector}
      </InputLabel>
      <Select
        native
        labelId="phoneSelector-label"
        id="phoneSelector"
        label={spanishLanguage.prefixSelector}
        value={countrySelected.id}
        onChange={handlePrefixChange}
        disabled ={disabled}
      >
        {countryList.map((country) => {
          return (
            <option key={country.code} value={country.id}>
              {country.code + " (+" + country.phonePrefix + ")"}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}
