import React, { useState, useEffect } from "react";
import { useTheme } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    CircularProgress,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    TableHead,
    FormControlLabel,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import {
    GetAllVerificationType,
    GetAllAttribute,
    PostUpdateAttributes,
    GetAllAttributesByAppCode,
} from "../../../services/ReasonService";
import { CorreosButton } from "../../CorreosButton/CorreosButton";
import { CommonStyles } from "../../../commons/CommonStyles.js";
import { ApplicationAdminStyles } from "../ApplicationAdminStyles";
import { checkIsNullUndefined, getErrorMessage } from "../../../services/Utilities";
import { useTranslation } from "react-i18next";
import { isResultOk, showAlert } from "../../../services/Utilities";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Searcher } from "../ApplicationAdminCC";

export function AttributeSelection(props) {

    const {
        applicationCode,
        finishOperation
    } = props;

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const commonClasses = CommonStyles(theme);
    const classes = ApplicationAdminStyles(theme);

    const attrInfo = { name: "nameAttribute", type: "personType" }
    const attrCheckName = { mandatory: "mandatory", registry: "mustRegistry" }
    const tableHeaders = [t("appAdmTableHeadName"), t("appAdmTableHeadMandatory"), t("appAdmTableHeadPersonType"), t("appAdmTableHeadVerification"), t("appAdmTableHeadRegistration"), t("action")]
    const includedAttributesOptions = ["all", "yes", "no"];

    const [circularProgressPage, setCircularProgressPage] = useState(false);
    const [circularProgressConfirmButton, setCircularProgressConfirmButton] = useState(false);
    const [allOriginAttributes, setAllOriginAttributes] = useState([]);
    const [allAttributes, setAllAttributes] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchCriteria, setSearchCriteria] = useState(attrInfo.name);
    const [verificationTypes, setVerificationTypes] = useState([]);
    const [includedAttributes, setIncludedAttributes] = useState(includedAttributesOptions[0]);

    useEffect(() => {
        if (applicationCode) {
            setCircularProgressPage(true);
            fetchData();
        }
    }, [applicationCode]);

    useEffect(() => {
        if (allAttributes.length > 0) {
            setCircularProgressPage(false);
        }
    }, [allAttributes]);

    const getAllOriginAttributes = () => {
        return allOriginAttributes;
    };

    const fetchData = async () => {
        const [appAttribute, verificationType, allAttribute] = await Promise.all([
            GetAllAttributesByAppCode(applicationCode),
            GetAllVerificationType(),
            allOriginAttributes.length == 0 ? GetAllAttribute() : getAllOriginAttributes(),
        ]);
        if (isResultOk([appAttribute, verificationType, allAttribute])) {
            let auxAllAttributes = [];
            let auxAllOriginAttributes = JSON.parse(JSON.stringify(allAttribute));
            allAttribute.forEach((attr) => {
                let auxAttr = appAttribute.find((auxAt) => auxAt.idMaeAttribute == attr.idMaeAttribute);
                if (!checkIsNullUndefined(auxAttr)) {
                    attr.idConTypeVerification = auxAttr.idConTypeVerification;
                    attr.mandatory = auxAttr.mandatory;
                    attr.mustRegistry = auxAttr.mustRegistry;
                    attr.included = true;
                } else {
                    attr.idConTypeVerification = "";
                    attr.mandatory = "";
                    attr.mustRegistry = "";
                    attr.included = false;
                }
                auxAllAttributes.push(attr);
            });
            setAllOriginAttributes(auxAllOriginAttributes);
            setAllAttributes(auxAllAttributes);
            setVerificationTypes(verificationType);

        } else {
            showAlert([appAttribute, verificationType, allAttribute], finishOperation);
        }
    };

    const resetAttribute = (attribute) => {
        attribute.idConTypeVerification = null;
        attribute.mandatory = false;
        attribute.mustRegistry = false;
    };

    const filterIncludedAttributes = (included) => {
        return allAttributes.filter((attribute) => attribute.included == included);
    }


    const filterAttributes = () => {
        let auxAttributes = includedAttributes == "yes" ? filterIncludedAttributes(true) : includedAttributes == "no" ? filterIncludedAttributes(false) : allAttributes;
        auxAttributes = auxAttributes.sort(function (a, b) {
            return a.idMaeAttribute - b.idMaeAttribute;
        });
        if (!checkIsNullUndefined(searchTerm)) {
            auxAttributes = auxAttributes.filter((attribute) => {
                let searchField =
                    searchCriteria === attrInfo.name
                        ? attribute.nameAttribute
                        : searchCriteria === attrInfo.type
                            ? attribute.personType
                            : "";

                return searchField.toLowerCase().includes(searchTerm.toLowerCase());
            });
        }
        return auxAttributes;
    };

    const handleSubmit = () => {
        setCircularProgressConfirmButton(true);
        let auxAppAttributes = allAttributes.filter((attribute) => attribute.included == true);
        PostUpdateAttributes(applicationCode, auxAppAttributes).then((response) => {
            if (response && !response.Status) {
                finishOperation("success", t("appAdmTableSuccess"));
            } else {
                finishOperation("error", getErrorMessage(response));
            }
            setCircularProgressConfirmButton(false);
        });
    };

    const handleCheckboxChange = (attribute, auxType) => {
        let auxAppAttributes = JSON.parse(JSON.stringify(allAttributes));
        let auxAttribute = auxAppAttributes.find((attr) => attr.idMaeAttribute == attribute.idMaeAttribute);
        auxAttribute[auxType] = !auxAttribute[auxType];
        setAllAttributes(auxAppAttributes);
    };

    const handleVerificationSelector = (event, attribute) => {
        let auxAppAttributes = JSON.parse(JSON.stringify(allAttributes));
        let auxAttribute = auxAppAttributes.find((attr) => attr.idMaeAttribute == attribute.idMaeAttribute);
        auxAttribute.idConTypeVerification = event.target.value;
        setAllAttributes(auxAppAttributes);
    }

    const verificationMapper = (idMaeAttribute) => {
        let auxArray = [{ id: 0, type: t("udelWithoutVerification") }];
        verificationTypes.forEach((obj) => {
            if (idMaeAttribute === obj.idMaeAttribute) {
                auxArray.push({ id: obj.idConTypeVerification, type: obj.typeVerificationName });
            }
        })
        return auxArray;
    }

    const getSearcher = () => {
        let auxSearchObj = {
            all: {
                textValue: searchTerm,
                textOnChange: setSearchTerm,
                criteriaValue: searchCriteria,
                criteriaOnChange: setSearchCriteria
            }
        };
        let auxEndpointInfo = [
            { value: attrInfo.name, text: t("appAdmTableHeadName") },
            { value: attrInfo.type, text: t("appAdmTableHeadPersonType") },
        ];
        return (
            <Searcher auxType={"all"} auxSearchObj={auxSearchObj} auxEndpointInfo={auxEndpointInfo} />
        );
    };

    const handleCheckboxIncludedAttribute = (option) => {
        setIncludedAttributes(option);
    };

    const mountCheckboxIncludedAttribute = () => {
        let auxText = { all: t("appAdmAttrTypeAll"), yes: t("appAdmAttrTypeIncl"), no: t("appAdmAttrTypeNotIncl") };
        return includedAttributesOptions.map((option) => <Grid item xs={4}><FormControlLabel
            control={
                <Checkbox
                    checked={option == includedAttributes}
                    onChange={() => handleCheckboxIncludedAttribute(option)}
                />
            }
            label={auxText[option]}
        /></Grid>);
    };

    const mountVerificationSelector = (attribute) => {
        let auxVerificationList = verificationMapper(attribute?.idMaeAttribute);
        let auxReturn = <Typography>{"N/A"}</Typography>
        if (auxVerificationList.length > 1) {
            auxReturn = <FormControl>
                <Select
                    style={{ color: "#002453" }}
                    value={
                        !checkIsNullUndefined(attribute.idConTypeVerification)
                            ? attribute.idConTypeVerification
                            : 0
                    }
                    onChange={(event) => handleVerificationSelector(event, attribute)}
                    disabled={!attribute.included}
                >
                    {auxVerificationList.map((verifObject) => (
                        <MenuItem key={verifObject.id} value={verifObject.id}>
                            {verifObject.type}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        }
        return auxReturn;
    };

    const mountRegistryCheckbox = (attribute) => {
        const registryAttr = [2, 14]; //Email y teléfono los únicos campos para el registro.
        let auxReturn = <Typography>{"N/A"}</Typography>;
        if (registryAttr.includes(attribute.idMaeAttribute)) {
            auxReturn = <Checkbox
                checked={attribute.mustRegistry}
                onChange={() => handleCheckboxChange(attribute, attrCheckName.registry)}
                disabled={!attribute.included}
            />
        };
        return auxReturn;
    };

    const mountAddRemoveButton = (attribute) => {
        let auxReturn = <AddCircleOutlineIcon />;
        let auxClass = classes.addIcon;
        let auxTooltip = t("add");
        if (attribute.included) {
            auxReturn = <HighlightOffIcon />;
            auxClass = classes.deleteIcon;
            auxTooltip = t("takeOut");
        }
        return <Tooltip title={auxTooltip}>
            <IconButton
                edge="inherit"
                className={auxClass}
                color="inherit"
                aria-label="menu"
                onClick={() => handleSwitchAttribute(attribute)}
            >
                {auxReturn}
            </IconButton>
        </Tooltip>;
    };

    const handleSwitchAttribute = (attribute) => {
        let auxAllAttributes = JSON.parse(JSON.stringify(allAttributes));
        let auxAttribute = auxAllAttributes.find((attr) => attr.idMaeAttribute == attribute.idMaeAttribute);
        if (auxAttribute.included) {
            resetAttribute(auxAttribute);
        }
        auxAttribute.included = !auxAttribute.included;
        setAllAttributes(auxAllAttributes);
    };

    return (

        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="h5" className={classes.title}>
                    {t("appAdmAttrTitle")}
                </Typography>
            </Grid>
            {circularProgressPage ? (
                <Grid item xs={12}>
                    <CircularProgress style={{ marginTop: "2.5em", marginBottom: "2.5em" }} />
                </Grid>
            ) : (
                <Grid container item xs={12}>
                    {getSearcher()}
                    <Grid container item xs={12} style={{ marginBottom: "1em" }}>
                        {mountCheckboxIncludedAttribute()}
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper} className={classes.customPaper}>
                                <Table className={classes.customTableRow}>
                                    <TableHead className={classes.stickyTableHead}>
                                        <TableRow>
                                            {tableHeaders.map((header) =>
                                                <TableCell className={classes.tableCellHeader}>
                                                    {header}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filterAttributes().map((attribute, index) => (
                                            <TableRow
                                                key={`attribute-${index}`}
                                                className={classes.tableRow}
                                                component="div"
                                            >
                                                <TableCell className={classes.tableCell}>
                                                    <Grid className={classes.tableCellText}>{attribute.nameAttribute}</Grid>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Checkbox
                                                        checked={attribute.mandatory}
                                                        onChange={() => handleCheckboxChange(attribute, attrCheckName.mandatory)}
                                                        disabled={!attribute.included}
                                                    />
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    <Grid className={classes.tableCellText}>{attribute.personType}</Grid>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {mountVerificationSelector(attribute)}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {mountRegistryCheckbox(attribute)}
                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {mountAddRemoveButton(attribute)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "2.5em", marginBottom: "2.5em" }}>
                        <CorreosButton
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            circularProgress={circularProgressConfirmButton}
                        >
                            {t("confirm")}
                        </CorreosButton>
                    </Grid>
                </Grid>
            )}
        </Grid >
    );
}