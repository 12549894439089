export const Attributes = {
    UserPersonaTipo: "UserPersonaTipo",
    UserEmail: "email",
    PersonaNombre: "name",
    PersonaApellido: "surname1",
    PersonaSegundoApellido: "surname2",
    PersonaTipoDocID: "idTypeUserDocument",
    PersonaPaisExpedicionDocID: "idCountryID",
    PersonaNumDocID: "number",
    PersonaFechaCadDocID: "expirationDate",
    PersonaFechaNacimiento: "birthDate",
    PersonaNacionalidad: "idCountry",
    PersonaGenero: "idGender",
    PersonaPrefijo: "idCountryPhone",
    PersonaTelfMovil: "phone",
    PersonaDireccion: "address",
    DirecTipoVia: "idRoadType",
    DirecDireccion: "address",
    DirecNumero: "address1",
    DirecPoblacion: "city",
    DirecCP: "idPostalCode",
    DirecProvincia: "idProvince",
    DirecPais: "idCountry",
    DirecBTipoVia: "idRoadType",
    DirecBDirecFact: "address",
    DirecBNumero: "address1",
    DirecBPoblacion: "DirecBPoblacion",
    DirecBCP: "idPostalCode",
    DirecBProvincia: "idProvince",
    DirecBPais: "idCountry",
    EmpresaNombre: "EmpresaNombre",
    EmpresaPrefijo: "EmpresaPrefijo",
    EmpresaTelefono: "EmpresaTelefono",
    EmpresaTipo: "EmpresaTipo",
    EmpresaCategoria: "EmpresaCategoria",
    EmpresaNombreLegal: "EmpresaNombreLegal",
    EmpresaSubcategoria: "EmpresaSubcategoria",
    EmpresaProductos: "EmpresaProductos",
    EmpresaBanco: "EmpresaBanco",
    EmpresaBancoTitular: "EmpresaBancoTitular",
    CorreosPrepagoCodigoAlta: "CorreosPrepagoCodigoAlta",
    CorreosPrepagoIdentificadorTarjeta: "CorreosPrepagoIdentificadorTarjeta",
    CorreosPrepagoDNI: "CorreosPrepagoDNI",
    DirecDirPostal: "address",
    DirecBDirPostal: "address",
    DirectDirApartPostal: "address1",
    DirectBDirApartPostal: "address1",
    DirecComment: "DirecComment",
    ConstitutedEnterprise: "ConstitutedEnterprise",
    DocumentEnterprise: "DocumentEnterprise",
    LlamadaSolti: "LlamadaSolti",
    AliasAttribute: "alias",
    BPGestor: "BPGestor",
    Documents: "Documents",
    NroDocument:"Nro documento",
    PlaneUserAttributes: ["NOM", "SUR", "SU2", "FNA", "NAC", "GEN", "LAN"],
    PlaneEnterpriseAttributes: ["EDN", "ENM", "ECN", "EPA", "ECY", "EDE"],
    EMA: {position: 10, attributeName: "email", size: 6},//Email
    PRE: {position: 20, attributeName: "idCountry", size: 2},//Prefijo
    MOV: {position: 30, attributeName: "phone", size: 4},//Teléfono
    NOM: {position: 40, attributeName: "name", size: 12},//Nombre
    SUR: {position: 50, attributeName: "surname1", size: 6},//Apellido1
    SU2: {position: 60, attributeName: "surname2", size: 6},//Apellido2
    FNA: {position: 70, attributeName: "birthDate", size: 6},//fecha nacimiento
    NAC: {position: 80, attributeName: "nationality", size: 6},//Nacionalidad
    TDO: {position: 90, attributeName: "idTypeUserDocument", size: 3},//Tipo de documento
    NDO: {position: 100, attributeName: "number", size: 3},//Nro documento
    PEX: {position: 110, attributeName: "idCountry", size: 3},//País de expedición
    FCA: {position: 120, attributeName: "expirationDate", size: 3},//Fecha de caducidad
    GEN: {position: 130, attributeName: "idGender", size: 6},//Sexo
    VIA: {position: 190, attributeName: "roadTypeCode", size: 3},//Via
    DIR: {position: 200, attributeName: "address", size: 9},//Dirección
    POB: {position: 170, attributeName: "cityCode", size: 4},//Población
    CPO: {position: 180, attributeName: "postalCode", size: 4},//Código postal
    PRO: {position: 160, attributeName: "provinceCode", size: 4},//Provincia/Región
    PAI: {position: 150, attributeName: "countryCode", size: 6},//País o región
    AD1: {position: 210, attributeName: "address1", size: 12},//Dirección 1
    ALI: {position: 140, attributeName: "alias", size: 6},//Alias
    CMM: {position: 220, attributeName: "comment", size: 12},//Observaciones
    LAN: {position: 230, attributeName: "idLanguage", size: 6},//Observaciones
    
    EDN: {position: 520, attributeName: "documentNumber", size: 3},//Empresa Nro de documento
    ENM: {position: 500, attributeName: "companyName", size: 6},//Empresa Nombre
    ECN: {position: 510, attributeName: "tradeName", size: 6},//Empresa Nombre comercial
    ECY: {position: 540, attributeName: "idCountry", size: 6},//Empresa País
    EDE: {position: 530, attributeName: "expirationDate", size: 3},//Empresa Fecha de caducidad
    EPA: {position: 550, attributeName: "idProfessionalActivity", size: 12},//Empresa Actividad profesional
    ECI: {position: 560, attributeName: null, size: 6},//Empresa Comercial
    EVI: {position: 620, attributeName: "roadTypeCode", size: 3},//Empresa Tipo de dirección
    EDR: {position: 630, attributeName: "address", size: 9},//Empresa Dirección
    EPO: {position: 600, attributeName: "cityCode", size: 4},//Empresa Población
    ECP: {position: 610, attributeName: "postalCode", size: 4},//Empresa Código postal
    EPR: {position: 590, attributeName: "provinceCode", size: 4},//Empresa Provincia/Región
    EPI: {position: 580, attributeName: "countryCode", size: 6},//Empresa País o región
    ED1: {position: 640, attributeName: "address1", size: 12},//Empresa Dirección 1
    EAL: {position: 570, attributeName: "alias", size: 6},//Empresa Alias
    ECM: {position: 650, attributeName: "comment", size: 6},//Empresa Observaciones
};
