import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from "@material-ui/core";
import { PostLogin, GetProfileUser } from "../../services/ReasonService";
import {
  setUserSession,
  setUserCookies,
  goToMain,
} from "../../services/Commons";
import ico_pass_on from "../../assets/images/ico_pass_on.svg";
import ico_pass_off from "../../assets/images/ico_pass_off.svg";
import { spanishLanguage } from "../../config/language/spanishLanguage";
import { LogInStyles } from "./LogInStyles";
import { CorreosButton } from "../CorreosButton/CorreosButton";
import { checkIsNullUndefined, getErrorMessage } from "../../services/Utilities";
import { PrefixPhone } from "../PrefixPhone/PrefixPhone";
import { CommonStyles } from "../../commons/CommonStyles";
import { useHistory } from "react-router-dom";

export function LogIn(props) {
  const { finishOperation } = props;
  const commonClasses = CommonStyles();
  const classes = LogInStyles();
  const history = useHistory();
  const cookies = new Cookies();

  const [username, setUsername] = React.useState("");
  const [userCompleteName, setUserCompleteName] = React.useState("");
  const [showUsername, setShowUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [prefixPhone, setPrefixPhone] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [errorUser, setErrorUser] = React.useState(false);
  const [errorMsgUser, setErrorMsgUser] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPhoneSelector, setShowPhoneSelector] = React.useState(false);
  const [showIdentityInput, setShowIdentityInput] = React.useState(true);
  const [showIdentityAvatar, setShowIdentityAvatar] = React.useState(false);
  const [showNextButton, setShowNextButton] = React.useState(true);
  const [progressBar, setProgressBar] = React.useState(false);

  useEffect(() => {
    setCookies();
  }, []);

  const setCookies = () => {
    if (cookies.get("userKyIdSignIn") != null) {
      let userCorreosIdSignIn = cookies.get("userKyIdSignIn");
      setUsername(userCorreosIdSignIn.username);
      setRememberMe(userCorreosIdSignIn.rememberMe);
      setUserCompleteName(userCorreosIdSignIn.userCompleteName);
      setShowPhoneSelector(userCorreosIdSignIn.showPhoneSelector);
    }
  };

  //inicializa los estados para que vuelva a aparecer
  //el input de indetidad de correos y desaparezca el avatar.
  const inicializeInputStates = () => {
    setShowIdentityInput(true);
    setShowIdentityAvatar(false);
    setShowNextButton(true);
    setError(false);
  };

  const generateCookie = (user) => {
    //usuario de isam
    if (
      (user.startsWith("E") || user.startsWith("C") || user.startsWith("P")) &&
      !user.includes("@") &&
      user.length == 7
    ) {
      //guardamos los datos que recibimos en un json para guardarlo en la cookie de inicio de sesión.
      let userKyIdSignIn = {
        username: username,
        showPhoneSelector: showPhoneSelector,
        prefixPhone: prefixPhone,
        rememberMe: rememberMe,
        userCompleteName: username,
      };

      //ponemos el tiempo de expiración
      let expiration = new Date();
      expiration.setDate(expiration.getDate() + 365);

      //creamos la cookie con una fecha de expiracion de 1 año
      cookies.set("userKyIdSignIn", userKyIdSignIn, {
        path: "/",
        expires: expiration,
      });
    }
    //usuario de correosId backOffice
    else {
      //obtenemos el nombre y apellidos del usuario
      GetProfileUser().then((response) => {
        if (response && !response.Status) {
          //guardamos los datos que recibimos en un json para guardarlo en la cookie de inicio de sesión.
          let userKyIdSignIn = {
            username: username,
            showPhoneSelector: showPhoneSelector,
            // selectorValue: selectorValue,
            prefixPhone: prefixPhone,
            rememberMe: rememberMe,
            userCompleteName:
              response.name +
              " " +
              response.surname1 +
              (checkIsNullUndefined(response.surname2)
                ? ""
                : " " + response.surname2),
          };
          //ponemos el tiempo de expiración
          let expiration = new Date();
          expiration.setDate(expiration.getDate() + 365);

          //creamos la cookie con una fecha de expiracion de 1 año
          cookies.set("userKyIdSignIn", userKyIdSignIn, {
            path: "/",
            expires: expiration,
          });
        }else{
          finishOperation("error", getErrorMessage(response));
        }
      });
    }
  };

  //borra la cookie de userKyIdSignIn del navegador
  const deleteCookie = () => {
    cookies.remove("userKyIdSignIn");
  };

  //inicia la sesión del usuario si todo va bien o activa los errores
  const logIn = () => {
    let errorMsgResponse = "";
    setError(false);
    setProgressBar(true);
    let user = username;
    if (showPhoneSelector) {
      user = prefixPhone + "-" + user;
    }

    PostLogin(user, password).then((response) => {
      if (response && !response.Status) {
        setUserSession(response).then(() => {
          setUserCookies(user, rememberMe, generateCookie);
          goToMain(history);
        });
      } else {
        setError(true);
        setProgressBar(false);
        setErrorMsg(getErrorMessage(response));
      }
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //controla el cambio en el selector de prefijo
  const handleSelectorChange = (prefix) => {
    setPrefixPhone(prefix.phonePrefix);
  };

  //cambia los estados para mostrar el botón de iniciar sesión y el identificador de usuario visible.
  //al darle al botón siguiente
  const handleNextClick = (event) => {
    event.preventDefault();
    let namePrefix =
      username.length >= 5 ? username.slice(0, 5).toLowerCase() : "";
    let isEmail = username.indexOf("@");
    if (namePrefix === "scid-" && isEmail === -1) {
      setErrorUser(true);
      setErrorMsgUser(spanishLanguage.loginSystemUserError);
    } else {
      //activamos el subtitulo de usuario, cambiamos el botón y ponemos el nombre del usuario en el subtitulo
      setShowIdentityInput(false);
      setShowIdentityAvatar(true);
      setShowNextButton(false);
      setShowUsername(
        (showPhoneSelector ? "+" + prefixPhone + " " : "") + username
      );
    }
  };

  const handleChipClick = (event) => {
    inicializeInputStates();
    setPassword("");
  };

  //llama a la función de inicio de sesión al pulsar el botón iniciar sesión
  //llama a la función de inicio de sesión al pulsar el botón iniciar sesión
  const handleLogInClick = (event) => {
    event.preventDefault();
    logIn();
  };

  //realiza los pasos necesarios para iniciar sesión al darle a enter en el formulario
  const handleSubmit = (event) => {
    event.preventDefault();
    if (showIdentityInput) {
      handleNextClick();
    } else {
      logIn(event);
    }
  };

  const handleIdentityInputChange = (event) => {
    var reg = new RegExp("^\\d+$");
    if (reg.test(event.target.value)) {
      setShowPhoneSelector(true);
    } else {
      setShowPhoneSelector(false);
    }
    setErrorUser(false);
    setErrorMsgUser("");
    setUsername(event.target.value);
  };

  //controla el botón de recuerdame.
  const handleChangeRememberMe = () => {
    let chekRememberMe = !rememberMe;
    setRememberMe(chekRememberMe);
    if (!chekRememberMe) {
      deleteCookie();
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid className={classes.loginTitle}>
        {userCompleteName != "" ? (
          <Typography className={classes.loginUserCompleteName}>
            {spanishLanguage.welcomeBack + " " + userCompleteName}
          </Typography>
        ) : (
          ""
        )}
        <Grid className={classes.logInSubtitle}>
          {showIdentityAvatar ? (
            <Link
              onClick={handleChipClick}
              color="secondary"
              className={classes.loginUserNameTypography}
            >
              {showUsername}
            </Link>
          ) : null}
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        {showIdentityInput ? (
          <Grid item xs={12} className={commonClasses.gridIdentity}>
            {showPhoneSelector ? (
              <Grid item sm={4} xs={12} className={commonClasses.gridIdentity}>
                <PrefixPhone handleSelectorChange={handleSelectorChange} />
              </Grid>
            ) : null}
            <Grid
              item
              sm={showPhoneSelector ? 8 : 12}
              xs={12}
              className={commonClasses.gridIdentity}
              style={{ paddingLeft: showPhoneSelector ? "0.5em" : "0em" }}
            >
              <TextField
                value={username}
                onInput={handleIdentityInputChange}
                variant="outlined"
                fullWidth
                id="username"
                label={spanishLanguage.correosIdentityLabel}
                name="username"
                autoFocus
                color="secondary"
                error={errorUser}
                helperText={errorUser ? errorMsgUser : ""}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} className={commonClasses.gridIdentity}>
            <TextField
              color="secondary"
              value={password}
              onInput={(e) => setPassword(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              autoFocus
              label={spanishLanguage.correosPasswordLabel}
              type={showPassword ? "text" : "password"}
              id="password"
              error={error}
              helperText={error ? errorMsg : ""}
              InputProps={{
                className: classes.underline,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <img src={ico_pass_on} alt="pass_on" />
                      ) : (
                        <img src={ico_pass_off} alt="pass_off" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {/*CheckBox RememberMe*/}
        <Grid item xs={12} className={commonClasses.gridIdentity}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleChangeRememberMe}
                  name="checkedA"
                />
              }
              label={spanishLanguage.rememberme}
              classes={{ label: classes.loginRememberMeLabel }}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid></Grid>
      <Grid container item xs={12} className={commonClasses.divButtons}>
        {showNextButton ? (
          <Grid item xs={12}>
            <CorreosButton
              className={commonClasses.rightButton}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleNextClick}
              disabled={checkIsNullUndefined(username)}
            >
              {spanishLanguage.nextButton}
            </CorreosButton>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CorreosButton
              className={commonClasses.rightButton}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleLogInClick}
              circularProgress={progressBar}
              disabled={checkIsNullUndefined(password)}
            >
              {spanishLanguage.logInButton}
            </CorreosButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
